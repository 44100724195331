export interface APIList {
    path: string,
    method: HttpMethod
}

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

export const BASE_URL: { [key: string]: string } = {
    USER: import.meta.env.REACT_APP_USER_BASE_URL,
    DATA: import.meta.env.REACT_APP_DATA_BASE_URL,
    DASHBOARD: import.meta.env.REACT_APP_DASHBOARD_BASE_URL,
    DRIVE_WEALTH: import.meta.env.REACT_APP_DRIVE_WEALTH_BASE_URL,
    BASKET: import.meta.env.REACT_APP_BASKET_BASE_URL,
    GIFT: import.meta.env.REACT_APP_GIFT_BASE_URL,
    GIRAFFE_LEARN: import.meta.env.REACT_APP_GIRAFFE_LEARN,
    PLAID: import.meta.env.REACT_APP_PLAID_BASE_URL,
};



export const USER_SERVICE: { [key: string]: APIList } = {
    ACCESS_TOKEN: {
        path: '/user/access-token',
        method: HttpMethod.GET
    },
    EMAIL_SUBSCRIPTION_DETAIL: {
        path: '/any/email-subscription-detail',
        method: HttpMethod.GET
    },
    UPDATE_EMAIL_SUBSCRIPTION: {
        path: '/any/update-email-subscription',
        method: HttpMethod.PUT
    },
    IS_BANK_ACCOUNT_LINKED: {
        path: '/user/userbankaccount/is-bank-account-linked',
        method: HttpMethod.GET
    },
    USER_DETAILS: {
        path: '/user/detail',
        method: HttpMethod.GET
    },
    ONBOARDING_STATIC_DATA: {
        path: '/user/onboarding-static-data',
        method: HttpMethod.POST
    },
    SEND_OTP: {
        path: '/user/send-otp',
        method: HttpMethod.POST
    },
    VERIFY_OTP: {
        path: '/user/validate-otp',
        method: HttpMethod.POST
    },
    USER_DATA_UPDATE: {
        path: '/user/update',
        method: HttpMethod.PUT
    },
    RESEND_EMAIL: {
        path: '/user/auth0/resend-email',
        method: HttpMethod.GET
    },
    BANK_UNLINK: {
        path: '/user/userbankaccount/unlink',
        method: HttpMethod.GET
    },
    BANK_LIST: {
        path: '/user/userbankaccount/all-by-access-token',
        method: HttpMethod.GET
    },
    UPDATE_EMAIL_NOTIFICATION: {
        path: '/user/update-email-notification',
        method: HttpMethod.PUT
    },
    CONTACT_US_URL: {
        path: '/user/contact-us',
        method: HttpMethod.POST
    },
    SEND_WORK_EMAIL_CODE: {
        path: '/user/send-work-email-code',
        method: HttpMethod.POST
    },
    VERIFY_WORK_EMAIL_CODE: {
        path: '/user/verify-work-email-code',
        method: HttpMethod.POST
    },
    USER_MONTHLY_SAVING_ADD: {
        path: '/user/usermonthlysaving/add',
        method: HttpMethod.POST
    },
    USER_BASIC_DETAIL: {
        path: '/user/basic-detail',
        method: HttpMethod.GET
    },
    USER_MONTHLY_SAVING_EDIT: {
        path: '/user/usermonthlysaving/update',
        method: HttpMethod.POST
    },
    USER_MONTHLY_SAVING_UPDATE_BASKET: {
        path: '/user/usermonthlysaving/update-basket',
        method: HttpMethod.POST
    },
    MONTHLY_SAVING_AGREEMENT: {
        path: '/user/usermonthlysaving/monthly-saving-agreement',
        method: HttpMethod.POST
    },
    REPORTS_MAILING_CHOICE: {
        path: '/user/update-reports-mailing-choice',
        method: HttpMethod.PUT
    },
    KYC_REJECTED_DETAIL: {
        path: '/user/kyc-rejected-detail',
        method: HttpMethod.GET
    },
    UPDATE_LOCALE: {
        path: '/user/update-locale',
        method: HttpMethod.GET
    }
}

export const DATA_SERVICE: { [key: string]: APIList } = {
    COUNTRY_IDENTIFIER: {
        path: '/user/country/identifier',
        method: HttpMethod.GET
    },
    BANK_ALL_LOCALIZED: {
        path: '/user/bank/all-localized',
        method: HttpMethod.POST
    },
}

export const DASHBOARD_SERVICE: { [key: string]: APIList } = {
    BASKET_BASIC_DETAILS: {
        path: '/user/basket-basic-details',
        method: HttpMethod.GET
    },
    PORTFOLIO_SUMMARY: {
        path: '/user/portfolio-summary',
        method: HttpMethod.POST
    },
    FUND_LOCATION: {
        path: '/user/fund-locations',
        method: HttpMethod.GET
    },
    IMPACT_DETAIL: {
        path: '/user/impact-detail',
        method: HttpMethod.POST
    },
    ACCOUNT_PERFORMANCE: {
        path: '/user/account-performance',
        method: HttpMethod.POST
    },
    IS_FUND_TRANSFER_PENDING: {
        path: '/user/is-fund-transfer-pending',
        method: HttpMethod.GET
    },
    FUND_TRANSFER: {
        path: '/user/transfer-fund',
        method: HttpMethod.POST
    },
    GET_GIFT_DETAIL: {
        path: '/user/gift-detail',
        method: HttpMethod.POST
    },
    ACCOUNT_FEES: {
        path: '/user/account-fees',
        method: HttpMethod.POST
    },
    POST_DIVIDENDS: {
        path: '/user/dividends',
        method: HttpMethod.POST
    },
    INVESTING_HISTORY: {
        path: '/user/investing-history',
        method: HttpMethod.POST
    },
    POST_TRANSACTION: {
        path: '/user/transactions',
        method: HttpMethod.POST
    },
    WITHDRAW_MONEY: {
        path: '/user/withdraw-money',
        method: HttpMethod.POST
    },
}

export const DRIVE_WEALTH_SERVICE: { [key: string]: APIList } = {
    DISCLOSURE_URL: {
        path: "/user/user/disclosure-url",
        method: HttpMethod.POST
    },
    STATEMENT_URL: {
        path: "/user/statements/statement-by-user",
        method: HttpMethod.POST
    },
    TAX_STATEMENT: {
        path: "/user/statements/tax-statement-by-user",
        method: HttpMethod.GET
    },
    TRADE_CONFIRMATION: {
        path: "/user/statements/trade-confirmation-by-user",
        method: HttpMethod.POST
    },
    PDF_DOWNLOAD: {
        path: "/user/statements/file-url-by-user",
        method: HttpMethod.GET
    }
}

export const BASKET_SERVICE: { [key: string]: APIList } = {
    BASKET_ORDER_PENDING: {
        path: '/user/basket/basket-order-pending',
        method: HttpMethod.GET
    },
    RENAME_BASKET: {
        path: '/user/basket/rename',
        method: HttpMethod.POST
    },
    PLACE_BASKET_CASH_OUT: {
        path: '/user/basket/place-basket-cash-out-order',
        method: HttpMethod.GET
    },
    BASKET_DETAIL: {
        path: '/user/basket/detail',
        method: HttpMethod.POST
    },
    BASKET_PERFORMANCE_HISTORICAL: {
        path: '/user/basket/basket-performance-historical',
        method: HttpMethod.POST
    },
    BASKET_FUTURE_PROJECTION: {
        path: '/user/basket/basket-performance-projection',
        method: HttpMethod.POST
    },
    BASKET_DELETE: {
        path: '/user/basket/delete-basket',
        method: HttpMethod.POST
    },
    PORTFOLIO_UPDATE: {
        path: '/user/basket/update',
        method: HttpMethod.PUT
    },
    CUSTOMIZATION_DETAIL: {
        path: '/user/basket/customization-detail',
        method: HttpMethod.POST
    },
    USER_EXCLUSION_COMPANIES: {
        path: '/user/basket/user-exclusion-companies',
        method: HttpMethod.POST
    },
    BASKET_PERFORMANCE_COMPARISON: {
        path: '/user/basket/basket-performance-comparison',
        method: HttpMethod.POST
    },
    SP500_RETURN_ON_INVESTMENT: {
        path: '/user/basket/sp500-return-on-investment',
        method: HttpMethod.GET
    },
    PORTFOLIO_BASIC_DETAILS: {
        path: '/user/basket/basic-detail',
        method: HttpMethod.POST
    },
    CREATE_PORTFOLIO: {
        path: '/user/basket/create',
        method: HttpMethod.POST
    },
    PORTFOLIO_BLUEPRINT: {
        path: '/user/basket/blueprint',
        method: HttpMethod.POST
    },
    REDEEM_GIFT: {
        path: '/user/basket/redeem-gift',
        method: HttpMethod.POST
    },
}

export const GIFT_SERVICE: { [key: string]: APIList } = {
    UPDATE_GIFT_USER: {
        path: '/user/giftaccessuser/update-gift-user',
        method: HttpMethod.POST
    },
    GIFT_USER_BY_ACCESS_TOKEN: {
        path: '/user/giftaccessuser/gift-user-by-access-token',
        method: HttpMethod.GET
    },
    GIFT_RECEIVED: {
        path: '/user/giftaccessuser/gift-received',
        method: HttpMethod.POST
    },
    REDEEM_GIFT_CODE: {
        path: '/user/giftaccessuser/can-redeem-gift-code',
        method: HttpMethod.GET
    }
}

export const LEARN_SERVICE: { [key: string]: APIList } = {
    LEARN_ANY_CONTENT: {
        path: '/any/any-content',
        method: HttpMethod.POST
    },
    LEARN_USER_VIDEO: {
        path: '/user/user-video',
        method: HttpMethod.PUT
    },
    USER_CONTENT: {
        path: '/user/user-content',
        method: HttpMethod.POST
    },
    USER_CALENDAR: {
        path: '/user/user-calendar',
        method: HttpMethod.POST
    },
}

export const PLAID_SERVICE: { [key: string]: APIList } = {
    CREATE_TOKEN: {
        path: '/user/create-token',
        method: HttpMethod.POST
    },
    GENERATE_PROCESSOR_TOKEN: {
        path: '/user/generate-processor-token',
        method: HttpMethod.POST
    },
}