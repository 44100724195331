// default library
import * as React from 'react';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// custom component
import SvgIcon from 'components/CommonComponent/SvgIcon';
// custom function
import { getUserName } from 'service/utils/UtilFunctions';

const DashboardProfile: React.FC = () => {

    const { user } = useAuth0();
    const locale = useSelector(getLocale);

    const localName = getUserName(locale.language);
    const userName = localName.userName ? localName.userName : user?.email

    return (
        <a >

            <span className="gi-g-profile-name">
                {userName}
            </span>
            <SvgIcon iconId='person-green-icon' width={34} height={30} />
        </a>
    )
}

export default DashboardProfile