//default library
import * as React from 'react';
//bootstrap library
import Modal from "react-bootstrap/Modal";
//auth0
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom component
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import LoadingComponent3 from 'components/LoadingComponent/LoadingComponent3';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import InputComponentCheckbox2 from 'components/InputComponent/InputComponentCheckbox2';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
// api call
import * as DriveWealthService from 'middleware/DriveWealthService'
import * as UserService from "middleware/UserService"

//defining props
type Props = {
    propsData: PropsFunction.PropsPageDataFunction
}

const GiraffeTC: React.FC<Props> = (props: Props) => {
    const { propsData } = props;//destructuring props
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [isLoading, setIsLoading] = React.useState<boolean>(false); //initial api call
    const [isLoadingAPI, setIsLoadingAPI] = React.useState<boolean>(false); //submit api

    const [terms, setTerms] = React.useState<boolean>(false);//manage terms and conditions checkbox

    const [openGiraffePrivacy, setOpenGiraffePrivacy] = React.useState<boolean>(false);// state for Giraffe privacy modal
    const [submitError, setSubmitError] = React.useState<boolean>(false);
    const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//field details
    const [isRouteChange, setIsRouteChange] = React.useState<boolean>(false);//handle route change after successful form submission

    // URLs for various disclosures and agreements
    const [disclosureURL, setDisclosureURL] = React.useState<string>(''); //disclosure url
    const [privacyPolicyURL, setPrivacyPolicyURL] = React.useState<string>(''); //privacy policy url
    const [termAndConditionURL, setTermAndConditionURL] = React.useState<string>(''); //term and condition url
    const [openGiraffeURL, setOpenGiraffeURL] = React.useState<string>(''); //giraffe modal URL
    const [taxDisclosureText, setTaxDisclosureText] = React.useState<string>(''); //tax disclosure text based on citizenship 
    const [taxDisclosureURL, setTaxDisclosureURL] = React.useState<string>(''); //tax disclosure url
    const [openDriveWealthURL, setOpenDriveWealthURL] = React.useState<string>(''); //driveWealth modal URL
    const [openDriveWealthHeader, setOpenDriveWealthHeader] = React.useState<string[]>([]); //driveWealth modal header
    const [openDriveWealthModal, setOpenDriveWealthModal] = React.useState<boolean>(false); //driveWealth modal
    const [loadingIframe, setLoadingIframe] = React.useState<boolean>(true); //loader in iframe

    const nextPage = 'kyc-pending';
    const sendText = false;
    const submitText = true;
    const parentButton = true;
    const advisoryURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-advisory-agreement.pdf#toolbar=0`; //giraffe advisory agreement
    const formCRS = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-form-crs.pdf#toolbar=0`; //giraffe form CRS
    const formADV = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-adv-2a.pdf#toolbar=0`; //giraffe form ADV Part II

    //fetch and set disclosure URLs
    React.useEffect(() => {
        (async () => {
            propsData({
                previousPage: 'page13',
                pageNumber: 15,
            });

            setIsLoading(true);

            const accessToken = await initiateAction();
            const disclosureWealth = await DriveWealthService.getDisclosureUrl(accessToken);

            setIsLoading(false);

            if (disclosureWealth.response || disclosureWealth.request) {
                setErrorLog(disclosureWealth)
                setIsError(true)
            } else {
                setDisclosureURL(disclosureWealth.disclosureUrl);
                setPrivacyPolicyURL(disclosureWealth.privacyPolicyUrl);
                setTermAndConditionURL(disclosureWealth.termsOfUseUrl);
                setTaxDisclosureText(disclosureWealth.taxDisclosure);
                setTaxDisclosureURL(disclosureWealth.taxDisclosureUrl);

            }

        })()

    }, []);

    // Event handler for opening DriveWealth modal
    const handleOpenDriveWealthModal = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        const anchorElement = e.target as HTMLAnchorElement;
        const name = anchorElement.getAttribute('data-name')
        setOpenDriveWealthModal(true);
        setLoadingIframe(true);
        setOpenDriveWealthURL(anchorElement.id);
        name && setOpenDriveWealthHeader(name.split(','));
    };

    // Event handler for opening Giraffe modal
    const openGiraffeModal = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        const anchorElement = e.target as HTMLAnchorElement;
        const name = anchorElement.getAttribute('data-name')
        setOpenGiraffePrivacy(true);
        setOpenGiraffeURL(anchorElement.id);
        name && setOpenDriveWealthHeader(name.split(','))
    };

    // Event handler for closing modals
    const onHandleClose = (): void => {
        setOpenDriveWealthModal(false);
        setOpenGiraffePrivacy(false);
    };

    // Event handler for hiding iframe spinner
    const hideSpinner = (): void => {
        setLoadingIframe(false);
    };

    // Event handler for handling checkbox input
    const setInputValue = (fieldName: string, fieldValue: boolean): void => {
        fieldName === 'terms' && setTerms(fieldValue);
    };

    // Event handler for form submission
    const onHandleSubmit = async () => {
        setIsLoadingAPI(true);

        const accessToken = await initiateAction();

        const submitData = await UserService.userDataUpdate(user?.email ?? '', accessToken);

        setIsLoadingAPI(false);

        if (submitData.response || submitData.request) {
            setSubmitError(true)
        } else {
            setIsRouteChange(true)
        }
    };

    //update field details when terms change
    React.useEffect(() => {
        setFieldDetails({ terms });
    }, [terms])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoadingAPI && <LoadingComponent />}

            {isLoading
                ? <LoadingComponent />
                : <>
                    <div className="gi-ob-slide-card error-kyc">
                        {submitError && (
                            <ErrorKYC message={'error_update_call_error'} fieldName={'updateCallError'} />
                        )}
                        <div className="gi-ob-full-name-text gi-ob-finish-text">
                            <h5>{IntlMessages('giraffe_tc_text1')}</h5>
                        </div>
                        <div className="gi-ob-full-name-form gi-ob-finish-form">
                            <div className="gi-onboard-approaching-check">
                                <div className="gi-onboard-checkbox form-check">
                                    <InputComponentCheckbox2
                                        name={'terms'}
                                        check={terms}
                                        id={'terms'}
                                        inputValue={setInputValue}
                                        dataTestId="terms-checkbox"
                                    />
                                    <p>
                                        {IntlMessages('giraffe_tc_text2')} <a id={advisoryURL} onClick={openGiraffeModal} data-name={['giraffe_tc_text3']} data-testid=''>{IntlMessages('giraffe_tc_text3')}</a>
                                        {IntlMessages('giraffe_tc_text4')} <a onClick={handleOpenDriveWealthModal} id={disclosureURL} data-name={['complete_application_t8']}>{IntlMessages('giraffe_tc_text5')}</a>
                                        ,&nbsp;<a onClick={handleOpenDriveWealthModal} id={privacyPolicyURL} data-name={['privacy_policy']}>{IntlMessages('giraffe_tc_text6')}</a>
                                        ,&nbsp;<a onClick={handleOpenDriveWealthModal} id={termAndConditionURL} data-name={['t_and_c']}>{IntlMessages('giraffe_tc_text7')}</a>
                                        ,&nbsp;<a onClick={handleOpenDriveWealthModal} id={taxDisclosureURL} data-name={['complete_application_t7', taxDisclosureText]}>{taxDisclosureText} {IntlMessages('giraffe_tc_text8')}</a>
                                        , {IntlMessages('giraffe_tc_text9')} <a id={formCRS} onClick={openGiraffeModal} data-name={['giraffe_tc_text10']}>{IntlMessages('giraffe_tc_text10')}</a>
                                        ,&nbsp;<a id={formADV} onClick={openGiraffeModal} data-name={['giraffe_tc_text11']}>{IntlMessages('giraffe_tc_text11')}</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gi-ob-let-btn-group gi-ob-employment-btn-group">
                        <ButtonComponent
                            isFooter={false}
                            fieldDetails={fieldDetails}
                            nextRoute={nextPage}
                            sendText={sendText}
                            isRouteChange={isRouteChange}
                            submitText={submitText}
                            parentButton={parentButton}
                            onHandleParentClick={onHandleSubmit}
                        />
                    </div>

                    {/* footer button for mobile */}
                    <KYCFooterButton
                        letStartText={false}
                        fieldDetails={fieldDetails}
                        nextRoute={nextPage}
                        sendText={sendText}
                        isRouteChange={isRouteChange}
                        submitText={submitText}
                        parentButton={parentButton}
                        onHandleParentClick={onHandleSubmit}
                    />
                </>
            }

            {/* Drivewealth's modals */}
            <Modal
                centered
                show={openDriveWealthModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className="gi-kyc-info-modal"
            >
                <Modal.Header>
                    {openDriveWealthHeader[1] && openDriveWealthHeader[1]} {IntlMessages(openDriveWealthHeader[0])}
                    <button type="button" className="btn-close" onClick={onHandleClose} data-testid="close-driveWealth-modal"></button>
                </Modal.Header>
                <Modal.Body>
                    {loadingIframe && (
                        <div className='d-flex justify-content-center'>
                            <LoadingComponent3 />
                        </div>
                    )}
                    <iframe src={openDriveWealthURL} title="DriveWealth" className='modalIframe' onLoad={hideSpinner} data-id='hide-driveWealth-spinner' ></iframe>
                </Modal.Body>
            </Modal>

            {/* Giraffe modals */}
            <Modal
                centered
                show={openGiraffePrivacy}
                onHide={onHandleClose}
                backdrop="static" size="lg"
                keyboard={false} className="gi-kyc-info-modal">
                <Modal.Header>
                    {IntlMessages(openDriveWealthHeader[0])}
                    <button type="button" className="btn-close" onClick={onHandleClose} data-testid="close-giraffe-modal"></button>
                </Modal.Header>
                <Modal.Body className='giraffe-policy'>
                    {loadingIframe && (
                        <div className='d-flex justify-content-center'>
                            <LoadingComponent3 />
                        </div>
                    )}
                    <iframe src={openGiraffeURL} title="Giraffe Terms" className='modalIframe' onLoad={hideSpinner} data-testid='hide-giraffe-spinner' ></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GiraffeTC;













