//default library
import * as React from 'react'

// lottie animation
import lottie from 'lottie-web/build/player/lottie_light'

// loading animation
import LoadingAnimation from 'service/animation/Loading.json'


const animationStyle = {
    height: 295,
    width: 250,
    margin: '0 auto',
}

const LoadingComponent3: React.FC = () => {

    const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

    // load lottie animation
    React.useEffect(() => {
        lottie.loadAnimation({
            container: lottieAnimation.current as any,
            renderer: 'svg',
            animationData: LoadingAnimation,
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true if you want the animation to play automatically
        });
    }, [])

    return (
        <div ref={lottieAnimation} style={animationStyle} />
    )
}

export default LoadingComponent3