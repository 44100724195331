import UserService from 'api/APIServices/UserService';
// default value
import * as DefaultValue from 'config/DefaultValue';
// type import
import * as UserType from 'api/APIMaster/UserType';
import * as DefaultType from 'interface/DefaultValueType';
import { InitialState } from 'reduxStore/reducers/IsraelKYCReducer';
import * as OnBoarding from 'interface/OnBoarding';
// custom function
import * as Local from 'service/utils/LocalStorageData';
// helper function
import * as UserFormHelper from 'helper/UserFormDataHelper'

const User = new UserService();

/**
 * @description Return User on the basis of accessToken
 *
 * @name GetByAccessToken
 * @summary Get User by accessToken
 * @request GET:/user/access-token
 * @secure
 */
export const getByAccessToken = async (accessToken: string) => {

    return await User.getByAccessToken({ authToken: accessToken })
        .then(res => {
            return res.data
        })
        .catch(err => { return { ...err } })
}

/**
 * @description Get email subscription details
 *
 * @name EmailSubscriptionDetail
 * @summary Get email subscription details
 * @request GET:/any/email-subscription-detail/{emailIdentifier}
 * @secure
 */
export const emailSubscriptionDetail = async (emailIdentifier: string) => {
    return await User.emailSubscriptionDetail(emailIdentifier)
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Update email subscription
 *
 * @name UpdateEmailSubscription
 * @summary Update email subscription
 * @request PUT:/any/update-email-subscription
 * @secure
 */
export const updateEmailSubscription = async (data: UserType.EmailSubscriptionRequestModel) => {
    return await User.updateEmailSubscription(data)
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Return true if user bank account is linked else false
 *
 * @name IsBankAccountLinked
 * @summary Check if user bank account is linked
 * @request GET:/user/userbankaccount/is-bank-account-linked
 * @secure
 */
export const isBankAccountLinked = async (accessToken: string) => {

    return await User.isBankAccountLinked({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Return User Detail on the basis of access token
 *
 * @name GetDetail
 * @summary Get User Detail by access token
 * @request GET:/user/detail
 * @secure
 */
export const getDetail = async (accessToken: string) => {
    return await User.getDetail({ authToken: accessToken })
        .then(res => { return res.data })
        .catch(err => { return { ...err } })
}

/**
 * @description Get onboarding static data
 *
 * @name GetOnboardingStaticData
 * @summary Get onboarding static data
 * @request POST:/user/onboarding-static-data
 * @secure
 */
export const getOnboardingStaticData = async (accessToken: string) => {
    const data = {
        locale: Local.getCurrentLanguage()
    }

    return await User.getOnboardingStaticData(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Send otp to user phone
 *
 * @name SendOtp
 * @summary Send otp
 * @request POST:/user/send-otp
 * @secure
 */
export const sendOtp = async (phoneNumber: string, accessToken: string) => {
    const data = {
        phone: phoneNumber
    }

    return await User.sendOtp(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Returns boolean
 *
 * @name ValidateOtp
 * @summary Validate otp
 * @request POST:/user/validate-otp
 * @secure
 */
export const verifyOTP = async (otp: string, phoneNumber: string, accessToken: string) => {
    const data = {
        phone: phoneNumber,
        otp: otp
    }

    return await User.verifyOTP(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Returns updated User for US user
 *
 * @name UpdateUserData 
 * @summary Update User
 * @request PUT:/user/update
 * @secure
 */
export const userDataUpdate = async (emailId: string, accessToken: string) => {
    const localData = Local.getLocalData("onBoardingDetails");

    // set data for helper class
    const data = {
        ...localData,
        emailId: emailId,
        identifier: Local.getLocalData('identifier'),
        phone: `+1${localData.phoneNumber}`,
        language: 'en_US',
        taxPayer: localData.taxPayer === 'true' || localData.countryLabel === 'United States' ? true : false,
    }
    // get the form data from helper
    const formData = UserFormHelper.userFormDataEN(data)

    return await User.userDataUpdate(formData, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Returns updated User for IL user
 *
 * @name UpdateUserData 
 * @summary Update User
 * @request PUT:/user/update
 * @secure
 */
export const userDataUpdateIL = async (kycData: InitialState, accessToken: string) => {

    // set data for helper class
    const data = {
        ...kycData,
        identifier: Local.getLocalData('identifier'),
        language: 'en_US',
        directorOf: kycData.stockOwner === 'true' ? `${kycData.companyNameStock} ${kycData.tickerSymbol}` : null,
        politicallyExposedNames: kycData.publicOfficial === 'true' ? kycData.publicOfficialText : null,
    }

    // get the form data from helper
    const formData = UserFormHelper.userFormDataIL(data);

    return await User.userDataUpdate(formData, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description send the verify email from auth0
 *
 * @name ResendEmail 
 * @summary send email from auth0
 * @request GET:/user/auth0/resend-email
 * @secure
 */
export const resendEmail = async (accessToken: string) => {

    return await User.resendEmail({ authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Returns true if unlinked else false
 *
 * @name UnlinkBank
 * @summary Unlink UserBankAccount  by id
 * @request GET:/user/userbankaccount/unlink/{id}
 * @secure
 */
export const unlinkBank = async (id: number, accessToken: string) => {
    return await User.unlinkBank(id, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Return list of all UserBankAccount on the basis of access token
 *
 * @name GetAllBankList
 * @summary Get all UserBankAccount by access token
 * @request GET:/user/userbankaccount/all-by-access-token
 * @secure
 */
export const getAllBankList = async (accessToken: string) => {

    return await User.getAllBankList({ authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Update email notification
 *
 * @name UpdateEmailNotification
 * @summary Update email notification
 * @request PUT:/user/update-email-notification
 * @secure
 */
export const updateEmailNotification = async (emailNotification: OnBoarding.CheckBoxList, accessToken: string) => {

    const marketing = emailNotification.filter(e => e.name === 'emailSubscribeMarketing')[0];
    const learn = emailNotification.filter(e => e.name === 'emailSubscribeLearn')[0];

    const data = {
        marketing: marketing ? marketing.active : false,
        learn: learn ? learn.active : false
    }

    return await User.updateEmailNotification(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description update the user account setting page data
 *
 * @name UpdateUserData 
 * @summary Update User
 * @request PUT:/user/update
 * @secure
 */
export const userDataUpdateAC = async (formData: UserType.UserRequestModel, accessToken: string) => {

    return await User.userDataUpdate(formData, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Contact us
 *
 * @name ContactUsAPI 
 * @summary contact us page
 * @request POST:/user/contact-us
 * @secure
 */
export const contactUsAPI = async (contactUsData: UserType.ContactUsRequestModel, accessToken: string) => {

    return await User.contactUsAPI(contactUsData, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description update the user language and country
 *
 * @name UpdateUserData 
 * @summary Update User
 * @request PUT:/user/update
 * @secure
 */
export const updateUserLang = async (userLang: DefaultType.LocalData, email: string, apiLang: string, accessToken: string) => {

    const formData = UserFormHelper.userFormData(userLang, email, apiLang);

    return await User.userDataUpdate(formData, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Send work email verification code
 *
 * @name SendWorkEmailCode
 * @summary Send work email verification code
 * @request POST:/user/send-work-email-code
 * @secure
 */
export const sendWorkEmailCode = async (workEmail: string, accessToken: string) => {

    const data = {
        workEmail,
    }

    return await User.sendWorkEmailCode(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description Verify work email
 *
 * @name VerifyWorkEmail
 * @summary Verify work email code
 * @request POST:/user/verify-work-email-code
 * @secure
 */
export const verifyWorkEmail = async (workEmail: string, securityCode: string, accessToken: string) => {

    const data = {
        workEmail,
        securityCode
    }

    return await User.verifyWorkEmail(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description set-up user monthly saving amount
 *
 * @name AddMonthlySaving
 * @summary user monthly saving setup
 * @request POST:/user/usermonthlysaving/add
 * @secure
 */
export const addMonthlySaving = async (data: UserType.AddUserMonthlySavingRequestModal, accessToken: string) => {

    return await User.addMonthlySaving(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description user basic details 
 *
 * @name UserBasicDetails
 * @summary user basic details 
 * @request GET:/user/basic-detail
 * @secure
 */
export const userBasicDetails = async (accessToken: string) => {

    return await User.userBasicDetails({ authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description edit user monthly saving
 *
 * @name EditMonthlySaving
 * @summary user monthly saving setup edit
 * @request POST:/user/user-monthly-saving/edit
 * @secure
 */
export const editMonthlySaving = async (data: UserType.EditUserMonthlySavingRequestModal, accessToken: string) => {

    return await User.editMonthlySaving(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
* @description edit user monthly saving update
*
* @name UpdateBasketMonthlySaving
* @summary update user monthly saving setup portfolio
* @request POST:/user/usermonthlysaving/update-basket
* @secure
*/
export const updateBasketMonthlySaving = async (basketId: string, accessToken: string) => {

    const data = {
        basketId: Number(basketId),
    }

    return await User.updateBasketMonthlySaving(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description get user monthly saving agreement 
 *
 * @name MonthlySavingAgreement
 * @summary user monthly saving agreement 
 * @request POST:/user/usermonthlysaving/monthly-saving-agreement
 * @secure
 */
export const monthlySavingAgreement = async (amount: string, accessToken: string) => {

    const data = {
        savingsAmount: amount ? Number(amount) : null
    }

    return await User.monthlySavingAgreement(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description to update the the report mail option
 *
 * @name ReportsMailingChoice
 * @summary update the the report mail option
 * @request PUT:/user/update-reports-mailing-choice
 * @secure
 */
export const updateReportsMailingChoice = async (notificationObject: OnBoarding.CheckBoxList, accessToken: string) => {

    const reportsMailingChoice = notificationObject.filter(e => e.active).map(e => e.name).join(', ');

    const data = {
        reportsMailingChoice
    }

    return await User.reportsMailingChoice(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description when KYC is rejected and get the data  for resubmit data
 *
 * @name KYCRejectedDetails
 * @summary give the list of the invalid data
 * @request GET:/user/kyc-rejected-detail
 * @secure
 */
export const kycRejectedDetails = async (accessToken: string) => {

    await new Promise(resolve => setTimeout(resolve, 500));

    return {
        kycError: null
    }

    return await User.kycRejectedDetails({ authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

/**
 * @description update the language based on the user selection
 *
 * @name UploadLocale
 * @summary locale update
 * @request GET:/user/update-locale/{locale}
 * @secure
 */
export const uploadLocale = async (locale: string, accessToken: string) => {

    const language = DefaultValue.localeOptions.find(e => e.code === locale);
    const updatedLocale = language ? language.language : Local.getCurrentLanguage();

    return await User.uploadLocale(updatedLocale, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}