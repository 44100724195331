// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from 'api/APIMaster/UserType';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//custom component
import DetailButton from 'components/ButtonComponent/DetailButton';
import SvgIcon from 'components/CommonComponent/SvgIcon';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';

export type Props = {
    currentGift: DashboardType.BasketSummaryResponseModel,
    monthlySaving: UserType.MonthlySaving | null;
    openInfoModal: PropsFunction.OpenInfoModal,
    setBuyOpenModal: PropsFunction.ParamsIdNumberFunction,
    infoMonthly: PropsFunction.SimpleFunction;
}

const HomeGiftDetailsCard: React.FC<Props> = (props: Props) => {

    const { currentGift, monthlySaving, openInfoModal, setBuyOpenModal, infoMonthly } = props;
    const history = useHistory();
    const intl = useIntl();

    // earning information
    const clickEarning = (): void => {
        const textHeading = IntlMessages('info_earning_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_earning_body')}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    // portfolio value information
    const clickPortfolioValue = (): void => {
        const textHeading = IntlMessages('info_portfolio_basket_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_portfolio_basket_body')}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    const gotoBasket = (event: React.MouseEvent): void => {
        const id = (event.target as HTMLAnchorElement).id;
        history.push(`/dashboard/portfolio/${id}`)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-dash-ao-gift-card mt-md-2 mt-1">
                    {/* name, status, btn */}
                    <div className="gi-dash-ao-gift-header d-flex align-items-center px-lg-5 py-lg-2 px-3 py-2">
                        <div className="row gx-lg-0 w-100">
                            {/* name */}
                            <div className="col-lg-5 col-6">
                                <div className="gi-dash-ao-gift-heading d-flex align-items-center h-100">
                                    <h5>{currentGift.name}</h5>
                                </div>
                            </div>
                            {/* status, btn */}
                            <div className="col-lg-7 col-6">
                                <div className="row">
                                    {/* status */}
                                    <div className="col-lg-5">
                                        <div className="gi-dash-ao-gift-settlement d-flex align-items-center text-nowrap h-100">
                                            {currentGift.isPending && (
                                                <a className="d-flex align-items-center gap-2" id={currentGift.id.toString()} onClick={gotoBasket}>
                                                    <SvgIcon iconId='notification' width={15} height={17} />
                                                    {IntlMessages('transaction_in_settlement')}
                                                </a>
                                            )}
                                            {(monthlySaving && (currentGift.id === monthlySaving.basketId)) && (
                                                <a className="d-flex align-items-center gap-2 fst-normal" id={currentGift.id.toString()} onClick={infoMonthly}>
                                                    <SvgIcon iconId='user-icon' width={13} height={13} />
                                                    {IntlMessages('home_monthly_saving')}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    {/* btn */}
                                    <div className="col-lg-7">
                                        <div className="gi-dash-ao-gift-btn-group d-none d-lg-flex justify-content-end gap-2">
                                            <DetailButton redirectId={currentGift.id} isMobile={false} addFundButton={setBuyOpenModal} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* mobile btn, amount */}
                    <div className="gi-dash-ao-gift-body px-lg-5 px-3">
                        {/* mobile btn */}
                        <div className="gi-dash-ao-gift-btn-group d-flex gap-2 justify-content-end d-lg-none py-2">
                            <DetailButton redirectId={currentGift.id} isMobile={true} addFundButton={setBuyOpenModal} />
                        </div>
                        {/* gift image, amount */}
                        <div className="row">
                            {/* gift image */}
                            <div className="col-lg-5">
                                <div className="gi-dash-ao-gift-gift-img">
                                    <span>${currentGift.giftAmount}</span>
                                    <img src={intl.formatMessage({ id: 'GIFT_CARD_DASHBOARD' })} className="img-fluid d-none d-lg-block" alt="" />
                                    <img src={intl.formatMessage({ id: 'GIFT_CARD_DASHBOARD_XS' })} className="img-fluid  d-block d-lg-none" alt="" />
                                </div>
                            </div>
                            {/* amount */}
                            <div className="col-lg-7">
                                <div className="row h-100 align-items-center">
                                    {/* portfolio value */}
                                    <div className="col-6">
                                        <div className="gi-dash-ao-gift-value-text">
                                            <h6 className="d-flex align-items-center gap-2 pb-lg-3 pb-0">
                                                {IntlMessages('portfolio_value')}
                                                <a className="d-flex align-items-center" onClick={clickPortfolioValue}>
                                                    <SvgIcon iconId='info-icon' width={16} height={16} />
                                                </a>
                                            </h6>
                                            <h5 className="pt-lg-2 pt-1 ltr">{currencyFormatter(currentGift.currentValue)}</h5>
                                        </div>
                                    </div>
                                    {/* earning */}
                                    <div className="col-6">
                                        <div className="gi-dash-ao-gift-value-text">
                                            <h6 className="d-flex align-items-center gap-2 pb-lg-3 pb-0">
                                                {IntlMessages('transaction_earning')}
                                                <a className="d-flex align-items-center" onClick={clickEarning}>
                                                    <SvgIcon iconId='info-icon' width={16} height={16} />
                                                </a>
                                            </h6>
                                            <h5 className="pt-lg-2 pt-1 ltr">{currencyFormatter(currentGift.totalGain)}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeGiftDetailsCard;