// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// config
import * as Config from 'config/Config';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// default value
import { actionValue } from 'config/DefaultValue';
// custom component
import InputRadioComponent from 'components/InputComponent/InputRadioComponent';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import InfoModalIL from 'components/ModalComponent/InfoModalIL';
import EmploymentStockModalIL from 'components/ModalComponent/EmploymentStockModalIL';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const reverseActionValue = [...actionValue].reverse(); //reverse the action value

const infoModalMessageBody = [
    <>
        <h5 className="mb-0">{IntlMessages('kyc_politically_exposed_info_head1')}</h5>
        <p>{IntlMessages('kyc_politically_exposed_info_body1', { br: <br /> })}</p>
    </>,
    <>
        <h5 className="mb-0">{IntlMessages('kyc_politically_exposed_info_head2')}</h5>
        <p>{IntlMessages('kyc_politically_exposed_info_body2', { br: <br /> })}</p>
    </>
]

const PoliticlyExposed: React.FC<Props> = (props: Props) => {

    const { propsData } = props;
    const history = useHistory();
    const intl = useIntl();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [publicOfficial, setPublicOfficial] = React.useState<string>('false'); //public position(question 1)
    const [publicOfficialText, setPublicOfficialText] = React.useState<string>(''); //family member name
    const [publicOfficialTextError, setPublicOfficialTextError] = React.useState<string>(''); //family member name
    const [openPublic, setOpenPublic] = React.useState<boolean>(false); //open modal for public figure name
    const [openInfo, setOpenInfo] = React.useState<boolean>(false); //open info modal
    const [sorryInfoModal, setSorryInfoModal] = React.useState<boolean>(false); //when user select yes for 1st question

    const [stockOwner, setStockOwner] = React.useState<string>('false'); //stock owner(question 2)
    const [companyName, setCompanyName] = React.useState<string>(''); //company name
    const [companyNameError, setCompanyNameError] = React.useState<string>(''); //company name
    const [tickerSymbol, setTickerSymbol] = React.useState<string>(''); //ticker symbol
    const [tickerSymbolError, setTickerSymbolError] = React.useState<string>(''); //ticker symbol
    const [openStock, setOpenStock] = React.useState<boolean>(false); //open modal for company name and ticker symbol

    const [brokerDealer, setBrokerDealer] = React.useState<string>('false'); //broker dealer(question 3)
    const [openBroker, setOpenBroker] = React.useState<boolean>(false); //info modal for question 3

    const [isDisabled, setIsDisabled] = React.useState<boolean>(false); //change the button

    React.useEffect(() => {
        propsData('/on-boarding/page10', 11);

        setPublicOfficial(kycData.publicOfficial);
        setPublicOfficialText(kycData.publicOfficialText);
        setStockOwner(kycData.stockOwner);
        setCompanyName(kycData.companyNameStock);
        setTickerSymbol(kycData.tickerSymbol);
        setBrokerDealer(kycData.brokerDealer);

    }, [])

    // open info modal for question 1
    const openInfoModal = (): void => {
        setOpenInfo(true);
    }

    // open stock modal
    const modalOpenStock = (): void => {
        setOpenStock(true);
    }

    // open public modal
    const modalOpenPublic = (): void => {
        setOpenPublic(true);
    };

    // closing stock modal
    const onHandleCloseStock = (): void => {
        setStockOwner('false');
        setCompanyName('');
        setTickerSymbol('');
        setCompanyNameError('');
        setTickerSymbolError('');

        onHandleClose();
    };

    // closing public modal
    const onHandleClosePublic = (): void => {
        setPublicOfficial('false');
        setPublicOfficialText('');
        setPublicOfficialTextError('');
        onHandleClose();
    }

    // close all modal
    const onHandleClose = (): void => {
        setOpenPublic(false); //question 1 modal
        setOpenInfo(false); //question 1 info modal
        setSorryInfoModal(false); //question 1 sorry modal
        setOpenStock(false); //question 2
        setOpenBroker(false); //question 3
    }

    //  updating input values in state
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === "question1") {
            setPublicOfficial(fieldValue);
            fieldValue === 'true' && setOpenPublic(true);
            setPublicOfficialTextError('');
        }
        if (fieldName === "question2") {
            setStockOwner(fieldValue);
            fieldValue === 'true' && setOpenStock(true);
            setCompanyName('');
            setTickerSymbol('');
            setCompanyNameError('');
            setTickerSymbolError('');
        }
        if (fieldName === "question3") {
            setBrokerDealer(fieldValue);
            fieldValue === 'true' && setOpenBroker(true);
        }
        fieldName === 'companyName' && setCompanyName(fieldValue);
        fieldName === 'tickerSymbol' && setTickerSymbol(fieldValue);
        fieldName === 'publicOfficialText' && setPublicOfficialText(fieldValue);
        setIsDataChanged(true)
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        if (publicOfficial === 'true') {
            setSorryInfoModal(true)
        } else {
            const newKycData = {
                ...kycData,
                publicOfficial,
                publicOfficialText,

                stockOwner,
                companyNameStock: companyName,
                tickerSymbol,

                brokerDealer
            }
            dispatch(setKYCData(newKycData))

            if (isDataChanged) {
                setIsLoading(true);
                const accessToken = await initiateAction();
                const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
                setIsLoading(false);
                if (updateUser.response || updateUser.request) {
                    setErrorLog(updateUser)
                    setIsErrorAPI(true)
                } else {
                    history.push('/on-boarding/page12')
                }
            } else {
                history.push('/on-boarding/page12')
            }
        }
    }

    // validate field name for the next screen
    const validateData = (): void => {
        // check dob
        if (publicOfficial === 'true' && (!publicOfficialText || publicOfficialTextError || publicOfficialText.length < 3)) {
            setIsDisabled(true)
        } else if (stockOwner === 'true' && (!companyName || companyNameError || companyName.length < 2)) {
            setIsDisabled(true)
        } else if (stockOwner === 'true' && (!tickerSymbol || tickerSymbolError || tickerSymbol.length < 2)) {
            setIsDisabled(true)
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    // set-up error message for the first and last name
    React.useEffect(() => {
        // company name validation
        companyName.length > Config.MAX_NUMBER_244 ? setCompanyNameError('error_max_length_244') : setCompanyNameError('');

        // ticker symbol validation
        if (tickerSymbol && (tickerSymbol.length > Config.MAX_NUMBER_10 || tickerSymbol.match(Config.TICKER_REGEX))) {
            tickerSymbol.length > Config.MAX_NUMBER_10 && setTickerSymbolError('error_ticker_max_length');
            tickerSymbol.match(Config.TICKER_REGEX) && setTickerSymbolError('error_ticker_message');

        } else {
            setTickerSymbolError('');
        }

        // public figure name
        publicOfficialText.length > Config.MAX_NUMBER_255 ? setPublicOfficialTextError('error_max_length_255_textarea') : setPublicOfficialTextError('');

    }, [publicOfficialText, companyName, tickerSymbol])

    // validation function call
    React.useEffect(() => {
        validateData()
    }, [publicOfficial, publicOfficialText, stockOwner, companyName, tickerSymbol, brokerDealer])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* page label */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5>{IntlMessages('kyc_politically_exposed_heading', { br: <br /> })}</h5>
                        </div>
                    </div>

                    {/* page content */}
                    <div className="col-12 mt-4">
                        {/* question 1(Public figure name) */}
                        <ul className="list-group border rounded mb-2">
                            {/* question 1 */}
                            <li className="list-group-item d-md-flex justify-content-between align-items-center py-md-3 pt-2 pb-3 px-3 mt-md-0 mt-1 border-0">
                                <div className="list-group-text d-flex align-items-center justify-content-md-start justify-content-between gap-2 pb-md-0 pb-3">
                                    <h5>
                                        {IntlMessages('kyc_politically_exposed_question1')}
                                        &nbsp;<a href="#!" className="mb-1" onClick={openInfoModal}>
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_INFO_GREY' })} alt="IL_ONBOARDING_INFO_GREY" />
                                        </a>
                                    </h5>
                                </div>
                                {/* radio button */}
                                <div>
                                    <InputRadioComponent
                                        name={'question1'}
                                        checkValue={publicOfficial}
                                        radioOption={reverseActionValue}
                                        inputValue={setInputValue}
                                        inputClass={''}
                                        labelClass={'gi-ob-form-check-label form-check-label '}
                                        isSimpleRadio={false}
                                        dataTestId={'question1'}
                                        divClassName='form-check form-check-inline'
                                    />
                                </div>
                            </li>
                            {/* edit & view data */}
                            {(publicOfficial === 'true') && (
                                <div className="gi-ob-edit-card border-0 rounded-top-0 rounded-bottom px-4 py-2">
                                    <div className="d-flex align-items-center gap-4">
                                        <h5 className="mb-0">
                                            {publicOfficialText.length < 30 ? publicOfficialText : `${publicOfficialText.substring(0, 30)}...`}
                                        </h5>
                                        <a href="#!" onClick={modalOpenPublic} data-testid="edit-button1">{IntlMessages('btn_click_edit')}</a>
                                    </div>
                                </div>
                            )}
                        </ul>

                        {/* question 2(stock owner) */}
                        <ul className="list-group border rounded mb-2">
                            {/* question 2 */}
                            <li className="list-group-item d-md-flex justify-content-between align-items-center py-md-3 pt-2 pb-3 px-3 mt-md-0 mt-1 border-0">
                                <div className="list-group-text d-flex align-items-center justify-content-md-start justify-content-between gap-2 pb-md-0 pb-3">
                                    <h5>{IntlMessages('kyc_politically_exposed_question2')}</h5>
                                </div>
                                {/* radio button */}
                                <div>
                                    <InputRadioComponent
                                        name={'question2'}
                                        checkValue={stockOwner}
                                        radioOption={reverseActionValue}
                                        inputValue={setInputValue}
                                        inputClass={''}
                                        labelClass={'gi-ob-form-check-label form-check-label '}
                                        isSimpleRadio={false}
                                        dataTestId={'question2'}
                                        divClassName='form-check form-check-inline'
                                    />
                                </div>
                            </li>
                            {/* edit & view data */}
                            {(stockOwner === 'true') && (
                                <div className="gi-ob-edit-card border-0 rounded-top-0 rounded-bottom px-4 py-2">
                                    <div className="d-flex align-items-center gap-4">
                                        <h5 className="mb-0">
                                            {companyName.length < 30 ? companyName : `${companyName.substring(0, 18)}...`} <span className="px-1"> | </span> {tickerSymbol}
                                        </h5>
                                        <a href="#!" onClick={modalOpenStock} data-testid="edit-button">{IntlMessages('btn_click_edit')}</a>
                                    </div>
                                </div>
                            )}
                        </ul>

                        {/* question 3(FINRA, Broker) */}
                        <ul className="list-group border rounded mb-2">
                            {/* question 3 */}
                            <li className="list-group-item d-md-flex justify-content-between align-items-center py-md-3 pt-2 pb-3 px-3 mt-md-0 mt-1 border-0">
                                <div className="list-group-text d-flex align-items-center justify-content-md-start justify-content-between gap-2 pb-md-0 pb-3">
                                    <h5>{IntlMessages('kyc_politically_exposed_question3')}</h5>
                                </div>
                                {/* radio button */}
                                <div>
                                    <InputRadioComponent
                                        name={'question3'}
                                        checkValue={brokerDealer}
                                        radioOption={reverseActionValue}
                                        inputValue={setInputValue}
                                        inputClass={''}
                                        labelClass={'gi-ob-form-check-label form-check-label '}
                                        isSimpleRadio={false}
                                        dataTestId={'question3'}
                                        divClassName='form-check form-check-inline'
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={clickNext} />
                    </div>
                </div>
            </div>

            {/* question 1 info modal  */}
            <InfoModalIL
                openModal={openInfo}
                messageBody={infoModalMessageBody}
                dialogClassName={''}
                bodyClassName={'py-4 px-4'}
                divClassName={'enter-code-modal-text-success  mt-2 px-3 mb-3'}
                onHandleClose={onHandleClose}
            />

            {/* stock info modal */}
            <EmploymentStockModalIL
                brokerDealer={brokerDealer === 'true' && openBroker}
                stockOwner={stockOwner === 'true' && openStock}
                publicOfficial={publicOfficial === 'true' && openPublic}
                companyName={companyName}
                companyNameError={companyNameError}
                tickerSymbol={tickerSymbol}
                tickerSymbolError={tickerSymbolError}
                publicOfficialText={publicOfficialText}
                publicOfficialTextError={publicOfficialTextError}
                onHandleConfirm={onHandleClose}
                onHandleCloseStock={onHandleCloseStock}
                onHandleClosePublic={onHandleClosePublic}
                setInputValue={setInputValue}
            />

            {/* sorry info modal when user select yes on first question */}
            <Modal
                centered
                size='sm'
                show={sorryInfoModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className="gi-ob-white-bottom-curve enter-code-modal"
            >
                <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button>
                <Modal.Body className='p-4'>
                    <div className="gi-ob-sorry-card p-3">
                        <div className="gi-ob-sorry-img text-center">
                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_GIRAFFE_HEART' })} alt="" />
                        </div>
                        <div className="gi-ob-sorry-text text-center pt-3">
                            <h3 className="mb-2">{IntlMessages('kyc_politically_exposed_info2_text1')}</h3>
                            <h5 className="mb-2 pb-1">{IntlMessages('kyc_politically_exposed_info2_text2')}</h5>
                            <p className="mb-0">{IntlMessages('kyc_politically_exposed_info2_text3')}</p>
                            <a href="mailto:support@giraffe.us">support@giraffe.us</a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PoliticlyExposed