//default library
import * as React from "react";
// custom component
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import MaskInput2 from "components/InputComponent/MaskInput2";
import ErrorKYC from "components/ErrorComponent/ErrorKYC";
import KYCFooterButton from "components/FooterComponent/KYCFooterButton";
// custom function
import AgeCalculator from "service/utils/AgeCalculator";
// config
import * as Config from "config/Config";
// custom component
import resizeErrorMessage from "service/utils/ResizeErrorMessage";
//message import
import IntlMessages from "helper/IntlMessages";
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
// custom function
import * as Local from 'service/utils/LocalStorageData'

// Defining the Props
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
};

const DobSsn: React.FC<Props> = (props: Props) => {
  const { propsData } = props; // Destructuring the receiving props

  const [dateOfBirth, setDateOfBirth] = React.useState(""); //storing the date of birth
  const [errorFlagDOB, setErrorFlagDOB] = React.useState(false); //tracking if there is an error
  const [validAgeFlag, setValidAgeFlag] = React.useState(false); //entered age is valid (not under 18)

  const [ssnNumber, setSsnNumber] = React.useState(""); //storing the SSN
  const [errorFlagSSN, setErrorFlagSSN] = React.useState(false); //tracking if there is an error in the SSN input
  const [fieldType, setFieldType] = React.useState("password"); //toggling the visibility of the password field

  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({}); //storing field details, initialized with dummy data

  const nextPage = "page5"; //next page route
  const sendText = false;
  const isRouteChange = true;
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {
    // Accessing data from localStorage
    const localStorageData = Local.getLocalData("onBoardingDetails");
    // Call the propsData function
    propsData({
      previousPage: "page3",
      pageNumber: 5,
    });

    //dateOfBirth and ssnNumber if available in localStorage
    if (localStorageData?.dateOfBirth) {
      const dob = localStorageData.dateOfBirth.split("-");
      const dd = dob[2];
      const mm = dob[1];
      const yyyy = dob[0];

      setDateOfBirth(`${mm}/${dd}/${yyyy}`);
    }

    if (localStorageData?.ssnNumber) {
      const ssnNumber = localStorageData.ssnNumber;
      const ssnNumber1 = ssnNumber.substring(0, 3);
      const ssnNumber2 = ssnNumber.substring(3, 5);
      const ssnNumber3 = ssnNumber.substring(5, 9);

      setSsnNumber(`${ssnNumber1}-${ssnNumber2}-${ssnNumber3}`);
    }
  }, []);

  // set the input data
  const setInputValue = (fieldName: string, fieldValue: string): void => {
    fieldName === "dateOfBirth" && setDateOfBirth(fieldValue);
    fieldName === "ssnNumber" && setSsnNumber(fieldValue);
  };

  // formate the dob
  const formateDOB = () => {
    // set default values
    setErrorFlagDOB(false);
    setValidAgeFlag(false);

    // format data
    const date = dateOfBirth.split("/");
    const mm = date[0]; //month
    const dd = date[1]; //day
    const yyyy = date[2]; //year

    if (mm.length === 2 && dd.length === 2 && yyyy.length === 4) {
      // check validation
      const comparisonDate = new Date(dateOfBirth);

      if (comparisonDate < new Date("1/1/1901") || comparisonDate > new Date(Date.now())) {
        setErrorFlagDOB(true);
      }

      if (AgeCalculator(comparisonDate) < 18 && comparisonDate < new Date(Date.now())) {
        setValidAgeFlag(true);
      }

      return `${yyyy}-${mm}-${dd}`;
    }

    return "";
  };

  // formate the ssn
  const formateSSN = () => {
    // set default value
    setErrorFlagSSN(false);

    // format SSN
    const ssn = ssnNumber.split("-");
    const ssn1 = ssn[0];
    const ssn2 = ssn[1];
    const ssn3 = ssn[2];

    if (ssnNumber.length === 11) {
      setErrorFlagSSN(!ssnNumber.match(Config.SSN_REGEX));

      return {
        ssnNumber: `${ssn1}${ssn2}${ssn3}`,
        ssnNumberMask: ssnNumber,
      };
    }

    return {
      ssnNumber: "",
      ssnNumberMask: ssnNumber,
    };
  };

  React.useEffect(() => {
    const formattedDOB = formateDOB(); //formate dob
    const formattedSSN = formateSSN(); //formate ssn

    const fieldDetails = {
      dateOfBirth: formattedDOB,
      ssnNumber: formattedSSN.ssnNumber,
      ssnNumberMask: formattedSSN.ssnNumberMask,
    };

    setFieldDetails(fieldDetails);

  }, [dateOfBirth, ssnNumber])

  React.useEffect(() => {
    return () => {
      resizeErrorMessage("dateOfBirth", "ssnNumber"); // resizing error messages
    };
  }, [errorFlagDOB, validAgeFlag, errorFlagSSN])

  //toggle the visibility of the password field
  const toggleFieldType = (): void => {
    setFieldType((prev) => prev === "password" ? "text" : "password");
  };

  return (
    <>
      <div className="gi-ob-slide-card" data-testid="dob-ssn">
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-tell-us-text">
          <h5>{IntlMessages("bod_ssn_text1")}</h5>
          <p>{IntlMessages("bod_ssn_text2")}</p>
        </div>

        {/* date of birth & ssn number */}
        <div className="gi-ob-full-name-form gi-ob-tell-us-form">
          {/* date of birth */}
          <div className="gi-ob-input-box gi-ob-tell-us-input gi-ob-dob-input">
            <label>{IntlMessages("bod_ssn_text3")}</label>
            <MaskInput2
              placeholder={"placeholder_dob"}
              name={"dateOfBirth"}
              readOnly={false}
              fieldType={"text"}
              fieldValue={dateOfBirth}
              inputValue={setInputValue}
              guide={true}
              dataTestId={"dob-number"}
              className={""}
            />
            {errorFlagDOB && (
              <ErrorKYC
                message={"error_not_valid_dob"}
                fieldName={"dateOfBirth"}
              />
            )}
            {validAgeFlag && (
              <ErrorKYC
                message={"error_dob_under_18"}
                fieldName={"dateOfBirth"}
              />
            )}
          </div>

          {/* ssn number */}
          <div className="gi-ob-input-box gi-ob-tell-us-input gi-ob-ssn-input">
            <label>{IntlMessages("bod_ssn_text4")}</label>
            <div className="ssn-input-box">
              <MaskInput2
                placeholder={""}
                name={"ssnNumber"}
                readOnly={false}
                fieldType={fieldType}
                fieldValue={ssnNumber}
                inputValue={setInputValue}
                guide={false}
                dataTestId={"ssn-number"}
                className={""}
              />

              <div className={`show-password ${fieldType === 'text' && 'hide-password'}`} onClick={toggleFieldType} data-testid='toggle-field'></div>
            </div>
            {errorFlagSSN && (
              <ErrorKYC
                message={"error_invalid_ssn_number"}
                fieldName={"ssnNumber"}
              />
            )}
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default DobSsn;
