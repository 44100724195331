// default library
import * as React from 'react';
// message import
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    showLogo: boolean
}

const privacyPolicy = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-privacy-policy-il.pdf`;

const IsraelKYCFooter: React.FC<Props> = (props: Props) => {
    const { showLogo } = props;
    const intl = useIntl()

    return (
        <>
            {showLogo
                ? <footer className="gi-ob-footer-intro d-flex align-items-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <img src={intl.formatMessage({ id: 'IL_ONBOARDING_GIRAFFE_WHITE' })} alt="" />
                                    <span className="mt-2 d-block">
                                        <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                                            {IntlMessages('il_footer_privacy_policy')}
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                : <footer className="gi-ob-footer-il d-md-block d-none py-4" />
            }
        </>
    )
}

export default IsraelKYCFooter