//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
// message import
import IntlMessages from 'helper/IntlMessages';
// config
import { MAX_WITHDRAW_MONEY, MAX_NUMBER_AMOUNT } from 'config/Config';
import { transferType } from 'config/DefaultValue';
// intl message library 
import { useIntl } from 'react-intl';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
// api call
import * as DashboardService from 'middleware/DashboardService';
//custom component
import InputComponentCurrency from 'components//InputComponent/InputComponentCurrency';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import SuccessModal from 'components/ModalComponent/SuccessModal';
import TextError from 'components/ErrorComponent/TextError';

export type Props = {
    openWithdrawModal: boolean;
    withdrawalAmount: number;
    getFundLocation: DashboardType.GetFundLocationsData;
    closeModal: PropsFunction.ParamsBooleanFunction;
    componentDidMount: PropsFunction.SimpleFunction;
}

const WithdrawMoneyModal: React.FC<Props> = (props: Props) => {
    const { openWithdrawModal, getFundLocation, withdrawalAmount, closeModal, componentDidMount } = props;
    const { initiateAction } = useAccessToken();
    const intl = useIntl();

    const [withdrawModalOpen, setWithdrawModalOpen] = React.useState<boolean>(false);
    const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = React.useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);

    const [fromCashAccountValue, setFromCashAccountValue] = React.useState<string>('');
    const [toBankAccountValue, setToBankAccountValue] = React.useState<string>('');
    const [toBankAccountLabel, setToBankAccountLabel] = React.useState<string>('');

    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
    const [errorFlag10, setErrorFlag10] = React.useState<boolean>(false);
    const [errorFlagMax, setErrorFlagMax] = React.useState<boolean>(false);
    const [errorMaxMessage, setErrorMaxMessage] = React.useState<string | PropsFunction.IntlMessages>('');

    const [successMessage, setSuccessMessage] = React.useState<string>('bank_transfer');
    const [withdrawAmount, setWithdrawAmount] = React.useState<number>(0);
    const [withdrawAmountInput, setWithdrawAmountInput] = React.useState<string>('');

    React.useEffect(() => {
        setWithdrawModalOpen(openWithdrawModal)
    }, [openWithdrawModal])

    React.useEffect(() => {
        const toBankAccount = getFundLocation.filter((e) => e.type === 'Bank Account')[0];

        const fromCashAccount = getFundLocation.filter((e) => e.type === 'Cash Account')[0];

        setToBankAccountValue(`${toBankAccount?.id}|${toBankAccount?.type}`)
        setFromCashAccountValue(`${fromCashAccount?.id}|${fromCashAccount?.type}`)
        setToBankAccountLabel(toBankAccount?.name)
    }, [])

    //close modal
    const onHandleClose = (): void => {
        setWithdrawModalOpen(false); //close withdraw modal
        setOpenConfirmModal(false); //close confirm modal
        setOpenLoadingModal(false); //close loading modal
        setOpenSuccessModal(false); //close success modal
        setErrorMessage('') //clear error message
        setErrorFlag10(false) //clear error message
        setErrorFlagMax(false) //clear error message
        setWithdrawAmount(0) //clear withdraw amount
        setWithdrawAmountInput('');

        setErrorFlag(false);

        closeModal(false)
    }

    //close modal
    const onHandleCloseSuccess = (): void => {
        setOpenSuccessModal(false); //close success modal
        setErrorFlag(false); //set error flag to false
        setErrorMessage('') //clear error message

        closeModal(false)
        componentDidMount() //load total card component
        onHandleClose()
    }

    //set amount
    const setInputValue: PropsFunction.ParamsIdStringFunction = (value): void => {
        setWithdrawAmountInput(value);
        setErrorMessage('');
        setErrorFlag(false);
        setErrorFlag10(false);
        setErrorFlagMax(false);
    }

    React.useEffect(() => {
        setWithdrawAmount(Number(withdrawAmountInput));
    }, [withdrawAmountInput]);

    //open confirm modal
    const openConfirmModalFunction = (): void => {
        if (withdrawAmount <= MAX_WITHDRAW_MONEY) {
            setErrorFlag10(true);
            setErrorFlag(false);
            setErrorFlagMax(false);
        }
        else if (withdrawAmount > MAX_NUMBER_AMOUNT || withdrawAmount > withdrawalAmount) {
            setErrorFlag10(false);
            setErrorFlag(false);
            setErrorFlagMax(true);
            setErrorMaxMessage(withdrawAmount > withdrawalAmount ? ['error_maximum_amount_withdraw', { amount: currencyFormatter(withdrawalAmount) }] : 'error_maximum_amount');
        }
        else {
            setOpenConfirmModal(true);
            setWithdrawModalOpen(false);
            setErrorFlag(false);
        }
    }

    const withdrawTransfer = async (): Promise<void> => {
        setOpenLoadingModal(true); //loading modal
        setOpenConfirmModal(false); //confirm modal

        // request modal 


        const accessToken = await initiateAction() //get access token
        const transferredFunds = await DashboardService.transferFund({ amount: withdrawAmount, fromAccount: fromCashAccountValue, toAccount: toBankAccountValue }, accessToken);

        if (transferredFunds.transferType === transferType.BASKET_BUY || transferredFunds.transferType === transferType.BASKET_SELL) {
            setSuccessMessage(transferredFunds.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open')
        }
        else {
            setSuccessMessage('bank_transfer')
        }
        setOpenLoadingModal(false); //loading modal
        setOpenSuccessModal(transferredFunds.isSuccessful); //success modal

        if (transferredFunds.response) {
            setErrorMessage(transferredFunds.response.data.message); //error message
            setWithdrawModalOpen(true); //fund modal
            setErrorFlag(true); //error flag
            closeModal(true)
        }
        else {
            closeModal(false)
        }

    }


    return (
        <>
            {/* withdraw modal */}
            <Modal
                size='xl'
                centered
                show={withdrawModalOpen}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal">
                <Modal.Body className='p-xl-4 p-3'>
                    <button className="btn-close p-0 border-0 position-absolute opacity-100" type="button" aria-label="Close" onClick={onHandleClose}></button>

                    <div className="p-xl-2 p-0">
                        <div className="row gx-0 ">
                            {/* left banner */}
                            <div className="col-lg-5">
                                <div className="gi-g-new-fund-modal-card-start d-flex align-items-center justify-content-lg-center justify-content-start text-lg-center text-start">
                                    <div>
                                        <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="" />
                                        <h5 className="d-none d-md-block">
                                            {IntlMessages('withdraw_bank_account', { br: <br /> })}
                                        </h5>
                                        <h5 className="d-block d-md-none">
                                            {IntlMessages('withdraw_bank_account', { br: '' })}
                                        </h5>
                                        <div className="gi-g-p-tag">
                                            <p>
                                                {IntlMessages('withdraw_text2')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* right input */}
                            <div className="col-lg-7">
                                <div className="gi-g-new-fund-modal-card-end">
                                    {/* input */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* amount */}
                                            <div className={`gi-g-new-input mb-4 pb-2 ${errorFlag || errorFlag10 || errorFlagMax ? 'gi-input-error' : ''}`}>
                                                <label className="form-label mb-2">
                                                    {IntlMessages('fund_select_amount')} |&nbsp;
                                                    {IntlMessages('withdraw_text3', { amount: currencyFormatter(withdrawalAmount) })}
                                                </label>
                                                <div className="gi-currency-input">
                                                    <InputComponentCurrency
                                                        placeholder={'placeholder_currency_10'}
                                                        name={'withdrawAmount'}
                                                        value={withdrawAmountInput}
                                                        inputValue={setInputValue}
                                                        className={''}
                                                        isDecimalAllow={true}
                                                    />
                                                </div>
                                                {errorFlag && (<TextError message2={errorMessage} />)}
                                                {errorFlag10 && (<TextError message={'error_minimum_amount_withdraw'} />)}
                                                {errorFlagMax && (<TextError message={errorMaxMessage} />)}
                                            </div>
                                            {/* from */}
                                            <div className="gi-g-new-input mb-4 pb-2">
                                                <label className="form-label mb-2">
                                                    {IntlMessages('from')}:
                                                </label>
                                                <div className="form-control-text">
                                                    {IntlMessages('giraffe_cash_account')}
                                                </div>
                                            </div>
                                            {/* to */}
                                            <div className="gi-g-new-input mb-4 pb-2">
                                                <label className="form-label mb-2">
                                                    {IntlMessages('withdraw_account')}
                                                </label>
                                                <div className="form-control-text">
                                                    {toBankAccountLabel}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* button */}
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <div className="gi-g-new-btn-groupe mt-4 pt-2">
                                                <button className="gi-g-new-cancel" type="button" onClick={onHandleClose}>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-g-new-transfer" type="button" disabled={!withdrawAmount || errorFlag || errorFlag10 || errorFlagMax} onClick={openConfirmModalFunction}>
                                                    {IntlMessages('withdraw')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirm modal */}
            <Modal
                size='xl'
                centered
                show={openConfirmModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal">
                <Modal.Body className='p-xl-4 p-3'>
                    <button className="btn-close p-0 border-0 position-absolute opacity-100" type="button" aria-label="Close" onClick={onHandleClose}></button>

                    <div className="p-xl-2 p-0">
                        <div className="row gx-0 ">
                            {/* left banner */}
                            <div className="col-lg-5">
                                <div className="gi-g-new-fund-modal-card-start d-flex align-items-center justify-content-lg-center justify-content-start text-lg-center text-start">
                                    <div>
                                        <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="" />
                                        <h5 className="d-none d-md-block">
                                            {IntlMessages('withdraw_bank_account', { br: <br /> })}
                                        </h5>
                                        <h5 className="d-block d-md-none">
                                            {IntlMessages('withdraw_bank_account', { br: '' })}
                                        </h5>
                                        <div className="gi-g-p-tag">
                                            <p>
                                                {IntlMessages('withdraw_text2')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* right input */}
                            <div className="col-lg-7">
                                <div className="gi-g-new-fund-modal-card-end">
                                    {/* input */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* title */}
                                            <div className="gi-g-new-input-text mb-4 pb-3">
                                                <h5>
                                                    {IntlMessages('fund_confirm_transaction')}
                                                </h5>
                                            </div>
                                            {/* amount */}
                                            <div className="gi-g-new-input-text mb-4 pb-3">
                                                <label>
                                                    {IntlMessages('table_amount')}
                                                </label>
                                                <h5>
                                                    {`$ ` + currencyFormatter(withdrawAmount).replace('$', '')}
                                                </h5>
                                            </div>
                                            {/* from */}
                                            <div className="gi-g-new-input-text mb-4 pb-3">
                                                <label>
                                                    {IntlMessages('from')}:
                                                </label>
                                                <h5>
                                                    {IntlMessages('giraffe_cash_account')}
                                                </h5>
                                            </div>
                                            {/* to */}
                                            <div className="gi-g-new-input-text mb-4 pb-3">
                                                <label>
                                                    {IntlMessages('withdraw_account')}
                                                </label>
                                                <h5>
                                                    {toBankAccountLabel}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    {/* button */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="gi-g-new-btn-groupe mt-4 pt-2">
                                                <button className="gi-g-new-cancel" type="button" onClick={onHandleClose}>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-g-new-transfer" type="button" onClick={withdrawTransfer}>
                                                    {IntlMessages('withdraw')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Loading Modal */}
            {openLoadingModal && <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={onHandleClose} />}

            {/* SuccessModal */}
            {openSuccessModal && <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleCloseSuccess={onHandleCloseSuccess} />}
        </ >
    )
}

export default WithdrawMoneyModal