const Error: { [key: string]: string } = {
    /* Error page*/
    'error_404': 'Oops! 404',
    'error_log_in': 'Log in',
    'error_register': 'Pre-Register',
    'error_notFound': 'The page you requested could not be found.',
    'error_validUrl': 'Please enter a valid page or ',
    'error_returnHome': ' return to our homepage :)',
    'error_fixURL': 'We are here to stay, we are not going anywhere. You just need to fix the url!',

    /* Error Message */
    'error_max_length': `Sorry, you can only enter up to {number} characters`,
    'error_max_length_36_name': `Sorry, you can only enter up to 36 characters for your name.`,
    'error_max_length_244': `Sorry, you can only enter up to 244 characters for your name.`,
    'error_max_length_64': `Sorry, you can only enter up to 64 characters for your name.`,
    'error_max_length_255_textarea': `Users cannot enter more than 255 characters in the field.`,
    'error_maximum_amount': `Maximum Amount $9,999,999,999,999,998`,
    'error_minimum_amount_invest': `The minimum investment amount is $100`,
    'error_minimum_amount_sell': `The minimum sell amount is {amount}`,
    'error_maximum_amount_sell': `The maximum partial sell amount is {amount}`,
    'error_first_name': `Sorry, your first name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_middle_name': `Sorry, your middle name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_last_name': `Sorry, your last name may contain letters, periods, apostrophes and spaces but starting with letter only`,
    'error_not_valid_dob': `Oops! It looks like you didn't enter a valid date`,
    'error_dob_under_18': `Sorry! We can't currently allow people under the age of 18 to sign up! Check in with your parents and see what you can do :)`,
    'error_invalid_address': `Sorry, this address is invalid address`,
    'error_invalid_citizenship': `Sorry, unfortunately we cannot accept citizens of that citizenship`,
    'error_invalid_ssn_number': `Oops! It looks like you didn't enter a valid SSN number`,
    'error_invalid_annual_income': `Annual income can not be escalate to 9,223,372,036,854,775,295 amount`,
    'error_invalid_net_worth': `Net worth can not be escalate to 9,223,372,036,854,775,295 amount`,
    'error_invalid_verification_code': `Oops! The code you entered is incorrect. Please double-check and try again. If you're still having trouble, click below to get a new code sent to you.`,
    'error_ticker_max_length': `Sorry, you can only enter up to 10 characters for ticker symbol.`,
    'error_ticker_message': `Sorry, you can only enter a valid ticker symbol`,
    'error_update_call_error': `Oops! Something went wrong. We couldn't submit your information at this time. Please retry in some time.`,
    'error_try_again': `Please try again later`,
    'error_not_enough_balance': `Sorry, you can't invest in your portfolio since you don't have enough balance in your cash account. Please fund your account.`,
    'error_something_wrong': `Uh oh, something went wrong with processing your information.`,
    'error_multiple_account_holder': `Looks like your information may be being used by multiple account holders.`,
    'error_contact_support': `Please contact our support.`,
    'error_contact_support2': `Please contact customer support if that isn't in line with your activity.`,
    'error_connecting_account': `Please verify that your name in our system is correct and if not then fix it either from account settings page or contact customer support.`,
    'error_connecting_account1': `There was an error while connecting to your bank account through Plaid.`,
    'error_money_route': `Sorry, you can't initiate another transaction while your money is still in route`,
    'error_transaction_process': `Your portfolio transaction is processing. Please wait until the transaction is finalized.`,
    'error_please_try': `Please try again.`,
    'error_unlink_bank': 'Sorry, you cannot unlink your bank account while a transaction is ongoing.',
    'error_basket_name': 'Sorry, your portfolio name is not start with space.',
    'error_non_us_taxpayer': 'Uy! Sorry we can only accept US tax payers at this time. Please contact support@giraffe.us if you think there has been an error',
    'error_unable_process': 'We are unable to process your request at this time.',
    'error_redemption_code': 'Sorry, you can only enter 16 characters for your redemption code.',
    'error_gift_already_redeem1': 'Oops! The code you entered has already been utilized to redeem this gift.',
    'error_gift_already_redeem2': 'Please see if you have another code available and try again',
    'error_gift_redemption_code_invalid1': 'Oops! Invalid code',
    'error_gift_redemption_code_invalid2': 'Please copy the code from the gift email and retry',
    'error_gift_redemption_code_expired1': 'Oops! The code you entered has expired.',
    'error_gift_redemption_code_expired2': 'Please see if you have another code available and try again',
    'error_email_not_match': 'Please enter the valid email.',
}

export default Error