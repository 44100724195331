const Footer: { [key: string]: string } = {
    'finra': `FINRA`,
    'sipc': `SIPC`,
    'form_crs': `Form CRS,`,
    'form_adv_2': `Form ADV Part II`,
    'code_of_ethics': `Code of Ethics`,
    'market_data': `Market Data`,
    'tiingo': `by Tiingo`,

    'footer_text1': `We're here to assist you!`,
    'footer_text2': `Send us a message, and we'll respond within 24 hours to provide the support and assistance you need.`,
    'footer_text3': `The articles and client support materials available are educational only and not investment or tax advice.`,
    'footer_text4': `Investment Advice: `,
    'footer_text5': `Advisory services for traditional investments are provided by Giraffe Invest, Inc., an SEC-registered investment adviser. Giraffe Invest, Inc. requires a minimum initial investment deposit of $100, but does not require clients to maintain a minimum investment account balance. However, accounts below a certain balance may have certain restrictions.`,
    'footer_text6': `Traditional Investment Brokerage Services & Custody: Brokerage services are provided to clients of Giraffe Invest, Inc. by DriveWealth, LLC an SEC-registered broker-dealer and member of`,
    'footer_text7': `If you choose to invest with Giraffe, please review our`,
    'footer_text8': `advisory agreement`,
    'footer_text9': `carefully, as it contains important terms such as our fee policy.`,
    'footer_text10': `Investing involves risk and there is the potential of losing money when you invest in securities. Past performance does not guarantee future results and the likelihood of investment outcomes are hypothetical in nature.`,
    'footer_text11': `Investments in securities are: `,
    'footer_text12': `Not FDIC Insured • Not Bank Guaranteed • May Lose Value.`,
    'footer_text13': `Before investing, consider your investment objectives and Giraffe Invest, Inc's fees and expenses. Giraffe Invest Inc's internet-based advisory services are designed to assist clients in achieving discrete financial goals. They are not intended to provide comprehensive tax advice or financial planning with respect to every aspect of a client's financial situation and do not incorporate specific investments that clients hold elsewhere. For more details, see Giraffe Invest's`,
    'footer_text14': `Not an offer, solicitation of an offer, or advice to buy or sell securities in jurisdictions where Giraffe Invest, Inc. is not registered.`,
    'footer_text15': `Who Provides the Market Data?`,
    'footer_text16': `You are viewing a web property located at`,
    'footer_text17': `Different properties may be provided by a different entity with different marketing standards.`,
    'footer_text18': `Certain products provided through a partnership with Standard & Poor's.`,
    'footer_text19': `More Information`,
    'footer_text20': `Terms of Use`,
    'footer_text21': `Privacy Policy`,

    /* ISRAEL FOOTER */
    'il_footer_text1': `We’re here to assist you!`,
    'il_footer_text2': `Check our FAQ section`,
    'il_footer_text3': `Or send us a message, and we'll respond within 24 hours to provide the support you need.`,
    'il_footer_text4': `The investment portfolio management services are provided by Giraffe Fintech Ltd. ("Giraffe"),`,
    'il_footer_text5': `licensed under the Israeli Investment Advice, Investment Marketing and Investment Portfolio Management Law, 5755-1995.`,
    'il_footer_text6': `Should you choose to invest with Giraffe, please review our`,
    'il_footer_text7': `portfolio management agreement`,
    'il_footer_text8': `carefully, as it contains important terms.`,
    'il_footer_text9': `Before investing, consider your investment objectives and`,
    'il_footer_text10': `Giraffe’s fees`,
    'il_footer_text11': `Term of use`,
    'il_footer_text12': `Privacy policy`,
    'il_footer_text13': `Disclaimer`,
};

export default Footer;