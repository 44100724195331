//default library
import * as React from 'react';
//auth library
import { useAuth0 } from '@auth0/auth0-react';
//types props
import * as DriveWealthType from 'api/APIMaster/DriveWealthType';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//api call
import * as DriveWealthService from "middleware/DriveWealthService"
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
import { DateRange } from 'service/utils/DateFilter';
//custom component
import StatementCard from 'components/CardComponent/StatementCard';
import AccountDetailCard from 'components/CardComponent/AccountDetailCard';
import TradeConfirmationTableCard from 'components/CardComponent/TradeConfirmationTableCard';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';

const TaxesPage: React.FC = () => {
    const { user } = useAuth0();

    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);
    const [errorLog, setErrorLog] = React.useState<any>({});//error message

    const [isLoading, setIsLoading] = React.useState<boolean>(true);//loading flag
    const [statementData, setStatementData] = React.useState<DriveWealthType.GetStatementByUserData>([]); //statement data
    const [taxStatementData, setTaxStatementData] = React.useState<DriveWealthType.TaxStatementResponseModel>([]); //tax statement data
    const [tradeConfirmationData, setTradeConfirmationData] = React.useState<DriveWealthType.TradeConfirmationResponseModel>([]); //trade confirmation data

    const { initiateAction } = useAccessToken();
    const locale = useSelector(getLocale);

    const userName = getUserName(locale.language);
    const firstName = userName.firstName ? userName.firstName : user?.email?.split('@')[0];

    React.useEffect(() => {
        initialAPICall();
    }, []);


    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0);

        const dateRange = DateRange('past-week');//set default value date range
        const accessToken = await initiateAction();//get access token
        const [fetchedStatementData, fetchedTaxStatementData, fetchedTradeConfirmationData] = await Promise.all([
            DriveWealthService.statementDetails(dateRange, accessToken,),
            DriveWealthService.taxStatementDetails(accessToken),
            DriveWealthService.tradeConfirmationDetails(dateRange, accessToken),
        ]);


        if ((fetchedStatementData.response || fetchedStatementData.request)
            || (fetchedTaxStatementData.response || fetchedTaxStatementData.request)
            || (fetchedTradeConfirmationData.response || fetchedTradeConfirmationData.request)
        ) {
            (fetchedStatementData.response || fetchedStatementData.request) && setErrorLog(fetchedStatementData);
            (fetchedTaxStatementData.response || fetchedTaxStatementData.request) && setErrorLog(fetchedTaxStatementData);
            (fetchedTradeConfirmationData.response || fetchedTradeConfirmationData.request) && setErrorLog(fetchedTaxStatementData);

            setErrorAPI(true);
        }
        else {
            setStatementData(fetchedStatementData);
            setTaxStatementData(fetchedTaxStatementData);
            setTradeConfirmationData(fetchedTradeConfirmationData);
        }
        setIsLoading(false);
    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading
                ? <LoadingComponent />
                : <>
                    {/* page header */}
                    <PageHeader
                        className={'g-heading px-sm-0 px-2'}
                        textStrong={'hello'}
                        userName={firstName}
                        otherAttr={'gift_head_text2'}
                    />
                    <div className="row gx-2" data-testid="tax-page">
                        <div className="col-lg-9">
                            <div className="row" >
                                <div className="col-12">
                                    {/* statement card */}
                                    <StatementCard statementData={statementData} />
                                    {/* tax statement card */}
                                    <AccountDetailCard taxStatementData={taxStatementData} />
                                    {/* statement card */}
                                    <TradeConfirmationTableCard tradeConfirmationData={tradeConfirmationData} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <GiraffeLearnBannerSection />
                        </div>

                    </div>
                    {/* bank space */}
                    <div className="row">
                        <div className="col-12">
                            <div className="h-dash"></div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default TaxesPage;






















