import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//type import
import * as LearnType from 'api/APIMaster/LearnType';
//dummy data
import * as LearnData from 'service/DummyData/LearnData';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//api call
import * as LearnService from "middleware/LearnService"
//custom function
import { getUserName } from 'service/utils/UtilFunctions';
//custom components
import LearnWeekCard from 'components/CardComponent/LearnWeekCard';
import AchievementSection from 'components/SectionComponent/AchievementSection';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
import ScheduleSection from 'components/SectionComponent/ScheduleSection';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

const GiraffeLearnListPage: React.FC = () => {
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const locale = useSelector(getLocale);

    const [errorLog, setErrorLog] = React.useState<any>({});//error message
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error flag

    const [isLoading, setIsLoading] = React.useState<boolean>(true);//loading flag
    const [userCalendar, setUserCalendar] = React.useState<LearnType.UserCalendarSection>(LearnData.userCalendarSection);//calender data
    const scheduleSection = LearnData.scheduleSectionData; //dummy data

    React.useEffect(() => {
        initialAPICall();
    }, [locale.language]);

    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0);
        const accessToken = await initiateAction(); // Use the useAuth hook directly
        const userCalendarData = await LearnService.getUserCalendarDetails(accessToken);

        if (userCalendarData.response) {
            setErrorLog(userCalendarData);
            setErrorAPI(true);
        } else {
            const currentCalendar = userCalendarData.userCalendarSectionResponseList.find((e: LearnType.CurrentUserCalenderType) => e.current);
            const userCalendarDataFormatted = {
                currentCalendar: currentCalendar,
                userCalendar: userCalendarData.userCalendarSectionResponseList,
            };
            setUserCalendar(userCalendarDataFormatted);
        }
        setIsLoading(false);
    };

    const dummyFunction = (): void => { }; //dummy function

    const localName = getUserName(locale.language);
    const userName = localName.userName ? localName.userName : user?.email?.split('@')[0];

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading
                ? <LoadingComponent />
                : <>
                    {/* page header */}
                    <PageHeader
                        className={'g-heading px-sm-0 px-3'}
                        textStrong={'giraffe_learn_welcome'}
                        userName={userName}
                        otherAttr={''}
                    />

                    {/* content */}
                    <div className="row gx-2" data-testid="giraffe-learn-list">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-12">
                                    {/* schedule section */}
                                    <ScheduleSection learnList={false} scheduleSection={scheduleSection} forWeek={dummyFunction} />
                                    {/* LearnWeekCard */}
                                    <LearnWeekCard userCalendar={userCalendar} />
                                    {/* achievement section */}
                                    <AchievementSection />
                                </div>
                            </div>
                        </div>
                        {/* Banner Section */}
                        <div className="col-lg-3">
                            <GiraffeLearnBannerSection />
                        </div>
                    </div>
                    {/* bank space */}
                    <div className="row">
                        <div className="col-12">
                            <div className="h-dash"></div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default GiraffeLearnListPage;

