// default library
import * as React from 'react';
import { NavLink } from 'react-router-dom';
// intl message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, setCurrentAPILang, getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as GiftType from 'api/APIMaster/GiftType';
import * as PropsFunction from 'interface/PropsFunction';
// custom hook
import { useLogoutHook } from 'helper/Logout';
// api call
import * as UserService from 'middleware/UserService';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

type Props = {
    basketList: DashboardType.GetBasketBasicDetailsByUser1Data;
    giftPendingList: GiftType.GetGiftUserByAccessTokenData;
    menuOpen: PropsFunction.SimpleFunction
}

const MobileSidebar: React.FC<Props> = (props: Props) => {
    const { basketList, giftPendingList, menuOpen } = props
    const logoutUser = useLogoutHook()
    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const dispatch = useDispatch();
    const appCommonData = useSelector(getAppCommonData);
    const locale = useSelector(getLocale);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [isLoading, setIsLoading] = React.useState<boolean>(false);  //error logs

    const logOut = (): void => {
        logoutUser()
    }

    // change local
    const changeLocal = async (e: React.MouseEvent): Promise<void> => {
        const ele = (e.target as HTMLAnchorElement).getAttribute('data-value')
        if (ele) {
            setIsLoading(true);

            const accessToken = await initiateAction() //get access token
            const isLocaleUpdate = await UserService.uploadLocale(ele, accessToken);

            setIsLoading(false);

            if (isLocaleUpdate.response || isLocaleUpdate.request) {
                setIsError(true);
                setErrorLog(isLocaleUpdate)
            } else {
                dispatch(setCurrentAPILang(ele))
                dispatch(setCurrentLanguage(ele))
            }
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const showGiftTab = !(appCommonData.isNewUser || appCommonData.isKycDenied || appCommonData.isKycRejected || appCommonData.isKycManualReview); //check to show gift tab or not

    // check the user country if country is IL or user from IL 
    const showLanguageOption: boolean = appCommonData.kycCountry ? appCommonData.kycCountry === 'ISR' : (locale.code === 'ISR' || locale.code === 'ISR_EN');

    return (
        <>
            {isLoading && <LoadingComponent />}

            <div className="gi-g-menu-mobile">
                {/* home and basket list */}
                <div className="gi-g-menu-new border-top">
                    {/* home page */}
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to={'/dashboard'} activeClassName='active' onClick={menuOpen}>
                                <span className="nav-icon">
                                    <img src={intl.formatMessage({ id: 'HOME_ICON' })} className="gi-d-icons-inactive" alt="" />
                                    <img src={intl.formatMessage({ id: 'HOME_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                </span>
                                {IntlMessages('dashboard_home')}
                            </NavLink >
                        </li>
                    </ul>
                    {/* basket list */}
                    <div className="collapse-nav small-point">
                        <ul>
                            {basketList.length > 0 && (basketList?.map((e) => {
                                return (
                                    <li key={e.id}>
                                        <NavLink exact to={`/dashboard/portfolio/${e.id}`} activeClassName='active' onClick={menuOpen}>
                                            <span className="sm-point"></span>
                                            {e.name}
                                        </NavLink >
                                    </li>
                                )
                            }))}
                        </ul>
                    </div>
                </div>

                {/* add portfolio */}
                <div className="gi-g-menu-new">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to={'/dashboard/add-portfolio'} activeClassName='active' onClick={menuOpen}>
                                <span className="nav-icon">
                                    <img src={intl.formatMessage({ id: 'LINE_CHART_ICON' })} className="gi-d-icons-inactive" alt="" />
                                    <img src={intl.formatMessage({ id: 'LINE_CHART_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                </span>
                                {IntlMessages('dashboard_create_investment')}
                            </NavLink >
                        </li>
                    </ul>
                </div>

                {/* My Impact */}
                <div className="gi-g-menu-new">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className="nav-link" to={'/dashboard/my-impact'} activeClassName='active' onClick={menuOpen}>
                                <span className="nav-icon">
                                    <img src={intl.formatMessage({ id: 'MY_IMPACT' })} className="gi-d-icons-inactive" alt="" />
                                    <img src={intl.formatMessage({ id: 'MY_IMPACT_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                </span>
                                {IntlMessages('dashboard_my_impact')}
                            </NavLink >
                        </li>
                    </ul>
                </div>

                {/* Giraffe learn */}
                {appCommonData.isLearnAccess && (
                    <div className="gi-g-menu-new">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink exact className="nav-link" to={'/dashboard/financial-playlist'} activeClassName='active' onClick={menuOpen}>
                                    <span className="nav-icon">
                                        <img src={intl.formatMessage({ id: 'LEARN_ICON' })} className="gi-d-icons-inactive" alt="" />
                                        <img src={intl.formatMessage({ id: 'LEARN_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                    </span>
                                    {IntlMessages('dashboard_learn')}
                                </NavLink >
                            </li>
                        </ul>
                    </div>
                )}

                {/* gift given */}
                {JSON.parse(import.meta.env.REACT_APP_SHOW_NAVIGATION_GIFT) && showGiftTab && (
                    <div className="gi-g-menu-new">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink exact to={`/dashboard/gifts`} className="nav-link" activeClassName='active' onClick={menuOpen}>
                                    <span className="nav-icon">
                                        <img src={intl.formatMessage({ id: 'GIFT_ICON' })} className="gi-d-icons-inactive" alt="" />
                                        <img src={intl.formatMessage({ id: 'GIFT_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                    </span>
                                    {IntlMessages('footer_gifts')}
                                </NavLink>
                            </li>
                        </ul>
                        <div className="collapse-nav lg-point">
                            <ul>
                                {!appCommonData.isNewUser && giftPendingList.length > 0 && (giftPendingList.map((e) => {
                                    return (
                                        !e.isRedeemed && (
                                            <li key={e.id} >
                                                <NavLink exact to={`/dashboard/gift/${e.identifier}`} activeClassName='active' onClick={menuOpen}>
                                                    <span className="lg-point"></span>
                                                    {IntlMessages('dashboard_new_gift_pending')}
                                                </NavLink>
                                            </li>
                                        )
                                    )
                                }))}
                            </ul>
                        </div>
                    </div>
                )}

                {/* taxes */}
                <div className="gi-g-menu-new">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className='nav-link no-icon' to={`/dashboard/taxes`} activeClassName='active' onClick={menuOpen}>
                                {IntlMessages('dashboard_taxes')}
                            </NavLink >
                        </li>
                    </ul>
                </div>

                {/* activity */}
                <div className="gi-g-menu-new">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className='nav-link no-icon' to={`/dashboard/activity`} activeClassName='active' onClick={menuOpen}>
                                {IntlMessages('dashboard_activity')}
                            </NavLink >
                        </li>
                    </ul>
                </div>

                {/* Contact us */}
                <div className="gi-g-menu-new ">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact className='nav-link no-icon' to={`/dashboard/contact-us`} activeClassName='active' onClick={menuOpen}>
                                {IntlMessages('dashboard_contact_us')}
                            </NavLink >
                        </li>
                    </ul>
                </div>

                {/* KYC page */}
                {(appCommonData.isNewUser || appCommonData.isKycRejected) && (
                    <div className="gi-g-menu-new border-bottom-mob">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink exact to={appCommonData.isKycRejected ? '/kyc-failure-form' : '/on-boarding'} className="nav-link no-icon cta-account" activeClassName='active' >
                                    {IntlMessages('dashboard_finish_setup')}
                                    <span className="nav-icon">
                                        <img src={intl.formatMessage({ id: 'FINISH_SETUP_ICON' })} alt="" />
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                )}

                {/* account setting and logout */}
                <div className="border-top my-5">
                    {!appCommonData.isNewUser && (
                        <div className="gi-g-menu-new">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <NavLink exact className='nav-link' to={`/dashboard/account-setting`} activeClassName='active' onClick={menuOpen}>
                                        <span className='nav-icon'>
                                            <img src={intl.formatMessage({ id: 'ACCOUNT_SETTING_INACTIVE_MOB' })} className="gi-d-icons-inactive" alt='' />
                                            <img src={intl.formatMessage({ id: 'ACCOUNT_SETTING_ACTIVE_MOB' })} className="gi-d-icons-active" alt='' />
                                        </span>
                                        {IntlMessages('dashboard_account_setting')}
                                    </NavLink >
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="gi-g-menu-new">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a className="nav-link " onClick={logOut}>
                                    <span className="nav-icon">
                                        <img src={intl.formatMessage({ id: 'LOG_OUT_INACTIVE' })} className="gi-d-icons-inactive" alt="" />
                                        <img src={intl.formatMessage({ id: 'LOG_OUT_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                    </span>
                                    {IntlMessages('log_out')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {showLanguageOption && (
                    <div className="gi-g-menu-new-lang border-top mb-5">
                        <div className="gi-g-menu-new">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link lang-he" href="#!" data-value="ISR" onClick={changeLocal}>
                                        עברית
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="gi-g-menu-new">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link lang-en" href="#!" data-value="ISR_EN" onClick={changeLocal}>
                                        English
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default MobileSidebar