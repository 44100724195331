/**
 * check the browser and open url in new tab
 * if browser is safari/firefox then give new button 
 * if browser is not safari/firefox then open url in new tab
 *
 * @param {string} url
 * @param {string} id
 */
export const downloadFile = (url: string, id: string): void => {

    const isSafari: boolean = (navigator.userAgent.indexOf("Safari") >= 0 && navigator.userAgent.indexOf("Chrome") < 0)
    const isFirefox: boolean = (navigator.userAgent.indexOf("Firefox") >= 0 && navigator.userAgent.indexOf("Chrome") < 0)

    if (isSafari || isFirefox) {
        const iconPDF = document.getElementById(id) as HTMLAnchorElement;
        const viewPDF = document.getElementById(`${id}-view`) as HTMLAnchorElement;

        viewPDF.href = url;
        viewPDF.style.display = 'block'
        iconPDF.style.display = 'none'

        setTimeout(() => {
            viewPDF.style.display = 'none'
            iconPDF.style.display = 'block'
        }, 15 * 1000 * 60)

    } else {
        openURL(url, `${id}-downloaded`);
    }

}

/**
 * reset all inline style
 *
 * @param {Array<string>} idList
 */
export const resetStyle = (idList: Array<string>): void => {
    idList.length > 0 && idList.filter((e: string) => {
        if (typeof e === 'string') {
            const iconPDF = document.getElementById(e) as HTMLAnchorElement;
            const viewPDF = document.getElementById(`${e}-view`) as HTMLAnchorElement;

            viewPDF && (viewPDF.style.display = 'none')
            iconPDF && (iconPDF.style.display = 'block')
        }
    })
}

/**
 * open url in new tab
 *
 * @param {string} url
 * @param {string} id
 */
export const openURL = (url: string, id: string): void => {
    const a = document.createElement("a") as HTMLAnchorElement;
    a.setAttribute('href', url);
    a.setAttribute('id', id);
    a.setAttribute('target', `_blank`);
    a.setAttribute('rel', `noopener noreferrer`);
    a.click();
}