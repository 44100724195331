// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// message formatting
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as PropsFunction from "interface/PropsFunction";
import * as DashboardType from 'api/APIMaster/DashboardType';
// api call
import * as UserService from 'middleware/UserService';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// custom component
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    openModal: boolean;
    getFundLocation: DashboardType.GetFundLocationsData;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
}

const ChangeCashMonthlySavingModal: React.FC<Props> = (props: Props) => {
    const { openModal, getFundLocation, onHandleClose, onHandleSuccess } = props;
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [basketUpdateModal, setBasketUpdateModal] = React.useState<boolean>(false); //basket update modal
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal

    const [savingPortfolioList, setSavingPortfolioList] = React.useState<PropsFunction.Option>([]); //portfolio list 
    const [portfolio, setPortfolio] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //position

    React.useEffect(() => {
        setBasketUpdateModal(openModal);
        setLoadingModal(false);

        // set dropdown data
        const portfolioList = getFundLocation
            .filter(e => (e.type !== 'Bank Account' && e.type !== 'Cash Account'))
            .map(e => {
                return {
                    value: e.id,
                    label: e.name,
                    active: true,
                }
            });

        setSavingPortfolioList(portfolioList);
        setPortfolio(OnBoardingData.defaultOptionValue); //reset the value
    }, [openModal]);

    // select value from dropdown
    const selectDropDownValue: PropsFunction.SelectedOption = (_fieldName, value) => {
        setPortfolio(value);
    }

    // api call
    const submitBasketUpdate = async (): Promise<void> => {
        setLoadingModal(true);
        setBasketUpdateModal(false);

        const accessToken = await initiateAction() //get access token
        const isBasketUpdate = await UserService.updateBasketMonthlySaving(portfolio.value.toString(), accessToken);

        setLoadingModal(false);

        if (isBasketUpdate.request || isBasketUpdate.response) {
            setIsError(true)
            setErrorLog(isBasketUpdate)
        } else {
            onHandleSuccess()
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            <Modal
                centered
                size='sm'
                show={basketUpdateModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                contentClassName={'border-0'}
                className="gi-ob-green-bottom-curve enter-code-modal enter-code-modal-success"
            >
                <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button>
                <Modal.Body className='p-4 mt-2'>
                    <div className="gi-setup-modal-text-success mt-2 px-3 mb-3 ">
                        <p className="mb-0">
                            {IntlMessages('home_monthly_cash_basket_text1', { br: <br /> })}
                        </p>
                        <div className="gi-ob-form form-group gi-cash-account-form mt-4">
                            {/* <select class="gi-cash-account-dropdown form-control" id="brunchNumber" required>
                                <option selected disabled value="">Cash Account</option>
                                <option value="Brunch number">Brunch number</option>
                            </select> */}
                            <SelectDropdown
                                labelClassName={''}
                                labelText={''}
                                name={'portfolio'}
                                placeholder={'home_monthly_cash_basket_placeholder'}
                                option={savingPortfolioList}
                                fieldValue={portfolio}
                                className={'custom-react-dropdown-il'}
                                ariaLabel={'portfolio'}
                                isDisabled={false}
                                inputValue={selectDropDownValue}
                                isModalDropdown={true}
                            />
                        </div>
                        <div className="text-center mt-4">
                            <button type="button" onClick={submitBasketUpdate} disabled={!portfolio.value}>
                                {IntlMessages('button_save2')}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default ChangeCashMonthlySavingModal