// default library
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// moment
import moment from 'moment';
//auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// react redux
import { useSelector, useDispatch } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData, setCurrentAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// intl message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// api call
import * as UserService from 'middleware/UserService';
import * as DashboardService from 'middleware/DashboardService';
// default value
import * as DefaultValue from 'config/DefaultValue';
import { amountRangeCalculator, amountRangeCalculator2 } from 'service/utils/AmountRange';
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
import { getCurrencySymbol } from 'service/utils/GetCurrencySymbol';
// dummy data
import * as UserData from 'service/DummyData/UserData';
// type import
import * as UserType from "api/APIMaster/UserType";
import * as DashboardType from "api/APIMaster/DashboardType";
import * as Dashboard from 'interface/Dashboard';
import * as OnBoarding from 'interface/OnBoarding';
import * as Routes from "interface/Route"
//custom component
import ListData from 'components/ListViewComponent/ListData';
import ListHeading from 'components/ListViewComponent/ListHeading';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection'
import ErrorPendingModal from 'components/ModalComponent/ErrorPendingModal';
import ConfirmationModal from 'components/ModalComponent/ConfirmationModal';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import BankConnectModal from 'components/ModalComponent/BankConnectModal';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
import MonthlySavingModals from 'components/ModalComponent/MonthlySavingModals';
import AccountEditModal from 'components/ModalComponent/AccountEditModal';
import MonthlySavingEditModal from 'components/ModalComponent/MonthlySavingEditModal';

const emailNotificationList: OnBoarding.CheckBoxList = [
    {
        label: IntlMessages('account_setting_email_marketing'),
        name: 'emailSubscribeMarketing',
        active: false
    },
    {
        label: IntlMessages('account_setting_email_learn'),
        name: 'emailSubscribeLearn',
        active: false
    },
]

const annualReportNotificationList: OnBoarding.CheckBoxList = [
    {
        label: IntlMessages('account_setting_email_report'),
        name: 'Email',
        active: false
    },
    {
        label: IntlMessages('account_setting_postal_service_report'),
        name: 'Postal service',
        active: false
    },
]

const AccountSetting: React.FC = () => {

    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const intl = useIntl();
    const history = useHistory();

    const location = useLocation<Routes.LocationMonthly>();

    const dispatch = useDispatch()
    const locale = useSelector(getLocale);
    const appCommonData = useSelector(getAppCommonData);

    const userName = getUserName(locale.language);
    const firstName = userName.firstName ? userName.firstName : user?.email?.split('@')[0];

    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false); //error occurred flag
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //loading state for api call
    const [isLoadingModal, setIsLoadingModal] = React.useState<boolean>(false); //loading state for modal

    const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true); //check redux data render first time or not

    // api data state
    const [userData, setUserData] = React.useState<UserType.GetDetailData>(UserData.userData); // Plaid connect
    const [staticData, setStaticData] = React.useState<UserType.OnboardingStaticDataResponseModel>(UserData.onBoardingStaticData);
    const [transferPending, setTransferPending] = React.useState<DashboardType.IsFundTransferPendingData>([]); //transfer bank connect page
    const [fundLocation, setFundLocation] = React.useState<DashboardType.GetFundLocationsData>([]);

    // bank link data
    const [connectBankData, setConnectBankData] = React.useState<Dashboard.ConnectBankData>(UserData.connectBankData); //bank connect data
    const [openUnlinkErrorModal, setOpenUnlinkErrorModal] = React.useState<boolean>(false); //bank unlink error modal
    const [openUnlinkConfirmationModal, setOpenUnlinkConfirmationModal] = React.useState<boolean>(false); //bank unlink confirmation modal
    const [openBankConnectModal, setOpenBankConnectModal] = React.useState<boolean>(false); //bank connect modal

    const [emailNotification, setEmailNotification] = React.useState<OnBoarding.CheckBoxList>(emailNotificationList); // notification object
    const [isEmailNotificationUpdate, setIsEmailNotificationUpdate] = React.useState<boolean>(false); //check notification object is update or not
    const [annualReportNotification, setAnnualReportNotification] = React.useState<OnBoarding.CheckBoxList>(annualReportNotificationList); // notification object for annual report
    const [isAnnualReportNotificationUpdate, setIsAnnualReportNotificationUpdate] = React.useState<boolean>(false); //check notification object for annual report is update or not
    const [isNotificationChange, setIsNotificationChange] = React.useState<boolean>(false); //check any notification button click or not
    const [notificationUpdate, setNotificationUpdate] = React.useState<boolean>(false); // notification update flag

    // modal open flag and data state
    const [openEditModalFlag, setOpenEditModalFlag] = React.useState<boolean>(false); // open account setting edit modal flag
    const [openEditModal, setOpenEditModal] = React.useState<string>(''); // open account setting edit modal

    const [monthlySavingModal, setMonthlySavingModal] = React.useState<boolean>(false); // open monthly saving modal
    const [monthlySavingEditModal, setMonthlySavingEditModal] = React.useState<boolean>(false); // open monthly saving edit modal
    const [loadingSavingModal, setLoadingSaving] = React.useState<boolean>(false); //monthly saving modal loading

    React.useEffect(() => {
        if (!isFirstRender) {
            updateLocalBaseAPI(); //update the local language based api when language is change
        } else {
            setIsFirstRender(false)
            initialAPICall()
        }
    }, [locale.language])

    const initialAPICall = async (): Promise<void> => {
        setIsLoading(true)
        setMonthlySavingModal(false);
        setMonthlySavingEditModal(false);

        const accessToken = await initiateAction() //get access token
        const [transferPending, userData, bankDataList, staticData, fundLocation] = await Promise.all([
            DashboardService.isFundTransferPending(accessToken), //funding is pending or not check
            UserService.getDetail(accessToken), //get user data
            UserService.getAllBankList(accessToken), //get all user bank list
            UserService.getOnboardingStaticData(accessToken), //on-boarding details api call
            DashboardService.getFundLocations(accessToken),  //get list of basket and account
        ])

        setIsLoading(false)

        if ((transferPending.response || transferPending.request)
            || (userData.response || userData.request)
            || (bankDataList.response || bankDataList.request)
            || (staticData.response || staticData.request)
        ) {
            (transferPending.response || transferPending.request) && setErrorLog(transferPending);
            (userData.response || userData.request) && setErrorLog(userData);
            (bankDataList.response || bankDataList.request) && setErrorLog(bankDataList);
            (staticData.response || staticData.request) && setErrorLog(staticData);

            setErrorAPI(true)
        } else {

            dispatch(setCurrentAppCommonData({
                ...appCommonData,
                isKycRejected: userData.isInternalKycRejected,
                isKycProcessing: userData.isInternalKycPending,
                isKycManualReview: userData.isInternalKycManualReview,
                isKycDenied: userData.isInternalKycDenied,
            }))

            setTransferPending(transferPending);
            setStaticData(staticData);
            setUserData(userData);
            setFundLocation(fundLocation);
            setNotificationUpdate(false);

            const connectBank: UserType.GetAllByBank = bankDataList.filter((e: UserType.GetAllByBank) => e.active)[0]
            connectBank
                ? setConnectBankData({
                    name: `${connectBank.bankName} - ${connectBank.accountType} - ${connectBank.accountNumber}`,
                    id: connectBank.id,
                })
                : setConnectBankData(UserData.connectBankData);


            // email notification object
            const notificationData = emailNotificationList.map(e => {
                if (e.name === 'emailSubscribeLearn' && userData.emailSubscribeLearn) {
                    return { ...e, active: true };
                }
                if (e.name === 'emailSubscribeMarketing' && userData.emailSubscribeMarketing) {
                    return { ...e, active: true };
                }
                return e
            })
            setEmailNotification(notificationData);

            // annual report notification object
            const reportsMailingChoice = userData.metadataList && userData.metadataList.find((e: UserType.Metadata) => e.fieldName === 'reportsMailingChoice');
            const annualReportNotificationData = annualReportNotificationList.map(e => {
                if (e.name === 'Email' && reportsMailingChoice && reportsMailingChoice.fieldValue.includes("Email")) {
                    return { ...e, active: true };
                }
                if (e.name === 'Postal service' && reportsMailingChoice && reportsMailingChoice.fieldValue.includes("Postal service")) {
                    return { ...e, active: true };
                }
                return e
            })
            setAnnualReportNotification(annualReportNotificationData);

            setMonthlySavingEditModal(location.state?.isMonthlySavingEditOpen ?? false); //monthly saving edit modal

            history.replace({ ...history.location, state: undefined });
        }
    }

    // update the local language based api when language is change
    const updateLocalBaseAPI = async (): Promise<void> => {
        setIsLoading(true);

        const accessToken = await initiateAction() //get access token
        const staticData = await UserService.getOnboardingStaticData(accessToken);

        setIsLoading(false);

        if (staticData.response || staticData.request) {
            setErrorLog(staticData);
            setErrorAPI(true)
        } else {
            setStaticData(staticData);
        }
    }

    // call user basic detail API
    const onHandleSuccessEmail = async (): Promise<void> => {
        setLoadingSaving(true);
        const accessToken = await initiateAction(); //get access token
        const userData = await UserService.getDetail(accessToken);

        setLoadingSaving(false);

        if (userData.response || userData.request) {
            setErrorAPI(true)
            setErrorLog(userData)
        } else {
            setUserData(userData);
        }
    }

    // open click modal based on the click on card header
    const editClick = (id: string): void => {
        if (id === 'monthlySaving') {
            setMonthlySavingEditModal(true);
        } else {
            setOpenEditModalFlag(true);
            setOpenEditModal(id);
        }
    }

    // set notification email value
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        setNotificationUpdate(false);
        setIsNotificationChange(true);

        // email notification object
        if (fieldName === 'emailSubscribeMarketing' || fieldName === 'emailSubscribeLearn') {
            setEmailNotification((prev) => {
                const emailNotification = [...prev]
                const index = emailNotification.findIndex(e => e.name === fieldName);
                if (index >= 0)
                    emailNotification[index].active = fieldValue;
                return emailNotification
            });

            setIsEmailNotificationUpdate(true);
        }

        // annual report notification object
        if (fieldName === 'Email' || fieldName === 'Postal service') {
            setAnnualReportNotification((prev) => {
                const annualReportNotification = [...prev]
                const index = annualReportNotification.findIndex(e => e.name === fieldName);
                const activeFields = annualReportNotification.findIndex(e => e.active)
                if (index >= 0 && activeFields !== index) {
                    annualReportNotification.forEach(e => e.active = false);
                    annualReportNotification[index].active = fieldValue;
                }
                return annualReportNotification
            });

            setIsAnnualReportNotificationUpdate(true);
        }
    }

    // update email notification api call
    const updateEmailNotification = async (): Promise<void> => {
        setIsLoading(true);

        const accessToken = await initiateAction() //get access token
        const apiCallList = []

        isEmailNotificationUpdate ? apiCallList.push(UserService.updateEmailNotification(emailNotification, accessToken)) : apiCallList.push(Promise.resolve([]));
        isAnnualReportNotificationUpdate ? apiCallList.push(UserService.updateReportsMailingChoice(annualReportNotification, accessToken)) : apiCallList.push(Promise.resolve([]));


        const [updateNotification, updateReportsMail] = await Promise.all(apiCallList)

        setIsLoading(false);
        if (updateNotification.response || updateNotification.request || updateReportsMail.response || updateReportsMail.request) {
            (updateNotification.response || updateNotification.request) && setErrorLog(updateNotification);
            (updateReportsMail.response || updateReportsMail.request) && setErrorLog(updateReportsMail);
            setErrorAPI(true)
        } else {
            setNotificationUpdate(true);
            setIsNotificationChange(false);
            setIsEmailNotificationUpdate(false);
            setIsAnnualReportNotificationUpdate(false);
        }

    }

    /**
     * check any transaction is on going or not 
     * if transaction is ongoing then open error modal
     * if transaction is not on going then open unlink confirmation modal
     */
    const openBankUnlinkModal = (): void => {
        if (transferPending.filter(e => e.isPending).length > 0) {
            setOpenUnlinkErrorModal(true);
        } else {
            setOpenUnlinkConfirmationModal(true);
        }
    }

    // open saving modal
    const openMonthlySavingModal = (): void => {
        setMonthlySavingModal(true)
    }

    // bank connect modal
    const bankConnectModal = (): void => {
        setOpenBankConnectModal(true)
    }

    // bank unlink api call
    const bankUnlinkModal = async (): Promise<void> => {
        setOpenUnlinkConfirmationModal(false); //bank unlink confirmation modal
        setIsLoadingModal(true);
        const accessToken = await initiateAction() //get access token
        const isBankUnlink = await UserService.unlinkBank(connectBankData.id, accessToken);

        setIsLoadingModal(false)
        if (isBankUnlink.response || isBankUnlink.request) {
            setErrorLog(isBankUnlink)
            setErrorAPI(true)
        } else {
            onHandleClose(); //close all modal
            initialAPICall(); //api all api
        }
    }

    //  update the user data
    const updateUser = async (formData: UserType.UserRequestModel): Promise<void> => {
        onHandleClose();
        setIsLoadingModal(true);
        const accessToken = await initiateAction() //get access token
        const updatedData = await UserService.userDataUpdateAC(formData, accessToken);
        setIsLoadingModal(false)
        if (updatedData.response || updatedData.request) {
            setErrorLog(updatedData)
            setErrorAPI(true)
        } else {
            onHandleClose(); //close all modal
            initialAPICall(); //api all api
        }
    }

    // close modal
    const onHandleClose = (): void => {
        setOpenUnlinkConfirmationModal(false); //bank unlink confirmation modal
        setIsLoadingModal(false); //loading modal
        setOpenUnlinkErrorModal(false); //bank unlink error modal
        setOpenBankConnectModal(false); //bank connect modal
        setOpenEditModalFlag(false); //account setting modal
        setOpenEditModal(''); //modal section for account setting
        setMonthlySavingModal(false); //monthly saving modal
        setMonthlySavingEditModal(false); //close the monthly saving edit modal
    }

    // not list modal open
    const onHandleNotListEmail = async (): Promise<void> => {
        setMonthlySavingModal(false);
        setIsLoading(true);
        await onHandleSuccessEmail();
        setIsLoading(false);
    }

    // monthly saving amount
    const getAmountWithSymbol = (amount: number): JSX.Element => {

        return (
            <>
                <span className="gi-currency-symbol">{getCurrencySymbol(userData.defaultCurrency)}</span> {amount}
            </>
        )
    }

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    // bank connect data
    const userAddress = userData.userAddress
    const plaidData = {
        customerFirstName: userData.firstNameEncrypted,
        customerMiddleName: userData.middleNameEncrypted,
        customerLastName: userData.lastNameEncrypted,
        customerPhoneNumber: userData.phoneEncrypted,
        customerEmail: user?.email,
        customerAddress: `${userAddress?.street1} ${userAddress?.city} ${userAddress?.province} ${userAddress?.postalCode}`,
    }
    // user phone number US
    const phoneNumberUS = `${userData.phoneEncrypted ? userData.phoneEncrypted.replace(/\D/g, '').slice(1).replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3') : ''}`;
    // user phone number IL
    const phoneNumberIL = `${userData.phoneEncrypted ? userData.phoneEncrypted.replace(/\D/g, '').slice(3).replace(/(\d{3})(\d{7})/, '$1 $2') : ''}`;
    // user legal address
    const fullAddress = [userAddress.street3, userAddress.street1, userAddress.street2, userAddress.city, userAddress.province, userAddress.postalCode].filter(Boolean).join(', ');
    //ssnNumber
    const ssnNumber = (userData.taxIdNumber && userData.taxIdNumber.length > 8)
        ? `${userData.taxIdNumber.slice(0, 3)}-${userData.taxIdNumber.slice(3, 5)}-${userData.taxIdNumber.slice(5)}`
        : '';
    // user country
    const country = staticData.countryList.filter(e => e.identifier === userData.citizenship)[0];
    // employment status
    const statusName = staticData.employmentStatusList.filter(e => e.identifier === userData.userEmployment.statusIdentifier)[0];
    // employee industry
    const industry = staticData.employmentTypeList.filter(e => e.identifier === userData.userEmployment.typeIdentifier)[0];
    // employee position
    const position = staticData.employmentPositionList.filter(e => e.identifier === userData.userEmployment.positionIdentifier)[0];
    // employee investing experience
    const investingExperience = staticData.investorExperienceList.filter(e => e.identifier === userData.userInvestor.experienceIdentifier)[0];
    // employee annual income
    const annualIncome = DefaultValue.amountRange.filter(e => e.value === amountRangeCalculator(userData.userInvestor.annualIncome))[0];
    // employee networth
    const netWorthTotal = DefaultValue.annualIncomeRange.filter(e => e.value === amountRangeCalculator2(userData.userInvestor.networthTotal))[0];
    // employee risk tolerance
    const riskTolerance = staticData.investorRiskToleranceList.filter(e => e.identifier === userData.userInvestor.riskToleranceIdentifier)[0];
    // gender
    const gender = userData.gender ? userData.gender.replace(/^./, match => match.toUpperCase()) : '';
    // monthly saving object
    const monthlySaving = userData.monthlySaving ? userData.monthlySaving : UserData.monthlySaving;
    // monthly saving status is failed
    const isFailed = monthlySaving.status === 'FAILED';
    const isPaused = monthlySaving.status === 'PAUSED';
    // Investing portfolio name
    const portfolioName = fundLocation.find(e => monthlySaving.forCashAccount
        ? (e.type === 'Cash Account')
        : (e.id === monthlySaving.basketId)
    );
    // date if status is PENDING or FAILED
    const statusDate = isPaused
        ? `(${moment(monthlySaving.statusDate).format('MMM YY')} - Unspecified)`
        : isFailed
            ? `(${moment(monthlySaving.statusDate).format('MMM YY')})`
            : '';
    // show next month amount
    const showChangeAmount = (monthlySaving.beforeCutoffAmount === monthlySaving.afterCutoffAmount || isFailed || isPaused);
    // kyc status check for monthly saving
    const showKycStatusMonthly = appCommonData.isKycProcessing || appCommonData.isKycManualReview || appCommonData.isKycRejected || appCommonData.isKycDenied;
    // if show kyc status is true then check status
    const kycStatusMonthly = (appCommonData.isKycProcessing || appCommonData.isKycManualReview)
        ? 'PENDING'
        : appCommonData.isKycRejected
            ? 'DENIED_ACTION'
            : 'DENIED';

    // date format for DOB
    const dobFormat = userData.kycCountry === 'ISR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

    return (
        <>
            {isLoading && <LoadingComponent />}

            {/* page header */}
            <PageHeader
                textStrong={`hello`}
                userName={firstName}
                className='g-heading px-sm-0 px-4'
                otherAttr={'gift_head_text2'}
            />

            {/* main page content */}
            <div className="row gx-2">
                {/* main content */}
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-12">
                            {/* My account & page header */}
                            <div className="row">
                                <div className="col-12">
                                    {/* page header */}
                                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                        <div className="d-flex align-items-center gi-d-new-heading py-2 px-md-5 px-3">
                                            <h5>
                                                {IntlMessages('dashboard_account_setting')}
                                            </h5>
                                        </div>
                                        {/* card section */}
                                        <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                            {/* card heading */}
                                            <ListHeading headingLabel={'account_setting_my_account'} editData={'myAccount'} editClick={editClick} />
                                            {/* card content */}
                                            <div className="gi-d-ac-card">
                                                <ListData
                                                    listLabel={`account_setting_email`}
                                                    listData={user?.email ?? ''}
                                                    showVerify={true}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />
                                                {userData.kycCountry === 'ISR'
                                                    ? <ListData
                                                        listLabel={`account_setting_phone_il`}
                                                        listData={phoneNumberIL}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName='ltr'
                                                        iText=''
                                                        bText=''
                                                    />
                                                    : <ListData
                                                        listLabel={`account_setting_phone`}
                                                        listData={phoneNumberUS}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Bank Account */}
                            {userData.kycCountry !== 'ISR' && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                            <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                                {/* card heading */}
                                                <ListHeading headingLabel='account_setting_bank' editData={''} editClick={editClick} />
                                                {/* card body */}
                                                <div className="gi-d-saving-ac-card px-md-4 px-0">
                                                    <div className="gi-d-saving-detail">
                                                        <div className="gi-d-saving-text">
                                                            {connectBankData.name}
                                                        </div>
                                                    </div>
                                                    <div className="gi-d-saving-link">
                                                        {connectBankData.id
                                                            ? <a onClick={openBankUnlinkModal} className='unlink-text'>
                                                                {IntlMessages('unlink')}
                                                            </a>
                                                            : <a onClick={bankConnectModal} className='link-text'></a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Monthly Saving */}
                            {(userData.kycCountry === 'ISR' && !(appCommonData.isKycDenied || appCommonData.isKycRejected)) && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                            <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                                {/* card heading */}
                                                <ListHeading headingLabel='account_setting_bank_monthly' editData={userData.monthlySavingSetup ? 'monthlySaving' : ''} editClick={editClick} />
                                                {/* card body */}
                                                {userData.monthlySavingSetup
                                                    ? <div className="gi-d-ac-card">
                                                        {/* Status */}
                                                        <ListData
                                                            listLabel={`account_setting_saving_status`}
                                                            listData={DefaultValue.monthlySavingStatus[showKycStatusMonthly ? kycStatusMonthly : monthlySaving.status].label}
                                                            showVerify={false}
                                                            labelClassName=''
                                                            dataClassName={DefaultValue.monthlySavingStatus[showKycStatusMonthly ? kycStatusMonthly : monthlySaving.status].className}
                                                            iText={statusDate}
                                                            bText=''
                                                        />
                                                        {/* Work Email */}
                                                        <ListData
                                                            listLabel={`account_setting_work_email`}
                                                            listData={userData.workEmail}
                                                            showVerify={!isFailed}
                                                            labelClassName={isFailed ? 'disabled-text' : ''}
                                                            dataClassName={isFailed ? 'disabled-text' : ''}
                                                            iText=''
                                                            bText=''
                                                        />
                                                        {/* Monthly savings amount */}
                                                        <ListData
                                                            listLabel={`account_setting_saving_amount`}
                                                            listData={getAmountWithSymbol(monthlySaving.beforeCutoffAmount)}
                                                            showVerify={false}
                                                            labelClassName={isFailed ? 'disabled-text' : ''}
                                                            dataClassName={isFailed ? 'disabled-text' : ''}
                                                            iText=''
                                                            bText={showChangeAmount ? '' : <>({getAmountWithSymbol(monthlySaving.afterCutoffAmount)})</>}
                                                        />
                                                        {/* Investment portfolio */}
                                                        <ListData
                                                            listLabel={`account_setting_portfolio`}
                                                            listData={portfolioName ? portfolioName.name : ''}
                                                            showVerify={false}
                                                            labelClassName={isFailed ? 'disabled-text' : ''}
                                                            dataClassName={isFailed ? 'disabled-text' : ''}
                                                            iText=''
                                                            bText=''
                                                        />
                                                    </div>
                                                    : <div className="gi-d-saving-ac-card">
                                                        <div className="gi-d-saving-detail">
                                                            <div className="gi-d-saving-text">
                                                                {IntlMessages('account_setup_bank_monthly')}
                                                            </div>
                                                        </div>
                                                        <div className="gi-d-saving-link">
                                                            <a onClick={openMonthlySavingModal} className='link-text'></a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Personal information */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                        <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                            {/* card heading */}
                                            <ListHeading headingLabel='account_setting_personal' editData={'personalData'} editClick={editClick} />
                                            {/* card body */}
                                            <div className="gi-d-ac-card">
                                                {/* first  name */}
                                                <ListData
                                                    listLabel={`account_setting_first`}
                                                    listData={userData.kycCountry === 'ISR' ? (userData.metadataList?.find(item => item.fieldName === 'firstNameLocale')?.fieldValue ?? '') : userData.firstNameEncrypted}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />

                                                {/* last name */}
                                                <ListData
                                                    listLabel={`account_setting_last`}
                                                    listData={userData.kycCountry === 'ISR' ? (userData.metadataList?.find(item => item.fieldName === 'lastNameLocale')?.fieldValue ?? '') : userData.lastNameEncrypted}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />

                                                {/* address */}
                                                <ListData
                                                    listLabel={`account_setting_address`}
                                                    listData={fullAddress}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />

                                                {/* country */}
                                                <ListData
                                                    listLabel={`account_setting_citizenship`}
                                                    listData={country && country.name}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />

                                                {/* ssn and ID number */}
                                                {userData.kycCountry === 'ISR'
                                                    ? <ListData
                                                        listLabel={`account_setting_id_number`}
                                                        listData={userData.taxIdNumber}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName='ltr'
                                                        iText=''
                                                        bText=''
                                                    />
                                                    : <ListData
                                                        listLabel={`account_setting_ssn`}
                                                        listData={ssnNumber}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                }

                                                {/* dob */}
                                                <ListData
                                                    listLabel={`account_setting_dob`}
                                                    listData={moment(userData.dob).format(dobFormat)}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />

                                                {/* gender, firstName and lastName in english*/}
                                                {userData.kycCountry === 'ISR' && (
                                                    <>
                                                        <ListData
                                                            listLabel={`account_setting_gender`}
                                                            listData={gender}
                                                            showVerify={false}
                                                            labelClassName=''
                                                            dataClassName=''
                                                            iText=''
                                                            bText=''
                                                        />
                                                        <ListData
                                                            listLabel={`account_setting_first_en`}
                                                            listData={userData.firstNameEncrypted}
                                                            showVerify={false}
                                                            labelClassName=''
                                                            dataClassName=''
                                                            iText=''
                                                            bText=''
                                                        />
                                                        <ListData
                                                            listLabel={`account_setting_last_en`}
                                                            listData={userData.lastNameEncrypted}
                                                            showVerify={false}
                                                            labelClassName=''
                                                            dataClassName=''
                                                            iText=''
                                                            bText=''
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Current Employment Status */}
                            <div className="row" >
                                <div className="col-12">
                                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                        <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                            {/* card heading */}
                                            <ListHeading headingLabel='account_setting_status' editData={'statusData'} editClick={editClick} />
                                            {/* card body */}
                                            <div className="gi-d-ac-card">
                                                <ListData
                                                    listLabel={`account_setting_status1`}
                                                    listData={statusName && statusName.nameLocale}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />
                                                <ListData
                                                    listLabel={`account_setting_company_name`}
                                                    listData={userData.userEmployment.company ? userData.userEmployment.company : 'No'}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />
                                                <ListData
                                                    listLabel={`account_setting_industry`}
                                                    listData={`${industry ? industry.nameLocale : 'No'}`}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />
                                                <ListData
                                                    listLabel={`account_setting_position`}
                                                    listData={`${position ? position.nameLocale : 'No'}`}
                                                    showVerify={false}
                                                    labelClassName=''
                                                    dataClassName=''
                                                    iText=''
                                                    bText=''
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Broker Dealer and Political Exposure */}
                            {userData.kycCountry !== 'ISR' && (
                                <div className="row" >
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                            <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                                {/* card heading */}
                                                <ListHeading headingLabel='account_setting_broker' editData={'brokerData'} editClick={editClick} />
                                                {/* card body */}
                                                <div className="gi-d-ac-card">
                                                    <ListData
                                                        listLabel={`account_setting_broker_dealer`}
                                                        listData={`${userData.userEmployment.affiliatedWithBroker ? 'Yes' : 'No'} `}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                    <ListData
                                                        listLabel={`account_setting_public_company`}
                                                        listData={`${userData.userEmployment.directorOf ?? 'No'}`}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                    <ListData
                                                        listLabel={`account_setting_political`}
                                                        listData={`${userData.politicallyExposedNames ?? 'No'}`}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Investing Information */}
                            {userData.kycCountry !== 'ISR' && (
                                <div className="row" >
                                    <div className="col-12">
                                        <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                            <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                                {/* card heading */}
                                                <ListHeading headingLabel='account_setting_investing' editData={'investingData'} editClick={editClick} />
                                                {/* card body */}
                                                <div className="gi-d-ac-card">
                                                    <ListData
                                                        listLabel={`account_setting_investing_exp`}
                                                        listData={investingExperience && investingExperience.nameLocale}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                    <ListData
                                                        listLabel={`account_setting_annual_income`}
                                                        listData={annualIncome && annualIncome.label}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                    <ListData
                                                        listLabel={`account_setting_net_worth`}
                                                        listData={netWorthTotal && netWorthTotal.label}
                                                        showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                    <ListData
                                                        listLabel={`account_setting_risk_tolerance`}
                                                        listData={riskTolerance && riskTolerance.nameLocale} showVerify={false}
                                                        labelClassName=''
                                                        dataClassName=''
                                                        iText=''
                                                        bText=''
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Email Notification */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2">
                                        <div className="gi-d-ac-new-card pt-md-0 p-md-5 pt-0 p-3">
                                            {/* card heading */}
                                            <div className="gi-d-ac-heading-main">
                                                <div className="gi-d-ac-heading py-2">
                                                    <h5>
                                                        {IntlMessages('account_setting_notification1')} | <span>{IntlMessages('account_setting_notification2')}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                            {/* card body */}
                                            <div className="gi-d-ac-card">
                                                <div className="gi-s-notification-main">
                                                    {/* email notification */}
                                                    <div className={`gi-s-notification-top ${userData.kycCountry === 'ISR' ? 'pb-4' : ''}`}>
                                                        <h6>{IntlMessages('account_setting_notification_text1')}</h6>
                                                        {/* email notification option */}
                                                        {emailNotification.map((e, i) => (
                                                            <div className="setting-radio form-check" key={i}>
                                                                <InputCheckboxComponent
                                                                    labelText={e.label}
                                                                    fieldName={e.name}
                                                                    fieldValue={e.name}
                                                                    checkValue={e.active}
                                                                    inputClass={'form-check-input'}
                                                                    labelClass={'form-check-label'}
                                                                    inputValue={setInputValueCheckbox}
                                                                    dataTestId={e.name}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* annual report notification */}
                                                    {userData.kycCountry === 'ISR' && (
                                                        <div className="gi-s-notification-top border-top pt-3">
                                                            <h6>{IntlMessages('account_setting_notification_report')}</h6>
                                                            {annualReportNotification.map((e, i) => (
                                                                <div className="setting-radio form-check" key={i}>
                                                                    <InputCheckboxComponent
                                                                        labelText={e.label}
                                                                        fieldName={e.name}
                                                                        fieldValue={e.name}
                                                                        checkValue={e.active}
                                                                        inputClass={'form-check-input'}
                                                                        labelClass={'form-check-label'}
                                                                        inputValue={setInputValueCheckbox}
                                                                        dataTestId={e.name}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {/* update notification btn & message */}
                                                    <div className="gi-s-notification-bottom">
                                                        <p>{IntlMessages('account_setting_notification_text2')}</p>
                                                        <div className="gi-s-notification-group">
                                                            <button type="button" disabled={!isNotificationChange} onClick={updateEmailNotification}>{IntlMessages('account_setting_notification_btn')}</button>
                                                            {notificationUpdate && <h6>{IntlMessages('account_setting_notification_msg')}</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Delete Account button */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-d-delete-ac mt-3 mb-4 py-1">
                                        {/* <a href="#" data-bs-toggle="modal" data-bs-target="#setting-error-modal">Delete account</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* right side banner */}
                <div className="col-lg-3" >
                    <GiraffeLearnBannerSection />
                </div>
            </div>

            {/* blank space */}
            <div className="row">
                <div className="col-12">
                    <div className="h-dash"></div>
                </div>
            </div>

            {/* bank unlink loading modal */}
            <LoadingModal
                openLoadingModal={isLoadingModal}
                title={'loading'}
                onHandleClose={onHandleClose}
            />

            {/* bank unlink confirmation modal */}
            <ConfirmationModal
                openConfirmationModal={openUnlinkConfirmationModal}
                message={'account_unlink_confirmation'}
                onHandleClose={onHandleClose}
                onHandleConfirm={bankUnlinkModal}
            />

            {/* bank unlink error modal */}
            <ErrorPendingModal
                openModelError={openUnlinkErrorModal}
                closeErrorModal={onHandleClose}
                h3={'error_unlink_bank'}
                label={'error_please_try'}
                image={intl.formatMessage({ id: 'UNLINK_BANK_SVG' })}
            />

            {/* Bank connect modal */}
            <BankConnectModal
                userName={userData.name}
                openBankModal={openBankConnectModal}
                closeBankModal={onHandleClose}
                componentDidMount={initialAPICall}
                plaidData={plaidData}
                message={'bank_text1'}
            />

            {/* account edit modal */}
            <AccountEditModal
                openEditModalFlag={openEditModalFlag}
                openEditModal={openEditModal}
                userData={userData}
                staticData={staticData}
                onHandleClose={onHandleClose}
                updatedData={updateUser}
            />

            {/* monthly saving modal */}
            <MonthlySavingModals
                isModalOpen={monthlySavingModal}
                organizationName={userData.organizationName}
                deductionDate={userData.deductionDate}
                defaultCurrency={userData.defaultCurrency}
                getFundLocation={fundLocation}
                loadingSavingModal={loadingSavingModal}
                isKycPending={userData.isKycProcessing}
                onHandleClose={onHandleClose}
                onHandleSuccess={initialAPICall}
                onHandleSuccessEmail={onHandleSuccessEmail}
                onHandleNotListEmail={onHandleNotListEmail} />

            {/* monthly saving modal edit */}
            <MonthlySavingEditModal
                isModalOpen={monthlySavingEditModal}
                organizationName={userData.organizationName}
                deductionDate={userData.deductionDate}
                defaultCurrency={userData.defaultCurrency}
                monthlySaving={monthlySaving}
                fundLocation={fundLocation}
                onHandleClose={onHandleClose}
                onHandleSuccess={initialAPICall}
            />
        </>
    )
}

export default AccountSetting