import enLang from './entries/en-US';
import hrLang from './entries/he-IL';
import * as ConfigInterface from 'interface/Config';

const AppLocale: { [key: string]: ConfigInterface.AppLang } = {
  'en_US': enLang,
  'he_IL': hrLang,
};

export default AppLocale;
