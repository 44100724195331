const OtherTranslations: {
    [key: string]: {
        [key: string]: string
    }
} = {
    en_US: {
        annualTitle: 'Annual return:',
        accumulatedTitle: 'Accumulated return:',
        series1: 'Your portfolio',
        series2: 'S&P 500 (managed by advisor)',
        average: 'Average',
        range: 'Range',
        inThousands: 'In thousands',
        portfolio: 'Portfolio',
        sp500: 'S&P 500',
        excludedCompanies: 'Excluded companies',
        addedCompanies: 'Added companies',
        portfolioCompanies: 'Portfolio companies',
        basketImpact: 'Impact fund',
        basketFiveYear: 'Five year savings',
        basketSchool: 'School fund',
    },
    he_IL: {
        annualTitle: ':תשואה שנתית',
        accumulatedTitle: ':תשואה מצטברת',
        series1: 'תיק העבודות שלך',
        series2: 'S&P 500 (מנוהל על ידי יועץ)',
        average: 'מְמוּצָע',
        range: 'טווח',
        inThousands: 'באלפים',
        portfolio: 'תיק עבודות',
        sp500: 'S&P 500',
        excludedCompanies: 'חברות שלא נכללו',
        addedCompanies: 'נוספו חברות',
        portfolioCompanies: 'חברות תיקים',
        basketImpact: 'קרן השפעה',
        basketFiveYear: 'חיסכון של חמש שנים',
        basketSchool: 'קרן בית ספר',
    },
}

export default OtherTranslations