//default library
import * as React from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
//auth0 library
import { useAuth0 } from "@auth0/auth0-react";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// datadog
import { datadogLogs } from '@datadog/browser-logs';
// jwt token decode
import jwt from 'jwt-decode';
//pages
import LandingPage from "pages/LandingPage/LandingPage";
import ThankYouPage from "pages/Auth0Pages/ThankYouPage";
import PlaidRedirect from "pages/Dashboard/PlaidRedirect";
import KYCFailureForm from "pages/Dashboard/KYCFailureForm";
// custom component
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
// session timeout component
import SessionTimeout from "components/SessionTimeoutComponent/SessionTimeout";
import SessionTimeOutModal from "components/ModalComponent/SessionTimeOutModal";
//routes
import ProtectedRoute from "routes/ProtectedRoute";
import OnBoardingRoutes from "routes/OnBoardingRoutes";
import DashboardRoutes from "routes/DashboardRoutes";
import IsraelOnBoarding from "routes/IsraelOnBoarding";
//api call
import * as UserService from "middleware/UserService";
import * as GiftService from "middleware/GiftService";
// type import
import * as RouteInterface from 'interface/Route';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, setCurrentAPILan2, getLocale } from 'reduxStore/reducers/LanguageReducer';
import { setCurrentAppCommonData, getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// custom function
import * as Local from 'service/utils/LocalStorageData';

let checkStatusCount: number = 0

const IS_DEV = import.meta.env.REACT_APP_ENVIRONMENT === 'dev'

const MainRoute: React.FC = () => {

    const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0(); //get auth0 details
    const { initiateAction } = useAccessToken();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);
    const appCommonData = useSelector(getAppCommonData);

    const location = useLocation<RouteInterface.RouteState>()
    const checkStatus = location.state //for the last page name

    const kycCompletePage: Array<string> = ['/on-boarding/kyc-pending', '/on-boarding/congratulation', '/on-boarding/bank-connect'] //confirmation route path

    const [openSessionModal, setOpenSessionModal] = React.useState<boolean>(false) //session modal

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [pageRedirect, setPageRedirect] = React.useState<boolean>(false) //page redirect of dashboard
    const [routeCreate, setRouteCreate] = React.useState<boolean>(false) //check the route is create or not
    const [isGiftUser, setIsGiftUser] = React.useState<boolean>(false) //check the user is gift user or not
    const [isLoadingAPI, setIsLoadingAPI] = React.useState<boolean>(true) //load page until api call finished

    const [defaultLocale, setDefaultLocale] = React.useState<string>(''); //user default language

    // get user details
    const userDetails = async (): Promise<void> => {
        if (isAuthenticated && user?.email_verified) {
            setIsLoadingAPI(true) //start loading
            const accessToken: string = await initiateAction()
            const userDetail = await UserService.getByAccessToken(accessToken)
            setIsLoadingAPI(false) //end loading

            if (userDetail.response || userDetail.request) {
                !locale.direction && dispatch(setCurrentLanguage('USA')); //when initial api is failed and direction is not set
                setErrorLog(userDetail)
                setIsError(true)
            } else {
                Local.setLocalData('hasKycErrors', userDetail.hasKycErrors);
                Local.setLocalData('username', userDetail.name);
                Local.setLocalData('firstNameEn', userDetail.firstNameEncrypted); //English first name
                Local.setLocalData('lastNameEn', userDetail.lastNameEncrypted); //English last name
                Local.setLocalData('firstNameHe', userDetail.firstNameLocale); //Hebrew first name
                Local.setLocalData('lastNameHe', userDetail.lastNameLocale); //Hebrew last name
                Local.setLocalData("identifier", userDetail.identifier);
                Local.setLocalData("dwUserIdentifier", userDetail.dwUserIdentifier); // set user dwUserIdentifier to localStorage

                const isGiftIdentifier = Boolean(jwt<string>(accessToken)[import.meta.env.REACT_APP_GIFT_IDENTIFIER])
                setIsGiftUser(isGiftIdentifier);

                setPageRedirect(true) //set dashboard redirect
                setRouteCreate(true) //route created

                // update the current app data
                dispatch(setCurrentAppCommonData({
                    ...appCommonData,
                    isNewUser: userDetail.isNewUser,
                    isKycProcessing: userDetail.isKycProcessing,
                    isLearnAccess: userDetail.learnAccess,
                    userCreateDateTime: userDetail.createDateTime,
                    kycCountry: userDetail.kycCountry,

                    isKycRejected: userDetail.isInternalKycRejected,
                    isKycManualReview: userDetail.isInternalKycManualReview,
                    isKycDenied: userDetail.isInternalKycDenied,
                }));

                // update the API language to redux
                dispatch(setCurrentAPILan2(userDetail.locale))

                setDefaultLocale(userDetail.locale);
            }
        }
    }

    // update the user if access token is present
    const updateGiftUser = async (): Promise<void> => {
        const accessToken = await initiateAction()
        const isGiftIdentifier = jwt<string>(accessToken)[import.meta.env.REACT_APP_GIFT_IDENTIFIER]
        Boolean(isGiftIdentifier) && GiftService.updateGiftUser(isGiftIdentifier, accessToken)
    }

    React.useEffect(() => {
        (async () => {
            if (!isLoading) {
                if (isAuthenticated && user?.email_verified) {
                    userDetails(); //update the user
                    updateGiftUser(); //update the user gift
                } else {
                    setIsLoadingAPI(false) //end loading
                }
            }
        })()
    }, [isAuthenticated, user, isLoading])

    // change local
    const changeLocal = (e: React.MouseEvent): void => {
        const ele = (e.target as HTMLAnchorElement).getAttribute('data-value')
        ele && dispatch(setCurrentLanguage(ele))
    }

    //check auth0 loading part
    if (isLoading || isLoadingAPI) {
        return <LoadingComponent />;
    }

    // session time-out modal
    const sessionTimeOut = (): void => {
        setOpenSessionModal(true)
    }

    // session time-out modal
    if (openSessionModal) {
        return (
            <div id="sectionOne">
                <SessionTimeOutModal timeoutModalOpen={true} />
            </div>
        )
    }

    //if user email not verify
    if (isAuthenticated && !user?.email_verified) {
        return (
            <>
                <SessionTimeout isAuthenticated={isAuthenticated} sessionTimeOut={sessionTimeOut} />
                <ThankYouPage />
                {IS_DEV && (
                    <div className="lang_box" dir='ltr'>
                        {appCommonData.isNewUser
                            ? <>
                                <a className="lang_selector trn" role="button" data-value="USA" onClick={changeLocal}>US</a>
                                <a className="lang_selector trn" role="button" data-value="ISR" onClick={changeLocal}>IL-HE</a>
                                <a className="lang_selector trn" role="button" data-value="ISR_EN" onClick={changeLocal}>IL-EN</a>
                            </>
                            : appCommonData.kycCountry === 'ISR'
                                ? <>
                                    <a className="lang_selector trn" role="button" data-value="ISR" onClick={changeLocal}>IL-HE</a>
                                    <a className="lang_selector trn" role="button" data-value="ISR_EN" onClick={changeLocal}>IL-EN</a>
                                </>
                                : <a className="lang_selector trn" role="button" data-value="USA" onClick={changeLocal}>US</a>
                        }
                    </div>
                )}
            </>
        )
    }

    //if user is not authenticate from auth0
    if (!isAuthenticated && location.pathname !== '/') {
        try {
            loginWithRedirect();
        } catch (e) {
            datadogLogs.logger.error('Error from Auth0:- ', {
                auth0Error: {
                    message: e.message,
                    user: user?.email
                }
            });
        }

        setIsLoadingAPI(true); //when redirect to login page start loading
        localStorage.clear() //clear all data when user is not authenticate
    }

    if (checkStatus && (checkStatus.updateAccessToken || checkStatus.isILKyc) && checkStatusCount === 0) {
        checkStatusCount++;
        userDetails(); //update the user
        updateGiftUser(); //update the user gift
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            <div id="sectionOne">
                {/* set session timeout */}
                <SessionTimeout isAuthenticated={isAuthenticated} sessionTimeOut={sessionTimeOut} />

                <Switch>
                    <Route exact path='/'>
                        {/* add check condition that user login or not */}
                        <LandingPage pageRedirect={pageRedirect} defaultLocale={defaultLocale} />
                    </Route>

                    <Route path={'/plaid-redirect'}>
                        <PlaidRedirect />
                    </Route>

                    {appCommonData.isKycRejected && <ProtectedRoute path="/kyc-failure-form" component={KYCFailureForm} />}

                    <ProtectedRoute path="/dashboard" component={DashboardRoutes} checkStatus={checkStatus} />

                    {((appCommonData.isNewUser || appCommonData.isKycProcessing) && locale.countryCode !== 'ISR') &&
                        <ProtectedRoute path="/on-boarding" component={OnBoardingRoutes} isGiftUser={isGiftUser} />
                    }

                    {(appCommonData.isNewUser && locale.countryCode === 'ISR') &&
                        <ProtectedRoute path="/on-boarding" component={IsraelOnBoarding} isGiftUser={isGiftUser} />
                    }

                    {routeCreate && <Route path={'*'}>
                        {kycCompletePage.includes(window.location.pathname) ? <Redirect to='/dashboard' /> : <Redirect to='/error' />}
                    </Route>}
                </Switch>

            </div>
            {IS_DEV && (
                <div className="lang_box" dir='ltr'>
                    {appCommonData.isNewUser
                        ? <>
                            <a className="lang_selector trn" role="button" data-value="USA" onClick={changeLocal}>US</a>
                            <a className="lang_selector trn" role="button" data-value="ISR" onClick={changeLocal}>IL-HE</a>
                            <a className="lang_selector trn" role="button" data-value="ISR_EN" onClick={changeLocal}>IL-EN</a>
                        </>
                        : appCommonData.kycCountry === 'ISR'
                            ? <>
                                <a className="lang_selector trn" role="button" data-value="ISR" onClick={changeLocal}>IL-HE</a>
                                <a className="lang_selector trn" role="button" data-value="ISR_EN" onClick={changeLocal}>IL-EN</a>
                            </>
                            : <a className="lang_selector trn" role="button" data-value="USA" onClick={changeLocal}>US</a>
                    }
                </div>
            )}
        </>
    );
}

export default MainRoute;