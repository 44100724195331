//default library
import * as React from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as BasketType from 'api/APIMaster/BasketType'
//custom component
import PortfolioSlider from 'components/SliderComponent/PortfolioSlider';

//defining props
export type Props = {
    selectedEsgcValueList: BasketType.BasketEsgcValueRequestModel[];
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
}

const PortfolioEsgcActivityCard: React.FC<Props> = (props: Props) => {
    const { selectedEsgcValueList, esgcValueList } = props; //destructring props

    React.useEffect(() => {
        return () => {
            ScrollTrigger.getAll().forEach((t: { kill: () => any; }) => t.kill());
        };
    }, []);


    return (
        <>
            {selectedEsgcValueList.length > 0 && (
                <div className="row" data-testid="select-esgc-value">
                    <div className="col-12">
                        <div className="gift-p-activity-card px-lg-5 ps-3 pe-0 py-4 mt-2">
                            {/* static text */}
                            <div className="gift-p-activity-header mt-lg-3 mt-0 pb-3 mb-1">
                                <h5>
                                    {IntlMessages('portfolio_text9')}
                                </h5>
                            </div>
                            {/* slider */}
                            <PortfolioSlider esgcValueList={esgcValueList} selectedEsgcValueList={selectedEsgcValueList} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PortfolioEsgcActivityCard;
