//default library
import * as React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//ProtectedRoute 
import ProtectedRoute from 'routes/ProtectedRoute';
// custom page import
import InformationPage from 'pages/OnBoardingPage/KYCDetails/index';
import { onBoardingIntiRoute } from 'routes/Route';
// react redux
import { useSelector } from 'react-redux';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// dummy data
import * as UserData from 'service/DummyData/UserData';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
// scroll top
import ScrollToTop from 'helper/ScrollToTop';
// api call
import * as UserService from 'middleware/UserService';
// url list check function
import { URLCheckFunction } from 'service/utils/URLCheckFunction';
// type import
import * as RouteInterface from 'interface/Route';
import * as UserType from "api/APIMaster/UserType"

export type Props = {
    isGiftUser: boolean,
}

const OnBoardingRoutes: React.FC<Props> = (props: Props) => {

    const { isGiftUser } = props;

    const appCommonData = useSelector(getAppCommonData);

    const { initiateAction } = useAccessToken();
    const location = useLocation();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [redirectURL, setRedirectURL] = React.useState<string>('');
    const [staticData, setStaticData] = React.useState<UserType.OnboardingStaticDataResponseModel>(UserData.onBoardingStaticData);

    const [workEmail, setWorkEmail] = React.useState<string>('');

    const kycCompletePage: string[] = ['/on-boarding/kyc-pending', '/on-boarding/congratulation', '/on-boarding/bank-connect']

    React.useEffect(() => {
        const sectionOneBG = document.getElementById('sectionOne')
        if (sectionOneBG) {
            sectionOneBG.style.background = 'none'
        }
    }, [])

    // initial api call
    React.useEffect(() => {
        initialAPICall()
    }, [])

    const initialAPICall = async (): Promise<void> => {
        if (!kycCompletePage.includes(location.search) || appCommonData.isNewUser) {
            const accessToken = await initiateAction() //get access token

            const [staticData, userDetails] = await Promise.all([
                UserService.getOnboardingStaticData(accessToken), //on-boarding details api call
                UserService.getDetail(accessToken), //user details api call
            ])
            setIsLoading(false)

            if (staticData.response || staticData.request) {
                setErrorLog(staticData)
                setIsError(true)
            } else {
                setStaticData(staticData);
                setWorkEmail(userDetails.workEmail);
            }
            // 
            if (appCommonData.isNewUser && window.location.pathname !== '/on-boarding') {
                setRedirectURL(URLCheckFunction() as string)
            }
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>

            <ScrollToTop />
            {isLoading
                ? <LoadingComponent />
                : <Switch >
                    {onBoardingIntiRoute.map((ele: RouteInterface.Route, i) => {
                        return (
                            <ProtectedRoute
                                exact
                                path={ele.path}
                                key={i}
                                component={ele.Component}
                                isGiftUser={isGiftUser}
                                staticData={staticData}
                                workEmail={workEmail}
                            />
                        )
                    })}

                    <ProtectedRoute
                        exact
                        path={'/on-boarding/:page'}
                        component={InformationPage}
                        isGiftUser={isGiftUser}
                        staticData={staticData}
                        workEmail={workEmail}
                    />
                </Switch>
            }

            {redirectURL && <Redirect push to={redirectURL} />}

        </>
    );
}

export default OnBoardingRoutes
