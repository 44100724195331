//default library
import * as React from 'react';
import { useParams } from 'react-router-dom';
//auth0 library
import { useAuth0 } from '@auth0/auth0-react';

import * as Route from 'interface/Route';

import { datadogLogs } from '@datadog/browser-logs';


const GiftLandingPage: React.FC = () => {

    const { loginWithRedirect, user } = useAuth0(); //auth0 library

    const params: Route.UrlParams = useParams(); //fetch params with the help of useParams

    const giftId: string = params.id; //set the gift

    React.useEffect(() => {
        if (giftId) {
            try {
                loginWithRedirect({
                    authorizationParams: {
                        screen_hint: "signup",
                        gift_identifier: giftId
                    },
                });
            } catch (e) {
                datadogLogs.logger.error('Error from Auth0:- ', {
                    auth0Error: {
                        message: e.message,
                        user: user?.email
                    }
                });
            }
        }
    }, []) //redirect to sign-up page

    return (
        <></>
    );
}

export default GiftLandingPage

