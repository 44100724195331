// default library
import * as React from 'react';
//types import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    name: string;
    fieldValue: string;
    option: PropsFunction.Option;
    isShowInvestmentClass: boolean;
    dataTestID: string;
    inputValue: PropsFunction.PropsInputValueFunction;
}

const TabSelectComponent: React.FC<Props> = (props: Props) => {

    const { name, fieldValue, option, isShowInvestmentClass, dataTestID, inputValue } = props;

    const handleOnClick = (e: React.MouseEvent): void => {
        const value = (e.target as HTMLLIElement).id
        inputValue(name, value)
    }

    return (
        <ul className={`gi-ob-custom-nav-pills ${isShowInvestmentClass ? 'gi-ob-investment-nav-pills ' : ''} nav nav-pills mt-2 `}>
            {option.map((e, i) => {
                const isActive = e.value === fieldValue
                return (
                    <li className="nav-item" key={i} onClick={handleOnClick} id={`${e.value}`}>
                        <a className={`nav-link text-center ${isActive ? 'active' : ''} `} id={`${e.value}`} data-testid={dataTestID}>
                            {e.label}
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}

export default TabSelectComponent