// default library
import React from 'react';
import moment from 'moment'
//chart
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateFormatter3 } from 'service/utils/DateFormatter';
import { DateRange, monthDiff } from 'service/utils/DateFilter';
// default value for dropdown
import * as DefaultValue from 'config/DefaultValue';
// intl message
import IntlMessages from 'helper/IntlMessages';
// chart option
import { HomePerformanceChart } from 'config/ChartOptions';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardInterface from 'interface/Dashboard';
import * as DashboardAPIType from 'api/APIMaster/DashboardType';
// custom component
import NotificationSection from 'components/SectionComponent/NotificationSection';
import SvgIcon from 'components/CommonComponent/SvgIcon';

export type Props = {
    accountPerformance: DashboardAPIType.AccountPerformanceResponseModel;
    portfolioSummaryData: DashboardAPIType.PortfolioSummaryResponseModel;
    notificationObj: DashboardInterface.NotificationArray;
    isMatchAnimationHeight: boolean;
    isILUser: boolean;
    isMonthlySavingCash: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
    openMonthlyCashModal: PropsFunction.SimpleFunction;
    openInfoModal: PropsFunction.OpenInfoModal;
    cashAccountFundModal: PropsFunction.ParamsIdNumberFunction;
}

const TotalCard: React.FC<Props> = (props: Props) => {

    const { accountPerformance, portfolioSummaryData, notificationObj, isMatchAnimationHeight, isILUser, isMonthlySavingCash, onHandleClose, openInfoModal, cashAccountFundModal, openMonthlyCashModal } = props
    const locale = useSelector(getLocale);

    const [chartSeries, setChartSeries] = React.useState<DashboardInterface.ChartSeries>([{
        name: "",
        data: []
    }])

    const [selectedRange, setSelectedRange] = React.useState<string>('one-month');
    const [showGraphFlag, setShowGraphFlag] = React.useState<boolean>(false);
    const [count, setCount] = React.useState<number>(0)

    const clickClose = (): void => {
        onHandleClose();
        clickMoreInfoAccount();
    }

    // account value info
    const clickAccountValue = (): void => {
        const textHeading = IntlMessages('info_account_heading')

        const messageBody = <p className='mt-3'>
            {IntlMessages('info_account_body1')} <a href='#!' className='text-decoration-underline' onClick={clickClose}>{IntlMessages('button_click')}</a> {IntlMessages('info_account_body2')}
        </p>

        openInfoModal(textHeading, messageBody, false)
    }

    // account value more click info
    const clickMoreInfoAccount = (): void => {
        const textHeading = ''

        const messageBody = <p className='mt-3'>
            {IntlMessages('info_account_more_body', { br: <br /> })}
        </p>

        openInfoModal(textHeading, messageBody, false)
    }

    // total portfolio value info
    const clickPortfolioValue = (): void => {
        const textHeading = IntlMessages('info_portfolio_heading')

        const messageBody = <p className='mt-3'>{IntlMessages('info_portfolio_body', { br: <br /> })}</p>

        openInfoModal(textHeading, messageBody, false)
    }

    // cash balance info
    const clickCashBalance = (): void => {
        const textHeading = IntlMessages('info_cash_balance_heading')

        const messageBody = <p className='mt-3'>{IntlMessages('info_cash_balance_body', { br: <br /> })}</p>

        openInfoModal(textHeading, messageBody, false)
    }

    // performance info
    const clickPerformance = (): void => {
        const textHeading = IntlMessages('info_performance_heading')

        const messageBody = <p className='mt-3'>{IntlMessages('info_performance_body', { br: <br /> })}</p>

        openInfoModal(textHeading, messageBody, false)
    }

    // dynamic tick amount based on the min and max value
    const dynamicTickAmount = (minValue: number, maxValue: number): number => {
        const range = Array(maxValue - minValue + 1)
            .map((_, i) => minValue + i).length;  //get the total number between min and max value

        if (range < 6) {
            return Math.trunc(range); // display range tick
        } else {
            return 6; // display 6 ticks
        }
    };

    // update the apex chart option
    const updateChartOption = (seriesList: DashboardAPIType.PerformanceHistory[]): void => {

        if (showGraphFlag) {

            const diffMonth = monthDiff(new Date(seriesList[0]?.performanceDate), new Date(seriesList[seriesList.length - 1]?.performanceDate))

            const format = diffMonth < 6 ? `MM/DD` : `MMM 'YY`
            const minValue = Math.floor(Math.min(...seriesList.map((e: any) => e.earning)))
            const maxValue = Math.ceil(Math.max(...seriesList.map((e: any) => e.earning)))

            // update apex chart options
            let annotations = {}
            let axisBorder = {
                show: true
            }
            // apex chart
            if (maxValue > 0 && minValue < 0) {
                annotations = {
                    yaxis: [{
                        y: 0,
                        strokeDashArray: 0,
                        borderColor: '#ffffff',
                        opacity: 0.3,
                    }]
                }
                axisBorder = {
                    show: false
                }
            } else {
                annotations = {
                    yaxis: []
                }
                axisBorder = {
                    show: true
                }
            }

            const newUpdateOptions = {
                annotations: annotations,
                xaxis: {
                    ...HomePerformanceChart.xaxis,
                    axisBorder,
                    labels: {
                        ...HomePerformanceChart.xaxis?.labels,
                    },
                    categories: seriesList.map((e: any) => moment(new Date(e.performanceDate).getTime()).format(format)),
                },
                yaxis: {
                    ...HomePerformanceChart.yaxis,
                    tickAmount: dynamicTickAmount(minValue, maxValue),
                    opposite: locale.direction !== 'rtl'
                }
            }

            ApexCharts.exec('performance', 'updateOptions', { ...newUpdateOptions }, false, false, false);
            ApexCharts.exec('performance', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')

            setCount(1)
        }
    }

    // change range of the performance graph
    const changeRange = (e: React.MouseEvent): void => {
        const id = (e.target as HTMLLIElement).id
        updateSeries(id)
    }

    // update the series
    const updateSeries = async (range: string): Promise<void> => {

        //get date range of selected option
        const dateRange = await DateRange(range)

        if (accountPerformance.performanceHistory.length) {
            const seriesList = dateRange.startDate
                ? accountPerformance.performanceHistory.filter(e => new Date(e.performanceDate).getTime() >= new Date(dateRange.startDate).getTime())
                : accountPerformance.performanceHistory;

            updateChartOption(seriesList)

            setChartSeries([{
                name: "",
                data: seriesList.map((e: any) => (e.earning))
            }])
            setSelectedRange(range)
        }

    }

    // 
    const cashAccountFundModalFunction = (): void => {
        cashAccountFundModal(0)
    }

    React.useEffect(() => {
        const showGraphArray = [...new Set(accountPerformance.performanceHistory.map(e => e.earning))]
        const showGraphFlag = showGraphArray.length > 1
            ? true
            : showGraphArray.length === 0
                ? false
                : showGraphArray[0] !== 0
                    ? true
                    : false;

        setShowGraphFlag(showGraphFlag);
    }, [])

    React.useEffect(() => {
        count === 0 && updateSeries('one-month');
    }, [showGraphFlag, count])

    return (
        <div className="row">
            <div className="col-12">
                <div className={`gi-dash-ao-green-card ${isMatchAnimationHeight && 'match-animation'}`}>
                    {/* account overview */}
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-dash-ao-green-header d-flex align-items-center justify-content-between px-lg-5 py-lg-2 px-3 py-3">
                                {/* text */}
                                <h5>{IntlMessages('account_overview')}</h5>
                                {/* button */}
                                <div className="gi-port-ao-green-group d-none d-sm-flex align-items-center gap-2">
                                    <button type="button" className={`gi-port-ao-green-funds-btn d-flex align-items-center justify-content-center gap-2 border-0 ${isILUser ? 'w-auto px-3' : ''}`} onClick={cashAccountFundModalFunction}>
                                        <SvgIcon iconId='white-plus' width={10} height={10} />
                                        {IntlMessages(isILUser ? 'button_monthly_saving' : 'button_add_funds')}
                                    </button>
                                </div>
                                {/* date in mobile */}
                                <div className="mob-date d-flex d-sm-none">
                                    <span>
                                        {DateFormatter3(new Date(Date.now()))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* content */}
                    <div className="gi-dash-ao-green-body px-lg-5 px-3">
                        {/* notification header */}
                        <NotificationSection notificationObj={notificationObj} />

                        {/* investment overview */}
                        <div className={`gi-dash-ao-total-card py-lg-4 py-3 ${notificationObj.length === 0 ? 'border-top-0' : ''}`}>
                            <div className="row gx-3">
                                <div className="col-lg-5">
                                    <div className="gi-dash-ao-total-main">
                                        {/* account value */}
                                        <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                            <h6 className="d-flex align-items-center gap-2">
                                                {IntlMessages('account_value')}
                                                <a className="d-flex align-items-center" onClick={clickAccountValue}>
                                                    <SvgIcon iconId='info-icon' width={16} height={16} />
                                                </a>
                                            </h6>
                                            <h3 className='ltr'>{currencyFormatter(portfolioSummaryData.totalAccountInformation.totalAccountValue)}</h3>
                                        </div>
                                        {/* add fund in mobile */}
                                        <div className="gi-port-ao-total-fund-btn pt-2 mt-1 d-flex flex-column align-items-end gap-2 d-lg-none">
                                            <button type="button" className={`gi-port-ao-green-funds-btn d-flex align-items-center justify-content-center gap-2 border-0 ${isILUser ? 'w-auto px-3' : ''}`} onClick={cashAccountFundModalFunction}>
                                                <SvgIcon iconId='white-plus' width={10} height={10} />
                                                {IntlMessages(isILUser ? 'button_monthly_saving' : 'button_add_funds')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        {/* portfolio value */}
                                        <div className="col-lg-7">
                                            <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                                <h6 className="d-flex align-items-center gap-2">
                                                    {IntlMessages('total_portfolio_value')}
                                                    <a className="d-flex align-items-center" onClick={clickPortfolioValue}>
                                                        <SvgIcon iconId='info-icon' width={16} height={16} />
                                                    </a>
                                                </h6>
                                                <h5 className="mt-lg-4 pt-lg-2 mt-1 pt-0 ltr">{currencyFormatter(portfolioSummaryData.totalInvestmentInformation.totalBasketValue)}</h5>
                                            </div>
                                        </div>
                                        {/* cash balance */}
                                        <div className="col-lg-5">
                                            <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                                <h6 className="d-flex align-items-center gap-2">
                                                    {IntlMessages('cash_balance')}
                                                    <a onClick={clickCashBalance}>
                                                        <SvgIcon iconId='info-icon' width={16} height={16} />
                                                    </a>
                                                    {isMonthlySavingCash && (
                                                        <a onClick={openMonthlyCashModal}>
                                                            <SvgIcon iconId='red-cash-triangle' width={20} height={18} />
                                                        </a>
                                                    )}
                                                </h6>
                                                <h5 className="mt-lg-4 pt-lg-2 mt-1 pt-0 ltr">{currencyFormatter(portfolioSummaryData.totalCashBalance)}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* performance graph */}
                        {showGraphFlag && <div className="gi-dash-ao-performance-card py-3 gi-dash-ao-total-card">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-Historical" role="tabpanel" aria-labelledby="pills-Historical-tab">
                                    <div className="gi-dash-ao-performance-card pt-1">
                                        <div className="row gap-lg-0 gap-4">
                                            {/* Performance */}
                                            <div className="col-lg-3">
                                                <div className="gi-dash-ao-total-text">
                                                    <h6 className="d-flex align-items-center gap-2">
                                                        {IntlMessages('transaction_performance')}
                                                        <a className="d-flex align-items-center" onClick={clickPerformance}>
                                                            <SvgIcon iconId='info-icon' width={16} height={16} />
                                                        </a>
                                                    </h6>
                                                    <h5 className="mt-lg-3 mt-1 ltr">
                                                        {accountPerformance.currentPerformance > 0 ? `+${currencyFormatter(accountPerformance.currentPerformance)}` : currencyFormatter(accountPerformance.currentPerformance)}
                                                    </h5>
                                                </div >
                                            </div >
                                            <div className="col-lg-9">
                                                <div className="gi-dash-ao-performance-tabs ps-2 ms-1">
                                                    <ul className="d-flex align-items-center">
                                                        {DefaultValue.dateRange.map((e, i) => {
                                                            return (
                                                                <li key={i} className={`${e.value === selectedRange && 'active'}`} id={e.value.toString()} onClick={changeRange}>
                                                                    <a id={e.value.toString()}>
                                                                        {e.label}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="gi-dash-ao-performance-graph">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <ReactApexChart options={HomePerformanceChart} series={chartSeries} type='area' height={171} dir='ltr' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >}
                    </div >
                </div >
            </div >
        </div >
    )
}

export default TotalCard