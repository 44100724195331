// default library
import * as React from 'react';
import { Redirect } from 'react-router-dom';
//Handle Button Click Event
import ButtonHandler from 'handler/ButtonHandler';
//message import
import IntlMessages from 'helper/IntlMessages';
//types import
import * as OnBoarding from "interface/OnBoarding"
import * as PropsFunction from "interface/PropsFunction"
// custom function
import * as Local from 'service/utils/LocalStorageData'

export type Props = {
    sendText: boolean;
    nextRoute: string;
    isFooter: boolean;
    isRouteChange: boolean;
    submitText: boolean;
    parentButton: boolean;
    fieldDetails: OnBoarding.OnBoardingData;
    onHandleParentClick?: PropsFunction.SimpleFunction;
}

const ButtonComponent: React.FC<Props> = (props: Props) => {
    const { sendText, nextRoute, isFooter, isRouteChange, submitText, parentButton, fieldDetails, onHandleParentClick } = props; // Destructuring the receiving props

    const [nextDisabled, setNextDisabled] = React.useState<boolean>(true);// next button disabled
    const [routeChange, setRouteChange] = React.useState<boolean>(false);//goto next button
    const [onBoardingDetails, setOnBoardingDetails] = React.useState<string>("{}"); //onboarding details

    React.useEffect(() => {
        const fieldValidation = ButtonHandler(fieldDetails);
        // Setting onBoardingDetails to a stringified 
        setOnBoardingDetails(JSON.stringify(fieldValidation.onBoardingDetails));

        // Update nextDisabled state 
        (fieldValidation.nextDisabled !== nextDisabled) && setNextDisabled(fieldValidation.nextDisabled);
    }, [fieldDetails]);

    const onHandleNext = (): void => {
        Local.setLocalData('onBoardingDetails', JSON.parse(onBoardingDetails));  // Save onBoardingDetails to localStorage
        setRouteChange(true); //goto next page
    };

    const onHandleParentClickFunction = (): void => {
        setRouteChange(true); //goto next page
        onHandleParentClick && onHandleParentClick();
    };

    return (
        <>
            {routeChange && isRouteChange && <Redirect push to={`/on-boarding/${nextRoute}`} />}

            {parentButton
                ? <button
                    type="button"
                    className={`${isFooter ? 'gi-ob-mob-let-btn' : 'gi-ob-next-btn d-none d-sm-block'}`}
                    disabled={nextDisabled}
                    onClick={onHandleParentClickFunction}
                    data-testid="parent-true"
                >
                    {submitText ? IntlMessages('button_submit') : sendText ? IntlMessages('button_send') : IntlMessages('button_next')}
                </button>
                : <button
                    type="button"
                    className={`${isFooter ? 'gi-ob-mob-let-btn' : 'gi-ob-next-btn d-none d-sm-block'}`}
                    disabled={nextDisabled}
                    onClick={onHandleNext}
                    data-testid="parent-false"
                >
                    {submitText ? IntlMessages('button_submit') : sendText ? IntlMessages('button_send') : IntlMessages('button_next')}
                </button>
            }
        </>
    )
}

export default ButtonComponent