import * as CryptoJS from "crypto-js";
import * as Config from 'config/Config'
// local data
import * as Local from 'service/utils/LocalStorageData'

export const URLCheckFunction = () => {
    const kycData = Local.getLocalData("onBoardingDetails")

    //local storage is null
    if (kycData === null) {
        return '/on-boarding';
    }
    // username check
    if (!kycData?.firstName || !kycData?.lastName) {
        return '/on-boarding/page1';
    }
    // phone number check
    else if (!kycData?.phoneNumber) {
        return '/on-boarding/page2';
    }
    // otp check
    else if (kycData?.verifyPhoneNumber && !JSON.parse(CryptoJS.AES.decrypt(kycData?.verifyPhoneNumber, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8))) {
        return '/on-boarding/verification';
    }
    // address check
    else if (!kycData?.addressAutoFill) {
        return '/on-boarding/page3';
    }
    // dob and ssn check
    else if (!kycData?.dateOfBirth || !kycData?.ssnNumber) {
        return '/on-boarding/page4';
    }
    // country check
    else if (!kycData?.citizenship) {
        return '/on-boarding/page5';
    }
    // tax payer check
    else if ((!kycData?.taxPayer || kycData?.taxPayer === 'false') && kycData?.countryLabel !== 'United States') {
        return '/on-boarding/page6';
    }
    // employment status check
    else if (!kycData?.employmentIdentifier) {
        return '/on-boarding/page7';
    }
    // employment details check
    else if (!kycData?.positionIdentifier && (kycData?.employmentLabel === 'Currently working' || kycData?.employmentLabel === 'Self-employed')) {
        return '/on-boarding/page8';
    }
    // employment stock details/broker information check
    else if (!kycData?.publicOfficial) {
        return '/on-boarding/page9';
    }
    // annual income check
    else if (!kycData?.annualIncome) {
        return '/on-boarding/page10';
    }
    // net worth check
    else if (!kycData?.netWorth) {
        return '/on-boarding/page11';
    }
    // investing experience check
    else if (!kycData?.experienceIdentifier) {
        return '/on-boarding/page12';
    }
    // willing risk taking check
    else if (!kycData?.riskTolerance) {
        return '/on-boarding/page13';
    }
    // t&c check
    else if (!kycData?.terms) {
        return '/on-boarding/page14';
    }
}