// default library
import * as React from 'react';
//message import
import IntlMessages from 'helper/IntlMessages';
// lottie animation
import lottie from 'lottie-web/build/player/lottie_light';
// loading animation
import LoadingAnimation from 'service/animation/giraffe_with_heart2.json'
// intl message library 
import { useIntl } from 'react-intl';

const animationStyle = {
    width: 74,
    margin: '0 auto',
    cursor: 'default'
}

const FooterIL: React.FC = () => {
    const linkedinURL = 'https://www.linkedin.com/company/giraffeinvest';
    const termsOfUseURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-terms-of-use-il.pdf`;
    const privacyPolicyURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-privacy-policy-il.pdf`;
    const disclaimerURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-disclaimer-il.pdf`;
    const advisoryURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-advisory-agreement-il.pdf`;

    const intl = useIntl();
    const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

    // load lottie animation
    React.useEffect(() => {
        lottie.loadAnimation({
            container: lottieAnimation.current as any,
            renderer: 'svg',
            animationData: LoadingAnimation,
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true if you want the animation to play automatically
        });
    }, [])

    return (
        <footer className="gi-footer-he">
            <div className="container-xxl">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="px-4">
                            <div className="px-2">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center ">
                                            <div ref={lottieAnimation} style={animationStyle} />
                                            <div className='mt-3'>
                                                <img src={intl.formatMessage({ id: 'GIFT_GIRAFFE_LOGO' })} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row gx-5">
                                    <div className="col-md-4">
                                        <div className="footer-faq-text-he pt-md-5 pt-3">
                                            <h5 className="text-md-start text-center">
                                                {IntlMessages('il_footer_text1')}
                                                <span>
                                                    {IntlMessages('il_footer_text2')}
                                                </span>
                                            </h5>
                                            <p className="mt-3 text-md-start text-center">
                                                {IntlMessages('il_footer_text3')}
                                                &nbsp;&nbsp;&nbsp;
                                                <a href="mailto:support@giraffe.us">support@giraffe.us</a>
                                            </p>
                                        </div>
                                        <div className="gi-footer-social-he d-md-none d-flex gap-4 justify-content-center pt-4 mt-1">
                                            <a href="#!">
                                                <img src={intl.formatMessage({ id: 'IL_QUORA_ICON' })} alt="" />
                                            </a>
                                            <a href="mailto:support@giraffe.us">
                                                <img src={intl.formatMessage({ id: 'IL_THREAD_ICON' })} alt="" />
                                            </a>
                                            <a href={linkedinURL} target="_blank" rel="noopener noreferrer">
                                                <img src={intl.formatMessage({ id: 'IL_LINKEDIN_ICON_MOB' })} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-service-text-he pt-md-5 pt-4 mt-md-0 mt-1">
                                            <p>
                                                <span>
                                                    {IntlMessages('il_footer_text4')}
                                                </span>
                                                &nbsp;{IntlMessages('il_footer_text5')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-agreement-text-he pt-md-5 pt-4 mt-md-0 mt-1">
                                            <p>
                                                {IntlMessages('il_footer_text6')}&nbsp;
                                                <a href={advisoryURL} target="_blank" rel="noopener noreferrer">
                                                    {IntlMessages('il_footer_text7')}
                                                </a>
                                                &nbsp;{IntlMessages('il_footer_text8')}
                                            </p>
                                            <p className="mt-md-3 mt-4 pt-md-0 pt-1">
                                                {IntlMessages('il_footer_text9')}&nbsp;
                                                <u>{IntlMessages('il_footer_text10')}</u>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex align-items-center gap-2 pt-md-5 pt-0 mt-md-1 mt-3">
                                            <div className="gi-footer-links-he px-md-4 px-0 py-md-3 py-0 w-100">
                                                <ul className="d-flex list-unstyled flex-md-row flex-column">
                                                    <li>
                                                        <a href={termsOfUseURL} target="_blank" rel="noopener noreferrer">
                                                            {IntlMessages('il_footer_text11')}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">
                                                            {IntlMessages('il_footer_text12')}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={disclaimerURL} target="_blank" rel="noopener noreferrer">
                                                            {IntlMessages('il_footer_text13')}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="gi-footer-social-he d-md-block d-none ms-1">
                                                <a href={linkedinURL} target="_blank" rel="noopener noreferrer">
                                                    <img src={intl.formatMessage({ id: 'IL_LINKEDIN_ICON_CURVE_DESK' })} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterIL