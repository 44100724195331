// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//types props
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    openModal: boolean;
    messageBody: JSX.Element[];
    dialogClassName: string;
    bodyClassName: string;
    divClassName: string;
    onHandleClose: PropsFunction.SimpleFunction;
}

const InfoModalIL: React.FC<Props> = (props: Props) => {

    const { openModal, onHandleClose, messageBody, dialogClassName, bodyClassName, divClassName } = props;

    return (
        <Modal
            centered
            size='sm'
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName={dialogClassName}
            contentClassName={'border-0'}
            className="gi-ob-green-lb-curve enter-code-modal enter-code-modal-success"
        >
            <button type="button" className="btn-close opacity-100" onClick={onHandleClose} data-testid='close-modal'></button>
            <Modal.Body className={bodyClassName} data-testid="info-modalIl">

                {messageBody.map((message, i) => (
                    <div className={divClassName} key={i}>
                        {message}
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
}

export default InfoModalIL