// default library
import * as React from 'react';
// data dog logs
import { datadogLogs } from '@datadog/browser-logs';
//auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// custom function
import * as Local from 'service/utils/LocalStorageData';

export type Props = {
    giftHash: string;
}

const GiftHeader: React.FC<Props> = (props: Props) => {
    const { giftHash } = props;
    const { loginWithRedirect, user } = useAuth0();
    const intl = useIntl();

    //login function
    const login = (): void => {
        try {
            loginWithRedirect({
                authorizationParams: {
                    gift_identifier: giftHash
                }
            });
        } catch (e) {
            datadogLogs.logger.error('Error from Auth0:- ', {
                auth0Error: {
                    message: e.message,
                    user: user?.email
                }
            });
        }

        Local.setLocalData('triggerMethod', 'register');
    };

    //user signup  function
    const signup = (): void => {
        try {
            loginWithRedirect({
                authorizationParams: {
                    screen_hint: 'signup',
                    gift_identifier: giftHash
                }
            });
        } catch (e) {
            datadogLogs.logger.error('Error from Auth0:- ', {
                auth0Error: {
                    message: e.message,
                    user: user?.email
                }
            });
        }

        Local.setLocalData('triggerMethod', 'register');
    };

    return (
        <header className="gift-header">
            <nav className="navbar navbar-expand-lg navbar-light py-0" aria-label="headerNav">
                <div className="container-fluid">
                    <div className="header-bottom-left">
                        <a className="navbar-brand py-3 d-block" href="https://giraffe.us/" target='_blank' rel="noreferrer">
                            <img className="gi-new-logo" src={intl.formatMessage({ id: 'DASHBOARD_HEADER_LOGO' })} alt="" />
                        </a>
                    </div>
                    <div className="mini-st-nav d-flex align-items-center gap-3 order-lg-2 order-1">
                        <div className="mini-st-nav d-none d-sm-flex align-items-center gap-3">
                            <div className="gi-st-login-btn">
                                <button className='d-flex align-items-center justify-content-center p-0' type="button" onClick={login}>
                                    <span className="user-icon"></span>
                                    {IntlMessages('gift_login')}
                                </button>
                            </div>
                            <div className="gi-st-register-btn">
                                <button className='d-flex align-items-center justify-content-center p-0' type="button" onClick={signup}>
                                    <span className="pencil-icon"></span>
                                    {IntlMessages('gift_register')}
                                </button>
                            </div>
                        </div>
                        <button className="gi-lp-menu d-lg-none d-md-flex p-0 border-0 position-relative" type="button" data-bs-toggle="collapse" data-bs-target="#web-navbar" aria-controls="web-navbar" aria-expanded="false" aria-label="Toggle navigation"></button>
                    </div>
                    <div className="collapse navbar-collapse order-lg-1 order-2 justify-content-end" id="web-navbar">
                        <div className="mini-st-nav d-flex d-sm-none mb-2 align-items-center">
                            <div className="gi-st-login-btn">
                                <button className='d-flex align-items-center justify-content-center p-0' type="button" onClick={login}>
                                    <span className="user-icon"></span>
                                    {IntlMessages('gift_login')}
                                </button>
                            </div>
                            <div className="gi-st-register-btn">
                                <button className='d-flex align-items-center justify-content-center p-0' type="button" onClick={signup}>
                                    <span className="pencil-icon"></span>
                                    {IntlMessages('gift_register')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default GiftHeader