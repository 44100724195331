//auth0 library
import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { BroadcastChannel } from 'broadcast-channel';

// broadcast message 
const logoutChannel = new BroadcastChannel('logout');

let logoutFunction: ((options: LogoutOptions) => void);

const LogoutUser = (): void => {
    logoutFunction({
        logoutParams: {
            returnTo: window.location.origin,
        }
    })
    localStorage.clear(); //clear localStorage
}

// wrap class component in react hook
export function wrapClassInHook(Component: any): (props: any) => JSX.Element {

    const InjectedHook = function (props: any): JSX.Element {

        const auth0 = useAuth0();
        logoutFunction = auth0.logout;
        const logOut = (): void => {
            if (!logoutChannel.close) {
                logoutChannel.postMessage({ logoutMessage: 'user logout' })
            }
            LogoutUser();
        }

        return <Component {...props} logoutUser={logOut} auth0={auth0} />;
    };

    return InjectedHook;
}

// custom hook for logout
export function useLogoutHook(): () => void {
    const auth0 = useAuth0()
    logoutFunction = auth0.logout
    const logoutUser = (): void => {
        logoutChannel.postMessage({ logoutMessage: 'user logout' })
        LogoutUser()
    }

    return logoutUser
}

// logout from all tab
export const logoutAllTabsEventListener = (): void => {
    logoutChannel.onmessage = () => {
        LogoutUser()
        if (!logoutChannel.close) {
            logoutChannel.close()
        }
    }
}