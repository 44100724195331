const Common: { [key: string]: string } = {

    /* Button Name */
    'button_next': 'Next',
    'button_ok': 'OK',
    'button_continue': 'Continue',
    'button_verify': 'Verify',
    'button_cancel': 'Cancel',
    'button_update': 'Update',
    'button_confirm': 'Confirm',
    'button_transfer': 'Transfer',
    'button_submit': 'Submit',
    'button_contact_us': 'Contact Us',
    'button_fund': `Fund your account`,
    'button_buy_fund': 'Transfer',
    'button_buy_invest': 'Invest',
    'button_sell': 'Sell',
    'button_view_details': `View details`,
    'button_invest': `Invest in portfolio`,
    'button_create': `Create`,
    'button_close': `Close`,
    'button_connect': `Connect`,
    'button_back': `Back`,
    'button_change': `Change`,
    'button_delete': `Delete`,
    'button_enter': `Enter`,
    'button_get_started': `Get started`,
    'button_redeem': `Redeem`,
    'button_personalize': `Personalize`,
    'button_start': `Start`,
    'button_add_funds': `Add funds`,
    'button_monthly_saving': `Set monthly savings`,
    'button_view': `View`,
    'button_advanced': `Advance`,
    'button_create_portfolio': `Create portfolio`,
    'button_send': `Send`,
    'button_withdraw': `Withdraw money`,
    'button_withdraw2': `Withdraw`,
    'button_resend_code': 'Resend code',
    'button_click': 'Click',
    'button_edit': 'Edit',
    'button_ok_2': 'Ok',
    'button_resend': 'Resend',
    'button_check': 'Check',
    'button_skip': 'Skip',
    'button_save': 'Save changes',
    'button_save2': 'Save',
    'button_pause': 'Pause',
    'button_resume': 'Resume',

    /* Common text */
    'logout': 'Logout',
    'logout2': 'Log out',
    'click_here': 'Click here',
    'of': 'of',
    'common_verify': 'Verified!',
    'common_verify2': '(verified)',
    'thank_you': `Thank you`,
    'unlink': `Unlink`,
    'hello': `Hello`,
    'note': `Note`,
    'minimum': `minimum`,
    'from': `From`,
    'page': `Page :`,
    'previous': `Previous`,
    'next': `Next`,
    'week': `Week`,
    'faqs': `FAQs`,
    'loading': 'Loading...',
    'gift': `Gift`,
    'total': `Total`,
    'complete': `Complete`,
    'to': `to`,
    'from2': `from`,
    'good': `good`,
    'sp500': `S&P 500`,
    'year': `Year`,
    'log_out': `Log out`,
    'unspecified': 'Unspecified',

    /* ESG-C value name */
    'sustainable_climate': `Sustainable climate`,

    /* Symbol */
    '$': '$',
    '%': '%',

    /* Header and Footer */
    'header_login': `Login`,
    'header_register': `Register`,
    'header_rtl': `Left to Right`,
    'header_ltr': `Right to Left`,
    'footer_giraffe': `What is Giraffe 500?`,
    'footer_products': `Products`,
    'footer_about': `About`,
    'footer_blog': `Blog`,
    'footer_business': `For Business`,
    'footer_education': `Education`,
    'footer_gifts': `Gifts`,
    'gift_login': 'Log in',
    'gift_register': 'Sign up',
    'heading_text_date': `Here's an overview of your investments`,
    'heading_text_hi': `Hi`,

    /* Placeholder */
    'placeholder_type_here': 'Type here',
    'placeholder_auto_select': 'Street address, city, state',
    'placeholder_apartment_unit': 'Apart. suite or unit #',
    'placeholder_country': 'Country',
    'placeholder_employment_status': 'Currently employed',
    'placeholder_amount_range': 'Amount range',
    'placeholder_investing_experience': 'Investing experience',
    'placeholder_email': 'Email Address',
    'placeholder_ticker': 'Ticker',
    'placeholder_investing_exp': 'Investing experience',
    'placeholder_industry': 'Industry',
    'placeholder_position': 'Position',
    'placeholder_dob': 'MM/DD/YYYY',
    'placeholder_phone': '(123) 456 - 7890',
    'placeholder_zip_code': '••••••',
    'placeholder_ssn': '111-11-1111',
    'placeholder_currency_1': '1.00',
    'placeholder_currency_10': '10.00',
    'placeholder_currency_100': '100.00',
    'placeholder_select_value': 'Select value...',
    'placeholder_change_name': 'My retirement',
    'placeholder_code': 'Insert code here',
    'placeholder_enter_amount': 'Enter an amount above $100',
    'placeholder_family_member': 'Name of official and family members',
    'placeholder_company_name': 'Company name',
    'placeholder_dropdown_select': 'Select',

    /* On boarding placeholder */
    'placeholder_first_name': 'Legal first name',
    'placeholder_last_name': 'Legal last name',
    'placeholder_phone_2': '(123) 456-7890',
    'placeholder_apartment_2': 'Apartment / unit #',
    'placeholder_annual_income': 'Income range',
    'placeholder_net_worth': 'Net worth range',

    /* verify email page */
    'thank_you1': `Thank You`,
    'verify_email_t1': `Please click on the link in the verification email to continue`,
    'resend_email': `Resend Email`,
    'wrong_email': `Wrong email?`,
    'sign_out': `Sign out`,

    /* Session time out modal */
    'session_timeout1': `Session Timeout`,
    'session_timeout2': `Session time-out`,
    'session_timeout_t1': `The current session is about to expire in`,
    'session_timeout_t2': `seconds.`,
    'session_timeout_t3': `We signed you out so that your {br} financial information stays safe.`,
    'session_timeout_t4': ` Any confirmed transactions are saved!`,
    'sign_in': `Sign in`,

    /* 2. Dashboard */
    /* 2.1 Route Name */
    'dashboard_home': 'Home',
    'dashboard_add_portfolio': 'Add Portfolio',
    'dashboard_activity': 'Activity',
    'dashboard_account_setting': 'Account Settings',
    'dashboard_account_setting2': 'Account settings',
    'dashboard_taxes': 'Taxes & documentation',
    'dashboard_my_gift': 'My Gifts',
    'dashboard_buy_gift': 'Buy a gift',
    'dashboard_giraffe_view': 'Giraffe View',
    'dashboard_giraffe_learn': 'Financial playlist',
    'dashboard_mini_mba': 'Mini-MBA',
    'dashboard_current_investment': 'Current Investments',
    'dashboard_create_investment': 'Create portfolio',
    'dashboard_learn': 'Financial playlist',
    'dashboard_gift_given': `Gifts I've given`,
    'dashboard_my_impact': `My impact`,
    'dashboard_new_gift_pending': `New Gift Pending`,
    'dashboard_contact_us': `Contact us`,
    'dashboard_finish_setup': `Finish setup`,

    /* KYC error */
    'kyc_trouble_data': `We're having trouble verifying your data`,
    'kyc_address': `We're having trouble verifying address`,
    'kyc_address_label': `Please verify or change your legal address`,
    'kyc_apartment_label': `Apartment/ unit`,
    'kyc_ssn': `We're having trouble verifying your social security number`,
    'kyc_dob': `We're having trouble verifying your date of birth`,
    'kyc_dob_label': `Please verify or change your date of birth`,
    'kyc_name': `We're having trouble verifying your name`,
    'kyc_number': `We're having trouble verifying your phone number`,
    'kyc_number_label': `Please verify or change your phone number`,
    'kyc_citizenship': `We're having trouble verifying your citizenship`,

    /* Modal Header */
    'modal_submit': `Submitting you data`,
    'modal_connect_bank': `Connecting Bank...`,
    'modal_fund_account': `Please fund your account`,

    /* Table Fields */
    'table_not_range': `No existing transactions in the specified date range`,
    'table_date': 'Date',
    'table_description': 'Description',
    'table_amount': 'Amount',
    'table_range': 'Date range',
    'table_ticker': 'Ticker',
    'table_dollar_value': 'Dollar Value',
    'table_industry': 'Industry',
    'table_percentage': 'Percentage of Portfolio',
    'table_document': 'Document name',
    'table_account_name': 'Account Name',
    'table_form_type': 'Form type',
    'table_status': 'Status',
    'table_trade': 'Trade Confirmation',
    'table_no_data': 'No data in specific date range',
    'table_company_name': 'Company Name',

    /* Gift Landing Page */
    'gift_header_about': `About us`,
    'gift_header_contact': `Contact us`,
    'gift_landing_text1': `Dear`,
    'gift_landing_text2': `The Gift of Impact`,
    'gift_landing_text3': `Redeem your gift`,
    'gift_landing_text4': `If you already have an account; you can enter this code on the "gifts" tab in your account:`,
    'gift_landing_text5': `Expand your knowledge with`,
    'gift_landing_text6': `A simple and easy platform where you can watch videos to learn about financial wellness, investing sustainably, inspiring stories about people changing the world, and more!`,
    'gift_landing_text7': `A one year subscription of:`,
    'gift_landing_text8': `Redeem your {giftAmount} investment gift card, and watch it grow with a clear conscience`,
    'gift_landing_text9': `Please see our`,
    'gift_landing_text10': `help center`,
    'gift_landing_text11': `to see a few articles that may help you`,
    'question1': `Who can receive a CLEAN S&P 500 gift?`,
    'answer1': `Anyone who is eligble to set up an investment account in the US - either a US citizen, permanent resident, and/or US tax payer. This is due to regulatory constraints.`,
    'question2': `How can I buy a CLEAN S&P 500 for myself?`,
    'answer2': `Love the attitude! It's actually very easy... just purchase yourself a gift. Send the shareable link to yourself. Click it and you're done.`,
    'question3': `Who can give a gift?`,
    'answer3': `Simply put, anyone! We set it up that you only need to have a credit card in order to give a gift.`,
    'question4': `Why doesn't everyone invest in the S&P 500?`,
    'answer4': `Most professional investors have the S&P 500 in their portfolio! It's one of the most popular indexes in the world.`,
    'question5': `Well then, why do so many investment advisors offer so many other investment strategies?`,
    'answer5': `The answer is simple: If they recommend you to invest only in the S&P 500, they can not justify their high fees. However, if they build a specific investment strategy, they feel that they can request a fee, although, per academic reseach, more than 80% of professtional investment advisors underperform the S&P 500.`,
    'question6': `Want to learn more?`,
    'answer6': `Read about the “efficient frontier” of Harry Markowitz, Nobel prize laureate in economics... or just go ahead and google the results of the S&P 500 vs. professional investment advisors`,
    'question7': `Have any questions about redeeming your gift?`,
    'redeemed_error_modal': 'Oops!',
    'redeemed_error_modal_text1': 'It looks like this gift has already been redeemed.',
    'redeemed_error_modal_text2': 'Please contact',
    'redeemed_error_modal_text3': 'support@giraffe.us',
    'redeemed_error_modal_text4': 'if you think there has been a mistake.',

    /* customer notes */
    'customer_note1': `If you would like to change your phone number or email, please first contact customer support.`,
    'customer_note2': `If you would like to change any of the locked information here, please first contact customer support.`,

    /* Static message */
    'buy_modal_static_message': `Due to constraints around minimum stock holdings, we restrict the lowest fraction of a stock you can buy to $0.05. As such, you may see a lower number of stocks purchased in your portfolio than shown in the page showing the ideal portfolio.`,
    'expense_ratio_text1': `Giraffe uses the term "expense ratio" to describe the percentage of the portfolio that Giraffe charges on an annual basis. Just as an exchange traded fund charges an expense ratio on the total assets in the fund, each portfolio Giraffe offers has its own specific annual fee charged as a percentage of the value of the assets in the portfolio.`,
    'expense_ratio_text2': `The fee shown is on an annual basis, however the fee will be charged on a monthly basis on the first of the month.`,
    'sell_info_text1': `Because of fees associated in selling hundreds of different stocks, Giraffe will need to charge portfolio deconstruction fee of {currency} in order to cover our costs`,
    'sell_info_text2': `in order to cover our costs`,
    'create_basket_static_text1': `Take out most of the companies in the S&P 500 that are in contrast with this value (20 companies)`,
    'create_basket_static_text2': `companies`,
    'create_basket_static_text3': `Only one value can be boosted`,
    'create_basket_static_text4': `Projected variance of your portfolio vs. the S&P 500 over the same time`,
    'taxes_static_text1': `Supplemental Tax Statement`,
    'taxes_static_text2': `foreign sourced income, US government interest, state-by-state municipal breakdown`,
    'giraffe_learn_text1': `With Financial playlist we wish to inspire you and provide you with knowledge about the impact of your investments. We hope that this will help you make more responsible decisions for yourself and the planet.`,

    /* Transaction Pending message */
    'add_fund_maximum_error': `We apologize, but the requested transfer amount exceeds the available cash for trade. You can authorize the transaction for the maximum available amount or wait for a few days and re-evaluate.`,
    'add_fund_zero_error': `We apologize, but you currently have insufficient funds for trading. Please consider adding funds to your account to continue trading.`,
    'error_maximum_amount_withdraw': `We apologize, but the requested withdrawal amount exceeds the available cash for withdrawal, currently at {amount}. Please enter a lower amount.`,
    'add_fund_minimum_error': `The minimum funding amount is $100. Please enter a higher amount to proceed.`,
    'error_minimum_amount_withdraw': `The minimum withdrawal amount is $0.25. Please enter a higher amount to proceed.`,
    'add_fund_from_message': 'Unfortunately, funding transactions are currently unavailable. Please wait until your {type} of {amount} is finalized.',
    'sell_error_message': `We apologize, but you cannot initiate a sell transaction at the moment. Please wait until your current {type} transaction of {amount} is finalized.`,
    'sell_all_message': `We apologize, but your portfolio transactions are not available until your sell-all transaction is finalized.`,
    'add_fund_to_message': `We apologize, but you cannot add funds to this portfolio at the moment. Please wait until your current {type} transaction of {amount} is finalized.`,
    'withdraw_message': 'We apologize, you are currently in the process of a withdrawal transaction. Please wait until your current withdrawal of {amount} is finalized before initiating another withdrawal.',

    'buy_deposit_message': 'Portfolio transactions are not available until your {type} transaction in {basketName} is finalized.',
    'sell_withdraw_message': `Portfolio transactions are not available until your {type} transaction in {basketName} is finalized.`,

    'total_card_message': 'Portfolio and withdrawal transactions are not available since your total cash balance is $0. Please fund your account.',

    'transfer_pending': 'Pending',
    'transfer_amount': 'Amount',
    'transfer_business_day_deposit': 'This transaction will take 2-3 business days to process. Afterwards, you will see the cash in your account and can invest!',
    'transfer_business_day_withdrawal': 'This transaction will take 2-3 business days to process. Afterwards, you will see the cash in your bank account.',

    'bank_transfer_text1': 'Cash transfer',

    'direct_bank': `Your transaction will be completed within 2-3 business days.`,
    'market_open': `Your transaction will be completed in market open hours.`,
    'simple_basket_transfer': `Your transaction will be completed within 5-10 minutes.`,

    /* Default Dropdown Value */
    'yes': 'Yes',
    'no': 'No',
    'past_week': 'Past week',
    'past_three_week': 'Past three months',
    'past_six_week': 'Past six months',
    'past_year': 'Past year',
    'all_time': 'All time',

    'one_month': 'One Month',
    'three_month': 'Three Month',
    'year_to_date': 'YTD',
    'one_year': 'One Year',
    'two_year': 'Two Year',

    'year_number': '{number} Year',
    'years_number': '{number} years',
    'number': '{number}',

    'dynamic_month': '{month} month',
    'dynamic_year': '{year} year',

    'less_forty_thousand': 'Less than $40,000',
    'less_eighty_thousand': 'Between $40,000 and $80,000',
    'less_one_fifty_thousand': 'Between $80,000 and $150,000',
    'less_three_hundred_thousand': 'Between $150,000 and $300,000',
    'more_three_hundred_thousand': 'More than $300,000',
    'less_fifty_thousand': 'Less than $100,000',
    'less_two_lakhs': 'Between $100,000 and $200,000',
    'less_five_lakhs': 'Between $200,000 and $500,000',
    'less_ten_lakhs': 'Between $500,000 and $1,000,000',
    'more_ten_lakhs': 'More than $1,000,000',

    /* Email Subscription */
    'email_subscription_text1': `Your Giraffe Notification Center`,
    'email_subscription_text2': `Your email:`,
    'email_subscription_text3': `Not you? Email support@giraffe.us to let us know about the issue`,
    'email_subscription_text4': `I would like to be subscribed to:`,
    'email_subscription_text5': `For further information, {br} please consult our`,
    'email_subscription_text6': `privacy policy`,
    'email_subscription_text7': `and`,
    'email_subscription_text8': `terms of service.`,
    'email_subscription_marketing': `Product and general Giraffe updates from the Savannah`,
    'email_subscription_learn': `Weekly Financial playlist emails`,
    'email_subscription_button1': `Update notifications`,
    'email_subscription_button2': `Unsubscribe from all`,
    'email_subscription_notification': `Notifications updated`,

    /* Note text */
    'first_note_create_basket': `Your investments are protected by the U.S. government's insurance program. As our brokerage service is a member of the Securities Investor Protection Corporation (SIPC), we offer additional security for your account. In the unlikely event of our firm's failure, the SIPC safeguards your eligible securities and cash up to $250,000 for each category, providing vital coverage against the loss of your investments. It's important to note that while SIPC protection does not cover investment losses resulting from market fluctuations, it does offer essential protection in the event of a brokerage firm's failure. Your trust is highly valued, and we remain dedicated to delivering the highest level of service to you. For more information on SIPC `,
    'second_note_create_basket': `Registered Investment Advisors (RIAs) are pivotal in the financial industry, providing personalized investment advice and portfolio management services to individual clients. What sets RIAs apart is their registration with the Securities and Exchange Commission (SEC) or state securities regulators, which subjects them to comprehensive regulation and oversight. This regulatory framework ensures transparency, integrity, and accountability in their operations, and offers crucial investor protection by requiring RIAs to act as fiduciaries, always prioritizing their clients' best interests.`,

    /* Language Location Dropdown */
    'location_start_heading': 'Before we begin,',
    'location_start_text': 'We just need to confirm{br}your place of residence is-',
    'location_start_sorry_heading': 'We are so sorry',
    'location_start_sorry_text': 'Our services are available{br}only in the United States{br}and Israel at this time.',
    'location_israel': 'Israel',
    'location_us': 'United States',
    'location_other': 'Other',
    'location_btn': 'Yes, continue',

    /* Israel Dropdown Value */
    // Employment Status
    'employment_status_employee': 'Employee',
    'employment_status_independent': 'Independent',
    'employment_status_student': 'Student',
    'employment_status_unemployee': 'Unemployed',
    'employment_status_pensioner': 'Pensioner',

    // Investment purpose
    'investment_purpose_long_term': 'Long term savings',
    'investment_purpose_future': 'Future property purchase',
    'investment_purpose_saving': 'Savings for the benefit of the children',
    'investment_purpose_no_specific': 'There is no specific designation',

    // Amount Intent
    'amount_intent_10': 'Less then 10%',
    'amount_intent_10_50': 'Between 10% - 50%',
    'amount_intent_50_95': 'Between 50% - 95%',
    'amount_intent_95': 'Over 95%',

    // Investment Fund
    'investment_fund_year_2': 'In the next two years',
    'investment_fund_year_2_5': '2 - 5 years',
    'investment_fund_year_6_10': '6 - 10 years',
    'investment_fund_year_11_15': '11 - 15 years',
    'investment_fund_year_15': 'More than 15 years',

    // Other Source of Money
    'other_source_1': 'Salary',
    'other_source_2': 'Business revenue/export/yielding assets',
    'other_source_3': 'Funds accumulated over the years in a bank account owned by me',
    'other_source_4': 'Currency trading activity',
    'other_source_5': 'Selling real estate/redemption of savings/redemption of a provident fund/educational fund',
    'other_source_6': 'Cryptocurrency Activity',
    'other_source_7': 'Scholarship/gift/compensation/claim/inheritance',
    'other_source_8': 'Gambling',
    'other_source_9': 'Annuity/Pension/National Insurance Funds',

    /* MOnthly Saving Status */
    'monthly_saving_status_active': 'Active',
    'monthly_saving_status_paused': 'Pause',
    'monthly_saving_status_failed': 'Failed',
    'monthly_saving_status_pending': 'Pending approval',
    'monthly_saving_status_denied': 'Denied',
    'monthly_saving_status_denied_action': 'Denied - action required ',
};

export default Common;