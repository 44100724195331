// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// message import
import { useIntl } from 'react-intl';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
// jquery library
import $ from 'jquery';
// interweave
import { Markup } from 'interweave';
// custom component
import MyImpactTable from 'components/TableComponent/MyImpactTable';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    openDescriptionModal: boolean;
    esgcIdentifier: string;
    myImpactList: DashboardType.ImpactDetailResponseModel[];
    selectedTab: string;
    onHandleClose: PropsFunction.SimpleFunction;
}

const option: PropsFunction.Option = [
    { value: '0', label: IntlMessages('removed_company'), active: true },
    { value: '1', label: IntlMessages('added_companies'), active: true },
]

const ESGCDescriptionModal: React.FC<Props> = (props: Props) => {

    const { openDescriptionModal, esgcIdentifier, myImpactList, selectedTab, onHandleClose } = props;
    const intl = useIntl();

    const [activeTab, setActiveTab] = React.useState<number>(0);

    React.useEffect(() => {
        setActiveTab(selectedTab === 'removed-company' ? 0 : 1)
    }, [selectedTab])

    const handleOnChange: PropsFunction.SelectedOption = async (_fieldName, fieldValue): Promise<void> => {
        if (fieldValue) {
            const value = Number(fieldValue.value);
            $('#pills-tab li a').removeClass('active show');
            $('#pills-tab li a').eq(value).addClass('active show');
            $('#pills-tabContent .tab-pane').removeClass('active show');
            $('#pills-tabContent .tab-pane').eq(value).addClass('active show');
        }
        const activeTab = checkActiveTab()
        setActiveTab(activeTab)
    }

    // tab change
    const tabClick = (): void => {
        const activeTab = checkActiveTab()
        setActiveTab(activeTab)
    }

    //check active tab in pill tabs
    const checkActiveTab = (): number => {
        const tabs = document.querySelectorAll('#pills-tab li a') //get all tab
        const activeTab = Array.from(tabs).findIndex((e, _i) => e.className.search('active') > 0) //get index of active tab
        return activeTab
    }

    return (
        <Modal
            centered
            size='xl'
            show={openDescriptionModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className="gi-port-ao-pc-companies-modal gi-my-impact-view-modal"
            contentClassName='border-0'
        >
            <Modal.Body className='p-lg-4 p-2'>
                <button className="btn-close-white border-0 opacity-100 p-0 position-absolute" type="button" onClick={onHandleClose} data-testid='close-icon'></button>

                {/* modal data */}
                <div className='p-lg-2 p-1'>
                    <div className="gi-my-impact-view-card">
                        {myImpactList.map((e, i) => {
                            const cardBackground = {
                                background: `url(${e.myImpactBanner2Desktop}) no-repeat top center`,
                            }
                            if (e.identifier === esgcIdentifier) {
                                return (
                                    <React.Fragment key={i}>
                                        {/* ESG-C value banner */}
                                        <div className="gi-my-impact-view-bg w-100" style={cardBackground}></div>
                                        {/*  */}
                                        <div className="gi-my-impact-view-text-card p-lg-5 pb-lg-4 pt-4 mt-lg-0 mt-1">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <div className="gi-my-impact-view-text pb-lg-4">
                                                        <h5 data-testid='value-name'> {e.name}</h5>
                                                        <span>
                                                            <Markup content={e.description} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gi-my-impact-view-table">
                                                <div className='gi-my-impact-view-select-tabs d-block d-lg-none pt-4'>
                                                    <SelectDropdown
                                                        labelClassName={''}
                                                        labelText={''}
                                                        name='companyType'
                                                        option={option}
                                                        fieldValue={option[activeTab]}
                                                        inputValue={handleOnChange}
                                                        placeholder={''}
                                                        ariaLabel={"company-type"}
                                                        className={'react-select-chart'}
                                                        isDisabled={false}
                                                        isModalDropdown={true}
                                                    />
                                                </div>
                                                <div className="gi-my-impact-view-tabs d-none d-lg-block">
                                                    <ul className="nav nav-pills nav-justified gap-2" id="pills-tab" role="tablist" onClick={tabClick} data-testid='tab-click'>
                                                        <li className="nav-item" role="presentation">
                                                            <a className={`nav-link px-1 rounded-0 d-flex align-items-center justify-content-center gap-2 gi-my-impact-remove  ${selectedTab === 'removed-company' ? 'active' : ''}`} id="removed-company" data-bs-toggle="pill" data-bs-target="#pills-home" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" data-testid='removed-tab'>
                                                                <img src={intl.formatMessage({ id: 'REMOVED_COMPANIES_ICON' })} alt="REMOVED_COMPANIES_ICON" />
                                                                {IntlMessages('removed_company')}
                                                            </a>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <a className={`nav-link px-1 rounded-0 d-flex align-items-center justify-content-center gap-2 gi-my-impact-add ${selectedTab === 'added-company' ? 'active' : ''}`} id="added-company" data-bs-toggle="pill" data-bs-target="#pills-profile" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" data-testid='added-tab'>
                                                                <img src={intl.formatMessage({ id: 'ADDED_COMPANIES_ICON' })} alt="ADDED_COMPANIES_ICON" />
                                                                {IntlMessages('added_companies')}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className={`tab-pane fade ${selectedTab === 'removed-company' ? 'show active' : ''} `} id="pills-home" role="tabpanel"
                                                        aria-labelledby="removed-company">
                                                        {e.removedCompanyList && e.removedCompanyList.length > 0
                                                            ? <MyImpactTable addCompany={false} totalCompanyList={e.removedCompanyList} />
                                                            : <h3 data-testid='no-remove-company'>{IntlMessages('impact_no_company_remove')}</h3>
                                                        }
                                                    </div>
                                                    <div className={`tab-pane fade ${selectedTab === 'added-company' ? 'show active' : ''}`} id="pills-profile" role="tabpanel"
                                                        aria-labelledby="added-company">
                                                        {e.addedCompanyList && e.addedCompanyList.length > 0
                                                            ? <MyImpactTable addCompany={true} totalCompanyList={e.addedCompanyList} />
                                                            : <h3 data-testid='no-add-company'>{IntlMessages('impact_no_company_add')}</h3>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ESGCDescriptionModal