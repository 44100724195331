//default library
import * as React from 'react';
//types import
import * as PropsFunction from "interface/PropsFunction";

//defining props
export type Props = {
    name: string;
    fieldLabel: React.ReactNode;
    dataTestId: string;
    fieldId: string;
    fieldValue: string | number;
    checked: boolean;
    inputValue: PropsFunction.PropsInputValueFunction;
}

const InputComponentRadio2: React.FC<Props> = (props: Props) => {
    const { name, fieldLabel, fieldId, fieldValue, checked, inputValue, dataTestId } = props;//destructuring props

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target;
        inputValue(target.name, target.value);
    };

    return (
        <>
            <input
                type="radio"
                name={name} // set field name
                className="form-check-input"
                data-testid={dataTestId}
                id={fieldId}
                value={fieldValue} // set field value
                checked={checked}
                onChange={handleOnChange} // invoke on every key press
            />
            <label className="form-check-label" htmlFor={fieldId}>
                {fieldLabel}
            </label>
        </>
    );
};

export default InputComponentRadio2;
