// default library
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// custom function
import * as Local from 'service/utils/LocalStorageData';
import { getUserName } from 'service/utils/UtilFunctions';
//helper message
import IntlMessages from 'helper/IntlMessages';
// api call
import * as BasketService from 'middleware/BasketService';
//types props
import * as Routes from "interface/Route"
import * as PropsFunction from "interface/PropsFunction";
import * as BasketType from "api/APIMaster/BasketType"
//mock dummy data
import * as BasketData from 'service/DummyData/BasketData'
// custom components
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import MultiGraphSection from 'components/SectionComponent/MultiGraphSection';
import AreaChartPortfolio from 'components/ChartSection/AreaChartPortfolio';
import PortfolioEsgcActivityCard from 'components/CardComponent/PortfolioEsgcActivityCard';
import PortfolioConfirmSection from 'components/SectionComponent/PortfolioConfirmSection';
import PageHeader from 'components/PageHeaderComponent/PageHeader';

// defining props
export type Props = {
  createBasketRoute: PropsFunction.SimpleFunction;
}

const CreatePortfolio: React.FC<Props> = (props: Props) => {
  const { createBasketRoute } = props

  const { initiateAction } = useAccessToken();
  const history = useHistory();
  const location = useLocation<Routes.LocationState>();
  const { user } = useAuth0();
  const locale = useSelector(getLocale);

  const [errorLog, setErrorLog] = React.useState<any>({});//error occurred flag
  const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error occurred flag
  const [isLoading, setIsLoading] = React.useState<boolean>(true);//loading page

  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true); //check redux data render first time or not

  const [esgcValueList, setEsgcValueList] = React.useState<BasketType.BasketCustomizationEsgcValueResponseModel[]>([]);//list of ESG-C value
  const [sp500GraphData, setSp500GraphData] = React.useState<BasketType.BasketPerformanceComparisonData>(BasketData.sp500GraphData);//S&P500 company comparison graph data
  const [investmentDetailGraph, setInvestmentDetailGraph] = React.useState<BasketType.Sp500ReturnOnInvestmentResponseModel[]>([]);// graph data for S&P500
  const [selectedEsgcValueList, setSelectedEsgcValueList] = React.useState<BasketType.GiftEsgcValueRequestModel[]>([]); //list of ESG-C value
  const [userExcludedList, setUserExcludedList] = React.useState<string[]>([]);//user Excluded company list

  const [basketName, setBasketName] = React.useState<string>('');//custom basket name
  const [defaultBasketName, setDefaultBasketName] = React.useState<string>('');//default basket name for edit portfolio
  const [basketID, setBasketID] = React.useState<number>(0); //edit portfolio id

  const userName = getUserName(locale.language);
  const firstName = userName.firstName ? userName.firstName : user?.email?.split('@')[0];


  React.useEffect(() => {
    Local.setLocalData('backButtonClick', true);
    if (!isFirstRender) {
      updateLocalBaseAPI(); //update the local language based api when language is change
    } else {
      setIsFirstRender(false)
      initialAPICall();
    }
  }, [locale.language])

  const initialAPICall = async (): Promise<void> => {
    window.scroll(0, 0);

    const portfolioSummaryData = Local.getLocalData('portfolioSummaryData');
    // if portfolio summary data is not present then redirect to add-portfolio page
    if (!portfolioSummaryData) {
      backClickHandler();
    } else {
      setIsLoading(true);

      const accessToken = await initiateAction();
      const [customizationDetail, sp500Data, investmentData] = await Promise.all([
        BasketService.getBasketCustomizationDetail(accessToken),
        BasketService.getBasketPerformanceComparison(portfolioSummaryData, accessToken),
        BasketService.getSp500ReturnOnInvestment(accessToken),
      ]);

      setIsLoading(false);

      if (customizationDetail.response || customizationDetail.request || sp500Data.response || sp500Data.request || investmentData.response || investmentData.request) {
        (customizationDetail.response || customizationDetail.request) && setErrorLog(customizationDetail);
        (sp500Data.response || sp500Data.request) && setErrorLog(sp500Data);
        (investmentData.response || investmentData.request) && setErrorLog(investmentData);
        setErrorAPI(true);
      } else {

        setEsgcValueList(customizationDetail.esgcValueList);
        setSelectedEsgcValueList(portfolioSummaryData.selectedEsgcValueList);
        setUserExcludedList(portfolioSummaryData.userExcludedList);
        setSp500GraphData(sp500Data);
        setInvestmentDetailGraph(investmentData);

        if (location.state && location.state.basketName) {
          setBasketName(location.state.basketName);
          setDefaultBasketName(location.state.basketName);
        }

        if (location.state && location.state.basketID) {
          setBasketID(location.state.basketID);
        }
      }
    }
  };

  // update the local language based api when language is change
  const updateLocalBaseAPI = async (): Promise<void> => {
    setIsLoading(true);

    const accessToken = await initiateAction() //get access token
    const customizationDetail = await BasketService.getBasketCustomizationDetail(accessToken);
    setIsLoading(false);

    if (customizationDetail.response || customizationDetail.request) {
      setErrorLog(customizationDetail);
      setErrorAPI(true)
    } else {
      setEsgcValueList(customizationDetail.esgcValueList);
    }
  }

  // input basket name
  const inputBasketName = (value: string): void => {
    setBasketName(value);
    setDefaultBasketName('');
    window.scroll(0, document.documentElement.scrollTop);//scroll to the top of the page
  };

  // previous page
  const backClickHandler = () => {
    history.push({ pathname: 'add-portfolio', state: { ...location.state, basketName: basketName, basketID: basketID } });
  };

  // confirm basket
  const confirmBasketHandler = async (): Promise<void> => {
    setIsLoading(true);
    const accessToken = await initiateAction();//get access token

    const basketData = {
      basketName: basketName,
      esgcValueList: selectedEsgcValueList,
      userExcludedCompanyList: userExcludedList,
    };

    const createBasket = basketID
      ? (await BasketService.editPortfolio({ ...basketData, basketId: basketID }, accessToken,))    //edit portfolio api call
      : (await BasketService.createBasket(basketData, accessToken));

    if (createBasket.response || createBasket.request) {
      setErrorLog(createBasket);
      setErrorAPI(true);
    } else {
      await createBasketRoute();
      setIsLoading(false);
      Local.removeLocalData('portfolioSummaryData');
      Local.removeLocalData('backButtonClick');

      if (location.state.previousPath) {
        history.push({
          pathname: location.state.previousPath,
          state: {
            isMonthlySavingEditOpen: true
          }
        });
      } else {
        history.push(`/dashboard/portfolio/${createBasket.id}`);
      }
    }
  };

  if (errorAPI) {
    throw new Error(JSON.stringify(errorLog));
  }

  return (
    <>
      {isLoading
        ?
        <LoadingComponent />
        : <>
          <PageHeader
            className={'g-heading px-sm-0 px-2'}
            textStrong={'contact_us_header2'}
            userName={firstName}
            otherAttr={'home_heading'}
          />

          {/* Basket name  */}
          <div className="row" >
            <div className="col-12">
              <div className="gift-p-portfolio-name-card">
                {/* static text */}
                <div className="row">
                  <div className="col-12">
                    <div className="gift-p-portfolio-name-header px-lg-5 px-4 py-4">
                      {IntlMessages('portfolio_text17')}
                    </div>
                  </div>
                </div>
                {/* portfolio name and confirm button */}
                <PortfolioConfirmSection
                  basketName={basketName}
                  inputBasketName={inputBasketName}
                  confirmBasket={confirmBasketHandler}
                  editBasketName={defaultBasketName}
                />
                {/* back and next button */}
                <div className="row">
                  <div className="col-12">
                    <div className="gift-p-portfolio-name-btn-group py-4 px-5 d-flex align-items-center justify-content-end gap-3">
                      {/* back button */}
                      <button
                        type="button"
                        className="back-btn d-flex align-items-center justify-content-center gap-2"
                        onClick={backClickHandler}
                        data-testid="back-button"
                      >
                        <span className="icon-end d-block"></span>
                      </button>
                      {/* next button */}
                      <button
                        type="button"
                        className="start-btn d-flex align-items-center justify-content-center gap-2"
                        disabled={basketName.length <= 1}
                        onClick={confirmBasketHandler}
                      >
                        {IntlMessages('button_next')}
                        <span className="icon-end d-block"></span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* blank space */}
                <div className="row">
                  <div className="col-12">
                    <div className="gift-p-portfolio-name-footer d-lg-block d-none pt-3 pb-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* multi graph */}
          <MultiGraphSection sp500GraphData={sp500GraphData} />

          {/* line graph */}
          <AreaChartPortfolio investmentDetailGraph={investmentDetailGraph} />

          {/* ESG-C value slider  banner  */}
          <PortfolioEsgcActivityCard selectedEsgcValueList={selectedEsgcValueList} esgcValueList={esgcValueList} />

          {/* blank space */}
          <div className="row">
            <div className="col-12">
              <div className="h-dash"></div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default CreatePortfolio;

