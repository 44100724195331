import * as PlaidType from "api/APIMaster/PlaidType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class PlaidService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.PLAID
        });
    }

    /**
     * @description Create token
     *
     * @name GetToken
     * @summary Create link token for customer
     * @request POST:/user/create-token
     */
    getToken = (data: PlaidType.CreateLinkRequestModel, params: RequestParams = {}) =>
        this.request<PlaidType.GetTokenData, void | ExceptionResponse>({
            secure: true,
            path: URLList.PLAID_SERVICE.CREATE_TOKEN.path,
            method: URLList.PLAID_SERVICE.CREATE_TOKEN.method,
            body: data,
            ...params,
        });

    /**
     * @description Generate processor token
     *
     * @name GenerateProcessorToken
     * @summary Generate processor token basis public token
     * @request POST:/user/generate-processor-token
     */
    generateProcessorToken = (data: PlaidType.ProcessorTokenRequestModel, params: RequestParams = {}) =>
        this.request<PlaidType.GenerateProcessorTokenData, void | ExceptionResponse>({
            secure: true,
            path: URLList.PLAID_SERVICE.GENERATE_PROCESSOR_TOKEN.path,
            method: URLList.PLAID_SERVICE.GENERATE_PROCESSOR_TOKEN.method,
            body: data,
            ...params,
        });

}

export default PlaidService