import * as DashboardType from 'api/APIMaster/DashboardType';
import * as Dashboard from 'interface/Dashboard';

export const totalAccountInformation: DashboardType.TotalAccountInformationResponseModel = {
    totalAccountValue: 0,
    totalGain: 0,
}

export const totalInvestmentInformation: DashboardType.TotalInvestmentInformationResponseModel = {
    totalBasketValue: 0,
    totalBasketGain: 0,
}

export const esgcValues: DashboardType.BasketSummaryEsgcValueResponseModel = {
    name: '',
    logo: '',
}

export const baskets: DashboardType.BasketSummaryResponseModel = {
    twentyYearProjection: 0,
    id: 0,
    identifier: '',
    name: '',
    isGiftBasket: false,
    giftAmount: 0,
    currentValue: 0,
    isFirstInvestmentPending: false,
    isInvestmentPending: false,
    isPending: false,
    isOrderOnHold: false,
    isBankOrderPending: false,
    pendingTransactionDate: '',
    pendingOrderType: '',
    pendingOrderAmount: 0,
    totalGain: 0,
    esgcValues: [esgcValues],
}

export const esgcValuesGift: DashboardType.GiftEsgcValueResponseModel = {
    esgcValueIdentifier: '',
    deepClean: false,
    addGoodCompanies: false,
}

export const pendingGifts: DashboardType.GiftDetailResponseModel = {
    amount: 0,
    status: '',
    fromGiftCode: false,
    redemptionDate: '',
    pendingDate: '',
    esgcValueChangeAllowed: false,
    indexTypeChangeAllowed: false,
    id: 0,
    identifier: '',
    giverName: '',
    receiverFirstName: '',
    receiverLastName: '',
    esgcValues: [esgcValuesGift],
    userExcludedCompanies: [''],
}

export const portfolioSummary: DashboardType.PortfolioSummaryResponseModel = {
    locale: '',
    totalAccountInformation: totalAccountInformation,
    totalCashBalance: 0,
    totalCashAvailableForTrade: 0,
    totalCashAvailableForWithdrawal: 0,
    totalPendingTransfer: 0,
    totalCashInSettlement: 0,
    isInvestmentPending: false,
    investmentPendingDate: '',
    totalInvestmentInformation: totalInvestmentInformation,
    baskets: [baskets],
    pendingGifts: [pendingGifts],
}

export const performanceHistory: DashboardType.PerformanceHistory = {
    earning: 0,
    performanceDate: ''
}

export const accountPerformance: DashboardType.GetAccountPerformanceData = {
    currentPerformance: 0,
    performanceHistory: [performanceHistory]
}

export const notificationObj: Dashboard.NotificationObject = {
    transactionDate: '',
    message: '',
}

export const companyList: DashboardType.ImpactCompanyResponseModel = {
    identifier: '',
    name: '',
    description: '',
    tickerSymbol: '',
    industry: '',
}

export const impactDetailResponseModelList: DashboardType.ImpactDetailResponseModel = {
    identifier: '',
    name: '',
    description: '',
    deepClean: false,
    myImpactDescription: '',
    myImpactDashboardBannerDesktop: '',
    myImpactDashboardBannerMobile: '',
    myImpactDashboardBannerText: '',
    myImpactDashboardDeepCleanBannerText: '',
    myImpactBannerText: '',
    myImpactDeepCleanBannerText: '',
    myImpactRemovedCompaniesText: '',
    myImpactDeepCleanRemovedCompaniesText: '',
    myImpactAddedCompaniesText: '',
    myImpactBanner1Desktop: '',
    myImpactBanner1Mobile: '',
    myImpactBanner1Color: '',
    myImpactBanner2Desktop: '',
    myImpactBanner2Mobile: '',
    removedCompanyList: [companyList],
    addedCompanyList: [companyList],
}

export const myImpactData: DashboardType.GetAllImpactDetailData = {
    isExample: false,
    isInvestmentPending: false,
    impactDetailResponseModelList: [impactDetailResponseModelList],
}

export const getGiftReceiveData: DashboardType.GiftDetailResponseModel = {
    amount: 0,
    status: "",
    fromGiftCode: false,
    redemptionDate: "",
    pendingDate: "",
    esgcValueChangeAllowed: false,
    indexTypeChangeAllowed: false,
    id: 0,
    identifier: "",
    giverName: "",
    receiverFirstName: "",
    receiverLastName: "",
    esgcValues: [esgcValuesGift],
    userExcludedCompanies: []
}

export const currentAdvisoryFeesData: DashboardType.GetAccountFeesData = [
    {
        amount: 0,
        description: "",
        date: ''
    }
]

export const currentDividendData: DashboardType.GetAccountFeesData = [
    {
        amount: 0,
        description: "",
        date: ''
    }
]

export const ordersData: DashboardType.OrderActivityResponseModel[] = [
    {
        symbol: "",
        side: "",
        actualAmount: 0
    },
]

export const currentInvestingHistoryData: DashboardType.GetInvestingHistoryData = [
    {
        actualAmount: 0,
        description: '',
        date: '',
        orders: ordersData
    }
]

export const currentTransactionData: DashboardType.GetTransactionsData = [
    {
        amount: 0,
        description: "",
        details: '',
        date: ""
    },
]

export const optionData: Dashboard.OptionData = { label: "", value: '' }