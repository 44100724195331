import * as DriveWealthType from "api/APIMaster/DriveWealthType";
import { AxiosClient, ExceptionResponse, RequestParams } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class DriveWealthService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.DRIVE_WEALTH
        });
    }

    /**
     * @description Returns disclosure URL
     *
     * @name GetDisclosureUrl
     * @summary Get disclosure URL
     * @request POST:/user/user/disclosure-url
     * @secure
     */
    getDisclosureUrl = (data: DriveWealthType.DiscloserURLRequestModel, params: RequestParams = {}) =>
        this.request<DriveWealthType.DiscloserURLResponseModel, void | ExceptionResponse>({
            path: URLList.DRIVE_WEALTH_SERVICE.DISCLOSURE_URL.path,
            method: URLList.DRIVE_WEALTH_SERVICE.DISCLOSURE_URL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return Statement Details
     *
     * @name GetDisclosureUrl
     * @summary Statement URL
     * @request POST:/user/statements/statement-by-user
     * @secure
     */
    statementDetails = (data: DriveWealthType.CommonStatementDateRequestModel, params: RequestParams = {}) =>
        this.request<DriveWealthType.StatementResponseModel, void | ExceptionResponse>({
            path: URLList.DRIVE_WEALTH_SERVICE.STATEMENT_URL.path,
            method: URLList.DRIVE_WEALTH_SERVICE.STATEMENT_URL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return Tax Statement Details
     *
     * @name TaxStatementDetails
     * @summary Tax Statement
     * @request GET:/user/statements/tax-statement-by-user
     * @secure
     */
    taxStatementDetails = (params: RequestParams = {}) =>
        this.request<DriveWealthType.TaxStatementResponseModel, void | ExceptionResponse>({
            path: URLList.DRIVE_WEALTH_SERVICE.TAX_STATEMENT.path,
            method: URLList.DRIVE_WEALTH_SERVICE.TAX_STATEMENT.method,
            secure: true,
            ...params,
        });

    /**
     * @description Return Trade Confirmation Details
     *
     * @name TradeConfirmationDetails
     * @summary  Trade Confirmation
     * @request POST:/user/statements/trade-confirmation-by-user
     * @secure
     */
    tradeConfirmationDetails = (data: DriveWealthType.CommonStatementDateRequestModel, params: RequestParams = {}) =>
        this.request<DriveWealthType.TradeConfirmationResponseModel, void | ExceptionResponse>({
            path: URLList.DRIVE_WEALTH_SERVICE.TRADE_CONFIRMATION.path,
            method: URLList.DRIVE_WEALTH_SERVICE.TRADE_CONFIRMATION.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return Download Pdf Details 
     *
     * @name downloadPdfDetails
     * @summary Download Pdf Details
     * @request GET:/user/statements/file-url-by-user/{fileKey}
     * @secure
     */
    downloadPdfDetails = (fileKey: string, params: RequestParams = {}) =>
        this.request({
            path: `${URLList.DRIVE_WEALTH_SERVICE.PDF_DOWNLOAD.path}/${fileKey}`,
            method: URLList.DRIVE_WEALTH_SERVICE.PDF_DOWNLOAD.method,
            secure: true,
            ...params,
        });
}

export default DriveWealthService