import * as React from 'react';
import lottie from 'lottie-web/build/player/lottie_light';

type Props = {
    animationData: object,
    width: number | string,
    height: number | string,
}

const LottieAnimation: React.FC<Props> = (props: Props) => {

    const { animationData, width, height } = props

    const lottieAnimation = React.useRef<HTMLInputElement>(null)

    const animationStyle = {
        margin: '0 auto',
        width: typeof width === 'string' ? width : `${width}px`,
        height: typeof height === 'string' ? height : `${height}px`,
    }

    React.useEffect(() => {
        if (lottieAnimation.current) {
            lottie.loadAnimation({
                container: lottieAnimation.current,
                renderer: 'svg',
                animationData: animationData,
                loop: true, // Set to true if you want the animation to loop
                autoplay: true, // Set to true if you want the animation to play automatically
            });
        }
    }, [])

    return (
        <div ref={lottieAnimation} style={animationStyle} />
    );
}

export default LottieAnimation
