// default library
import * as React from 'react';
// intl message
import IntlMessages from 'helper/IntlMessages';


export type Props = {
    headText: string;
    paragraphText: string
}

const AccountSettingModalHeader: React.FC<Props> = (props: Props) => {

    const { headText, paragraphText } = props;

    return (
        <div className="setting-modal-header ps-sm-0 pe-sm-3 px-3 my-3">
            {headText && <h5 className="mb-0">{IntlMessages(headText)}</h5>}
            {paragraphText && <p className="mt-3 mb-0">{IntlMessages(paragraphText)}</p>}
        </div>
    )
}

export default AccountSettingModalHeader