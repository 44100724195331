// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// device detect library
import { isMobile } from "react-device-detect";
// default sort type
import { sortType } from 'config/DefaultValue';
// sorting function
import * as SortingFunction from 'service/utils/SortingFunction'
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as BasketType from 'api/APIMaster/BasketType';
import * as DefaultType from 'interface/DefaultValueType';

export type Props = {
    companyListModal: boolean;
    totalCompanyTable: boolean;
    excludedCompanyTable: boolean;
    addedCompanyTable: boolean;
    companyCount: number;
    companyList: BasketType.BasketCompanyResponseModel[];
    onHandleClose: PropsFunction.SimpleFunction;
}

const CompanyListModal: React.FC<Props> = (props: Props) => {

    const { companyListModal, totalCompanyTable, excludedCompanyTable, addedCompanyTable, companyCount, companyList, onHandleClose } = props;

    const [sortTypes, setSortTypes] = React.useState<DefaultType.SortType>(sortType.sortTypesPercentage['down']);
    const [currentSortCompany, setCurrentSortCompany] = React.useState<keyof DefaultType.SortTypes>('default');
    const [currentSortDollarValue, setCurrentSortDollarValue] = React.useState<keyof DefaultType.SortTypes>('default');
    const [currentSortIndustry, setCurrentSortIndustry] = React.useState<keyof DefaultType.SortTypes>('default');
    const [currentSortPercentage, setCurrentSortPercentage] = React.useState<keyof DefaultType.SortTypes>('down');

    const sortTypesName: DefaultType.SortTypes = sortType.sortTypesPercentage;

    const sortTable = (e: React.MouseEvent) => {
        let sortingValue: DefaultType.SortReturnType = null

        const dataType = e.target as HTMLTableElement;

        if (dataType.getAttribute('data-type') === 'sortCompanyName') {
            sortingValue = SortingFunction.sortCompanyName(currentSortCompany, sortType)
        }
        if (dataType.getAttribute('data-type') === 'sortDollarValue') {
            sortingValue = SortingFunction.sortDollarValue(currentSortDollarValue, sortType)
        }
        if (dataType.getAttribute('data-type') === 'sortIndustry') {
            sortingValue = SortingFunction.sortIndustry(currentSortIndustry, sortType)
        }
        if (dataType.getAttribute('data-type') === 'sortPercentage') {
            sortingValue = SortingFunction.sortPercentage(currentSortPercentage, sortType)
        }

        if (sortingValue !== null) {
            setCurrentSortCompany(sortingValue.currentSortCompany);
            setCurrentSortDollarValue(sortingValue.currentSortDollarValue);
            setCurrentSortIndustry(sortingValue.currentSortIndustry);
            setCurrentSortPercentage(sortingValue.currentSortPercentage);
            setSortTypes(sortingValue.sortTypes);
        }
    }

    const scrollData: JSX.Element = <>
        <div className="table-responsive">
            <table className={`gi-dash-ao-pc-table table ${totalCompanyTable || addedCompanyTable ? 'table-success' : 'table-danger'} table-striped text-nowrap`} role="presentation">
                <thead>
                    <tr>
                        <th scope="col" onClick={sortTable} data-type='sortCompanyName'>
                            <strong data-type='sortCompanyName'>{IntlMessages('company_name_ticker')}</strong>
                            <i className={`fa fa-${sortTypesName[currentSortCompany].className}`} data-type='sortCompanyName' data-testid='sortCompanyName' />
                        </th>
                        {totalCompanyTable && (
                            <th scope="col" onClick={sortTable} data-type='sortDollarValue'>
                                <strong data-type='sortDollarValue'>{IntlMessages('table_value')}</strong>
                                <i className={`fa fa-${sortTypesName[currentSortDollarValue].className}`} data-type='sortDollarValue' data-testid='sortDollarValue' />
                            </th>
                        )}
                        <th scope="col" onClick={sortTable} data-type='sortIndustry'>
                            <strong data-type='sortIndustry'>{IntlMessages('table_industry')}</strong>
                            <i className={`fa fa-${sortTypesName[currentSortIndustry].className}`} data-type='sortIndustry' data-testid='sortIndustry' />
                        </th>
                        {totalCompanyTable && (
                            <th scope="col" onClick={sortTable} data-type='sortPercentage'>
                                <strong data-type='sortPercentage'>{IntlMessages('table_percentage_of_basket')}</strong>
                                <i className={`fa fa-${sortTypesName[currentSortPercentage].className}`} data-type='sortPercentage' data-testid='sortPercentage' />
                            </th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {[...companyList].sort(sortTypes.fn).map((e, i) => {
                        return (
                            <tr key={i}>
                                <td><strong>{`${e.companyName} (${e.tickerSymbol})`}</strong></td>
                                {totalCompanyTable && (<td><span>${Math.floor(e.value * 100) / 100}</span></td>)}
                                <td><span>{e.industry}</span></td>
                                {totalCompanyTable && (<td><span>{Math.floor(e.weight * 100) / 100}%</span></td>)}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>

    return (
        <Modal
            centered
            show={companyListModal}
            size='xl'
            backdrop="static"
            keyboard={false} className="gi-port-ao-pc-companies-modal"
            contentClassName="border-0"
        >
            <Modal.Header className='px-3 py-2'>
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={onHandleClose} data-testid='close-btn'></button>
            </Modal.Header>
            <Modal.Body>
                <div className="gi-dash-ao-pc-modal-card">
                    {/* company count */}
                    <div className="gi-dash-ao-pc-modal-header">
                        {totalCompanyTable && (
                            <>
                                <h5>
                                    {IntlMessages('portfolio_companies')}: {companyCount}
                                </h5>
                                <p>
                                    {IntlMessages('basket_text1')}
                                </p>
                            </>
                        )}

                        {excludedCompanyTable && (
                            <>
                                <h5>
                                    {IntlMessages('excluded_companies')}: {companyCount}
                                </h5>
                                <p>
                                    {IntlMessages('basket_text2')}
                                </p>
                            </>
                        )}

                        {addedCompanyTable && (
                            <>
                                <h5>
                                    {IntlMessages('added_companies')}: {companyCount}
                                </h5>
                                <p>
                                    {IntlMessages('added_companies_text')}
                                </p>
                            </>
                        )}
                    </div>
                    {/* company list */}
                    <div className="gi-dash-ao-pc-modal-body">
                        <div className="gi-dash-ao-pc-modal-main">
                            {isMobile
                                ? <div className='scroll-portfolio-modal3 scroll-thumb p-0' >
                                    {scrollData}
                                </div>
                                : <ScrollBar component="div" className='scroll-portfolio-modal3'>
                                    {scrollData}
                                </ScrollBar>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CompanyListModal