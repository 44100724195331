//default library
import * as React from 'react';
//default value
import { actionValue } from 'config/DefaultValue';
//custom components
import InputComponentRadio2 from 'components/InputComponent/InputComponentRadio2';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import EmploymentStockModal from 'components/ModalComponent/EmploymentStockModal';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
import resizeErrorMessage from 'service/utils/ResizeErrorMessage';
//helper message
import IntlMessages from 'helper/IntlMessages';
//type import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
// custom function
import * as Local from 'service/utils/LocalStorageData'

//Defining props
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
}

const EmploymentStock: React.FC<Props> = (props: Props) => {
  const { propsData } = props//destructuring props

  const [brokerDealer, setBrokerDealer] = React.useState<string>('false');//brokerDealer with initial value 'false'
  const [openBroker, setOpenBroker] = React.useState<boolean>(false);//openBroker modal with initial value 'false'

  const [stockOwner, setStockOwner] = React.useState<string>('false');//stockOwner with initial value 'false'
  const [companyName, setCompanyName] = React.useState<string>('');//companyName with initial value an empty string
  const [tickerSymbol, setTickerSymbol] = React.useState<string>('');//tickerSymbol with initial value an empty string
  const [openStock, setOpenStock] = React.useState<boolean>(false);//openStock modal with initial value 'false'

  const [publicOfficial, setPublicOfficial] = React.useState<string>('false');//publicOfficial with initial value 'false'
  const [publicOfficialText, setPublicOfficialText] = React.useState<string>('');//publicOfficialText with initial value an empty string
  const [openPublic, setOpenPublic] = React.useState<boolean>(false);//openPublic modal with initial value 'false'

  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({}); //fieldDetails with initial value an empty object

  // flags for controlling component behavior
  const nextPage = 'page10';//nextPage with initial value 'page10'
  const sendText = false;
  const isRouteChange = true;
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {

    const localStorageData = Local.getLocalData('onBoardingDetails');
    const previousPage = localStorageData?.employmentLabel === 'Currently working' || localStorageData?.employmentLabel === 'Self-employed' ? 'page8' : 'page7'

    //propsData function to update previousPage and pageNumber
    propsData({
      previousPage: previousPage,
      pageNumber: 10,
    });

    // If localStorageData is not null and brokerDealer is defined, set component state
    localStorageData?.brokerDealer && setBrokerDealer(localStorageData.brokerDealer);
    localStorageData?.stockOwner && setStockOwner(localStorageData.stockOwner);
    localStorageData?.publicOfficial && setPublicOfficial(localStorageData.publicOfficial);
    localStorageData?.companyName && setCompanyName(localStorageData.companyName);
    localStorageData?.tickerSymbol && setTickerSymbol(localStorageData.tickerSymbol);
    localStorageData?.publicOfficialText && setPublicOfficialText(localStorageData.publicOfficialText);
  }, []);

  // Function to set input values for radio buttons
  const setInputValue = (fieldName: string, fieldValue: string): void => {
    if (fieldName === 'brokerDealer') {
      setBrokerDealer(fieldValue)
      setOpenBroker(true);
    }

    if (fieldName === 'stockOwner') {
      setStockOwner(fieldValue)
      setOpenStock(true);
      setCompanyName('');
      setTickerSymbol('');
    }

    if (fieldName === 'publicOfficial') {
      setPublicOfficial(fieldValue)
      setPublicOfficialText('');
      setOpenPublic(true);
    }
  };

  // Function to set input values for modal
  const setInputModalValue = (fieldName: string, fieldValue: string): void => {
    fieldName === 'companyName' && setCompanyName(fieldValue);
    fieldName === 'tickerSymbol' && setTickerSymbol(fieldValue);
    fieldName === 'publicOfficialText' && setPublicOfficialText(fieldValue);
  };

  // Function to open stock modal
  const modalOpenStock = (): void => {
    setOpenStock(true);
  }

  // Function to open public modal
  const modalOpenPublic = (): void => {
    setOpenPublic(true);
  };

  // Function to handle modal confirmation
  const onHandleConfirm = (): void => {
    setOpenBroker(false);
    setOpenStock(false);
    setOpenPublic(false);
  }

  // Function to handle closing stock modal
  const onHandleCloseStock = (): void => {
    setStockOwner('false');
    setCompanyName('');
    setTickerSymbol('');

    onHandleConfirm();
  };

  // Function to handle closing public modal
  const onHandleClosePublic = (): void => {
    setPublicOfficial('false');
    setPublicOfficialText('');
    onHandleConfirm();
  }

  //update details and next page
  React.useEffect(() => {
    const details = {
      brokerDealer,
      stockOwner,
      publicOfficial,
      companyName,
      tickerSymbol,
      publicOfficialText,
    };

    setFieldDetails(details);
    //resize error check
    return () => {
      resizeErrorMessage('companyName', 'tickerSymbol', 'publicOfficialText');
    }
  }, [brokerDealer, stockOwner, publicOfficial, companyName, tickerSymbol, publicOfficialText])

  return (
    <>
      <div className="gi-ob-slide-card">
        <div className="gi-ob-full-name-form gi-ob-stock-form">
          {/* broker dealer */}
          <div className="gi-ob-stock-text gi-ob-broken-dealer">
            {/* static text */}
            <p>{IntlMessages('employment_stock_text1')}</p>
            {/* yes/no selection */}
            <div className="gi-onboard-tax-payer-radio">
              {actionValue.map((e, i) => {
                const checked = e.value === brokerDealer;
                return (
                  <div className="gi-onboard-radio-inline form-check form-check-inline" key={i}>
                    <InputComponentRadio2
                      name={'brokerDealer'}
                      fieldLabel={e.label}
                      fieldId={typeof e.label === 'object' && e.label.props.id + 1}
                      fieldValue={e.value}
                      checked={checked}
                      inputValue={setInputValue}
                      dataTestId={'broker-input'}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {/* stock owner */}
          <div className="gi-ob-stock-text gi-ob-stock">
            {/* static text */}
            <p>{IntlMessages('employment_stock_text2')}</p>
            {/* yes/no selection */}
            <div className="gi-onboard-tax-payer-radio">
              {actionValue.map((e, i) => {
                const checked = e.value === stockOwner;
                return (
                  <div className="gi-onboard-radio-inline form-check form-check-inline" key={i}>
                    <InputComponentRadio2
                      name={'stockOwner'}
                      fieldLabel={e.label}
                      fieldId={typeof e.label === 'object' && e.label.props.id + 2}
                      fieldValue={e.value}
                      checked={checked}
                      inputValue={setInputValue}
                      dataTestId={'stock-input'}
                    />
                  </div>
                );
              })}
            </div>
            {/* edit option for stock owner */}
            {stockOwner === 'true' && companyName && tickerSymbol && !openStock && (
              <div className="gi-ob-edit-text">
                <span data-testid='edit-text-stock'>
                  {companyName} | {tickerSymbol}
                </span>
                <a onClick={modalOpenStock} data-testid='edit-stock'>{IntlMessages('button_edit')}</a>
              </div>
            )}
          </div>

          {/* public official */}
          <div className="gi-ob-stock-text gi-ob-foreign">
            {/* static text */}
            <p>{IntlMessages('employment_stock_text3')} </p>
            {/* yes/no selection */}
            <div className="gi-onboard-tax-payer-radio">
              {actionValue.map((e, i) => {
                const checked = e.value === publicOfficial;
                return (
                  <div className="gi-onboard-radio-inline form-check form-check-inline" key={i}>
                    <InputComponentRadio2
                      name={'publicOfficial'}
                      fieldLabel={e.label}
                      fieldId={typeof e.label === 'object' && e.label.props.id + 3}
                      fieldValue={e.value}
                      checked={checked}
                      inputValue={setInputValue}
                      dataTestId={'public-input'}
                    />
                  </div>
                );
              })}
            </div>
            {/* edit option for public official */}
            {publicOfficial === 'true' && publicOfficialText && !openPublic && (
              <div className="gi-ob-edit-text">
                <span data-testid='edit-public-official'>{publicOfficialText.length < 30 ? publicOfficialText : `${publicOfficialText.substring(0, 30)}...`}</span>
                <a onClick={modalOpenPublic} data-testid='edit-public'>{IntlMessages('button_edit')}</a>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText} isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />

      {/* stock info modal */}
      <EmploymentStockModal
        brokerDealer={brokerDealer === 'true' && openBroker}
        stockOwner={stockOwner === 'true' && openStock}
        publicOfficial={publicOfficial === 'true' && openPublic}
        companyName={companyName}
        tickerSymbol={tickerSymbol}
        publicOfficialText={publicOfficialText}
        onHandleConfirm={onHandleConfirm}
        onHandleCloseStock={onHandleCloseStock}
        onHandleClosePublic={onHandleClosePublic}
        setInputValue={setInputModalValue}
      />
    </>
  );
};

export default EmploymentStock;









