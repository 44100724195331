// default library
import React from 'react'
// lottie animation
import lottie from 'lottie-web/build/player/lottie_light';
// loading animation
import LoadingAnimation from 'service/animation/giraffe_with_heart2.json'
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';

const animationStyle = {
    width: 74,
    margin: '0 auto',
    cursor: 'default'
}

const FooterApp: React.FC = () => {
    const linkedinURL = 'https://www.linkedin.com/company/giraffeinvest';
    const facebookURL = 'https://www.facebook.com/giraffe.us?mibextid=LQQJ4d';
    const finraURL = 'https://www.finra.org/#/';
    const sipcURL = 'https://www.sipc.org/';
    const advisoryURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-advisory-agreement.pdf`;
    const formCrsURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-form-crs.pdf`;
    const formAdv2URL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-adv-2a.pdf`;
    const codeOfEthicsURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-code-of-ethics.pdf`;
    const marketDataURL = 'https://www.tiingo.com/';
    const standardPoorURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-standard-poor.pdf`;
    const termsOfUseURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-terms-of-use.pdf`;
    const privacyPolicyURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-privacy-policy.pdf`;


    const intl = useIntl();
    const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

    // load lottie animation
    React.useEffect(() => {
        lottie.loadAnimation({
            container: lottieAnimation.current as any,
            renderer: 'svg',
            animationData: LoadingAnimation,
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true if you want the animation to play automatically
        });
    }, [])

    return (
        <footer className="app-static-footer app-static-footer-dashboard">
            <div className="container-xxl px-sm-4">
                <div className="row">
                    <div className="col-12">
                        <div className="app-static-foo-main">
                            <div className="app-static-foo-animation-section">
                                <div className="app-static-foo-animation">
                                    <div ref={lottieAnimation} style={animationStyle} />
                                </div>
                                <div className="app-static-foo-logo">
                                    <img src={intl.formatMessage({ id: 'GIRAFFE_LOGO_NEW_FOOTER' })} alt="" />
                                </div>
                                <div className="app-static-foo-text">
                                    <h5>{IntlMessages('footer_text1')}</h5>
                                    <p>{IntlMessages('footer_text2')}</p>
                                    <a href='mailto:support@giraffe.us'>support@giraffe.us</a>
                                </div>
                                <div className="app-static-foo-social">
                                    <ul>
                                        <li>
                                            <a href={linkedinURL} target="_blank" rel="noreferrer noopener">
                                                <img src={intl.formatMessage({ id: 'LINKEDIN_ICON' })} alt="Linked-In" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={facebookURL} target="_blank" rel="noreferrer noopener">
                                                <img src={intl.formatMessage({ id: 'FACEBOOK_ICON' })} alt="Facebook" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="app-static-foo-text-section">
                                <div className="app-static-foo-text-01">
                                    <strong>
                                        {IntlMessages('footer_text3')}
                                    </strong>
                                    <br /><br />

                                    <strong>{IntlMessages('footer_text4')}</strong>
                                    <br />
                                    {IntlMessages('footer_text5')}
                                    <br /><br />
                                    {IntlMessages('footer_text6')}&nbsp;
                                    <a href={finraURL} target="_blank" rel="noreferrer noopener">{IntlMessages('finra')}</a> / <a href={sipcURL} target="_blank" rel="noreferrer noopener">{IntlMessages('sipc')}</a>.
                                    <br /><br />
                                    {IntlMessages('footer_text7')}&nbsp;
                                    <a href={advisoryURL} target="_blank" rel="noreferrer noopener">{IntlMessages('footer_text8')}</a>
                                    &nbsp;{IntlMessages('footer_text9')}
                                    <br /><br />
                                    {IntlMessages('footer_text10')}
                                    <br /><br />

                                    <strong> {IntlMessages('footer_text11')} </strong> <br /> {IntlMessages('footer_text12')}
                                </div>
                                <div className="app-static-foo-text-02">
                                    {IntlMessages('footer_text13')}
                                    <a href={formCrsURL} target="_blank" rel="noreferrer noopener"> {IntlMessages('form_crs')}</a>
                                    &nbsp;<a href={formAdv2URL} target="_blank" rel="noreferrer noopener">{IntlMessages('form_adv_2')}</a>
                                    &nbsp;and&nbsp;
                                    <a href={codeOfEthicsURL} target="_blank" rel="noreferrer noopener">{IntlMessages('code_of_ethics')}</a>.
                                    <br /><br />
                                    {IntlMessages('footer_text14')}
                                    <br /><br />

                                    <strong> {IntlMessages("footer_text15")}</strong>
                                    <br />
                                    <a href={marketDataURL} target="_blank" rel="noreferrer noopener"> {IntlMessages('market_data')}</a> {IntlMessages('tiingo')}.
                                    <strong>
                                        <br /><br />
                                        {IntlMessages('footer_text16')} <span> giraffe.us. </span>
                                    </strong>
                                    <br /><br />
                                    {IntlMessages('footer_text17')}
                                    <br /><br />
                                    {IntlMessages('footer_text18')}&nbsp;
                                    <a href={standardPoorURL} target="_blank" rel="noreferrer noopener">{IntlMessages('footer_text19')}</a>.
                                    <br /><br /><br />
                                    <a href={termsOfUseURL} className='footer-anchor-bold' target="_blank" rel="noreferrer noopener" >{IntlMessages('footer_text20')}</a>
                                    <br /><br />
                                    <a href={privacyPolicyURL} className='footer-anchor-bold' target="_blank" rel="noreferrer noopener" >{IntlMessages('footer_text21')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterApp