// default library
import * as React from 'react';
import { NavLink } from 'react-router-dom';
// react redux
import { useSelector } from 'react-redux';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// intl message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as GiftType from 'api/APIMaster/GiftType';

type Props = {
    basketList: DashboardType.GetBasketBasicDetailsByUser1Data;
    giftPendingList: GiftType.GetGiftUserByAccessTokenData;
}

const DesktopSidebar: React.FC<Props> = (props: Props) => {
    const { basketList, giftPendingList } = props
    const intl = useIntl();

    const appCommonData = useSelector(getAppCommonData);

    const showGiftTab = !(appCommonData.isNewUser || appCommonData.isKycDenied || appCommonData.isKycRejected || appCommonData.isKycManualReview); //check to show gift tab or not

    return (
        <div className="gi-g-menu-desktop">
            {/* Home and portfolio list */}
            <div className="gi-g-menu-new">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact className="nav-link" to={'/dashboard'} activeClassName='active' >
                            <span className="nav-icon">
                                <img src={intl.formatMessage({ id: 'HOME_ICON' })} className="gi-d-icons-inactive" alt="" />
                                <img src={intl.formatMessage({ id: 'HOME_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                            </span>
                            {IntlMessages('dashboard_home')}
                        </NavLink >
                    </li>
                </ul>
                {/* portfolio list */}
                <div className="collapse-nav small-point">
                    <ul>
                        {basketList.length > 0 && (basketList?.map((e) => {
                            return (
                                <li className="nav-item" key={e.id}>
                                    <NavLink exact to={`/dashboard/portfolio/${e.id}`} activeClassName='active' >
                                        <span className="sm-point"></span>
                                        <span className="sm-point-text">
                                            {e.name}
                                        </span>
                                    </NavLink >
                                </li>
                            )
                        }))}
                    </ul>
                </div>
            </div>

            {/* Add Portfolio */}
            <div className="gi-g-menu-new">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact className="nav-link" to={'/dashboard/add-portfolio'} activeClassName='active' >
                            <span className="nav-icon">
                                <img src={intl.formatMessage({ id: 'LINE_CHART_ICON' })} className="gi-d-icons-inactive" alt="" />
                                <img src={intl.formatMessage({ id: 'LINE_CHART_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                            </span>
                            {IntlMessages('dashboard_create_investment')}
                        </NavLink >
                    </li>
                </ul>
            </div>

            {/* My Impact & giraffe learn */}
            <div className="gi-g-menu-new">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact className="nav-link" to={'/dashboard/my-impact'} activeClassName='active' >
                            <span className="nav-icon">
                                <img src={intl.formatMessage({ id: 'MY_IMPACT' })} className="gi-d-icons-inactive" alt="" />
                                <img src={intl.formatMessage({ id: 'MY_IMPACT_ACTIVE' })} className="gi-d-icons-active" alt="" />
                            </span>
                            {IntlMessages('dashboard_my_impact')}
                        </NavLink >
                    </li>
                    <li className="nav-item">
                        {appCommonData.isLearnAccess && (
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <NavLink exact className="nav-link" to={'/dashboard/financial-playlist'} activeClassName='active' >
                                        <span className="nav-icon">
                                            <img src={intl.formatMessage({ id: 'LEARN_ICON' })} className="gi-d-icons-inactive" alt="" />
                                            <img src={intl.formatMessage({ id: 'LEARN_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                        </span>
                                        {IntlMessages('dashboard_learn')}
                                    </NavLink >
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>

            {/* gift given */}
            {JSON.parse(import.meta.env.REACT_APP_SHOW_NAVIGATION_GIFT) && showGiftTab && (
                <div className="gi-g-menu-new">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact to={`/dashboard/gifts`} className="nav-link" activeClassName='active' >
                                <span className="nav-icon">
                                    <img src={intl.formatMessage({ id: 'GIFT_ICON' })} className="gi-d-icons-inactive" alt="" />
                                    <img src={intl.formatMessage({ id: 'GIFT_ICON_ACTIVE' })} className="gi-d-icons-active" alt="" />
                                </span>
                                {IntlMessages('footer_gifts')}
                            </NavLink>
                        </li>
                    </ul>
                    <div className="collapse-nav lg-point">
                        <ul>
                            {!appCommonData.isNewUser && giftPendingList.length > 0 && (giftPendingList.map((e) => {
                                return (
                                    !e.isRedeemed && (
                                        <li key={e.id} >
                                            <NavLink exact to={`/dashboard/gift/${e.identifier}`} activeClassName='active' >
                                                <span className="lg-point"></span>
                                                <span className="lg-point-text">
                                                    {IntlMessages('dashboard_new_gift_pending')}
                                                </span>
                                            </NavLink>
                                        </li>
                                    )
                                )
                            }))}
                        </ul>
                    </div>
                </div>
            )}

            {/* taxes, activity, setting */}
            <div className="gi-g-menu-new">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact className='nav-link no-icon' to={`/dashboard/taxes`} activeClassName='active' >
                            {IntlMessages('dashboard_taxes')}
                        </NavLink >
                    </li>
                    <li className="nav-item">
                        <NavLink exact className='nav-link no-icon' to={`/dashboard/activity`} activeClassName='active' >
                            {IntlMessages('dashboard_activity')}
                        </NavLink >
                    </li>
                    {!appCommonData.isNewUser && (
                        <li className="nav-item">
                            <NavLink exact className='nav-link no-icon' to={`/dashboard/account-setting`} activeClassName='active' >
                                {IntlMessages('dashboard_account_setting2')}
                            </NavLink >
                        </li>
                    )}
                </ul>
            </div>

            {/* Contact us */}
            <div className={`gi-g-menu-new ${appCommonData.isNewUser ? '' : 'border-bottom-0'}`}>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact className='nav-link no-icon' to={`/dashboard/contact-us`} activeClassName='active' >
                            {IntlMessages('dashboard_contact_us')}
                        </NavLink >
                    </li>
                </ul>
            </div>

            {/* KYC page */}
            {(appCommonData.isNewUser || appCommonData.isKycRejected) && (
                <div className="gi-g-menu-new border-bottom-0">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink exact to={appCommonData.isKycRejected ? '/kyc-failure-form' : '/on-boarding'} className="nav-link no-icon cta-account" activeClassName='active'>
                                {IntlMessages('dashboard_finish_setup')}
                                <span className="nav-icon finish-setup">
                                    <img src={intl.formatMessage({ id: 'FINISH_SETUP_ICON' })} alt="" />
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default DesktopSidebar