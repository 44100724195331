// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// moment
import moment from 'moment';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
// message import
import IntlMessages from 'helper/IntlMessages';
//import regex and max number length
import { MAX_NUMBER_36, NAME_REGEX } from "config/Config";
// type import
import * as PropsFunction from 'interface/PropsFunction';
// age calculator
import AgeCalculator from 'service/utils/AgeCalculator';
//config
import { actionValue, genderOption } from 'config/DefaultValue';
// custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import DatePickerComponent from 'components/DatePickerComponent/DatePickerComponent';
import InputRadioComponent from 'components/InputComponent/InputRadioComponent';
import TaxPayerInfoModal from 'components/ModalComponent/TaxPayerInfoModal';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const DobGender: React.FC<Props> = (props: Props) => {
    const { propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);
    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [dob, setDob] = React.useState<Date | null>(null); //date
    const [dobError, setDobError] = React.useState<string>(''); //error message for date

    const [firstNameEn, setFirstNameEn] = React.useState<string>(''); //first name
    const [firstNameError, setFirstNameError] = React.useState<string>(''); //error message for first name

    const [lastNameEn, setLastNameEn] = React.useState<string>(''); //last name
    const [lastNameError, setLastNameError] = React.useState<string>(''); //error message for last name

    const [taxPayer, setTaxPayer] = React.useState<string>(''); //tax payer
    const [isTaxPayerChanged, setIsTaxPayerChanged] = React.useState<boolean>(false);
    const [openTaxInfoModal, setOpenTaxInfoModal] = React.useState<boolean>(false); //open tax info modal

    const [gender, setGender] = React.useState<string>(''); //gender

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    // initial data set-up
    React.useEffect(() => {
        propsData('/on-boarding/page1', 2)

        // set filed data
        kycData.dob && setDob(new Date(kycData.dob))
        setFirstNameEn(kycData.firstNameEn ?? '') //first name
        setLastNameEn(kycData.lastNameEn ?? '') //last name
        setTaxPayer(kycData.taxPayer) //tax payer
        setIsTaxPayerChanged(!kycData.taxPayer) //default taxpayer change
        setGender(kycData.gender) //gender
    }, [])

    // set date selection
    const setDateValue: PropsFunction.PropsDateFunction = (date) => {
        setDob(date);
        date && AgeCalculator(date) < 18 ? setDobError('error_dob_18') : setDobError('');
        setIsDataChanged(true);
    }

    //  updating input values in state
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        fieldName === "firstNameEn" && setFirstNameEn(fieldValue);
        fieldName === "lastNameEn" && setLastNameEn(fieldValue);
        if (fieldName === "taxPayer") {
            setTaxPayer(fieldValue)
            setIsTaxPayerChanged(true)
        }
        if (fieldName === 'gender') {
            setGender(fieldValue)
        }
        setIsDataChanged(true)
    };

    // raised support ticket 
    const raiseTicket = (): void => {
        saveData('/dashboard')
    }

    // save data at backend
    const saveData = async (redirectPath: string): Promise<void> => {
        setOpenTaxInfoModal(false)
        const newKycData = {
            ...kycData,
            dob: moment(dob).format('YYYY-MM-DD'),
            firstNameEn,
            lastNameEn,
            taxPayer,
            gender
        }
        dispatch(setKYCData(newKycData)); //store to redux

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);

            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push(redirectPath); //redirect to next page
            }
        } else {
            history.push(redirectPath); //redirect to next page
        }
    }

    // goto next page
    const clickNext = (): void => {
        saveData('/on-boarding/page3')
    }

    // open tax payer info modal
    const openTPModal = (): void => {
        isTaxPayerChanged && setOpenTaxInfoModal(true); //when tax pater radio change or click
        !isTaxPayerChanged && clickNext(); //when tax payer info not changed
    }

    // close tax payer info modal
    const onHandleClose = (): void => {
        setOpenTaxInfoModal(false)
    }

    // validate field name for the next screen
    const validateData = (): void => {
        // check dob
        if (!dob || dobError) {
            setIsDisabled(true)
        } else if (!firstNameEn || firstNameError || firstNameEn.length < 2) {
            setIsDisabled(true)
        } else if (!lastNameEn || lastNameError || lastNameEn.length < 2) {
            setIsDisabled(true)
        } else if (!taxPayer) {
            setIsDisabled(true)
        } else if (!gender) {
            setIsDisabled(true)
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    // set-up error message for the first and last name
    React.useEffect(() => {
        // first name validation
        if (firstNameEn && (firstNameEn.length > MAX_NUMBER_36 || !firstNameEn.match(NAME_REGEX))) {
            firstNameEn.length > MAX_NUMBER_36 && setFirstNameError('error_max_length_36_name');
            !firstNameEn.match(NAME_REGEX) && setFirstNameError('error_first_name');
        } else {
            setFirstNameError('')
        }

        // last name validation
        if (lastNameEn && (lastNameEn.length > MAX_NUMBER_36 || !lastNameEn.match(NAME_REGEX))) {
            lastNameEn.length > MAX_NUMBER_36 && setLastNameError('error_max_length_36_name');
            !lastNameEn.match(NAME_REGEX) && setLastNameError('error_last_name');
        } else {
            setLastNameError('')
        }
    }, [firstNameEn, lastNameEn])

    // validation function call
    React.useEffect(() => {
        validateData()
    }, [dob, firstNameEn, lastNameEn, taxPayer, gender, firstNameError, lastNameError])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation" data-testid='dob-gender'>
                <div className="row">
                    {/* page content */}
                    <div className="col-md-11">
                        <div className="row gx-4 gy-4">
                            {/* page header */}
                            <div className="col-12">
                                <div className="gi-ob-heading mt-4">
                                    <h5>{IntlMessages('kyc_name_page_header')}</h5>
                                </div>
                            </div>
                            {/* calender and  gender */}
                            <div className="col-12">
                                <div className="row gx-5 gy-4">
                                    {/* calender */}
                                    <div className="col-md-6">
                                        <div className="gi-ob-form form-group">
                                            <DatePickerComponent
                                                labelText={'kyc_dob_label'}
                                                labelClassName={'mb-2'}
                                                dateValue={dob}
                                                newDate={setDateValue}
                                                name={'dob'}
                                                className={'calender-icon'}
                                                errorMessage={dobError}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    {/* gender */}
                                    <div className="col-md-6">
                                        <div className="gi-ob-form form-group">
                                            <label className="mb-2">
                                                {IntlMessages('kyc_gender_label')}
                                            </label>
                                        </div>
                                        <div className="py-2">
                                            <InputRadioComponent
                                                name={'gender'}
                                                checkValue={gender}
                                                radioOption={genderOption}
                                                inputValue={setInputValue}
                                                inputClass={''}
                                                labelClass={'gi-ob-form-check-label form-check-label '}
                                                isSimpleRadio={false}
                                                dataTestId={'radio-button'}
                                                divClassName='form-check form-check-inline'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* break line */}
                            <div className="col-12">
                                <hr />
                            </div>
                            {/* first name and last name in english */}
                            <div className="col-12">
                                <div className="row gx-4 gy-4" dir="ltr">
                                    {/* first name */}
                                    <div className="col-md-6">
                                        <div className="gi-ob-form gi-ob-form-eng form-group">
                                            <InputTextComponent
                                                labelClassName={"mb-2"}
                                                labelText={'kyc_first_name_label_en'}
                                                fieldValue={firstNameEn}
                                                name={'firstNameEn'}
                                                placeholder={'placeholder_name_en'}
                                                readOnly={false}
                                                disabled={false}
                                                isError={Boolean(firstNameError)}
                                                errorMessage={firstNameError}
                                                inputValue={setInputValue}
                                                className={''}
                                                dataTestId={'first-name'}
                                            />
                                        </div>
                                    </div>

                                    {/* last name */}
                                    <div className="col-md-6">
                                        <div className="gi-ob-form gi-ob-form-eng form-group">
                                            <InputTextComponent
                                                labelClassName={"mb-2"}
                                                labelText={'kyc_last_name_label_en'}
                                                fieldValue={lastNameEn}
                                                name={'lastNameEn'}
                                                placeholder={'placeholder_name_en'}
                                                readOnly={false}
                                                disabled={false}
                                                isError={Boolean(lastNameError)}
                                                errorMessage={lastNameError}
                                                inputValue={setInputValue}
                                                className={''}
                                                dataTestId={'last-name'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* tax payer option */}
                            <div className="col-12">
                                <div className="py-2 mt-1 row">
                                    <div className="col-md-6">
                                        <div className="form-check-inline ps-0 me-0 mb-mb-0 mb-3 me-3">
                                            <label className="gi-ob-form-check-label-heading form-check-label ms-2">
                                                {IntlMessages('kyc_us_citizen')}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <InputRadioComponent
                                            name={'taxPayer'}
                                            checkValue={taxPayer}
                                            radioOption={actionValue}
                                            inputValue={setInputValue}
                                            inputClass={''}
                                            labelClass={'gi-ob-form-check-label form-check-label '}
                                            isSimpleRadio={false}
                                            dataTestId={'tax-payer'}
                                            divClassName='form-check form-check-inline'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={openTPModal} />
                    </div>
                </div>
            </div>

            <TaxPayerInfoModal
                openModal={openTaxInfoModal}
                taxPayer={taxPayer}
                onHandleClose={onHandleClose}
                onHandleSuccess={clickNext}
                raiseTicket={raiseTicket}
            />
        </>
    )
}

export default DobGender