//default library
import * as React from 'react';
//custom component
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import SelectOnBoarding from 'components/SelectComponent/SelectOnBoarding';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
// custom function
import resizeErrorMessage from 'service/utils/ResizeErrorMessage';
// message import
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as UserType from "api/APIMaster/UserType"
import * as OnBoarding from "interface/OnBoarding";
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// local data import
import * as Local from 'service/utils/LocalStorageData'

// Define the Props
type Props = {
  propsData: PropsFunction.PropsPageDataFunction
  staticData: UserType.OnboardingStaticDataResponseModel
}

const Country: React.FC<Props> = (props: Props) => {
  const { propsData, staticData } = props // Destructure props

  // set list of country
  const countryList = staticData.countryList.map((value) => ({
    active: value.active,
    label: value.name,
    value: value.identifier,
  }));

  const [citizenship, setCitizenship] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//managing the citizenship data
  const [isActive, setIsActive] = React.useState(true);//managing the active state of the country
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});
  const [nextPage, setNextPage] = React.useState('page6');//next page

  const sendText = false;//to display send text in the button
  const isRouteChange = true;//check page change
  const submitText = false;//to display submit text in the button
  const parentButton = false;//to display parent click props button


  React.useEffect(() => {
    // Accessing data from localStorage
    const localStorageData = Local.getLocalData(('onBoardingDetails') || "{}");

    // props data 
    propsData({
      previousPage: 'page4',
      pageNumber: 6,
    });

    // get local storage data
    if (localStorageData?.countryLabel) {
      setCitizenship({
        label: localStorageData.countryLabel,
        value: localStorageData.citizenship,
      });
    } else {
      const country = countryList.find(e => e.label === 'United States')

      country && setCitizenship({
        label: country.label,
        value: country.value,
      });
    }
  }, []);


  const setInputValue: OnBoarding.SelectedOption = (fieldValue) => {
    // Updating citizenship state with the selected values
    setCitizenship({
      label: fieldValue.label,
      value: fieldValue.value,
    });

    setIsActive(fieldValue.active);// Update isActive state
  };

  React.useEffect(() => {
    //new fieldDetails object with updated citizenship data
    const newFieldDetails = {
      citizenship: citizenship.value,
      active: isActive,
      countryLabel: citizenship.label,
    };
    //next page based on citizenship label
    const newNextPage = citizenship.label === 'United States' ? 'page7' : 'page6';

    setFieldDetails(newFieldDetails);
    setNextPage(newNextPage);

    return () => {
      resizeErrorMessage("citizenship"); // resizing error messages
    };
  }, [citizenship])

  return (
    <>
      <div className="gi-ob-slide-card" data-testid='country'>
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-country-text">
          <h5>{IntlMessages('country_text1')}</h5>
        </div>

        {/* country */}
        <div className="gi-ob-full-name-form gi-ob-country-form">
          <div className="gi-ob-input-box gi-ob-country-input">
            {countryList.length > 0 && (
              <SelectOnBoarding
                name={'citizenship'}
                option={countryList}
                placeholder={'placeholder_country'}
                fieldValue={citizenship}
                inputValue={setInputValue}
                ariaLabel={"country-label"}
              />
            )}
            {!isActive &&
              <ErrorKYC
                message={'error_invalid_citizenship'}
                fieldName={'citizen-ship'}
              />
            }
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group gi-ob-country-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default Country;





