//default library
import * as React from 'react';
// react google auto place library
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
// datadog logs
import { datadogLogs } from '@datadog/browser-logs';
//types import
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType";
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// config 
import { ShouldBeComponentAddress } from 'config/DefaultValue';
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    labelText: string;
    labelClassName: string;
    textCenter: boolean;
    className: string;
    fieldValue: string;
    dataTestId: string;
    countryList: UserType.CountryResponseModel[];
    inputValue: PropsFunction.AutoCompleteIsrael
}

const AutoCompleteIsrael: React.FC<Props> = (props: Props) => {
    const { labelText, labelClassName, textCenter, className, fieldValue, countryList, dataTestId, inputValue } = props;

    const [addressValue, setAddressVale] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue)

    const handlePlaceSelect = (place: google.maps.GeocoderResult[]) => {
        const address: {
            [key: string]: string;
            streetNumber: string;
            address1: string;
            postcode: string;
            city: string;
            province: string;
            country: string;
        } = {
            streetNumber: "",
            address1: "",
            postcode: "",
            city: "",
            province: "",
            country: ""
        };
        let countryIdentifier = '';
        let isValid = false;

        const AddressComponent: {
            [key: string]: string[];
        } = {
            ...ShouldBeComponentAddress,
            city: [
                'locality',
                ...ShouldBeComponentAddress.city,
            ]
        }

        place?.[0].address_components.forEach((component: any) => {
            for (const shouldBe in AddressComponent) {
                if (AddressComponent[shouldBe].includes(component.types[0]) && address[shouldBe] === '') {
                    address[shouldBe] = shouldBe === "province" ? component.short_name : component.long_name;
                }
            }
        });

        // set the country identifier
        const countryData = countryList.find(e => e.name === 'Israel')
        countryData && (countryIdentifier = countryData.identifier)

        if (address.address1 === '' || address.city === '' || address.province === '' || address.country === '') {
            isValid = true
            datadogLogs.logger.error(`Auto Complete Address Error: `, { place: place?.[0] });

            (address.address1 === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'address' });
            (address.city === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'city' });
            (address.province === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'state' });
            (address.country === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'country' });
        }

        //set country data to localStorage
        const autoCompleteData = {
            streetNumber: address.streetNumber,
            street1: address.address1,
            city: address.city,
            province: address.province,
            postalCode: address.postcode,
            country: countryIdentifier,
            countryDisclosure: address.country,
            isValid: isValid,
        } //create obj for send data to parent

        inputValue(autoCompleteData) //pass data to props
    }

    // Function to handle changes in the input field
    const onChangeHandle = async (e: any): Promise<void> => {
        if (e?.value.place_id) {
            setAddressVale({
                label: e.value.terms[0].value,
                value: '',
                active: true
            })
            const address = await geocodeByPlaceId(e.value.place_id)
            handlePlaceSelect(address)
        } else {
            // If no place is selected, reset error flag and postal code
            const autoCompleteData = {
                streetNumber: '',
                street1: '',
                city: '',
                province: '',
                postalCode: '',
                country: '',
                countryDisclosure: '',
                isValid: false,
            };
            inputValue(autoCompleteData);
        }
    };

    // Return the GooglePlacesAutocomplete component
    return (
        <>
            <label className={labelClassName}>{IntlMessages(labelText)}</label>
            <GooglePlacesAutocomplete
                apiKey={import.meta.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                apiOptions={{ language: 'iw', region: 'il' }}
                data-testid={dataTestId}
                withSessionToken={true}
                debounce={100}
                autocompletionRequest={{
                    componentRestrictions: { country: 'il' },
                    types: ['address'],
                }}
                selectProps={{
                    value: addressValue,
                    id: 'addressAutoFill',
                    onChange: onChangeHandle,
                    placeholder: '',
                    classNamePrefix: className,
                    className: `form-control ${className} ${textCenter ? 'gi-text-center' : ''}`,
                    defaultInputValue: fieldValue,
                    isClearable: true,
                }}
            />
        </>
    );
}

export default AutoCompleteIsrael