// default library
import * as React from 'react';
// intl message library 
import { useIntl } from 'react-intl';
// custom component
import LogoutComponent from 'components/LogoutComponent/LogoutComponent'

const Header: React.FC = () => {
    const intl = useIntl();

    return (
        <header className="gift-header">
            <nav className="navbar navbar-expand-lg navbar-light py-0" aria-label="headerNav">
                <div className="container-xxl">
                    <div className="header-bottom-left">
                        <a className="navbar-brand d-block py-3 my-md-0 my-1" href="index.html">
                            <img className="gi-new-logo img-fluid" src={intl.formatMessage({ id: 'GIRAFFE_LOGO_NEW' })} alt="" />
                        </a>
                    </div>
                    <LogoutComponent />
                </div>
            </nav>
        </header>
    )
}

export default Header