// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// intl message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// email regex
import { EMAIL_REGEX } from 'config/Config';
//types props
import * as PropsFunction from "interface/PropsFunction";
// api call
import * as UserService from "middleware/UserService";
// custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import MaskInputComponent from 'components/InputComponent/MaskInputComponent';
import SuccessModalNew from 'components/ModalComponent/SuccessModalNew';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    openModal: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSavingModal: PropsFunction.SimpleFunction;
    onHandleSuccessEmail: PropsFunction.SimpleFunction;
    onHandleNotListEmail: PropsFunction.SimpleFunction;
}

const MonthlyEmailModal: React.FC<Props> = (props: Props) => {
    const { openModal, onHandleClose, onHandleSavingModal, onHandleSuccessEmail, onHandleNotListEmail } = props;

    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [emailModal, setEmailModal] = React.useState<boolean>(false); //email input modal
    const [emailVerificationModal, setEmailVerificationModal] = React.useState<boolean>(false); //email verification modal
    const [emailNotListModal, setEmailNotListModal] = React.useState<boolean>(false); //email not list modal
    const [emailSuccessModal, setEmailSuccessModal] = React.useState<boolean>(false); //email success modal
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal

    const [workEmail, setWorkEmail] = React.useState<string>(''); //user work email
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //email button is disabled

    const [verifyCode, setVerifyCode] = React.useState<string>(''); //user work email verification code
    const [verifyCodeError, setVerifyCodeError] = React.useState<boolean>(false); //user work email verification code error

    const messageBody: JSX.Element = <div className="enter-code-modal-text text-center py-5 px-1">
        <img src={intl.formatMessage({ id: 'IL_ONBOARDING_SUCCESS_CHECK' })} alt="" />
    </div>

    React.useEffect(() => {
        setEmailModal(openModal); //open close email modal
        setEmailVerificationModal(false); //close email verification modal
        setEmailNotListModal(false); //close email not list modal
        setEmailSuccessModal(false); //close email success modal
        setLoadingModal(false); //loading modal hide

        setWorkEmail(''); //reset the value
        setIsDisabled(true); //reset the value
        setVerifyCode(''); //reset the value
        setVerifyCodeError(false); //reset the value
    }, [openModal])

    // send work email verification code API
    const sendEmailCodeAPI = async (): Promise<void> => {
        setEmailVerificationModal(false); //verify code modal 
        setLoadingModal(true); //loading modal show
        setVerifyCode(''); //reset the verified code
        setVerifyCodeError(false); //reset the verification code error
        setEmailModal(false); //close email modal

        const accessToken = await initiateAction() //get access token
        const sendWorkCode = await UserService.sendWorkEmailCode(workEmail, accessToken);

        setLoadingModal(false); //loading modal hide

        if (sendWorkCode.response || sendWorkCode.request) {
            setIsError(true);
            setErrorLog(sendWorkCode);
        } else {
            //open email verification modal when send work email code return true
            if (sendWorkCode) {
                setEmailVerificationModal(true);
            } else {
                // open email not list if email is not verified
                setEmailNotListModal(true);
            }
        }

    }

    // verify work email verification code API
    const verifyEmailAPI = async (): Promise<void> => {
        setLoadingModal(true); //loading modal show
        setEmailVerificationModal(false); //close email verification modal

        const accessToken = await initiateAction() //get access token
        const isEmailVerified = await UserService.verifyWorkEmail(workEmail, verifyCode, accessToken);

        setLoadingModal(false); //loading modal hide

        if (isEmailVerified.response || isEmailVerified.request) {
            setIsError(true);
            setErrorLog(isEmailVerified);
        } else {
            if (isEmailVerified) {
                setEmailSuccessModal(true);
                onHandleSuccessEmail(); //call basic detail api form home page
                // open monthly saving modal after 1sec
                setTimeout(() => {
                    onHandleSavingModal(); //show saving modal
                }, 2000);
            } else {
                setEmailVerificationModal(true);
                setVerifyCodeError(true);
            }

        }
    }

    // update the input value 
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === 'workEmail') {
            setWorkEmail(fieldValue);
        }
        fieldName === 'verifyCode' && setVerifyCode(fieldValue);
    }

    // work email check
    React.useEffect(() => {
        if (!workEmail) {
            setIsDisabled(true);
        } else if (!workEmail.match(EMAIL_REGEX)) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [workEmail])

    //verification code length check
    React.useEffect(() => {
        verifyCode.length === 6 && verifyEmailAPI()
    }, [verifyCode])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* email modal */}
            <Modal
                centered
                size='sm'
                show={emailModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>
                {/* main content */}
                <Modal.Body className={'py-4 px-4'}>
                    <div className="gi-monthly-savings-modal-text px-3 pt-3">
                        <h5 className="mb-0 text-center">{IntlMessages('monthly_saving_email_modal_head', { br: <br /> })}</h5>
                        <div className=" gi-ob-form form-group mt-4">
                            <InputTextComponent
                                labelClassName={"label-green-text mb-2 text-center"}
                                labelText={'monthly_saving_email_label'}
                                className='text-center'
                                fieldValue={workEmail}
                                name={'workEmail'}
                                placeholder={'monthly_saving_email_placeholder'}
                                readOnly={false}
                                disabled={false}
                                isError={false}
                                errorMessage={''}
                                inputValue={setInputValue}
                                dataTestId={'work-email'}
                            />
                        </div>
                        <div className="mt-3 text-center pt-3 pb-3">
                            <button type="button" className="gi-ob-continue-btn" onClick={sendEmailCodeAPI} disabled={isDisabled}>
                                {IntlMessages('button_continue')}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* verify email modal */}
            <Modal
                centered
                size='sm'
                show={emailVerificationModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>
                {/* main content */}
                <Modal.Body className={'py-4 px-4'}>
                    <div className="gi-monthly-savings-modal-text  text-center px-3 pt-3">
                        {/* simple message */}
                        {verifyCodeError
                            ? <>
                                <h5 className="mb-0 text-error">
                                    {IntlMessages('monthly_saving_email_code_wrong')}
                                </h5>
                                <p className="mt-2 mb-3">
                                    {IntlMessages('monthly_saving_email_code_wrong_text')}
                                </p>
                            </>
                            : <>
                                <h5 className="mb-0">
                                    {IntlMessages('monthly_saving_email_code')}
                                </h5>
                                <p className="mt-2 mb-3">
                                    {IntlMessages('monthly_saving_email_code_text')}
                                </p>
                            </>
                        }

                        <div className="gi-ob-form form-group mb-2 w-75 mx-auto">
                            <MaskInputComponent
                                placeholder={'placeholder_zip_code'}
                                name={'verifyCode'}
                                readOnly={false}
                                fieldType={'password'}
                                fieldValue={verifyCode}
                                inputValue={setInputValue}
                                guide={false}
                                dataTestId='verify-code'
                                labelText={''}
                                className={`text-center`}
                                errorMessage={''}
                                isError={verifyCodeError}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='gi-monthly-savings-modal-footer justify-content-center py-4'>
                    <div className="text-center my-2">
                        {!verifyCodeError && (
                            <p className="mb-0">
                                {IntlMessages('monthly_saving_email_code_spam_text', { br: <br /> })}
                            </p>
                        )}
                        <span className="d-block mt-2 mb-1">
                            <a href="#!" onClick={sendEmailCodeAPI}>{IntlMessages('button_resend')}</a>
                        </span>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* email not list modal */}
            <Modal
                centered
                size='sm'
                show={emailNotListModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleNotListEmail}></button>
                {/* main content */}
                <Modal.Body className={'py-4 px-4'}>
                    <div className="gi-monthly-savings-modal-text text-center pt-3 pb-2">
                        <div className="gi-monthly-savings-modal-text text-center pt-3 pb-2">
                            <h5 className="mb-0">
                                {IntlMessages('monthly_saving_error_text1')}
                            </h5>
                            <h6 className="mt-2 mb-2">
                                {IntlMessages('monthly_saving_error_text2')}
                            </h6>
                            <p className="mb-2">
                                {IntlMessages('monthly_saving_error_text3')}
                            </p>
                            <div className="text-center">
                                <img src={intl.formatMessage({ id: 'BLACK_SMILEY_ICON' })} alt="" />
                            </div>
                            <p className="mt-2 mb-3">
                                {IntlMessages('monthly_saving_error_text4')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />

            {/* success modal */}
            <SuccessModalNew
                openModal={emailSuccessModal}
                bodyClassName='py-5 px-4'
                messageBody={messageBody}
                showCloseButton={false}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default MonthlyEmailModal