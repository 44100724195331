import BasketService from 'api/APIServices/BasketService';
import * as BasketType from "api/APIMaster/BasketType";
import * as Local from 'service/utils/LocalStorageData';

const Basket = new BasketService();

export const getBasketOrderPending = async (accessToken: string) => {

    return await Basket.getBasketOrderPending({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const renameBasket = async (data: BasketType.BasketRenameRequestModel, accessToken: string) => {

    return await Basket.renameBasket(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const placeBasketCashOutOrder = async (data: string, accessToken: string) => {

    return await Basket.placeBasketCashOutOrder(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketDetail = async (basketId: number, accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        basketId: basketId
    }

    return await Basket.getBasketDetail(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketPerformanceHistorical = async ({
    basketId,
    dateRange }: {
        basketId: number,
        dateRange: { startDate: string, endDate: string }
    }, accessToken: string) => {

    const data = {
        basketId: basketId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Basket.getBasketPerformanceHistorical(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketPerformanceProjection = async ({
    basketId,
    yearRange }: {
        basketId: number,
        yearRange: number
    }, accessToken: string) => {

    const data = {
        basketId: basketId,
        years: yearRange
    }

    return await Basket.getBasketPerformanceProjection(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const deleteBasket = async (data: string, accessToken: string) => {

    return await Basket.deleteBasket(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketCustomizationDetail = async (accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
    }

    return await Basket.getBasketCustomizationDetail(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getAllCompanyForUserExclusion = async (accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
    }

    return await Basket.getAllCompanyForUserExclusion(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketPerformanceComparison = async ({
    selectedEsgcValueList,
    userExcludedList }: {
        selectedEsgcValueList: BasketType.BasketEsgcValueRequestModel[];
        userExcludedList: string[];
    }, accessToken: string) => {

    const data = {
        esgcValueList: selectedEsgcValueList,
        userExcludedCompanyList: userExcludedList
    }

    return await Basket.getBasketPerformanceComparison(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getSp500ReturnOnInvestment = async (accessToken: string) => {

    return await Basket.getSp500ReturnOnInvestment({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const portfolioBasicDetails = async (basketId: number, accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        basketId: basketId,
    }

    return await Basket.getBasketBasicDetail(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const editPortfolio = async (basketData: BasketType.BasketUpdateRequestModel, accessToken: string) => {

    const data = {
        basketId: basketData.basketId,
        basketName: basketData.basketName,
        esgcValueList: basketData.esgcValueList,
        userExcludedCompanyList: basketData.userExcludedCompanyList
    }

    return await Basket.updateBasket(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const createBasket = async (basketData: BasketType.BasketCreateRequestModel, accessToken: string) => {

    const data = {
        basketName: basketData.basketName,
        esgcValueList: basketData.esgcValueList,
        userExcludedCompanyList: basketData.userExcludedCompanyList
    }

    return await Basket.createBasket(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getBasketBlueprint = async ({
    selectedEsgcValueList,
    userExcludedList }: {
        selectedEsgcValueList: BasketType.BasketEsgcValueRequestModel[];
        userExcludedList: string[];
    }, accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        esgcValueList: selectedEsgcValueList,
        userExcludedCompanyList: userExcludedList
    }

    return await Basket.getBasketBlueprint(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const redeemedGift = async (redeemedGiftDetails: BasketType.GiftRedemptionRequestModel, accessToken: string,) => {

    const data = {
        giftIdentifier: redeemedGiftDetails.giftIdentifier,
        esgcValueList: redeemedGiftDetails.esgcValueList,
        userExcludedCompanyList: redeemedGiftDetails.userExcludedCompanyList
    }

    return await Basket.redeemGift(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}