import * as React from 'react';
//router library
import { Route, Switch, useHistory } from "react-router-dom";
// radium for inline style
import Radium from "radium";
//local file
import { IntlProvider } from 'react-intl';
import AppLocale from '@/lang';
// error boundaries
import ErrorBoundaries from 'pages/ErrorBoundaries'
// logout helper
import { logoutAllTabsEventListener } from "helper/Logout";
// interface
import * as ConfigInterface from 'interface/Config';
import * as RouteInterface from 'interface/Route';

// route import
import { initialRoutes } from 'routes/Route';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer'

const App: React.FC = () => {
    const history = useHistory();

    const locale = useSelector(getLocale);
    const currentAppLocale: ConfigInterface.AppLang = AppLocale[locale.language];

    // check email is verified or not from url
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('success') === 'true') {
            history.replace(window.location.pathname);
        }
    }, [])

    // logout from all the tab
    React.useEffect(() => {
        logoutAllTabsEventListener(); //logout from all tabs
    }, [])

    return (
        <IntlProvider
            textComponent={React.Fragment}
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <ErrorBoundaries>
                <Radium.StyleRoot>
                    <Switch>
                        {initialRoutes.map((ele: RouteInterface.Route, i) =>
                            <Route exact path={ele.path} component={ele.Component} key={i} />
                        )}
                    </Switch>
                </Radium.StyleRoot>
            </ErrorBoundaries>
        </IntlProvider>
    );
}

export default App;
