//default library
import * as React from 'react';
//types import
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    name: string;
    check: boolean;
    id: string;
    dataTestId: string;
    inputValue: PropsFunction.PropsCheckedInputFunction;
}

const InputComponentCheckbox2: React.FC<Props> = (props: Props) => {
    const { name, check, id, dataTestId, inputValue } = props;//destructuring props

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target;
        inputValue(target.name, target.checked);
    };

    return (
        <input
            className="form-check-input"
            id={name}
            type="checkbox"
            name={id}
            onChange={handleOnChange}
            checked={check}
            data-testid={dataTestId}
        />
    );
};

export default InputComponentCheckbox2