// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
// custom function
import * as Local from 'service/utils/LocalStorageData';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
//regex from number
import { MAX_NUMBER_36, ONLY_NUMBER } from "config/Config";
// custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import OTPVerificationModal from 'components/ModalComponent/OTPVerificationModal';
import MaskInputComponent from "components/InputComponent/MaskInputComponent";
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const privacyPolicy = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-privacy-policy-il.pdf`;

const FullNameID: React.FC<Props> = (props: Props) => {
    const { propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [firstName, setFirstName] = React.useState<string>(''); //first name
    const [firstNameError, setFirstNameError] = React.useState<string>(''); //error message for first name

    const [lastName, setLastName] = React.useState<string>(''); //last name
    const [lastNameError, setLastNameError] = React.useState<string>(''); //error message for last name

    const [docID, setDocID] = React.useState<string>(''); //document id
    const [docIDError, setDocIDError] = React.useState<string>(''); //error message for document id

    const [phoneNumber, setPhoneNumber] = React.useState<string>(''); //phone number

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    const [openOTP, setOpenOTP] = React.useState<boolean>(false); //open send otp modal

    React.useEffect(() => {
        Local.removeLocalData('onBoardingDetails'); //remove local data
        propsData('/on-boarding', 1)

        // set filed data
        setFirstName(kycData.firstNameLocale)
        setLastName(kycData.lastNameLocale)

        if (kycData.phoneNumber && kycData.otpVerified) {
            const phoneNumber = kycData.phoneNumber.replace(/\D/g, '').slice(3).replace(/(\d{3})(\d{7})/, '$1 $2');
            setPhoneNumber(phoneNumber)
        }

    }, [])

    //  updating input values in state
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        fieldName === "firstName" && setFirstName(fieldValue);
        fieldName === "lastName" && setLastName(fieldValue);
        if (fieldName === "docID") {
            const value = fieldValue.replace(ONLY_NUMBER, '')
            setDocID(value);
        }
        if (fieldName === "phoneNumberIL") {
            setPhoneNumber(fieldValue);
            dispatch(setKYCData({ otpVerified: false }));
        }
        setIsDataChanged(true)
    };

    // goto next page
    const sendOTP = (): void => {
        if (!kycData.otpVerified) {
            setOpenOTP(true);
            dispatch(setKYCData({ phoneNumber: `+972${phoneNumber.split(' ').join('')}` }));
        } //if otp is not verified then open otp verification modal
        kycData.otpVerified && clickNext(); //if otp is verified then redirect to next page
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const newKycData = {
            ...kycData,
            firstNameLocale: firstName,
            lastNameLocale: lastName,
            docID,
            otpVerified: true
        }
        dispatch(setKYCData(newKycData));

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);

            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page2')
            }
        } else {
            history.push('/on-boarding/page2')
        }
    }

    // validate field name for the next screen
    const validateData = (): void => {
        // check dob
        if (!firstName || firstNameError || firstName.length < 2) {
            setIsDisabled(true)
        } else if (!lastName || lastNameError || lastName.length < 2) {
            setIsDisabled(true)
        } else if (!docID || docIDError || docID.length < 7) {
            setIsDisabled(true)
        } else if (!phoneNumber || phoneNumber.length !== 11) {
            setIsDisabled(true)
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    // set-up error message for the first and last name
    React.useEffect(() => {
        // first name validation
        firstName.length > MAX_NUMBER_36 ? setFirstNameError('error_max_length_36_name') : setFirstNameError('');

        // last name validation
        lastName.length > MAX_NUMBER_36 ? setLastNameError('error_max_length_36_name') : setLastNameError('');

        // israeli document id
        docID.length > 9 ? setDocIDError('error_invalid_ID_card') : setDocIDError('');

    }, [firstName, lastName, docID])

    // validation function call
    React.useEffect(() => {
        validateData()
    }, [firstName, lastName, docID, phoneNumber, firstNameError, lastNameError, docIDError])

    // close the modal
    const onHandleClose = (): void => {
        setOpenOTP(false)
    }

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* page content */}
                    <div className="col-md-11">
                        <div className="row gx-4 gy-4">
                            {/* page header */}
                            <div className="col-12">
                                <div className="gi-ob-heading mt-4">
                                    <h5>{IntlMessages('kyc_name_page_header')}</h5>
                                </div>
                            </div>

                            {/* first name */}
                            <div className="col-md-6">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={"mb-2"}
                                        labelText={'kyc_first_name_label'}
                                        className=''
                                        fieldValue={firstName}
                                        name={'firstName'}
                                        placeholder={'placeholder_IL_first_name'}
                                        readOnly={false}
                                        disabled={false}
                                        isError={Boolean(firstNameError)}
                                        errorMessage={firstNameError}
                                        inputValue={setInputValue}
                                        dataTestId={"first-name"}
                                    />
                                </div>
                            </div>

                            {/* last name */}
                            <div className="col-md-6">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={"mb-2"}
                                        labelText={'kyc_last_name_label'}
                                        className=''
                                        fieldValue={lastName}
                                        name={'lastName'}
                                        placeholder={'placeholder_IL_last_name'}
                                        readOnly={false}
                                        disabled={false}
                                        isError={Boolean(lastNameError)}
                                        errorMessage={lastNameError}
                                        inputValue={setInputValue}
                                        dataTestId={'last-name'} />
                                </div>
                            </div>

                            {/* document id */}
                            <div className="col-md-6">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={"mb-2"}
                                        labelText={'kyc_last_doc_id'}
                                        className=''
                                        fieldValue={docID}
                                        name={'docID'}
                                        placeholder={'placeholder_IL_doc_id'}
                                        readOnly={false}
                                        disabled={false}
                                        isError={Boolean(docIDError)}
                                        errorMessage={docIDError}
                                        inputValue={setInputValue}
                                        dataTestId={'doc-id'} />
                                </div>
                            </div>

                            {/* phone number */}
                            <div className="col-md-6">
                                <div className="gi-ob-form form-group">
                                    <MaskInputComponent
                                        labelText={'kyc_last_phone_label'}
                                        placeholder={"placeholder_IL_phone"}
                                        name={"phoneNumberIL"}
                                        readOnly={false}
                                        fieldType={"text"}
                                        fieldValue={phoneNumber}
                                        inputValue={setInputValue}
                                        guide={false}
                                        dataTestId='phone-number'
                                        className={'text-start'}
                                        errorMessage={''}
                                        isError={false}
                                    />
                                </div>
                            </div>

                            {/* bottom static text */}
                            <div className="col-12">
                                <div className="gi-ob-policy-text">
                                    <p>
                                        {IntlMessages('kyc_name_page_footer1')}
                                        &nbsp;<a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                                            {IntlMessages('privacy_policy_il')}
                                        </a>
                                        {IntlMessages('kyc_name_page_footer2')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={sendOTP} />
                    </div>
                </div>
            </div>

            {/* OTP verification modal */}
            <OTPVerificationModal
                openModal={openOTP}
                onHandleClose={onHandleClose}
                onHandleSuccess={clickNext}
            />
        </>
    )
}

export default FullNameID