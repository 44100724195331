//default library
import * as  React from 'react';
// lottie animation
import lottie from 'lottie-web/build/player/lottie_light';
// spinner animation
import SpinnerAnimationDesktop from 'service/animation/SpinnerAnimationDesktop.json';

export type Props = {
    loadAnimation: boolean;
    width: number;
}

const PortfolioAnimation: React.FC<Props> = (props: Props) => {

    const { loadAnimation, width } = props; //destructuring props

    const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (loadAnimation && lottieAnimation.current) {
            const animation = lottie.loadAnimation({
                container: lottieAnimation.current,
                renderer: 'svg',
                animationData: SpinnerAnimationDesktop,
                loop: true,
                autoplay: true,
            });

            return () => {
                // Cleanup animation on component unmount
                animation.destroy();
            };
        }
    }, [loadAnimation]);

    const animationStyle: React.CSSProperties = {
        width: width,
    };

    return (
        <div className="pie-animation" ref={lottieAnimation}>
            {loadAnimation && <div style={animationStyle} />}
        </div>
    );
};

export default PortfolioAnimation;
