// default library
import * as React from 'react';
import Select from 'react-select';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//types import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    labelClassName: string;
    labelText: string;
    name: string;
    placeholder: string;
    option: PropsFunction.Option;
    className: string;
    fieldValue: PropsFunction.OptionData;
    ariaLabel: string;
    isDisabled: boolean;
    isModalDropdown: boolean;
    inputValue: PropsFunction.SelectedOption;
}

const SelectDropdown: React.FC<Props> = (props: Props) => {

    const { labelText, labelClassName, name, placeholder, option, className, ariaLabel, isDisabled, isModalDropdown, fieldValue, inputValue } = props;

    const intl = useIntl();

    const handleOnChange = (e: any): void => {
        inputValue(name, e);
    };

    return (
        <div className={className}>
            {labelText && <label className={labelClassName} htmlFor={name}>{IntlMessages(labelText)}</label>}
            <Select
                name={name}
                id={name}
                classNamePrefix={`${className}-react-select`}
                options={option}
                aria-label={ariaLabel}
                onChange={handleOnChange}
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
                value={fieldValue.value ? fieldValue : ''}
                isDisabled={isDisabled}
                components={{ IndicatorSeparator: () => null }}
                menuPortalTarget={isModalDropdown ? null : document.body}
            />
        </div>
    )
}

export default SelectDropdown