const Common: { [key: string]: string } = {

    /* Button Name */
    'button_next': 'הבא',
    'button_ok': 'אישור',
    'button_continue': 'המשך',
    'button_verify': 'אימות',
    'button_cancel': 'ביטול',
    'button_update': 'עדכון',
    'button_confirm': 'אישור',
    'button_transfer': 'העברה',
    'button_submit': 'שליחה',
    'button_contact_us': 'צרו קשר',
    'button_fund': `ממנו את החשבון שלכם`,
    'button_buy_fund': 'העברה',
    'button_buy_invest': 'השקעה',
    'button_sell': 'מכירה',
    'button_view_details': `צפייה בפרטים`,
    'button_invest': `השקיעו בתיק`,
    'button_create': `יצירה`,
    'button_close': `סגירה`,
    'button_connect': `חיבור`,
    'button_back': `חזרה`,
    'button_change': `שינוי`,
    'button_delete': `מחיקה`,
    'button_enter': `כניסה`,
    'button_get_started': `התחילו`,
    'button_redeem': `פדיון`,
    'button_personalize': `התאמה אישית`,
    'button_start': `התחלה`,
    'button_add_funds': `הוספת כספים`,
    'button_monthly_saving': `הגדר חיסכון חודשי`,
    'button_view': `צפייה`,
    'button_advanced': `מתקדם`,
    'button_create_portfolio': `יצירת תיק`,
    'button_send': `שלח`,
    'button_withdraw': `למשוך כסף`,
    'button_withdraw2': `לָסֶגֶת`,
    'button_resend_code': 'שליחת קוד שוב',
    'button_click': 'נְקִישָׁה',
    'button_edit': 'עריכה',
    'button_ok_2': 'בסדר',
    'button_resend': 'שלח שוב',
    'button_check': 'בדוק',
    'button_skip': 'דלג',
    'button_save': 'שמור שינויים',
    'button_save2': 'להציל',
    'button_pause': 'השהה',
    'button_resume': 'המשך',

    /* Common text */
    'logout': 'התנתקות',
    'logout2': 'התנתקות',
    'click_here': 'לחצו כאן',
    'of': 'של',
    'common_verify': 'מאומת!',
    'common_verify2': '(מאומת)',
    'thank_you': `תודה`,
    'unlink': `ביטול הקישור`,
    'hello': `שלום`,
    'note': `הערה`,
    'minimum': `מינימום`,
    'from': `מן`,
    'page': `דף :`,
    'previous': `הקודם`,
    'next': `הבא`,
    'week': `שבוע`,
    'faqs': `שאלות ותשובות`,
    'loading': 'טוען...',
    'gift': `מתנה`,
    'total': `סה“כ`,
    'complete': `השלמה`,
    'to': `אל`,
    'from2': `מן`,
    'good': `טוב`,
    'sp500': 'S&P 500',
    'year': 'שָׁנָה',
    'log_out': `להתנתק`,
    'unspecified': 'לא מצוין',

    /* ESG-C value name */
    'sustainable_climate': `אקלים בר קיימא`,

    /* Symbol */
    '$': '$',
    '%': '%',

    /* Header and Footer */
    'header_login': `התחברות`,
    'header_register': `הרשמה`,
    'header_rtl': `שמאל לימין`,
    'header_ltr': `ימין לשמאל`,
    'footer_giraffe': `מה זה Giraffe 500?`,
    'footer_products': `מוצרים`,
    'footer_about': `אודות`,
    'footer_blog': `בלוג`,
    'footer_business': `לעסקים`,
    'footer_education': `חינוך`,
    'footer_gifts': `מתנות`,
    'gift_login': 'התחברות',
    'gift_register': 'הרשמה',
    'heading_text_date': `להלן סקירה כללית של ההשקעות שלך`,
    'heading_text_hi': `שלום`,

    /* Placeholder */
    'placeholder_type_here': 'הקלידו כאן',
    'placeholder_auto_select': 'כתובת רחוב, עיר',
    'placeholder_apartment_unit': 'מספר בית או דירה',
    'placeholder_country': 'מדינה',
    'placeholder_employment_status': 'מועסק כרגע',
    'placeholder_amount_range': 'טווח כמות',
    'placeholder_investing_experience': 'ניסיון בהשקעות',
    'placeholder_email': 'כתובת דוא“ל',
    'placeholder_ticker': 'סמל מנייה',
    'placeholder_investing_exp': 'ניסיון בהשקעות',
    'placeholder_industry': 'תעשייה',
    'placeholder_position': 'תפקיד',
    'placeholder_dob': 'MM/DD/YYYY',
    'placeholder_phone': '(123) 456 - 7890',
    'placeholder_zip_code': '••••••',
    'placeholder_ssn': '111-11-1111',
    'placeholder_currency_1': '1.00',
    'placeholder_currency_10': '10.00',
    'placeholder_currency_100': '100.00',
    'placeholder_select_value': 'בחרו ערך...',
    'placeholder_change_name': 'היציאה לגמלאות שלי',
    'placeholder_code': 'הכניסו קוד כאן',
    'placeholder_enter_amount': 'הזינו סכום מעל $100',
    'placeholder_family_member': 'שם הפקיד ובני המשפחה',
    'placeholder_company_name': 'שם החברה',
    'placeholder_dropdown_select': 'בחר',

    /* On boarding placeholder */
    'placeholder_first_name': 'שם פרטי חוקי',
    'placeholder_last_name': 'שם משפחה חוקי',
    'placeholder_phone_2': '(123) 456-7890',
    'placeholder_apartment_2': 'מספר דירה / יחידה',
    'placeholder_annual_income': 'טווח הכנסה',
    'placeholder_net_worth': 'טווח שווי נכסים',

    /* verify email page */
    'thank_you1': `תודה`,
    'verify_email_t1': `אנא לחצו על הקישור בהודעת האימות בדוא"ל כדי להמשיך`,
    'resend_email': `שלחו דוא“ל שוב`,
    'wrong_email': `דוא“ל שגוי?`,
    'sign_out': `התנתקות`,

    /* Session time out modal */
    'session_timeout1': `החיבור נותק`,
    'session_timeout2': `החיבור נותק`,
    'session_timeout_t1': `החיבור הנוכחי עומד לפוג בעוד`,
    'session_timeout_t2': `שניות`,
    'session_timeout_t3': `הוצאתנו אותך מהחשבון כדי שהמידע הפיננסי של {br} יישאר בטוח.`,
    'session_timeout_t4': `כל העסקאות שאושרו נשמרות!`,
    'sign_in': `התחברות`,

    /* 2. Dashboard */
    /* 2.1 Route Name */
    'dashboard_home': 'בית',
    'dashboard_add_portfolio': 'הוספת תיק',
    'dashboard_activity': 'פעילות',
    'dashboard_account_setting': 'הגדרות חשבון',
    'dashboard_account_setting2': 'הגדרות חשבון',
    'dashboard_taxes': 'מיסים ותיעוד',
    'dashboard_my_gift': 'המתנות שלי',
    'dashboard_buy_gift': 'קניית מתנה',
    'dashboard_giraffe_view': 'תצפית ג‘ירפה',
    'dashboard_giraffe_learn': 'רשימת השמעה פיננסית',
    'dashboard_mini_mba': 'מיני מאסטר במנהל עסקים',
    'dashboard_current_investment': 'השקעות נוכחיות',
    'dashboard_create_investment': 'יצירת תיק',
    'dashboard_learn': 'רשימת השמעה פיננסית',
    'dashboard_gift_given': `מתנות שחילקתי`,
    'dashboard_my_impact': `ההשפעה שלי`,
    'dashboard_new_gift_pending': `מתנה חדשה בהמתנה`,
    'dashboard_contact_us': `צרו קשר`,
    'dashboard_finish_setup': `סיימו התקנה`,

    /* KYC error */
    'kyc_trouble_data': `אנו מתקשים לאמת את הנתונים שלך`,
    'kyc_address': `אנו מתקשים לאמת את הכתובת שלך`,
    'kyc_address_label': `אנא אמתו או שנו את הכתובת החוקית שלכם`,
    'kyc_apartment_label': `דירה/יחידה`,
    'kyc_ssn': `אנו מתקשים לאמת את מספר תעודת זהות שלך`,
    'kyc_dob': `אנו מתקשים לאמת את תאריך הלידה שלך`,
    'kyc_dob_label': `אנא אמתו או שנו את תאריך הלידה שלכם`,
    'kyc_name': `אנו מתקשים לאמת את שמך`,
    'kyc_number': `אנו מתקשים לאמת את מספר הטלפון שלך`,
    'kyc_number_label': `אנא אמתו או שנו את מספר הטלפון שלכם`,
    'kyc_citizenship': `אנו מתקשים לאמת את האזרחות שלך`,

    /* Modal Header */
    'modal_submit': `שליחת המידע שלך`,
    'modal_connect_bank': `מתחבר לבנק...`,
    'modal_fund_account': `נא לממן את חשבונך`,

    /* Table Fields */
    'table_not_range': `אין עסקאות קיימות בטווח התאריכים שצוין`,
    'table_date': 'תאריך',
    'table_description': 'תיאור',
    'table_amount': 'סכום',
    'table_range': 'טווח תאריכים',
    'table_ticker': 'סמל מנייה',
    'table_dollar_value': 'ערך הדולר',
    'table_industry': 'תעשייה',
    'table_percentage': 'אחוז מהתיק',
    'table_document': 'שם המסמך',
    'table_account_name': 'שם החשבון',
    'table_form_type': 'סוג הטופס',
    'table_status': 'סטטוס',
    'table_trade': 'אישור מסחר',
    'table_no_data': 'אין נתונים בטווח תאריכים ספציפי',
    'table_company_name': 'שם החברה',

    /* Gift Landing Page */
    'gift_header_about': `אודות`,
    'gift_header_contact': `צרו קשר`,
    'gift_landing_text1': `יקר`,
    'gift_landing_text2': `מתנת ההשפעה`,
    'gift_landing_text3': `ממשו את המתנה שלכם`,
    'gift_landing_text4': `אם כבר יש לך חשבון; ניתן להזין את הקוד הזה בכרטיסייה "מתנות" בחשבונך:`,
    'gift_landing_text5': `הרחיבו את הידע שלכם עם`,
    'gift_landing_text6': `פלטפורמה פשוטה וקלה שבה ניתן לצפות בסרטונים כדי ללמוד על בריאות פיננסית, השקעה בת קיימא, סיפורים מעוררי השראה על אנשים שמשנים את העולם ועוד!`,
    'gift_landing_text7': `מנוי לשנה של:`,
    'gift_landing_text8': `פדו את כרטיס המתנה להשקעה שלכם {giftAmount} וצפו בו צומח במצפון נקי`,
    'gift_landing_text9': `אנא ראו את ה- שלנו`,
    'gift_landing_text10': `מרכז התמיכה`,
    'gift_landing_text11': `כדי לראות כמה מאמרים שעשויים לעזור לך`,
    'question1': `מי יכול לקבל מתנת CLEAN S&P 500?`,
    'answer1': `כל מי שזכאי להקים חשבון השקעות בארה"ב - אזרח ארה"ב, תושב קבע ו/או משלם מסים בארה"ב. זה נובע ממגבלות רגולטוריות.`,
    'question2': `איך אני יכול לקנות CLEAN S&P 500 לעצמי?`,
    'answer2': `אוהבים את הגישה! זה בעצם קל מאוד... פשוט רכשו לעצמכם מתנה. שלחו את הקישור הניתן לשיתוף לעצמכם. לחצו עליו וסיימתם.`,
    'question3': `מי יכול לתת מתנה?`,
    'answer3': `במילים פשוטות, כל אחד! הגדרנו זאת כך שצריך רק כרטיס אשראי בשביל לתת מתנה.`,
    'question4': `למה לא כולם משקיעים ב-S&P 500?`,
    'answer4': `לרוב המשקיעים המקצועיים יש את ה-S&P 500 בתיק! זהו אחד המדדים הפופולריים ביותר בעולם.`,
    'question5': `ובכן, מדוע כל כך הרבה יועצי השקעות מציעים כל כך הרבה אסטרטגיות השקעה אחרות?`,
    'answer5': `התשובה פשוטה: אם הם ממליצים לך להשקיע רק ב-S&P 500, הם לא יכולים להצדיק את העמלות הגבוהות שלהם. עם זאת, אם הם בונים אסטרטגיית השקעה ספציפית, הם מרגישים שהם יכולים לבקש שכר טרחה, אם כי, לפי מחקר אקדמי, יותר מ-80% מיועצי ההשקעות המקצועיים מפיקים ביצועים פחות טובים מ-S&P 500.`,
    'question6': `רוצים ללמוד עוד?`,
    'answer6': `קראו על "הגבול היעיל" של הארי מרקוביץ, חתן פרס נובל לכלכלה... או פשוט גשו לחפש בגוגל את התוצאות של S&P 500 לעומת יועצי השקעות מקצועיים`,
    'question7': `יש לך שאלות לגבי מימוש המתנה שלך?`,
    'redeemed_error_modal': 'אופס!',
    'redeemed_error_modal_text1': 'נראה שהמתנה הזו כבר מומשה.',
    'redeemed_error_modal_text2': 'אנא צרו קשר',
    'redeemed_error_modal_text3': 'support@giraffe.us',
    'redeemed_error_modal_text4': 'אם אתם חושבים שנפלה טעות.',

    /* customer notes */
    'customer_note1': `אם תרצו לשנות את מספר הטלפון או הדוא"ל שלכם, אנא פנו תחילה לשירות הלקוחות.`,
    'customer_note2': `אם תרצו לשנות את המידע הנעול כאן, אנא פנו תחילה לשירות הלקוחות.`,

    /* Static message */
    'buy_modal_static_message': `בשל אילוצים סביב אחזקות מינימליות במניות, אנו מגבילים את השבר הנמוך ביותר של מניה שתוכלו לקנות ל-$0.05. ככזה, ייתכן שתראו מספר נמוך יותר של מניות שנרכשו בתיק שלכם מאשר בדף המציג את התיק האידאלי.`,
    'expense_ratio_text1': `Giraffe משתמשים במונח "יחס הוצאות" כדי לתאר את האחוז מהתיק שגובה Giraffe על בסיס שנתי. בדיוק כמו שקרן נסחרת בבורסה גובה יחס הוצאות על סך הנכסים בקרן, לכל תיק ש-Giraffe מציעה יש עמלה שנתית ספציפית משלה הנגבית כאחוז משווי הנכסים בתיק.`,
    'expense_ratio_text2': `העמלה המוצגת היא על בסיס שנתי, אולם העמלה תחויב על בסיס חודשי בראשון לחודש.`,
    'sell_info_text1': `בגלל עמלות הכרוכות במכירת מאות מניות שונות, Giraffe תצטרך לגבות עמלת פירוק תיק של {currency} על מנת לכסות את העלויות שלנו`,
    'sell_info_text2': `על מנת לכסות את העלויות שלנו`,
    'create_basket_static_text1': `הוציאו את רוב החברות ב-S&P 500 המנוגדות לערך זה (20 חברות)`,
    'create_basket_static_text2': `חברות`,
    'create_basket_static_text3': `ניתן לשדרג רק ערך אחד`,
    'create_basket_static_text4': `השונות החזויה בין תיק ההשקעות שלך לבין S&P 500 לאורך אותו זמן`,
    'taxes_static_text1': `הצהרת מס משלימה`,
    'taxes_static_text2': `הכנסה ממקורות חוץ, ריבית של ממשלת ארה"ב, פירוט מוניציפלי לפי מדינה`,
    'giraffe_learn_text1': `עם רשימת השמעה פיננסית אנו רוצים לעורר בך השראה ולספק לך ידע על השפעת ההשקעות שלך. אנו מקווים שזה יעזור לך לקבל החלטות אחראיות יותר עבור עצמך ועבור כדור הארץ.`,

    /* Transaction Pending message */
    'add_fund_maximum_error': `אנו מתנצלים, אך סכום ההעברה המבוקש חורג מהמזומן הזמין למסחר. אתם יכולים לאשר את העסקה עבור הסכום הזמין המקסימלי או להמתין מספר ימים ולהעריך מחדש.`,
    'add_fund_zero_error': `אנו מתנצלים, אך כרגע אין לכם מספיק כסף למסחר. אנא שקלו להוסיף כספים לחשבונכם כדי להמשיך לסחור.`,
    'error_maximum_amount_withdraw': `אנו מתנצלים, אך סכום המשיכה המבוקש עולה על המזומנים הזמינים למשיכה, כרגע ב- {amount}. נא להזין סכום נמוך יותר.`,
    'add_fund_minimum_error': `סכום ההשקעה המינימלי הוא $100. נא להזין סכום גבוה יותר כדי להמשיך.`,
    'error_minimum_amount_withdraw': `סכום המשיכה המינימלי הוא $0.25. נא להזין סכום גבוה יותר כדי להמשיך.`,
    'add_fund_from_message': 'למרבה הצער, עסקאות השקעה אינן זמינות כעת. נא להמתין עד שה-{type} של ה-{amount} שלך יושלם.',
    'sell_error_message': `אנו מתנצלים, אך אין אפשרות ליזום עסקת מכירה כרגע. נא להמתין עד לסיום עסקת ה-{type} הנוכחית של ה-{amount} שלך.`,
    'sell_all_message': `אנו מתנצלים, אך עסקאות התיק שלך אינן זמינות עד לסיום עסקת המכירה הכוללת שלך.`,
    'add_fund_to_message': `אנו מתנצלים, אך אין אפשרות להוסיף כספים לתיק זה כרגע. נא להמתין עד לסיום עסקת ה-{type} הנוכחית של ה-{amount} שלך.`,
    'withdraw_message': 'אנו מתנצלים, אתם נמצאים כעת בתהליך של עסקת משיכה. נא המתינו עד שהמשיכה הנוכחית {amount} שלכם של תושלם, לפני שתתחילו במשיכה נוספת.',

    'buy_deposit_message': '{} עסקאות בתיק אינן זמינות עד שהעסקה ה-{type} שלך ב-{basketName} תושלם.',
    'sell_withdraw_message': `{} עסקאות בתיק אינן זמינות עד שהעסקה ה-{type} שלך ב-{basketName} תושלם.`,

    'total_card_message': 'עסקאות בתיק ומשיכה ממנו אינן זמינות מכיוון שיתרת המזומנים הכוללת שלך היא $0. נא להפקיד כספים בחשבונך.',

    'transfer_pending': 'ממתין',
    'transfer_amount': 'סכום',
    'transfer_business_day_deposit': 'עיבוד עסקה זו ייקח 2-3 ימי עסקים. לאחר מכן, תראו את המזומנים בחשבונכם ותוכלו להשקיע!',
    'transfer_business_day_withdrawal': 'עיבוד עסקה זו ייקח 2-3 ימי עסקים. לאחר מכן, תראו את המזומנים בחשבון הבנק שלכם.',

    'bank_transfer_text1': 'העברת מזומן',

    'direct_bank': `העסקה שלך תושלם תוך 2-3 ימי עסקים.`,
    'market_open': `העסקה שלך תושלם בשעות הפתיחה של השוק.`,
    'simple_basket_transfer': `העסקה שלך תושלם תוך 5-10 דקות.`,

    /* Default Dropdown Value */
    'yes': 'כן',
    'no': 'לא',
    'past_week': 'שבוע שעבר',
    'past_three_week': 'שלושת החודשים האחרונים',
    'past_six_week': 'ששת החודשים האחרונים',
    'past_year': 'שנה שעברה',
    'all_time': 'בכל הזמן',

    'one_month': 'חודש אחד',
    'three_month': 'שלושה חודשים',
    'year_to_date': 'מתחילת השנה',
    'one_year': 'שנה אחת',
    'two_year': 'שנתיים',

    'year_number': '{number} שנה',
    'years_number': '{number} שנים',
    'number': '{number}',

    'dynamic_month': '{month} חודש',
    'dynamic_year': '{year} שנה',

    'less_forty_thousand': 'פחות מ-40,000 דולר',
    'less_eighty_thousand': 'בין 40,000 ל-80,000 דולר',
    'less_one_fifty_thousand': 'בין 80,000 ל-150,000 דולר',
    'less_three_hundred_thousand': 'בין 150,000 ל-300,000 דולר',
    'more_three_hundred_thousand': 'יותר מ-300,000 דולר',
    'less_fifty_thousand': 'פחות מ-100,000 דולר',
    'less_two_lakhs': 'בין 100,000 ל-200,000 דולר',
    'less_five_lakhs': 'בין $200,000 ל-$500,000',
    'less_ten_lakhs': 'בין $500,000 ל$1,000,000',
    'more_ten_lakhs': 'יותר מ-1,000,000 דולר',

    /* Email Subscription */
    'email_subscription_text1': `מרכז התראות ה-Giraffe שלך`,
    'email_subscription_text2': `הדוא“ל שלך:`,
    'email_subscription_text3': `לא את/ה? שלחו דוא"ל אל support@giraffe.us כדי ליידע אותנו על הבעיה`,
    'email_subscription_text4': `אני רוצה להירשם ל:`,
    'email_subscription_text5': `למידע נוסף,{br} אנא פנו אלינו`,
    'email_subscription_text6': `מדיניות הפרטיות`,
    'email_subscription_text7': `ו`,
    'email_subscription_text8': `תנאי השירות.`,
    'email_subscription_marketing': `עדכוני Giraffe כלליים ועל המוצר מהסוואנה`,
    'email_subscription_learn': `הודעות דוא“ל שבועיות של רשימת השמעה פיננסית`,
    'email_subscription_button1': `עדכון התראות`,
    'email_subscription_button2': `ביטול הרשמה מכולם`,
    'email_subscription_notification': `התראות עודכנו`,

    /* Note text */
    'first_note_create_basket': `ההשקעות שלך מוגנות על ידי תוכנית הביטוח של ממשלת ארה"ב. מכיוון ששירות הברוקראז‘ שלנו חבר בתאגיד להגנת משקיעים בניירות ערך (SIPC), אנו מציעים אבטחה נוספת לחשבונך. במקרה הבלתי סביר של כישלון משרדנו, ה-SIPC שומר על ניירות הערך הכשירים שלך ומזומן עד $250,000 עבור כל קטגוריה, ומספק כיסוי חיוני מפני אובדן ההשקעות שלך. חשוב לציין שבעוד שהגנת SIPC אינה מכסה הפסדי השקעה הנובעים מתנודות בשוק, היא כן מציעה הגנה חיונית במקרה של כישלון של חברת ברוקראז'. האמון שלך מוערך מאוד, ואנו נשארים מחויבים לספק לך את רמת השירות הגבוהה ביותר. למידע נוסף על SIPC `,
    'second_note_create_basket': `יועצי השקעות רשומים (RIA) הנם גורם מכריע בתעשייה הפיננסית, ומספקים ייעוץ השקעות מותאם אישית ושירותי ניהול תיקים ללקוחות בודדים. מה שמייחד את יועצי ההשקעות הרשומים הוא הרישום שלהם ברשות ניירות ערך (SEC) או רגולטורים של ניירות ערך במדינה, מה שמכפיף אותם לרגולציה ופיקוח מקיפים. מסגרת רגולטורית זו מבטיחה שקיפות, יושרה ואחריות בפעילותם, ומציעה הגנה חיונית למשקיעים על ידי דרישה מ-RIA לפעול כנאמנים, תוך מתן עדיפות תמיד לאינטרסים של לקוחותיהם.`,

    /* Language Location Dropdown */
    'location_start_heading': 'רגע לפני שנתחיל',
    'location_start_text': 'עלינו רק לוודא.{br} מרכז מגוריך נמצא ב-',
    'location_start_sorry_heading': 'אנו ממש מצטערים',
    'location_start_sorry_text': 'בשלב זה השירותים ניתנים{br}לתושבי ישראל וארה״ב בלבד',
    'location_israel': 'ישראל',
    'location_us': 'ארה״ב',
    'location_other': 'אחר',
    'location_btn': 'כן, אפשר להתחיל',

    /* Israel Dropdown Value */
    // Employment Status
    'employment_status_employee': 'שכיר',
    'employment_status_independent': 'עצמאי',
    'employment_status_student': 'סטודנט',
    'employment_status_unemployee': 'מובטל',
    'employment_status_pensioner': 'גמלאי',

    // Investment purpose
    'investment_purpose_long_term': 'חסכון ארוך טווח',
    'investment_purpose_future': 'רכישת נכס עתידי',
    'investment_purpose_saving': 'חסכון לטובת הילדים',
    'investment_purpose_no_specific': 'אין ייעוד ספציפי',

    // Amount Intent
    'amount_intent_10': 'פחות מ 10%',
    'amount_intent_10_50': 'בין 10% - 50%',
    'amount_intent_50_95': 'בין 50% - 95%',
    'amount_intent_95': 'מעל 95%',

    // Investment Fund
    'investment_fund_year_2': 'בשנתיים הקרובות',
    'investment_fund_year_2_5': '5 - 2 שנים',
    'investment_fund_year_6_10': '10 - 6 שנים',
    'investment_fund_year_11_15': '15 - 11 שנים',
    'investment_fund_year_15': 'יותר מ 15 שנה',

    // Other Source of Money
    'other_source_1': 'משכורת',
    'other_source_2': 'פדיון עסקי/ייצוא/נכסים מניבים',
    'other_source_3': 'כספים שהצטברו לאורך השנים בחשבון בנק בבעלותי',
    'other_source_4': 'פעילות מסחר במטבעות',
    'other_source_5': 'מכירת נדל״ן/פדיון חסכונות/פדיון קופת גמל/קרן השתלמות',
    'other_source_6': 'פעילות במטבעות קריפטוגרפים',
    'other_source_7': 'מלגה/מתנה/פיצויים/תביעה/ירושה',
    'other_source_8': 'הימורים',
    'other_source_9': 'קצבה/פנסיה/כספי ביטוח לאומי',

    /* Monthly Saving Status */
    'monthly_saving_status_active': 'פָּעִיל',
    'monthly_saving_status_paused': 'הַפסָקָה',
    'monthly_saving_status_failed': 'נִכשָׁל',
    'monthly_saving_status_pending': 'ממתין לאישור',
    'monthly_saving_status_denied': 'נדחה',
    'monthly_saving_status_denied_action': 'נדחה - נדרשת פעולה ',
};

export default Common;