// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// intl message
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// email regex
import { EMAIL_REGEX } from 'config/Config';
//types props
import * as PropsFunction from "interface/PropsFunction";
// api call
import * as UserService from "middleware/UserService";
// custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import MaskInputComponent from 'components/InputComponent/MaskInputComponent';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    openModal: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleContinue: PropsFunction.SimpleFunction;
    onHandleDashboard: PropsFunction.SimpleFunction;
}

const KYCEmailSetupModal: React.FC<Props> = (props: Props) => {
    const { openModal, onHandleClose, onHandleContinue, onHandleDashboard } = props;

    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [emailModal, setEmailModal] = React.useState<boolean>(false); //email input modal
    const [emailVerificationModal, setEmailVerificationModal] = React.useState<boolean>(false); //email verification modal
    const [emailNotListModal, setEmailNotListModal] = React.useState<boolean>(false); //email not list modal
    const [emailSkipModal, setEmailSkipModal] = React.useState<boolean>(false); //email skip modal
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal

    const [workEmail, setWorkEmail] = React.useState<string>(''); //user work email
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //email button is disabled

    const [verifyCode, setVerifyCode] = React.useState<string>(''); //user work email verification code
    const [verifyCodeError, setVerifyCodeError] = React.useState<boolean>(false); //user work email verification code error

    React.useEffect(() => {
        openWorkEmailModal()
    }, [openModal])

    // send work email verification code API
    const sendEmailCodeAPI = async (): Promise<void> => {
        setEmailVerificationModal(false); //verify code modal 
        setLoadingModal(true); //loading modal show
        setVerifyCode(''); //reset the verified code
        setVerifyCodeError(false); //reset the verification code error
        setEmailModal(false); //close email modal

        const accessToken = await initiateAction() //get access token
        const sendWorkCode = await UserService.sendWorkEmailCode(workEmail, accessToken);

        setLoadingModal(false); //loading modal hide

        if (sendWorkCode.response || sendWorkCode.request) {
            setIsError(true);
            setErrorLog(sendWorkCode);
        } else {
            //open email verification modal when send work email code return true
            if (sendWorkCode) {
                setEmailVerificationModal(true);
            } else {
                // open email not list if email is not verified
                setEmailNotListModal(true);
            }
        }

    }

    // verify work email verification code API
    const verifyEmailAPI = async (): Promise<void> => {
        setLoadingModal(true); //loading modal show
        setEmailVerificationModal(false); //close email verification modal

        const accessToken = await initiateAction() //get access token
        const isEmailVerified = await UserService.verifyWorkEmail(workEmail, verifyCode, accessToken);

        setLoadingModal(false); //loading modal hide

        if (isEmailVerified.response || isEmailVerified.request) {
            setIsError(true);
            setErrorLog(isEmailVerified);
        } else {
            if (isEmailVerified) {
                onHandleContinue() //continue kyc flow
            } else {
                setEmailVerificationModal(true);
                setVerifyCodeError(true);
            }

        }
    }

    // open email setup modal
    const openWorkEmailModal = (): void => {
        setEmailModal(openModal); //open close email modal
        setEmailVerificationModal(false); //close email verification modal
        setEmailNotListModal(false); //close email not list modal
        setEmailSkipModal(false); //close email success modal
        setLoadingModal(false); //loading modal hide

        setWorkEmail(''); //reset the value
        setIsDisabled(true); //reset the value
        setVerifyCode(''); //reset the value
        setVerifyCodeError(false); //reset the value
    }

    // open skip modal
    const openSkipModal = (): void => {
        setEmailModal(false); //open close email modal
        setEmailSkipModal(true); //open skip modal
    }

    // update the input value 
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === 'workEmail') {
            setWorkEmail(fieldValue);
        }
        fieldName === 'verifyCode' && setVerifyCode(fieldValue);
    }

    // work email check
    React.useEffect(() => {
        if (!workEmail) {
            setIsDisabled(true);
        } else if (!workEmail.match(EMAIL_REGEX)) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [workEmail])

    //verification code length check
    React.useEffect(() => {
        verifyCode.length === 6 && verifyEmailAPI()
    }, [verifyCode])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* email modal */}
            <Modal
                centered
                size='sm'
                show={emailModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                contentClassName='border-0'
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>
                {/* main body */}
                <Modal.Body className={'pt-4 pb-3 mt-3 px-4'}>
                    <div className="enter-code-modal-text px-1">
                        <h5>{IntlMessages('work_email_modal_head')}</h5>
                        <p className="mt-3 mb-4">{IntlMessages('work_email_modal_sub_head', { br: <br /> })}</p>
                        <div className="gi-ob-form form-group mb-2 w-75 mx-auto px-1">
                            <InputTextComponent
                                labelClassName={"gi-ob-modal-form-label mb-2"}
                                labelText={'work_email_label'}
                                className='text-center'
                                fieldValue={workEmail}
                                name={'workEmail'}
                                placeholder={''}
                                readOnly={false}
                                disabled={false}
                                isError={false}
                                errorMessage={''}
                                inputValue={setInputValue}
                                dataTestId={'work_email_label'}
                            />
                        </div>
                    </div>
                </Modal.Body>
                {/* button */}
                <Modal.Footer className={'enter-code-modal-footer justify-content-center'}>
                    <div className="text-center py-3">
                        {/* footer text */}
                        <p className='mb-3'>{IntlMessages('work_email_footer', { br: <br /> })}</p>
                        {/* check and skip btn */}
                        <div className="d-flex gap-2 pt-2 justify-content-center" dir='rtl'>
                            {/* check btn */}
                            <button className="gi-ob-check-btn" type="button" disabled={isDisabled} onClick={sendEmailCodeAPI}>
                                {IntlMessages('button_check')}
                            </button>
                            {/* skip btn */}
                            <button className="gi-ob-skip-btn" type="button" onClick={openSkipModal}>
                                {IntlMessages('button_skip')}
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* verify email modal */}
            <Modal
                centered
                size='sm'
                show={emailVerificationModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>
                {/* main body */}
                <Modal.Body className={'pt-4 pb-3 mt-3 px-4'}>
                    <div className="enter-code-modal-text px-1">
                        {verifyCodeError
                            ? <>
                                <h5 className="mb-0 text-error">
                                    {IntlMessages('work_email_verify_error_text1')}
                                </h5>
                                <p className="mt-3 mb-4">{IntlMessages('work_email_verify_error_text2', { br: <br /> })}</p>
                            </>
                            : <>
                                <h5>{IntlMessages('work_email_verify_text1')}</h5>
                                <p className="mt-3 mb-4">{IntlMessages('work_email_verify_text2', { br: <br /> })}</p>
                            </>
                        }
                        <div className="gi-ob-form form-group mb-2 w-75 mx-auto px-1">
                            <MaskInputComponent
                                placeholder={'placeholder_zip_code'}
                                name={'verifyCode'}
                                readOnly={false}
                                fieldType={'password'}
                                fieldValue={verifyCode}
                                inputValue={setInputValue}
                                guide={false}
                                dataTestId='verify-code'
                                labelText={''}
                                className={`text-center`}
                                errorMessage={''}
                                isError={verifyCodeError}
                            />
                        </div>
                    </div>
                </Modal.Body>
                {/* button */}
                <Modal.Footer className={'enter-code-modal-footer justify-content-center'}>
                    <div className={`text-center ${verifyCodeError ? 'm-0 pb-4 pt-2 mb-1' : 'py-3'}`}>
                        {/* footer text */}
                        {!verifyCodeError && <p className='mb-3'>{IntlMessages('work_email_verify_text3', { br: <br /> })}</p>}
                        {/* resend code */}
                        <span>
                            <a href="#!" className="text-grass-green" onClick={sendEmailCodeAPI}>
                                {IntlMessages('work_email_verify_resend')}
                            </a>
                        </span>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* email not list modal */}
            <Modal
                centered
                size='sm'
                show={emailNotListModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleDashboard} aria-label="Close" data-testid='close-sell'></button>
                {/* main body */}
                <Modal.Body className={'pt-4 pb-3 mt-3 px-4'}>
                    <div className="enter-code-modal-text px-1">
                        <h5>{IntlMessages('work_email_not_list_text1')}</h5>
                        <p className="mt-3 mb-4">{IntlMessages('work_email_not_list_text2', { br: <br /> })}</p>
                    </div>
                </Modal.Body>
                {/* footer */}
                <Modal.Footer className={'enter-code-modal-footer justify-content-center'}>
                    <div className="text-center py-3">
                        <p className="mb-4">
                            {IntlMessages('work_email_not_list_text3', { br: <br /> })}
                        </p>
                        <div className="d-flex gap-2 pt-4 justify-content-center" dir='rtl'>
                            <button className="gi-ob-check-btn" type="button" onClick={onHandleContinue}>
                                {IntlMessages('button_continue')}
                            </button>
                            <button className="gi-ob-skip-btn" type="button" onClick={onHandleDashboard}>
                                {IntlMessages('work_email_btn_back_account')}
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* email skip modal */}
            <Modal
                centered
                size='sm'
                show={emailSkipModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleDashboard} aria-label="Close" data-testid='close-sell'></button>
                {/* main body */}
                <Modal.Body className={'pt-4 pb-3 mt-3 px-4'}>
                    <div className="enter-code-modal-text px-1">
                        <h5>{IntlMessages('work_email_skip_modal_text1')}</h5>
                        <p className="mt-3 mb-4">{IntlMessages('work_email_skip_modal_text2', { br: <br /> })}</p>
                        <p className="mt-3 mb-4">{IntlMessages('work_email_skip_modal_text3', { br: <br /> })}</p>
                    </div>
                </Modal.Body>
                {/* footer */}
                <Modal.Footer className={'enter-code-modal-footer justify-content-center'}>
                    <div className="text-center py-3">
                        <p className="mb-4">{IntlMessages('work_email_skip_modal_text4', { br: <br /> })}</p>
                        <div className="d-flex gap-2 pt-2 pt-2 justify-content-center" dir='rtl'>
                            <button className="gi-ob-check-btn" type="button" onClick={onHandleContinue}>
                                {IntlMessages('button_continue')}
                            </button>
                            <button className="gi-ob-skip-btn" type="button" onClick={onHandleDashboard}>
                                {IntlMessages('work_email_btn_back_account')}
                            </button>
                        </div>
                        <span className="d-block mt-3">
                            <a className="text-grass-green" href="#!" onClick={openWorkEmailModal}>
                                {IntlMessages('work_email_skip_modal_text5')}
                            </a>
                        </span>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default KYCEmailSetupModal