//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// config
import { MAX_NUMBER_100, MAX_NUMBER_AMOUNT } from 'config/Config';
import { transferType } from 'config/DefaultValue';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as BasketType from 'api/APIMaster/BasketType';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// api call
import * as DashboardService from 'middleware/DashboardService';
//custom component
import InputComponentCurrency from 'components/InputComponent/InputComponentCurrency';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import SuccessModal from 'components/ModalComponent/SuccessModal';
import TextError from 'components/ErrorComponent/TextError';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import PlaidComponent from 'components/PlaidComponent/PlaidComponent2';

export type Props = {
  openFundModelFlag: boolean;
  totalCashAvailableForTrade: number;
  basketId: number;
  getFundLocation: DashboardType.GetFundLocationsData;
  isFunding: DashboardType.IsFundTransferPendingData;
  isBuyOrder: BasketType.BasketOrderPending;
  closeModal: PropsFunction.ParamsBooleanFunction;
  componentDidMount: PropsFunction.SimpleFunction;
}

const FundModal: React.FC<Props> = (props: Props) => {

  const { openFundModelFlag, totalCashAvailableForTrade, basketId, getFundLocation, isFunding, isBuyOrder, closeModal, componentDidMount } = props;

  const { initiateAction } = useAccessToken();
  const intl = useIntl();
  const [openFundModel, setOpenFundModel] = React.useState<boolean>(false);
  const [accountBalance, setAccountBalance] = React.useState<number>(0);

  const [errorMessage, setErrorMessage] = React.useState<string>(''); //error message comes from backend
  const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false);  //loading modal
  const [openSuccessModal, setOpenSuccessModal] = React.useState<boolean>(false);  //success modal
  const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);  //confirm modal
  const [errorFlagMessage, setErrorFlagMessage] = React.useState<boolean>(false);  //error flag when error message comes from backend 
  const [errorFlag100, setErrorFlag100] = React.useState<boolean>(false);  //error flag when amount less then MAX_NUMBER_100
  const [errorFlagMax, setErrorFlagMax] = React.useState<boolean>(false);  //error flag when amount reached max limit
  const [errorMaxMessage, setErrorMaxMessage] = React.useState<string>(''); //maximal amount error message

  const [portfolioList, setPortfolioList] = React.useState<PropsFunction.Option>([]);
  const [selectFromAccount, setSelectFromAccount] = React.useState<boolean>(false);

  const [toPortfolio, setToPortfolio] = React.useState<PropsFunction.Option>([]);
  const [toPortfolioValue, setToPortfolioValue] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue);

  const [fromAccount, setFromAccount] = React.useState<PropsFunction.Option>([]);
  const [fromAccountValue, setFromAccountValue] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue);

  const [successMessage, setSuccessMessage] = React.useState<string>('bank_transfer');

  const [fromAccountError, setFromAccountError] = React.useState<boolean>(false);
  const [toPortfolioError, setToPortfolioError] = React.useState<boolean>(false);
  const [fromAccountErrorMessage, setFromAccountErrorMessage] = React.useState<PropsFunction.IntlMessages | string>('');
  const [toPortfolioErrorMessage, setToPortfolioErrorMessage] = React.useState<PropsFunction.IntlMessages | string>('');

  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [openPlaid, setOpenPlaid] = React.useState<boolean>(false); //open plaid when fund transfer api failed and return ITEM_LOGIN_REQUIRED

  React.useEffect(() => {
    const fromDropdown = getFundLocation
      .filter(e => e.type === 'Bank Account' || e.type === 'Cash Account')
      .map(e => {
        return {
          active: true,
          value: `${e.id}|${e.type}`,
          label: e.name,
        }
      })

    const toDropdown = getFundLocation
      .filter(e => e.type !== 'Bank Account')
      .map(e => {
        return {
          active: true,
          value: `${e.id}|${e.type}`,
          label: e.name,
        }
      })


    setFromAccount(fromDropdown);
    setPortfolioList(toDropdown);
  }, [])

  React.useEffect(() => {
    const basketIndex = portfolioList.findIndex(e => e.value === `${basketId}|Basket`)
    const defaultFromAccount = basketIndex === -1 ? 0 : fromAccount.length > 1 ? totalCashAvailableForTrade > 100 ? 1 : 0 : 0
    const defaultToPortfolio = basketIndex === -1 ? 0 : basketIndex

    const updatedPortfolio = JSON.parse(JSON.stringify(toPortfolio))
    basketIndex >= 0 && updatedPortfolio.shift(0)

    if (openFundModelFlag) {
      setOpenFundModel(openFundModelFlag);
      fromAccount.length > 0 && setFromAccountValue(fromAccount[defaultFromAccount]);

      setToPortfolioValue(portfolioList[defaultToPortfolio]);

      setToPortfolio(updatedPortfolio);

      fromAccount.length > 0 && fromAccount[defaultFromAccount].value && selectDropDownValue('fromAccount', fromAccount[defaultFromAccount]);

      portfolioList[defaultToPortfolio].value && selectDropDownValue('toPortfolio', portfolioList[defaultToPortfolio]);

    } else {
      setToPortfolio(portfolioList);
    }
  }, [openFundModelFlag])

  React.useEffect(() => {
    checkValue()
  }, [fromAccountValue, toPortfolioValue, accountBalance])

  const onHandleClose = (): void => {
    setOpenFundModel(false);
    setOpenLoadingModal(false);
    setOpenSuccessModal(false);
    setOpenConfirmModal(false);
    setErrorFlagMessage(false);
    setErrorFlag100(false);
    setErrorFlagMax(false);
    setFromAccountError(false); //transfer button
    setToPortfolioError(false); //transfer button
    setAccountBalance(0);
    setFromAccountValue(OnBoardingData.defaultOptionValue);
    setToPortfolioValue(OnBoardingData.defaultOptionValue);
    setIsDisabled(false);
    setOpenPlaid(false)
    closeModal(false) //close fund transfer modal
  }

  //close confirm modal
  const onHandleCloseSuccess = (): void => {
    setOpenSuccessModal(false);
    setAccountBalance(0);
    setErrorFlagMessage(false);
    setErrorFlag100(false);
    setErrorFlagMax(false);

    closeModal(false) //close fund transfer modal
    componentDidMount() //load total card component
  }

  //set input value
  const setInputValue: PropsFunction.ParamsIdStringFunction = (value): void => {
    setAccountBalance(Number(value));
    setErrorFlagMessage(false);
    setErrorFlag100(false);
    setErrorFlagMax(false);
  }

  // dropdown value
  const selectDropDownValue: PropsFunction.SelectedOption = (fieldName, fieldValue) => {
    if (fieldName === 'fromAccount') {
      setFromAccountValue(fieldValue);

      setToPortfolio([]);
      setSelectFromAccount(false);
      setFromAccountError(false);
      setToPortfolioError(false);

      // BANK_TO_BASKET or DEPOSIT transfer on-going
      isFunding.map(e => {
        if ((e.transferType === transferType.BANK_TO_BASKET || e.transferType === transferType.DEPOSIT) &&
          (fieldValue.value.toString().split('|')[1] === 'Bank Account')) {
          setFromAccountError(true);
          setFromAccountErrorMessage(['add_fund_from_message', { type: e.transferType === transferType.BANK_TO_BASKET ? 'bank to portfolio transfer' : e.transferType.toLowerCase(), amount: currencyFormatter(e.transferAmount).split('.')[0] }]);
        }
      })

      const fromAccountType = fieldValue.value && fieldValue.value.toString().split('|')[1];

      const toDropDown = getFundLocation
        .filter(e => (
          (fromAccountType === 'Bank Account' && (e.type === 'Cash Account' || e.type === 'Basket'))
          || (fromAccountType === 'Cash Account' && e.type === 'Basket')))
        .map(e => {
          return {
            active: true,
            value: `${e.id}|${e.type}`,
            label: e.name,
          }
        })

      setToPortfolio(toDropDown);
      setToPortfolioValue(OnBoardingData.defaultOptionValue);
      setTimeout(() => {
        setSelectFromAccount(true);
      }, 0);
    }

    if (fieldName === 'toPortfolio') {
      setToPortfolioError(false);

      // BUY or SELL transfer on-going
      isBuyOrder.map(e => {
        if (e.basketId === Number(fieldValue.value.toString().split('|')[0]) && e.isPending) {
          const errorMessage = e.orderAmount === 0
            ? 'sell_all_message'
            : ['add_fund_to_message', { type: e.orderType.toLowerCase(), amount: currencyFormatter(e.orderAmount).split('.')[0] }] as PropsFunction.IntlMessages;

          setToPortfolioError(true);
          setToPortfolioErrorMessage(errorMessage);
        }
        return null
      })

      setToPortfolioValue(fieldValue);
    }
  }
  //enable and disable transfer button
  const checkValue = (): void => {
    setIsDisabled(true);
    // button enabled when all value is present

    if (accountBalance && fromAccountValue && toPortfolioValue && fromAccountValue.value && toPortfolioValue.value && !fromAccountError && !toPortfolioError) {
      setIsDisabled(false);
    }
  }

  //open confirm modal
  const openConfirmModalFunction = (): void => {
    if (accountBalance < MAX_NUMBER_100) {
      setErrorFlag100(true);
      setErrorFlagMax(false);
      setErrorFlagMessage(false);
    }
    else if (accountBalance > MAX_NUMBER_AMOUNT) {
      setErrorFlag100(false);
      setErrorFlagMax(true);
      setErrorMaxMessage('error_maximum_amount');
      setErrorFlagMessage(false);
    }
    else if ((accountBalance > totalCashAvailableForTrade || totalCashAvailableForTrade === 0) && fromAccountValue.value.toString().split('|')[1] === 'Cash Account') {
      setErrorFlag100(false);
      setErrorFlagMax(true);
      setErrorMaxMessage(totalCashAvailableForTrade === 0 ? 'add_fund_zero_error' : 'add_fund_maximum_error');
      setErrorFlagMessage(false);
    }
    else {
      setOpenConfirmModal(true);
      setOpenFundModel(false);
    }
  }

  //transfer fund
  const fundTransfer = async (): Promise<void> => {

    //if true set loading modal
    setOpenLoadingModal(true); //loading modal
    setOpenConfirmModal(false); //confirm modal
    setOpenPlaid(false)

    const accessToken = await initiateAction() //get access token
    const transferredFunds = await DashboardService.transferFund({ amount: accountBalance, fromAccount: fromAccountValue.value, toAccount: toPortfolioValue.value }, accessToken);
    setOpenLoadingModal(false); //loading modal

    if (transferredFunds.response) {
      const message = transferredFunds.response.data.message
      if (message === 'ITEM_LOGIN_REQUIRED' || message === 'Failed to get bank account balance') {
        setOpenPlaid(true)
      } else {
        setErrorMessage(transferredFunds.response.data.message); //error message
        setOpenFundModel(true); //fund modal
        setErrorFlagMessage(true); //error flag
      }
      closeModal(true)
    }
    else {
      if (transferredFunds.transferType === transferType.BASKET_BUY) {
        setSuccessMessage(transferredFunds.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open')
      }
      else {
        setSuccessMessage('bank_transfer')
      }

      setOpenSuccessModal(transferredFunds.isSuccessful); //success modal

      closeModal(false)
    }
  }

  return (
    <>
      {/* Fund Modal */}
      {openFundModelFlag && (
        <Modal
          size='xl'
          centered
          show={openFundModel}
          onHide={onHandleClose}
          backdrop="static"
          keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal">
          <Modal.Body className='p-xl-4 p-3'>
            <button className="btn-close p-0 border-0 position-absolute opacity-100" type="button" onClick={onHandleClose} aria-label="Close"></button>
            <div className="p-xl-2 p-0">
              <div className="row gx-0 ">
                {/* left image */}
                <div className="col-lg-5">
                  <div
                    className="gi-g-new-fund-modal-card-start d-flex align-items-center justify-content-lg-center justify-content-start text-lg-center text-start">
                    <div>
                      <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="" />
                      <h5 className="d-none d-xl-block mt-4">{IntlMessages('fund_your_portfolio', { br: <br /> })}</h5>
                      <h5 className="d-block d-xl-none mt-4">{IntlMessages('fund_your_portfolio', { br: '' })}</h5>
                    </div>
                  </div>
                </div>
                {/* right content */}
                <div className="col-lg-7">
                  <div className="gi-g-new-fund-modal-card-end">
                    {/* input component */}
                    <div className="row">
                      <div className="col-12">
                        <div className={`gi-g-new-input mb-4 pb-2 ${errorFlagMessage || errorFlag100 || errorFlagMax ? 'gi-input-error' : ''}`}>
                          <label className="form-label mb-2 form-label-react flex-wrap flex-lg-nowrap">
                            <span>
                              {IntlMessages('fund_select_amount')} | ${MAX_NUMBER_100} {IntlMessages('minimum')}
                            </span>
                            <span>
                              {IntlMessages('fund_cash_available')} {currencyFormatter(totalCashAvailableForTrade)}*
                            </span>
                          </label>
                          <div className="gi-currency-input">
                            <InputComponentCurrency
                              placeholder={'placeholder_currency_100'}
                              name={'accountBalance'}
                              inputValue={setInputValue}
                              value={accountBalance === 0 ? '' : accountBalance}
                              className={''}
                              isDecimalAllow={false}
                            />
                          </div>
                          {errorFlagMessage && (<TextError message2={errorMessage} />)}
                          {errorFlag100 && (<TextError message={'add_fund_minimum_error'} />)}
                          {errorFlagMax && (<TextError message={errorMaxMessage} />)}
                        </div>

                        {/* from account */}
                        <div className="gi-g-new-input gi-select mb-4 pb-2">
                          <div>
                            <SelectDropdown
                              labelClassName={'form-label mb-2'}
                              labelText={'fund_account'}
                              name='fromAccount'
                              option={fromAccount}
                              fieldValue={fromAccountValue}
                              inputValue={selectDropDownValue}
                              placeholder={'placeholder_select_value'}
                              ariaLabel={"from-account"}
                              className={'custom-react-fund'}
                              isDisabled={false}
                              isModalDropdown={true}
                            />
                            {fromAccountError && (<TextError message={fromAccountErrorMessage} />)}
                          </div>
                        </div>

                        {/* to portfolio */}
                        <div className="gi-g-new-input gi-select mb-4 pb-2">
                          <div>
                            {selectFromAccount && <SelectDropdown
                              labelClassName={'form-label mb-2'}
                              labelText={'fund_portfolio'}
                              name='toPortfolio'
                              option={toPortfolio}
                              fieldValue={toPortfolioValue}
                              inputValue={selectDropDownValue}
                              placeholder={'placeholder_select_value'}
                              ariaLabel={"to-portfolio"}
                              className={'custom-react-fund'}
                              isDisabled={toPortfolio.length > 0 ? false : true}
                              isModalDropdown={true}
                            />}
                            {toPortfolioError && (<TextError message={toPortfolioErrorMessage} />)}
                          </div>
                        </div>

                      </div>
                    </div>
                    {/* button */}
                    <div className="row">
                      <div className="col-12">
                        <div className="gi-g-new-btn-groupe mt-30">
                          {/* cancel */}
                          <button className="gi-g-new-cancel" type="button" onClick={onHandleClose}>
                            {IntlMessages('button_cancel')}
                          </button>
                          {/* confirm */}
                          <button className="gi-g-new-transfer" type="button" onClick={openConfirmModalFunction} disabled={isDisabled || errorFlagMessage || errorFlag100 || errorFlagMax}>
                            {IntlMessages('button_buy_fund')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* confirm Modal */}
      <Modal
        size='xl'
        centered
        show={openConfirmModal}
        onHide={onHandleClose}
        backdrop="static"
        keyboard={false} className='gi-port-ao-pc-companies-modal gi-g-new-fund-modal'>
        <Modal.Body className='p-xl-4 p-3'>
          <button className="btn-close p-0 border-0 position-absolute opacity-100" type="button" aria-label="Close" onClick={onHandleClose}></button>
          <div className='p-xl-2 p-0'>
            <div className="row gx-0 ">
              {/* left image */}
              <div className="col-lg-5">
                <div className='gi-g-new-fund-modal-card-start d-flex align-items-center justify-content-lg-center justify-content-start text-lg-center text-start'>
                  <div>
                    <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="" />
                    <h5 className="d-none d-md-block">{IntlMessages('fund_your_portfolio', { br: <br /> })}</h5>
                    <h5 className="d-block d-md-none">{IntlMessages('fund_your_portfolio', { br: '' })}</h5>
                  </div>
                </div>
              </div>
              {/* right content */}
              <div className="col-lg-7">
                <div className="gi-g-new-fund-modal-card-end">
                  {/* input */}
                  <div className="row">
                    <div className="col-12">
                      {/* heading */}
                      <div className="gi-g-new-input-text mb-4 pb-3">
                        <h5>
                          {IntlMessages('fund_confirm_transaction')}
                        </h5>
                      </div>
                      {/* input */}
                      <div className="gi-g-new-input-text mb-4 pb-3">
                        <label>
                          {IntlMessages('table_amount')}
                        </label>
                        <h5 className='ltr'>
                          {`$ ` + currencyFormatter(accountBalance).replace('$', '')}
                        </h5>
                      </div>

                      {/* from account */}
                      <div className="gi-g-new-input-text mb-4 pb-3">
                        <label>
                          {IntlMessages('fund_account')}
                        </label>
                        <h5>
                          {fromAccount.length > 0
                            ? fromAccount.map((e) => {
                              if (e.value === fromAccountValue.value) {
                                return e.label
                              }
                              else {
                                return null
                              }
                            })
                            : ''
                          }
                        </h5>
                      </div>

                      {/* to portfolio */}
                      <div className="gi-g-new-input-text mb-4 pb-3">
                        <label>
                          {IntlMessages('fund_portfolio')}
                        </label>
                        <h5>
                          {toPortfolio.length > 0
                            ? toPortfolio.map((e) => {
                              if (e.value === toPortfolioValue.value) {
                                return e.label
                              }
                              else {
                                return null
                              }
                            })
                            : ''
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* button */}
                  <div className="row">
                    <div className="col-12">
                      <div className="gi-g-new-btn-groupe mt-4 pt-2">
                        <button className="gi-g-new-cancel" type="button" onClick={onHandleClose}>
                          {IntlMessages('button_cancel')}
                        </button>
                        <button className="gi-g-new-transfer" type="button" onClick={fundTransfer}>
                          {IntlMessages('button_confirm')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Loading Modal */}
      {openLoadingModal && <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={onHandleClose} />}

      {/* SuccessModal */}
      <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleCloseSuccess={onHandleCloseSuccess} />

      {/* open plaid when fund transfer api failed with ITEM_LOGIN_REQUIRED */}
      {openPlaid && (
        <PlaidComponent
          plaidSuccess={fundTransfer}
          plaidExit={onHandleClose}
        />
      )}
    </>
  )
}

export default FundModal;