// default library
import * as React from 'react';
// plaid library
import { PlaidLink, PlaidLinkError, PlaidLinkOnEventMetadata, PlaidLinkOnExitMetadata, PlaidLinkOnSuccessMetadata, PlaidLinkStableEvent } from 'react-plaid-link';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// datadog log library 
import { datadogLogs } from '@datadog/browser-logs';
// api call
import * as PlaidService from 'middleware/PlaidService';
// config
import * as Config from 'config/Config';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// local storage 
import * as Local from 'service/utils/LocalStorageData'

export type Props = {
    userName: string,
    plaidData: any,
    className: string,
    buttonName: JSX.Element,
    tokenGenerate: PropsFunction.ParamsBooleanFunction,
    isLoading: PropsFunction.SimpleFunction,
    componentDidMount: PropsFunction.SimpleFunction,
    handleErrorModal: PropsFunction.ParamsAnyFunction,
    onHandleClose: PropsFunction.SimpleFunction,
    openPlaid: PropsFunction.SimpleFunction,
}

const useReactUserName = JSON.parse(import.meta.env.REACT_APP_USE_CUSTOMER_NAME)

const PlaidComponent: React.FC<Props> = (props: Props) => {

    const { plaidData, className, buttonName, tokenGenerate, isLoading, componentDidMount, handleErrorModal, onHandleClose, openPlaid } = props;
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [token, setToken] = React.useState<string>('')

    React.useEffect(() => {
        (async () => {
            const plaidToken = Local.getLocalData('plaidToken')
            if (plaidToken === null) {
                tokenGenerate(true)
                const accessToken = await initiateAction() //get access token
                const getToken = await PlaidService.createToken(false, accessToken)
                if (getToken.response) {
                    setErrorLog(getToken)
                    setIsError(true)
                } else {
                    Local.setLocalData('plaidToken', getToken.token)
                    setToken(getToken.token)
                }
                tokenGenerate(false)
            }
            else {
                setToken(plaidToken)
            }
        })()

        return () => {
            const modalBackdrop: NodeListOf<Element> = document.querySelectorAll("#iframe-modal");
            if (modalBackdrop.length > 0) {
                modalBackdrop.forEach(e => {
                    e.remove();
                })
            }
        }
    }, [])

    const onSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata): Promise<void> => {
        isLoading()
        const processorTokenRequestData = {
            customerIdentifier: Local.getLocalData('dwUserIdentifier'),
            customerFirstName: useReactUserName ? Config.PLAID_CUSTOMER_FIRST_NAME : plaidData.customerFirstName,
            customerMiddleName: useReactUserName ? Config.PLAID_CUSTOMER_LAST_NAME : plaidData.customerMiddleName,
            customerLastName: useReactUserName ? Config.PLAID_CUSTOMER_MIDDLE_NAME : plaidData.customerLastName,
            customerPhoneNumber: useReactUserName ? Config.PLAID_CUSTOMER_PHONE : plaidData.customerPhoneNumber,
            customerEmail: useReactUserName ? Config.PLAID_CUSTOMER_EMAIL : plaidData.customerEmail,
            customerAddress: useReactUserName ? Config.PLAID_CUSTOMER_ADDRESS : plaidData.customerAddress,
            publicToken: publicToken,
            institutionName: metadata.institution?.name ?? '',
            account: {
                id: metadata.accounts[0].id,
                mask: metadata.accounts[0].mask,
                name: metadata.accounts[0].name,
                subtype: metadata.accounts[0].subtype,
                type: metadata.accounts[0].type
            }
        };
        const accessToken = await initiateAction() //get access token
        const processToken = await PlaidService.generateProcessorToken(processorTokenRequestData, accessToken);
        if (processToken.response) {
            const errorResponse = processToken.response
            if (errorResponse.status === 500) {
                handleErrorModal({
                    message: errorResponse.data.message,
                    error: true
                })
            } else {
                setErrorLog(processToken)
                setIsError(true)
            }
        } else {
            componentDidMount()
            Local.removeLocalData('plaidToken')
        }

    };

    const onExit = (_error: null | PlaidLinkError, _metadata: PlaidLinkOnExitMetadata): void => {
        onHandleClose()
    };

    const onEvent = (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata): void => {
        datadogLogs.logger.info(`Plaid ${eventName}`, { plaidR: metadata, plaidToken: token });
        // add background for blur 
        if (eventName === 'TRANSITION_VIEW') {
            const modalBackdrop: NodeListOf<Element> = document.querySelectorAll(".fade.modal-backdrop.show");
            if (modalBackdrop.length === 0) {
                const newDiv = document.createElement("div");
                newDiv.className = 'fade modal-backdrop show';
                newDiv.id = 'iframe-modal'

                document.body.appendChild(newDiv);
            }
        }
        // remove background for blur 
        if (eventName === 'EXIT') {
            const modalBackdrop: NodeListOf<Element> = document.querySelectorAll("#iframe-modal");
            if (modalBackdrop.length > 0) {
                modalBackdrop.forEach(e => {
                    e.remove();
                })
            }
        }
        if (eventName === 'OPEN') {
            openPlaid();
        }
        if (metadata.error_code !== null && metadata.error_code !== undefined) {
            handleErrorModal(''); //if any error occurred
        }
    }


    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const config = {
        className: className,
        token: token,
        onSuccess: onSuccess,
        onExit: onExit,
        onEvent: onEvent,
    }

    return (
        <PlaidLink{...config}>
            {buttonName}
        </PlaidLink>
    )
}

export default PlaidComponent