// default library
import * as React from 'react';
// Import the useIntl hook from react-intl
import { useIntl } from 'react-intl';
// message import
import IntlMessages from 'helper/IntlMessages';
//types props
import * as PropsFunction from "interface/PropsFunction";
// custom component
import ErrorKYCIsrael from 'components/ErrorComponent/ErrorKYCIsrael';

export type Props = {
    labelClassName: string;
    labelText: string;
    className: string;
    fieldValue: string;
    name: string;
    placeholder: string;
    readOnly: boolean;
    disabled: boolean;
    dataTestId: string;
    isError: boolean;
    errorMessage: string | PropsFunction.IntlMessages;
    inputValue: PropsFunction.PropsInputValueFunction;
    [key: string]: any
}

const InputTextComponent: React.FC<Props> = (props: Props) => {
    const { labelClassName, labelText, dataTestId, className, fieldValue, name, placeholder, isError, readOnly, disabled, errorMessage, inputValue, ...res } = props;

    const intl = useIntl();

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);// Call the inputValue function with the updated value
    };

    return (
        <>
            {labelText && <label className={labelClassName} htmlFor={name}>{IntlMessages(labelText)}</label>}
            <input
                type='text'
                className={`form-control ${className} ${isError ? 'form-error' : ''}`}
                name={name} //set field name
                id={name} //set field name
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                value={fieldValue} //set field value
                onChange={handleOnChange} //invoke on every key press
                readOnly={readOnly} //check field is readOnly or not
                disabled={disabled} //disabled field
                data-testid={dataTestId}
                {...res}
            />
            {errorMessage && <ErrorKYCIsrael message={errorMessage} fieldName={name} />}
        </>
    )
}

export default InputTextComponent