const Error: { [key: string]: string } = {
    /* Error page*/
    'error_404': 'אופס! 404',
    'error_log_in': 'התחברות',
    'error_register': 'הרשמה מוקדמת',
    'error_notFound': 'העמוד שביקשת לא נמצא.',
    'error_validUrl': 'אנא הכניסו דף תקין או',
    'error_returnHome': ' חזרה לעמוד הבית שלנו',
    'error_fixURL': 'אנחנו כאן כדי להישאר, אנחנו לא הולכים לשום מקום. צריך רק לתקן את כתובת האתר!',

    /* Error Message */
    'error_max_length': `מצטערים, אפשר להזין רק עד {number} תווים בלבד`,
    'error_max_length_36_name': `מצטערים, אפשר להזין רק עד 36 תווים בלבד בשביל שמך.`,
    'error_max_length_244': `מצטערים, אפשר להזין רק עד 244 תווים בלבד בשביל שמך.`,
    'error_max_length_64': `מצטערים, אפשר להזין רק עד 64 תווים בלבד בשביל שמך.`,
    'error_max_length_255_textarea': `מצטערים, אפשר להזין רק עד 255 תווים בלבד בשדה זה.`,
    'error_maximum_amount': `סכום מקסימלי $9,999,999,999,999,998`,
    'error_minimum_amount_invest': `סכום ההשקעה המינימלית היא 100$`,
    'error_minimum_amount_sell': `סכום המכירה המינימלי הוא {amount}`,
    'error_maximum_amount_sell': `סכום המכירה החלקי המינימלי הוא {amount}`,
    'error_first_name': `מצטערים, השם הפרטי שלך עשוי להכיל אותיות, נקודות, סימני פיסוק ורווחים אך מתחיל באות בלבד`,
    'error_middle_name': `מצטערים, השם האמצעי שלך עשוי להכיל אותיות, נקודות, סימני פיסוק ורווחים אך מתחיל באות בלבד`,
    'error_last_name': `מצטערים, השם משפחה שלך עשוי להכיל אותיות, נקודות, סימני פיסוק ורווחים אך מתחיל באות בלבד`,
    'error_not_valid_dob': `אופס! נראה שלא הכנסת תאריך תקין`,
    'error_dob_under_18': `מצטערים! אנחנו כעת לא יכולים לאפשר לאנשים מתחת לגיל 18 להירשם! התייעצו עם ההורים שלכם ובדקו מה אפשר לעשות :)`,
    'error_invalid_address': `מצטערים, הכתובת הזו אינה כתובת תקינה`,
    'error_invalid_citizenship': `מצטערים, למרבה הצער איננו יכולים לקבל אזרחים בעלי אזרחות זו`,
    'error_invalid_ssn_number': `אופס! נראה שלא הזנתם מספר SSN חוקי`,
    'error_invalid_annual_income': `לא ניתן להזין הכנסה שנתית מעל סכום של 9,223,372,036,854,775,295`,
    'error_invalid_net_worth': `ערך הנכסים לא יכול להיות גדול מסכום של 9,223,372,036,854,775,295`,
    'error_invalid_verification_code': `אופס! הקוד שהזנתם שגוי. אנא בדקו זאת ונסו שוב. אם אתם עדיין נתקלים בקושי, לחצו למטה כדי לקבל קוד חדש שנשלח אליכם.`,
    'error_ticker_max_length': `מצטערים, אפשר להזין רק עד 10 תווים בלבד בסמל המנייה.`,
    'error_ticker_message': `מצטערים, אפשר להזין רק סמל מנייה תקין`,
    'error_update_call_error': `אופס! משהו השתבש. לא יכולנו לשלוח את המידע שלכם בשלב זה. אנא נסו שוב מאוחר יותר.`,
    'error_try_again': `אנא נסו שוב מאוחר יותר`,
    'error_not_enough_balance': `מצטערים, אינכם יכולים להשקיע בתיק ההשקעות שלכם מכיוון שאין לכם מספיק יתרה בחשבון המזומנים שלכם. אנא העבירו כספים לחשבונכם.`,
    'error_something_wrong': `אוי, משהו השתבש בעיבוד המידע שלכם.`,
    'error_multiple_account_holder': `נראה שהמידע שלכם עשוי להיות בשימוש על ידי מספר בעלי חשבונות.`,
    'error_contact_support': `אנא צרו קשר עם התמיכה שלנו`,
    'error_contact_support2': `אנא צרו קשר עם שירות הלקוחות אם זה לא תואם את הפעילות שלכם.`,
    'error_connecting_account': `אנא ודאו שהשם שלכם במערכת שלנו נכון ואם לא, תקנו זאת מדף הגדרות החשבון או צרו קשר עם שירות הלקוחות.`,
    'error_connecting_account1': `אירעה שגיאה בעת ההתחברות לחשבון הבנק שלכם דרך Plaid.`,
    'error_money_route': `מצטערים, אינכם יכולים לבצע עסקה נוספת בזמן שהכסף שלכם עדיין בתהליך העברה`,
    'error_transaction_process': `ההעברה בתיק ההשקעות שלכם בעיבוד. אנא המתינו עד שההעברה תושלם.`,
    'error_please_try': `אנא נסו שוב.`,
    'error_unlink_bank': 'מצטערים, אינכם יכולים לבטל את הקישור לחשבון הבנק שלכם בזמן שעסקה נמצאת בתהליך.',
    'error_basket_name': 'מצטערים, שם התיק השקעות שלכם לא מתחיל ברווח.',
    'error_non_us_taxpayer': 'אוי! מצטערים, אנחנו יכולים לקבל רק משלמי מס בארה"ב בשלב זה. אנא צרו קשר עם support@giraffe.us אם אתם חושבים שאירעה שגיאה',
    'error_unable_process': 'איננו יכולים לעבד את בקשתכם בשלב זה.',
    'error_redemption_code': 'מצטערים, אתם יכולים להזין רק 16 תווים עבור קוד המימוש שלכם.',
    'error_gift_already_redeem1': 'אופס! הקוד שהזנתם כבר נוצל למימוש מתנה זו.',
    'error_gift_already_redeem2': 'אנא בדקו אם יש לכם קוד אחר זמין ונסו שוב',
    'error_gift_redemption_code_invalid1': 'אופס! קוד לא תקין',
    'error_gift_redemption_code_invalid2': 'אנא העתיקו את הקוד ממייל המתנה ונסו שוב',
    'error_gift_redemption_code_expired1': 'אופס! פג תוקף הקוד שהזנת.',
    'error_gift_redemption_code_expired2': 'אנא בדקו אם יש לכם קוד אחר זמין ונסו שוב',
    'error_email_not_match': 'אנא הזינו את האימייל התקין.',
}

export default Error