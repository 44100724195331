import * as DefaultType from 'interface/DefaultValueType';

export const sortTicker: DefaultType.SortFunction = (currentSortTicker, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortTicker === 'default') nextSort = 'up';
    if (currentSortTicker === 'down') nextSort = 'up';
    if (currentSortTicker === 'up') nextSort = 'down';

    return {
        currentSortCompany: 'default',
        currentSortCompany2: 'default',
        currentSortTicker: nextSort,
        currentSortPercentage: 'default',
        currentSortDollarValue: 'default',
        currentSortIndustry: 'default',
        sortTypes: sortType.sortTypesTicker[nextSort],
    };
};

export const sortCompanyName2: DefaultType.SortFunction = (currentSortCompany, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortCompany === 'default') nextSort = 'up';
    if (currentSortCompany === 'down') nextSort = 'up';
    else if (currentSortCompany === 'up') nextSort = 'down';

    return ({
        currentSortCompany: 'default',
        currentSortCompany2: nextSort,
        currentSortTicker: 'default',
        currentSortPercentage: 'default',
        currentSortDollarValue: 'default',
        currentSortIndustry: 'default',
        sortTypes: sortType.sortTypesCompany2[nextSort],
    })
}

export const sortPercentage: DefaultType.SortFunction = (currentSortPercentage, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortPercentage === 'default') nextSort = 'up';
    if (currentSortPercentage === 'down') nextSort = 'up';
    else if (currentSortPercentage === 'up') nextSort = 'down';

    return ({
        currentSortCompany: 'default',
        currentSortCompany2: 'default',
        currentSortTicker: 'default',
        currentSortPercentage: nextSort,
        currentSortDollarValue: 'default',
        currentSortIndustry: 'default',
        sortTypes: sortType.sortTypesPercentage[nextSort],
    })

}

export const sortCompanyName: DefaultType.SortFunction = (currentSortCompany, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortCompany === 'default') nextSort = 'up';
    if (currentSortCompany === 'down') nextSort = 'up';
    else if (currentSortCompany === 'up') nextSort = 'down';

    return ({
        currentSortCompany: nextSort,
        currentSortCompany2: 'default',
        currentSortTicker: 'default',
        currentSortDollarValue: 'default',
        currentSortIndustry: 'default',
        currentSortPercentage: 'default',
        sortTypes: sortType.sortTypesCompany[nextSort],
    })
}

export const sortDollarValue: DefaultType.SortFunction = (currentSortDollarValue, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortDollarValue === 'default') nextSort = 'up';
    if (currentSortDollarValue === 'down') nextSort = 'up';
    else if (currentSortDollarValue === 'up') nextSort = 'down';

    return ({
        currentSortCompany: 'default',
        currentSortCompany2: 'default',
        currentSortTicker: 'default',
        currentSortDollarValue: nextSort,
        currentSortIndustry: 'default',
        currentSortPercentage: 'default',
        sortTypes: sortType.sortTypesDollarValue[nextSort],
    })

}

export const sortIndustry: DefaultType.SortFunction = (currentSortIndustry, sortType) => {
    let nextSort: keyof DefaultType.SortTypes = 'default';

    if (currentSortIndustry === 'default') nextSort = 'up';
    if (currentSortIndustry === 'down') nextSort = 'up';
    else if (currentSortIndustry === 'up') nextSort = 'down';

    return ({
        currentSortCompany: 'default',
        currentSortCompany2: 'default',
        currentSortTicker: 'default',
        currentSortDollarValue: 'default',
        currentSortIndustry: nextSort,
        currentSortPercentage: 'default',
        sortTypes: sortType.sortTypesIndustry[nextSort],
    })

}
