//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// config 
import { ONLY_NUMBER } from 'config/Config';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as BasketType from 'api/APIMaster/BasketType';
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as DashboardInterface from 'interface/Dashboard';
// api call
import * as DashboardService from 'middleware/DashboardService';
import * as BasketService from 'middleware/BasketService';
// custom component
import InputComponentText2 from 'components/InputComponent/InputComponentText2';
import InputComponentCheckbox2 from 'components/InputComponent/InputComponentCheckbox2';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import SuccessModal from 'components/ModalComponent/SuccessModal';

export type Props = {
    openSellModal: boolean;
    basketDetails: BasketType.BasketDetailResponseModel;
    getFundLocation: DashboardType.GetFundLocationsData;
    basketId: number;
    closeModal: PropsFunction.ParamsBooleanFunction;
    componentDidMount: PropsFunction.SimpleFunction;
}

const SellModal: React.FC<Props> = (props: Props) => {

    const { openSellModal, basketDetails, getFundLocation, basketId, closeModal, componentDidMount } = props
    const { initiateAction } = useAccessToken();
    const intl = useIntl();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [openModalSell, setOpenModalSell] = React.useState<boolean>(false); //open or close modal
    const [sellAmount, setSellAmount] = React.useState<number>(0); //sell amount

    const [fromAccount, setFromAccount] = React.useState<DashboardInterface.BasketDropDown>([]); //dropdown to portfolio
    const [toAccountValue, setToAccountValue] = React.useState<string>(''); //cash account value

    const [errorMessage, setErrorMessage] = React.useState<string>(''); //error message comes from backend
    const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false);  //loading modal
    const [openSuccessModal, setOpenSuccessModal] = React.useState<boolean>(false);  //success modal
    const [openConfirmModal, setOpenConfirmModal] = React.useState<boolean>(false);  //confirm modal
    const [errorMinValue, setErrorMinValue] = React.useState<boolean>(false);  //min value for error message
    const [errorMaxValue, setErrorMaxValue] = React.useState<boolean>(false);  //max value for error message
    const [errorAPIFlag, setErrorAPIFlag] = React.useState<boolean>(false);  //for error message

    const [successMessage, setSuccessMessage] = React.useState<string>('bank_transfer'); //successful transfer message

    const [sellAllCheck, setSellAllCheck] = React.useState<boolean>(false);  //toggle button flag for sell all & partial sell

    const minSellValue = Math.floor(basketDetails.minSellValue); //min value of the selling amount
    const maxSellValue = Math.floor(basketDetails.maxSellValue); //max value of the selling amount
    const totalSellAmount = basketDetails.currentValue; //total amount of the selling amount

    React.useEffect(() => {
        setOpenModalSell(openSellModal);
        setSellAmount(basketDetails.currentValue);
    }, [openSellModal, basketDetails.currentValue])

    React.useEffect(() => {
        const fromAccount = getFundLocation
            .filter(e => e.type === 'Basket' && e.id === Number(basketId))
            .map(e => {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name,
                }
            })


        const toAccount = getFundLocation
            .filter(e => e.type === 'Cash Account')
            .map(e => {
                return {
                    value: `${e.id}|${e.type}`,
                    label: e.name,
                }
            })

        setFromAccount(fromAccount)
        setToAccountValue(toAccount.length > 0 ? toAccount[0].value : '')
    }, [])

    //close modal
    const onHandleClose = (): void => {
        setOpenModalSell(false); //close sell modal
        setOpenConfirmModal(false); //close confirm modal
        setOpenLoadingModal(false); //close loading modal
        setOpenSuccessModal(false); //close success modal

        setErrorMinValue(false); //min value for error message
        setErrorMaxValue(false); //max value for error message
        setErrorAPIFlag(false); //for error message

        setSellAllCheck(false); //toggle button flag for sell all & partial sell
        setSellAmount(basketDetails.currentValue); //sell amount

        closeModal(false)
    }

    // sell button check
    const sellCheckFunction = (_name: string, value: boolean,): void => {
        const sellAll = basketDetails.currentValue === basketDetails.minSellValue

        setSellAllCheck(value && !sellAll);
        setSellAmount(value && !sellAll ? 0 : totalSellAmount);
        setErrorMinValue(false); //min value for error message
        setErrorMaxValue(false); //max value for error message
        setErrorAPIFlag(false); //for error message
    }

    // enter sell amount
    const enterSellAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const number = e.target.value.replace(ONLY_NUMBER, '');
        setSellAmount(Number(number))
        setErrorMinValue(false); //min value for error message
        setErrorMaxValue(false); //max value for error message
        setErrorAPIFlag(false); //for error message
    };

    //open confirm modal
    const openConfirmModalFunction = (): void => {
        if (sellAmount < minSellValue && sellAllCheck) {
            setErrorMinValue(true);
            setErrorMaxValue(false);
        }
        else if (sellAmount > maxSellValue && sellAllCheck) {
            setErrorMinValue(false);
            setErrorMaxValue(true);
        }
        else {
            setOpenConfirmModal(true);
            setOpenModalSell(false);
        }
    }

    //close confirm modal
    const onHandleCloseSuccess = (): void => {
        setOpenSuccessModal(false); //close confirm modal
        setSellAmount(0); //clear sell amount
        setErrorMessage(''); //clear error message

        closeModal(false)
        componentDidMount() //load total card component
    }

    // set input value
    const setInputValue = (): void => { };

    // sell transfer call API
    const sellTransfer = async (): Promise<void> => {

        //if true set loading modal
        setOpenLoadingModal(true); //loading modal
        setOpenConfirmModal(false); //confirm modal
        const accessToken = await initiateAction() //get access token

        // call partial sell api sellAllCheck = false
        if (sellAllCheck) {
            // request modal 
            const transferredFunds = await DashboardService.transferFund({
                amount: sellAmount,
                fromAccount: fromAccount[0].value,
                toAccount: toAccountValue
            }, accessToken);

            setOpenLoadingModal(false); //loading modal

            if (transferredFunds.response) {
                setErrorMessage(transferredFunds.response.data.message); //error message
                setOpenModalSell(true); //fund modal
                setErrorAPIFlag(true); //error flag
                closeModal(true)
            }
            else {
                setSuccessMessage(transferredFunds.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open')
                setOpenSuccessModal(transferredFunds.isSuccessful); //success modal
                closeModal(false)
            }
        } else {
            const basketCashOut = await BasketService.placeBasketCashOutOrder(basketDetails.identifier, accessToken)
            setOpenLoadingModal(false); //loading modal
            if (basketCashOut.response) {
                setIsError(true);
                setErrorLog(basketCashOut);
            } else {
                setOpenSuccessModal(true); //success modal
                setSuccessMessage(basketCashOut.isOnHold ? 'fund_transfer_market_close' : 'fund_transfer_market_open') //success message
            }
        } // call sell all api sellAllCheck = true
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const disabled = !sellAmount || errorMinValue || errorMaxValue

    return (
        <>
            {/* Sell Modal */}
            <Modal
                size='xl'
                centered
                show={openModalSell}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className="gi-port-ao-pc-companies-modal gi-dash-sell-modal"
                contentClassName='border-0'
            >
                <Modal.Body className='p-md-4 p-2'>
                    {/* close button */}
                    <button className="btn-close border-0 p-0 opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>

                    <div className="p-md-1 p-0">
                        <div className="gi-d-new-sell-modal-card" data-testid='sell-modal'>
                            <div className="gi-d-sell-green" data-testid='sell-modal-image'>
                                <div className="gi-d-sell-green-text">
                                    <img src={intl.formatMessage({ id: 'GIRAFFE_ICON' })} alt="" />
                                    <h5>{IntlMessages('sell_stock')}</h5>
                                </div>
                            </div>
                            <div className="gi-d-sell-form" data-testid='sell-modal-content'>
                                {/* portfolio name and stock */}
                                <div className="gi-d-sell-form-row">
                                    {/* portfolio name */}
                                    <div className="gi-d-sell-form-colum-left">
                                        <div className="gi-d-input gi-d-input-portfolio" data-testid='sell-name-div'>
                                            <label htmlFor="">{IntlMessages('sell_portfolio')}</label>
                                            <InputComponentText2
                                                placeholder={'placeholder_email'}
                                                className={'form-control-sell-01'}
                                                disabled={true}
                                                name={'fromPortfolio'}
                                                fieldValue={fromAccount[0]?.label}
                                                dataTestId={'sell-name-input'}
                                                readOnly={false}
                                                inputValue={setInputValue}
                                            />
                                        </div>
                                    </div>
                                    {/* stock amount */}
                                    <div className="gi-d-sell-form-colum-right">
                                        <div className="gi-d-input gi-d-input-value d-none d-lg-block">
                                            <label htmlFor="">{IntlMessages('sell_value_stock')}</label>
                                            <InputComponentText2
                                                placeholder={'placeholder_email'}
                                                className={'form-control-sell-01'}
                                                disabled={true}
                                                name={'fromPortfolio'}
                                                fieldValue={currencyFormatter(totalSellAmount)}
                                                dataTestId={'sell-stock-desk'}
                                                inputValue={setInputValue}
                                                readOnly={false}
                                            />
                                        </div>

                                        <div className="gi-d-input gi-d-input-mob-value d-block d-lg-none">
                                            <span>{IntlMessages('sell_current_value')}</span>
                                            <InputComponentText2
                                                placeholder={'placeholder_email'}
                                                className={'form-control-sell-01'}
                                                disabled={true}
                                                name={'fromPortfolio'}
                                                fieldValue={currencyFormatter(totalSellAmount)}
                                                dataTestId={'sell-stock-mob'}
                                                inputValue={setInputValue}
                                                readOnly={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* switch and note */}
                                <div className="gi-d-sell-form-row">
                                    {/* switch */}
                                    <div className="gi-d-sell-form-colum-left">
                                        <div className="gi-d-customize-switch form-check form-switch sell-switch-btn">
                                            <span className={`sell-all-text ${sellAllCheck ? '' : 'active'}`} data-testid='sell-all'>{IntlMessages('sell_all_text')}</span>
                                            <InputComponentCheckbox2
                                                name={'terms'}
                                                check={sellAllCheck}
                                                id={'terms'}
                                                inputValue={sellCheckFunction}
                                                dataTestId="sell-checkbox"
                                            />
                                            <span className={`partial-text ${sellAllCheck ? 'active' : ''}`} data-testid='partial-sell'>{IntlMessages('partial_sell_text')}</span>
                                        </div>
                                        {sellAllCheck && (
                                            <div className="gi-d-input gi-d-input-amount" data-testid='partial-sell-div'>
                                                <label htmlFor="">{IntlMessages('select_amount')} </label>
                                                <input
                                                    className="d-none d-sm-block"
                                                    type="text"
                                                    placeholder={intl.formatMessage({ id: "select_amount_placeholder" }, { minSellValue: minSellValue, maxSellValue: maxSellValue })}
                                                    value={sellAmount === 0 ? '' : sellAmount}
                                                    onChange={enterSellAmount}
                                                    data-testid={'sell-amount-input-desk'}
                                                />
                                                <input
                                                    className="d-block d-sm-none"
                                                    type="text"
                                                    placeholder={intl.formatMessage({ id: "select_amount_placeholder" }, { minSellValue: minSellValue, maxSellValue: maxSellValue })}
                                                    value={sellAmount === 0 ? '' : sellAmount}
                                                    onChange={enterSellAmount}
                                                    data-testid={'sell-amount-input-mob'}
                                                />
                                                {errorMinValue && (<div className="gi-d-input-error">{IntlMessages('error_minimum_amount_sell', { amount: currencyFormatter(minSellValue) })}</div>)}
                                                {errorMaxValue && (<div className="gi-d-input-error">{IntlMessages('error_maximum_amount_sell', { amount: currencyFormatter(maxSellValue) })}</div>)}
                                                {errorAPIFlag && (<div className="gi-d-input-error">{errorMessage}</div>)}
                                            </div>
                                        )}
                                    </div>
                                    {/* note */}
                                    <div className="gi-d-sell-form-colum-right">
                                        <div className={`gi-d-sell-note-text sell-error`} data-testid='sell-note'>
                                            <p><span>{IntlMessages('sell_modal_note_text1')}</span> {IntlMessages('sell_modal_note_text2')} </p>
                                        </div>
                                    </div>
                                </div>
                                {/* button */}
                                <div className="gi-d-sell-form-footer">
                                    <div className="gi-d-sell-form-footer-button">
                                        <button
                                            type="button"
                                            disabled={disabled}
                                            onClick={openConfirmModalFunction}
                                            data-testid='approve-sale-btn'
                                        >{IntlMessages('approve_sale_btn')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirm Modal */}
            <Modal
                size='xl'
                centered
                show={openConfirmModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal">
                <Modal.Body className='p-xl-4 p-3'>
                    <button className="btn-close p-0 border-0 position-absolute opacity-100" type="button" aria-label="Close" onClick={onHandleClose} data-testid='close-confirmation'></button>
                    <div className='p-xl-2 p-0' data-testid='confirmation-modal'>
                        <div className="row gx-0 ">
                            {/* left image */}
                            <div className="col-lg-5">
                                <div className="gi-g-new-fund-modal-card-start d-flex align-items-center justify-content-lg-center justify-content-start text-lg-center text-start" data-testid='confirmation-modal-image'>
                                    <div>
                                        <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="" />
                                        <h5 className="d-none d-md-block">
                                            {IntlMessages('sell_stock')}
                                        </h5>
                                        <h5 className="d-block d-md-none">
                                            {IntlMessages('sell_stock')}
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            {/* right content */}
                            <div className="col-lg-7">
                                <div className="gi-g-new-fund-modal-card-end">
                                    {/* input */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* header */}
                                            <div className="gi-g-new-input-text mb-4 pb-3">
                                                <h5 data-testid='confirm-heading-text'>
                                                    {IntlMessages('fund_confirm_transaction')}
                                                </h5>
                                            </div>
                                            {/* from */}
                                            <div className="gi-g-new-input-text mb-4 pb-3" data-testid='confirm-basket-text'>
                                                <label>
                                                    {IntlMessages('sell_portfolio')}
                                                </label>
                                                <h5>
                                                    {fromAccount.length > 0 && fromAccount.map((e) => {
                                                        return e.label
                                                    })}
                                                </h5>
                                            </div>
                                            {/* amount */}
                                            <div className="gi-g-new-input-text mb-4 pb-3" data-testid='confirm-amount-text'>
                                                <label>
                                                    {IntlMessages('transfer_amount')}
                                                </label>
                                                <h5>
                                                    {`$ ` + currencyFormatter(sellAmount).replace('$', '')}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* button */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="gi-g-new-btn-groupe mt-4 pt-2">
                                                <button className="gi-g-new-cancel" type="button" onClick={onHandleClose} data-testid='close-confirm-btn'>
                                                    {IntlMessages('button_cancel')}
                                                </button>
                                                <button className="gi-g-new-transfer" type="button" onClick={sellTransfer} data-testid='confirm-btn'>
                                                    {IntlMessages('button_confirm')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            {/* Loading Modal */}
            {openLoadingModal && <LoadingModal openLoadingModal={openLoadingModal} title={'fund_preparing'} onHandleClose={onHandleClose} />}

            {/* SuccessModal */}
            <SuccessModal openSuccessModal={openSuccessModal} successMessage={successMessage} onHandleCloseSuccess={onHandleCloseSuccess} />
        </>
    )
}

export default SellModal