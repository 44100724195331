// type import
import * as UserType from 'api/APIMaster/UserType';
import Dashboard from 'interface/Dashboard'
// message import
import IntlMessages from 'helper/IntlMessages';

export const onBoardingStaticData: UserType.OnboardingStaticDataResponseModel = {
    locale: '',
    countryList: [],
    employmentStatusList: [],
    employmentPositionList: [],
    employmentTypeList: [],
    investorExperienceList: [],
    investorRiskToleranceList: [],
}

export const userStatus: UserType.UserStatusResponseModel = {
    id: 0,
    identifier: '',
    name: '',
    active: false,
}

export const kycStatus: UserType.KycStatusResponseModel = {
    id: 0,
    identifier: '',
    name: '',
    active: false,
}

export const userKycDwStatusMap: UserType.UserKycDwStatusMapResponseModel = {
    id: 0,
    userStatus: userStatus,
    kycStatus: kycStatus,
    dwUserStatusIdentifier: '',
    dwKycStatusIdentifier: '',
}

export const userAddress: UserType.UserAddressCommonModel = {
    apartmentNumber: '',
    street1: '',
    street2: '',
    street3: '',
    city: '',
    province: '',
    postalCode: '',
    countryIdentifier: '',
}

export const userEmployment: UserType.UserEmploymentCommonModel = {
    statusIdentifier: '',
    company: '',
    typeIdentifier: '',
    positionIdentifier: '',
    affiliatedWithBroker: false,
    directorOf: '',
}

export const userInvestor: UserType.UserInvestorCommonModel = {
    experienceIdentifier: '',
    annualIncome: 0,
    networthTotal: 0,
    riskToleranceIdentifier: '',
}

export const userDisclosure: UserType.UserDisclosureCommonModel = {
    termsOfUse: false,
    customerAgreement: false,
    marketDataAgreement: false,
    rule14b: false,
    findersFee: false,
    privacyPolicy: false,
    dataSharing: false,
    extendedHoursAgreement: false,
    giraffeTermsAndConditions: false,
}

export const userData: UserType.GetDetailData = {
    id: 0,
    identifier: '',
    name: '',
    firstName: '',
    middleName: '',
    lastName: '',
    firstNameEncrypted: '',
    middleNameEncrypted: '',
    lastNameEncrypted: '',
    email: '',
    emailEncrypted: '',
    marketing: false,
    kycStatus: '',
    kycError: '',
    showKycStatus: false,
    status: '',
    dwUserIdentifier: '',
    userType: '',
    phone: '',
    phoneEncrypted: '',
    language: '',
    taxIdNumber: '',
    taxIdType: '',
    citizenship: '',
    usTaxPayer: false,
    dob: '',
    gender: '',
    marital: '',
    politicallyExposedNames: '',
    irsBackupWithholdings: false,
    isNewUser: false,
    isKycProcessing: false,
    hasKycErrors: false,
    isKycDenied: false,
    learnAccess: false,
    miniMbaAccess: false,
    treeOfImpactAccess: false,
    emailSubscribe: '',
    emailSubscribeMarketing: false,
    emailSubscribeLearn: false,
    feeStartDateTime: '',
    createDateTime: '',
    updateDateTime: '',
    userKycDwStatusMap: userKycDwStatusMap,
    userAddress: userAddress,
    userEmployment: userEmployment,
    userInvestor: userInvestor,
    userDisclosure: userDisclosure,
    metadataList: [],
    kycCountry: '',
    locale: '',
    monthlySavingSetup: false,
    monthlySaving: null,
    organizationName: null,
    workEmail: '',
    deductionDate: 0,
    defaultCurrency: '',
    isInternalKycDenied: false,
    isInternalKycRejected: false,
    isInternalKycManualReview: false,
    isInternalKycPending: false,
}

export const countryData: UserType.OnBoardingResponseModal = {
    id: 0,
    identifier: '',
    name: '',
    active: false,
    nameLocale: '',
}

export const connectBankData: Dashboard.ConnectBankData = {
    name: IntlMessages('account_setup_bank_connect'),
    id: 0
}

export const userBasicDetails: UserType.UserBasicDetailResponse = {
    name: "",
    firstNameEncrypted: "",
    middleNameEncrypted: null,
    lastNameEncrypted: "",
    phoneEncrypted: "",
    kycError: null,
    hasKycErrors: false,
    monthlySavingSetup: false,
    organizationName: null,
    msCutoffDate: 0,
    defaultCurrency: "",
    kycCountry: null,
    userAddress: userAddress,
    monthlySaving: null,
    workEmail: '',
    isInternalKycDenied: false,
    isInternalKycRejected: false,
    isInternalKycManualReview: false,
    isInternalKycPending: false,
}

export const monthlySaving: UserType.MonthlySaving = {
    beforeCutoffAmount: 0,
    afterCutoffAmount: 0,
    forCashAccount: false,
    basketId: 0,
    status: '',
    statusDate: '',
    isDeductionInitiated: false,
}