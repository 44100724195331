
// default library
import React from 'react';
// slider 
import Slider, { Settings } from "react-slick";
// import library for react slick carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Markup } from 'interweave';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as BasketType from 'api/APIMaster/BasketType'

//defining props
export type Props = {
    selectedEsgcValueList: BasketType.GiftEsgcValueRequestModel[];
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
}

const PortfolioSlider: React.FC<Props> = (props: Props) => {
    const { esgcValueList, selectedEsgcValueList } = props;//destructruing props

    const sliderSetting: Settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    centerMode: true,
                    centerPadding: '60px',
                    dots: false,
                    slidesToShow: 0.8,
                },
            },
        ],
    }; // slider setting

    return (
        <div className="gift-p-activity-body">
            <Slider {...sliderSetting} className="gift-p-activity-slider slider slick-dotted" >
                {esgcValueList.map((e, i) => {
                    const selectedEsgc = selectedEsgcValueList.find((a) => a.esgcValueIdentifier === e.identifier);
                    if (selectedEsgc) {
                        return (
                            <div key={i}>
                                <div className="gift-p-activity-value-card">
                                    <div className="row gx-0">
                                        {/* ESG-C value image */}
                                        <div className="col-md-6">
                                            <div className="gift-p-activity-value-img h-100">
                                                <img src={e.banner2Desktop} className="img-fluid w-100 h-100" alt="" />
                                            </div>
                                        </div>
                                        {/* ESG-C value description */}
                                        <div className="col-md-6">
                                            <div className="gift-p-activity-value-main h-100" style={{ background: e.banner2Color }}>
                                                <div className="gift-p-activity-value-text-top">
                                                    <h5 data-testid="value-name">{e.name}</h5>
                                                    <Markup content={selectedEsgc.deepClean ? e.deepCleanBannerText : e.bannerText} />
                                                </div>
                                                {/* remove and add */}
                                                <div className="gift-p-activity-value-text-bottom pt-4">
                                                    <span className="mb-2">
                                                        {IntlMessages('portfolio_removed')}
                                                        <a href="#!" className="d-inline-block ps-1" data-testid='removed-company'>{selectedEsgc.deepClean ? e.deepCleanExclusionList.length : e.exclusionList.length}</a>
                                                    </span>
                                                    {e.inclusionList.length > 0
                                                        ? <span className="mb-2">
                                                            {IntlMessages('portfolio_added')}
                                                            <a href="#!" className="d-inline-block ps-1" data-testid='added-company'>{selectedEsgc.addGoodCompanies ? e.inclusionList.length : 0}</a>
                                                        </span>
                                                        : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </Slider>
        </div>
    );
};

export default PortfolioSlider;




























