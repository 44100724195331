// default library
import * as React from 'react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// encrypt and decrypt
import * as CryptoJS from 'crypto-js';
// config
import * as Config from 'config/Config';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import MaskInput2 from 'components/InputComponent/MaskInput2';
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
// custom function
import resizeErrorMessage from 'service/utils/ResizeErrorMessage';
//message import
import IntlMessages from 'helper/IntlMessages';
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
// api call
import * as UserService from "middleware/UserService";
// local data
import * as Local from 'service/utils/LocalStorageData';

// Define the PropTypes for the PhoneNumber component
type Props = {
  propsData: PropsFunction.PropsPageDataFunction
}

const OTPVerification: React.FC<Props> = (props: Props) => {
  const { propsData } = props// Destructuring the receiving props
  const { initiateAction } = useAccessToken()

  const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
  const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [formattedNumber, setFormattedNumber] = React.useState<string>(''); //enter phone number
  const [verificationCode, setVerificationCode] = React.useState<string>(''); //store otp
  const [errorFlag, setErrorFlag] = React.useState<boolean>(false); //otp not verified
  const [isRouteChange, setIsRouteChange] = React.useState<boolean>(false); //check page change
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({}); //storing field details

  const nextPage = "page3"; //next page
  const sendText = false; //to display send text in the button
  const submitText = false; //to display submit text in the button
  const parentButton = true; //to display parent click props button

  const phoneNumber = `+1${Local.getLocalData("onBoardingDetails").phoneNumber}`; //phone number

  // useEffect hook to fetch data when the component mounts
  React.useEffect(() => {
    // Accessing data from localStorage
    const localStorageData = Local.getLocalData('onBoardingDetails');

    // props data for header previous button and process bar
    propsData({
      previousPage: 'page2',
      pageNumber: 3,
    });

    //get phone number from localStorage
    if (localStorageData?.phoneNumber) {
      const phoneNumber = localStorageData.phoneNumber;
      const phoneNumber1 = phoneNumber.substring(0, 3);
      const phoneNumber2 = phoneNumber.substring(3, 6);
      const phoneNumber3 = phoneNumber.substring(6, 10);

      const formattedNumber = `+1 (${phoneNumber1}) ${phoneNumber2} - ${phoneNumber3}`;
      setFormattedNumber(formattedNumber);

      sendOTP(); //send api call
    }
  }, []);

  // Function to set input value and update state
  const setInputValue = (_fieldName: string, fieldValue: string): void => {

    setVerificationCode(fieldValue);

    setErrorFlag(false)
    setIsLoading(false)
  };

  // send api call
  const sendOTP = async (): Promise<void> => {
    setIsLoading(true);

    const accessToken = await initiateAction();
    const sendOTP = await UserService.sendOtp(phoneNumber, accessToken);// Calling the sendOTP function

    setIsLoading(false);

    if (sendOTP.response || sendOTP.request) {
      setErrorLog(sendOTP)
      setIsError(true)
    } else {
      setErrorFlag(false)
      setVerificationCode(''); // reset the verificationCode state
    }
  };

  // verify api call
  const verifyOTP = async (): Promise<void> => {
    setIsLoading(true);

    // Checking verification code is a  number
    if (verificationCode) {
      const accessToken = await initiateAction();//the auth0 access token
      const verifyOtp = await UserService.verifyOTP(verificationCode, phoneNumber, accessToken);//Calling the verifyOTP function 

      setIsLoading(false);

      if (verifyOtp?.response || verifyOtp?.request) {
        setErrorLog(verifyOtp)
        setIsError(true)
      } else {
        if (verifyOtp) {
          setIsRouteChange(true); // Set state to  route change
          setErrorFlag(false); // Set state to indicate no verification error

          const localStorageData = Local.getLocalData('onBoardingDetails');
          const fieldDetails = { ...localStorageData, verifyPhoneNumber: CryptoJS.AES.encrypt(JSON.stringify(verifyOtp), Config.SECRET_KEY).toString() };
          Local.setLocalData('onBoardingDetails', fieldDetails);
        }
        else {
          setIsRouteChange(false)
          setErrorFlag(true)
        }
      }
    }
  };

  React.useEffect(() => {
    return () => {
      resizeErrorMessage('verificationCode');
    };
  }, [errorFlag])

  React.useEffect(() => {
    setFieldDetails({ verificationCode: verificationCode });
  }, [verificationCode])

  if (isError) {
    throw new Error(JSON.stringify(errorLog));
  }

  return (
    <>
      {isLoading && <LoadingComponent />}

      <div className="gi-ob-slide-card" data-testid="otp-page">
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-six-digit-text">
          <h5>{IntlMessages('otp_page_text1')}</h5>
          <p>{formattedNumber}</p>
        </div>

        {/* otp */}
        <div className="gi-ob-full-name-form gi-ob-six-digit-form">
          <div className="gi-ob-input-box gi-ob-six-digit">
            <MaskInput2
              placeholder={'placeholder_zip_code'}
              name={'verificationCode'}
              readOnly={false}
              fieldType={'password'}
              fieldValue={verificationCode}
              inputValue={setInputValue}
              guide={false}
              dataTestId='verification-code'
              className={''}
            />
            {errorFlag &&
              <ErrorKYC
                message={'error_invalid_verification_code'}
                fieldName={'verificationCode'}
              />
            }
          </div>
        </div>

        {/* resend otp */}
        <div className="gi-ob-six-digit-resmed-code">
          <a onClick={sendOTP} data-testid='send-otp'>{IntlMessages('button_resend_code')}</a>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          isFooter={false}
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
          onHandleParentClick={verifyOTP}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
        onHandleParentClick={verifyOTP}
      />
    </>
  );
};

export default OTPVerification;

























































