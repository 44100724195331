//default library
import * as  React from 'react';
//import image
import { useIntl } from 'react-intl';
//message provider
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
//api call
import * as DashboardService from "middleware/DashboardService"
//custom component
import PaginationNew from 'components/PaginationComponent/PaginationNew';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';

//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateFormatter } from 'service/utils/DateFormatter';
import { DateRange } from 'service/utils/DateFilter';
//default values
import { taxesDropdown } from 'config/DefaultValue';

//defining props
export type Props = {
    investingHistoryData: DashboardType.GetInvestingHistoryData
}

const InvestingHistoryCard: React.FC<Props> = (props: Props) => {
    const { investingHistoryData } = props

    const [dataLoad, setDataLoad] = React.useState<boolean>(false);//loading flag
    const [errorLog, setErrorLog] = React.useState<any>({});//error message
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error occurred flag

    const [totalInvestingHistoryData, setTotalInvestingHistoryData] = React.useState<DashboardType.GetInvestingHistoryData>([]); //investing data, date wise
    const [currentInvestingHistoryData, setCurrentInvestingHistoryData] = React.useState<DashboardType.GetInvestingHistoryData>([]);//current transaction data
    const [selectedValue, setSelectedValue] = React.useState<PropsFunction.OptionData>(taxesDropdown[0]);

    const intl = useIntl()
    const { initiateAction } = useAccessToken();

    React.useEffect(() => {
        if (totalInvestingHistoryData.length === 0) {
            setTotalInvestingHistoryData(investingHistoryData);
            setDataLoad(true);
        }
    }, []);

    //page change function
    const onPageChanged: PropsFunction.OnPageChanged = (data): void => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentInvestingData = totalInvestingHistoryData.slice(offset, offset + pageLimit);
        setCurrentInvestingHistoryData(currentInvestingData);
    };

    //selected value function 
    const onSelectValue: PropsFunction.SelectedOption = async (_fieldName, fieldValue): Promise<void> => {
        setDataLoad(false);

        const dateRange = DateRange(fieldValue.value.toString());
        const accessToken = await initiateAction();
        const investingHistoryData = await DashboardService.investingHistoryDetails(dateRange, accessToken);

        if (investingHistoryData.response) {
            setErrorLog(investingHistoryData)
            setErrorAPI(true);
        }
        else {
            setTotalInvestingHistoryData(investingHistoryData);
            setDataLoad(true);
            setSelectedValue(fieldValue)
        }
    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {!dataLoad && <LoadingComponent />}
            <div className="row gx-20" data-testid="investing-card">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="gi-d-ac-select-dropdown d-flex align-items-center flex-wrap gap-2 my-4 py-2">
                                <label>{IntlMessages('table_range')}:</label>
                                <div className='activity-dropdown'>
                                    <SelectDropdown
                                        labelClassName={''}
                                        labelText={''}
                                        name='transaction'
                                        option={taxesDropdown}
                                        fieldValue={selectedValue}
                                        inputValue={onSelectValue}
                                        placeholder={''}
                                        ariaLabel={"investing-label"}
                                        className={'custom-react-fund'}
                                        isDisabled={false}
                                        isModalDropdown={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table gi-d-table-striped-02 border-top gi-d-table-investing-history mb-4" role="presentation">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <span>
                                            {IntlMessages('table_date')}
                                        </span>
                                    </th>
                                    <th scope="col" className="description-th">
                                        <span>
                                            {IntlMessages('table_description')}
                                        </span>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col" className="amount-th text-start">
                                        <span>
                                            {IntlMessages('table_amount')}
                                        </span>
                                    </th>
                                    <th scope="col" className="collapse-th"></th>
                                </tr>
                            </thead>
                            {currentInvestingHistoryData.length > 0
                                ? currentInvestingHistoryData.map((e, i) => {
                                    if (e.orders) {
                                        return (
                                            <React.Fragment key={i}>
                                                <tbody>
                                                    <tr className={i % 2 === 0 ? 'bg-light' : ''} data-testid="tabel-content">
                                                        <td>
                                                            <span data-testid="date-name">{DateFormatter(e.date)}</span>
                                                        </td>
                                                        <td>
                                                            <span data-testid="description-name">{e.description}</span>
                                                        </td>
                                                        <td></td>
                                                        <td className="amount-td">
                                                            <span className={`${e.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'} ltr`}>
                                                                {e.actualAmount > 0
                                                                    ? `+${currencyFormatter(e.actualAmount)}`
                                                                    : currencyFormatter(e.actualAmount)
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="align-middle">
                                                            <a className="gi-d-down-btn px-2 py-1 d-flex justify-content-end h-100 collapsed" data-bs-toggle="collapse" href={`#collapse-${i}`} role="button"
                                                                aria-expanded="false" aria-controls={`collapse-${i}`}>
                                                                <img className="down-collapsed" src={intl.formatMessage({ id: 'DOWN_ARROW_BLACK' })} alt="" />
                                                                <img className="up-collapsed" src={intl.formatMessage({ id: 'UP_ARROW_BLACK' })} alt="" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody className="collapse" id={`collapse-${i}`}>
                                                    {
                                                        e.orders.map((v, index) => {
                                                            return (
                                                                <tr key={index} >
                                                                    <td></td>
                                                                    <td>
                                                                        <span >{v.side}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span >{v.symbol}</span>
                                                                    </td>
                                                                    <td className="amount-td">
                                                                        <span className={`${v.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'} ltr`}>
                                                                            {v.actualAmount > 0 ? `+${currencyFormatter(v.actualAmount)}` : currencyFormatter(v.actualAmount)}
                                                                        </span>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </React.Fragment>
                                        )
                                    }
                                    else {
                                        return (
                                            <tbody key={i}>
                                                <tr className={i % 2 === 0 ? 'bg-light' : ''} >
                                                    <td>
                                                        <span >{DateFormatter(e.date)}</span>
                                                    </td>
                                                    <td>
                                                        <span >{e.description}</span>
                                                    </td>
                                                    <td></td>
                                                    <td className="amount-td">
                                                        <span className={`${e.actualAmount > 0 ? 'text-gi-green' : 'text-gi-clay'} ltr`}>
                                                            {e.actualAmount > 0 ? `+${currencyFormatter(e.actualAmount)}` : currencyFormatter(e.actualAmount)}
                                                        </span>
                                                    </td>
                                                    <td>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                })
                                : <tbody>
                                    <tr className="bg-light" data-testid="tabel-content">
                                        <td colSpan={12}>
                                            <span>
                                                {IntlMessages('table_not_range')}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    {dataLoad && <PaginationNew totalRecords={totalInvestingHistoryData.length} pageLimit={5} pageNeighbors={1} onPageChanged={onPageChanged} />}
                </div>
            </div>
        </>
    );

};

export default InvestingHistoryCard;