// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// custom component
import KycCompleteFooter from 'components/FooterComponent/KycCompleteFooter';
// stop browser back button routing
import PreventBackNavigation from 'helper/PreventBackNavigation';
// import { getUserDetails } from 'middleware/User';
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//api call
import * as UserService from "middleware/UserService"
// custom function
import * as Local from 'service/utils/LocalStorageData';
import * as UtilFunctions from 'service/utils/UtilFunctions';

const TaskComplete: React.FC = () => {
  //functions from Auth0 library
  const { initiateAction } = useAccessToken();
  const history = useHistory();
  const intl = useIntl();

  const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
  const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

  React.useEffect(() => {
    UtilFunctions.stopBrowserBack(); //stop user to browser back button

    //reload page and check user details
    const reloadPage = async () => {
      const accessToken = await initiateAction(); // get access token
      const userDetails = await UserService.getByAccessToken(accessToken); // Get user details using the access token
      Local.setLocalData("dwUserIdentifier", userDetails.dwUserIdentifier); // set user dwUserIdentifier to localStorage

      if (userDetails.response || userDetails.request) {
        setErrorLog(userDetails)
        setIsError(true)
      }
      else {
        !userDetails.isKycProcessing && history.push('/on-boarding/congratulation')
      }
    };

    const intervalId = setInterval(reloadPage, 10000); // call reload function every 10 sec
    // Cleanup function 
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (isError) {
    throw new Error(JSON.stringify(errorLog));
  }

  return (
    <>
      {/* prevent browser back button */}
      <PreventBackNavigation />
      {/* main content */}
      <div className="gi-ob-body-main">
        <section className="gi-ob-confirm-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="gi-ob-white-logo">
                  <img src={intl.formatMessage({ id: 'WHITE_LOGO_GIRAFFE' })} alt="" />
                </div>
                <div className="gi-ob-rock-card">
                  <div className="gi-ob-rock-text">
                    <h3>{IntlMessages('kyc_pending_text1')}</h3>
                    <h5>{IntlMessages('kyc_pending_text2')}</h5>
                    <p>{IntlMessages('kyc_pending_text3')}</p>
                    <span>{IntlMessages('kyc_pending_text4')}</span>
                    <div className="gi-ob-spinner">
                      <img src={intl.formatMessage({ id: 'SPINNER_ON_BOARDING' })} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <KycCompleteFooter />
      </div>
    </>
  );
};

export default TaskComplete;