//default library
import * as React from 'react';
// intl message library
import { useIntl } from 'react-intl';
//helper message
import IntlMessages from 'helper/IntlMessages';
//props types
import * as BasketType from "api/APIMaster/BasketType"

//defining props
export type Props = {
  sp500GraphData: BasketType.YearlyPerformanceData[];
}

const TablePortfolioChart: React.FC<Props> = (props: Props) => {
  const { sp500GraphData } = props; //destructuring props
  const intl = useIntl();

  return (
    <>
      {/* table */}
      <div className="table-responsive pb-2">
        <table className="g-data-table-graph table" role="presentation">
          <tbody>
            {/* portfolio */}
            <tr className="table-success">
              <th scope="row">{IntlMessages('portfolio_text0')}</th>
              {sp500GraphData.map((e, i) => (
                <td key={i}>
                  {e.personalizedPerformance > 0
                    ? <span className="d-block">
                      <img src={intl.formatMessage({ id: 'UP_ARROW_WHITE_ICON' })} alt="" />
                    </span>
                    : <span className="d-block">
                      <img src={intl.formatMessage({ id: 'DOWN_ARROW_RED_ICON' })} alt="" />
                    </span>
                  }
                  {e.personalizedPerformance}
                </td>
              ))}
            </tr>
            {/* s&p500 */}
            <tr className="table-dark">
              <th scope="row">{IntlMessages('sp500')}</th>
              {sp500GraphData.map((e, i) => (
                <td key={i}>
                  {e.sp500Performance > 0
                    ? <span className="d-block">
                      <img src={intl.formatMessage({ id: 'UP_ARROW_WHITE_ICON' })} alt="" />
                    </span>
                    : <span className="d-block">
                      <img src={intl.formatMessage({ id: 'DOWN_ARROW_RED_ICON' })} alt="" />
                    </span>
                  }
                  {e.sp500Performance}
                </td>
              ))}
            </tr>
            {/* year */}
            <tr className="table-white">
              <th>{IntlMessages('year')}</th>
              {sp500GraphData.map((e, i) => (
                <td key={i}>{e.performanceYear}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {/* legend */}
      <div className="gi-portfolio-legend d-flex justify-content-between pt-2 mt-1">
        <ul className="d-flex flex-wrap gap-4">
          <li className="portfolio-legend">
            <a>{IntlMessages('portfolio_text0')}</a>
          </li>
          <li className="sp-legend">
            <a>{IntlMessages('portfolio_text1')}</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TablePortfolioChart;
