// default library
import * as React from 'react';
// date picker library
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer'
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// custom component
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';

import he from "date-fns/locale/he";
import enUS from "date-fns/locale/en-US";
registerLocale("he", he);
registerLocale("enUS", enUS);

export type Props = {
    labelText: string;
    labelClassName: string;
    className: string;
    dateValue: Date | null,
    name: string;
    errorMessage: string;
    disabled: boolean;
    newDate: PropsFunction.PropsDateFunction;
}

const DatePickerComponent: React.FC<Props> = (props: Props) => {
    const { labelText, labelClassName, className, dateValue, name, errorMessage, disabled, newDate } = props;

    const locale = useSelector(getLocale);

    return (
        <>
            <label className={labelClassName}>{IntlMessages(labelText)}</label>
            <DatePicker
                locale={locale.language === 'he_IL' ? he : enUS}
                className={`${className}`}
                name={name} //calender 
                selected={dateValue} //select date
                onChange={newDate} //change date
                dateFormat="dd-MM-yyyy" //date formate 
                placeholderText="DD-MM-YYYY" //placeholder
                minDate={new Date('1/1/1901')} //minimum date of the calender
                maxDate={new Date()} //maximum date of the calender
                showMonthDropdown //show the dropdown for the month
                showYearDropdown //show the dropdown for the year
                scrollableYearDropdown //scroll the dropdown
                yearDropdownItemNumber={200} //number of year to display in dropdown
                disabled={disabled} //date disabled
            />
            {errorMessage && <ErrorKYC message={errorMessage} fieldName={name} />}
        </>
    );
}

export default DatePickerComponent;