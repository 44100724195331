import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// type import
import { RootState } from 'reduxStore/store';

export type InitialState = {
    isNewUser: boolean;
    isKycProcessing: boolean;
    isKycManualReview: boolean;
    isKycRejected: boolean;
    isKycDenied: boolean;
    isLearnAccess: boolean;

    userCreateDateTime: string;
    kycCountry: string;

    popUpCounter: number;
}

export const initialState: InitialState = {
    isNewUser: false,
    isKycProcessing: false,
    isKycManualReview: false,
    isKycRejected: false,
    isKycDenied: false,
    isLearnAccess: false,

    userCreateDateTime: '',
    kycCountry: '',

    popUpCounter: 0,
};

const appCommonDataSlice = createSlice({
    name: 'appCommonData',
    initialState,
    reducers: {
        setCurrentAppCommonData: (state: InitialState, action: PayloadAction<InitialState>): InitialState => {
            return {
                ...state,
                ...(action.payload)
            }
        }
    }
});

export const { setCurrentAppCommonData } = appCommonDataSlice.actions;

export default appCommonDataSlice.reducer;

export const getAppCommonData = (state: RootState) => state.appCommonData;