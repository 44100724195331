// default library
import * as React from 'react';
// plaid library
import { usePlaidLink, PlaidLinkError, PlaidLinkOnExitMetadata, PlaidLinkOnSuccessMetadata, PlaidLinkStableEvent, PlaidLinkOnEventMetadata } from 'react-plaid-link';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as PlaidService from 'middleware/PlaidService';
// type import
import * as PropsFunction from 'interface/PropsFunction';

import LoadingModal from 'components/LoadingComponent/LoadingModal';

export type Props = {
    plaidSuccess: PropsFunction.SimpleFunction,
    plaidExit: PropsFunction.SimpleFunction,
}


const PlaidComponent: React.FC<Props> = (props: Props) => {

    const { plaidSuccess, plaidExit } = props
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [token, setToken] = React.useState<string>('')

    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        (async () => {
            const accessToken = await initiateAction() //get access token
            const getToken = await PlaidService.createToken(true, accessToken)
            if (getToken.response) {
                setErrorLog(getToken)
                setIsError(true)
            } else {
                setToken(getToken.token)
            }
        })()

        return () => {
            const modalBackdrop: NodeListOf<Element> = document.querySelectorAll("#iframe-modal");
            if (modalBackdrop.length > 0) {
                modalBackdrop.forEach(e => {
                    e.remove();
                })
            }
        }
    }, [])

    const onSuccess = (_publicToken: string, _metadata: PlaidLinkOnSuccessMetadata): void => {
        plaidSuccess()
    };

    const onExit = (_error: null | PlaidLinkError, _metadata: PlaidLinkOnExitMetadata): void => {
        plaidExit()
    };

    const onEvent = (eventName: PlaidLinkStableEvent | string, _metadata: PlaidLinkOnEventMetadata): void => {
        // add background for blur 
        if (eventName === 'TRANSITION_VIEW') {
            const newDiv = document.createElement("div");
            newDiv.className = 'fade modal-backdrop show';
            newDiv.id = 'iframe-modal'

            document.body.appendChild(newDiv);
        }
        // remove background for blur 
        if (eventName === 'EXIT') {
            const modalBackdrop: NodeListOf<Element> = document.querySelectorAll("#iframe-modal");
            if (modalBackdrop.length > 0) {
                modalBackdrop.forEach(e => {
                    e.remove();
                })
            }
        }

        if (eventName === 'OPEN') {
            setIsLoading(false)
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const config = {
        token: token,
        onSuccess: onSuccess,
        onExit: onExit,
        onEvent: onEvent,
    }

    const { open, ready } = usePlaidLink(config)
    if (ready) {
        open()
    }

    return (
        <>
            {isLoading && (
                <LoadingModal openLoadingModal={true} title={'fund_preparing'} onHandleClose={() => { }} />
            )}
        </>
    )
}

export default PlaidComponent