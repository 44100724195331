// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from "react-router-dom";
// datadog logs
import { datadogLogs } from '@datadog/browser-logs';

export const useAccessToken = () => {
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

    const history = useHistory();

    const initiateAction = async (): Promise<string> => {
        if (isAuthenticated) {
            try {
                const token: string = await getAccessTokenSilently();
                return token;
            } catch (error) {
                datadogLogs.logger.error('Error from Auth0:- ', {
                    auth0Error: {
                        message: error.message,
                        user: user?.email
                    }
                });

                history.push('/'); //redirect to landing page
            }
        }

        return '';
    };

    return { initiateAction };
};