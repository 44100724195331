//default library
import * as React from "react";
//custom component
import InputComponentText2 from "components/InputComponent/InputComponentText2";
import SelectOnBoarding from "components/SelectComponent/SelectOnBoarding";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import ErrorKYC from "components/ErrorComponent/ErrorKYC";
import KYCFooterButton from "components/FooterComponent/KYCFooterButton";
import resizeErrorMessage from "service/utils/ResizeErrorMessage";
//helper message
import IntlMessages from "helper/IntlMessages";
//config
import { MAX_NUMBER_64 } from "config/Config";
// dummy data
import * as OnBoardingData from "service/DummyData/OnBoardingData";
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
import * as UserType from "api/APIMaster/UserType"
// custom function
import * as Local from 'service/utils/LocalStorageData'

//defining receiving props
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
  staticData: UserType.OnboardingStaticDataResponseModel
}

const EmploymentDetails: React.FC<Props> = (props: Props) => {
  const { propsData, staticData } = props//destructuring props

  const [companyName, setCompanyName] = React.useState<string>("");//company name, initialized as an empty string
  const [industryList, setIndustryList] = React.useState<OnBoarding.OptionData[]>([]);//industry options, initialized as an empty array
  const [positionList, setPositionList] = React.useState<OnBoarding.OptionData[]>([]);//list of position options, initialized as an empty array

  const [currentIndustry, setCurrentIndustry] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//currently selected industry, initialized with labelCitizenship from OnBoardingData
  const [currentPosition, setCurrentPosition] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//selected position, initialized with labelCitizenship from OnBoardingData
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//initialized with newDetailsEmployment from OnBoardingData

  const nextPage = "page9";
  const sendText = false;
  const isRouteChange = true;
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {
    // Accessing  data from local storage
    const localStorageData = Local.getLocalData("onBoardingDetails");

    // Set props data 
    propsData({
      previousPage: "page7",
      pageNumber: 9,
    });

    // mapping employmentTypeList and employmentPositionList data for Select components
    const employmentTypeList = staticData.employmentTypeList.map((e) => {
      return {
        active: e.active,
        label: e.nameLocale,
        value: e.identifier,
      }
    });

    const employmentPositionList = staticData.employmentPositionList.map((e) => {
      return {
        active: e.active,
        label: e.nameLocale,
        value: e.identifier,
      }
    });

    setIndustryList(employmentTypeList);
    setPositionList(employmentPositionList);

    // restoring local storage values if present
    localStorageData?.company && setCompanyName(localStorageData.company);

    if (localStorageData?.typeIdentifier) {
      const industry = employmentTypeList.find(e => e.value === localStorageData.typeIdentifier)
      industry && setCurrentIndustry({
        label: industry.label,
        value: industry.value,
      });
    } //store industry

    if (localStorageData?.positionIdentifier) {
      const position = employmentPositionList.find(e => e.value === localStorageData.positionIdentifier)
      position && setCurrentPosition({
        label: position.label,
        value: position.value,
      });
    } // store position
  }, []);


  //set input value for companyName
  const setInputValue = (fieldName: string, fieldValue: string): void => {
    fieldName === "companyName" && setCompanyName(fieldValue);
  };

  //setting industry value
  const setIndustryValue: OnBoarding.SelectedOption = (fieldValue) => {
    setCurrentIndustry({
      label: fieldValue.label,
      value: fieldValue.value,
    });

  };

  //set position value
  const setPositionValue: OnBoarding.SelectedOption = (fieldValue) => {
    setCurrentPosition({
      label: fieldValue.label,
      value: fieldValue.value,
    });

  };

  //update fieldDetails based on state changes
  React.useEffect(() => {
    const newFieldDetails = {
      company: companyName,
      industry: currentIndustry.value,
      position: currentPosition.value,
    };
    setFieldDetails(newFieldDetails);

    return () => {
      resizeErrorMessage("companyName"); // resizing error messages
    };
  }, [companyName, currentIndustry, currentPosition])

  return (
    <>
      <div className="gi-ob-slide-card" data-testid="employment-details">
        <div className="gi-ob-full-name-form gi-ob-industry-form">
          <div className="gi-ob-input-box gi-ob-industry-input gi-ob-company">
            <label>{IntlMessages("employment_detail_text1")}</label>
            <InputComponentText2
              name={"companyName"}
              placeholder={"placeholder_company_name"}
              className={""}
              fieldValue={companyName}
              inputValue={setInputValue}
              readOnly={false}
              disabled={false}
              dataTestId={"company-name"}
            />
            {companyName.length > MAX_NUMBER_64 && (
              <ErrorKYC
                message={"error_max_length_64"}
                fieldName={"companyName"}
              />
            )}
          </div>
          <div className="gi-ob-input-box gi-ob-industry-input gi-ob-position">
            <label>{IntlMessages("employment_detail_text2")}</label>
            {positionList.length > 0 && (
              <SelectOnBoarding
                name={"positionList"}
                option={positionList}
                placeholder={"placeholder_position"}
                fieldValue={currentPosition}
                inputValue={setPositionValue}
                ariaLabel={"position-label"}
              />
            )}
          </div>
          <div className="gi-ob-input-box gi-ob-industry-input gi-ob-industry">
            <label>{IntlMessages("employment_detail_text3")}</label>
            {industryList.length > 0 && (
              <SelectOnBoarding
                name={"industryList"}
                option={industryList}
                placeholder={"placeholder_industry"}
                fieldValue={currentIndustry}
                inputValue={setIndustryValue}
                ariaLabel={"industry-label"}
              />
            )}
            <span className="gi-ob-input-info-text">
              {IntlMessages("employment_detail_text4")}
            </span>
          </div>
        </div>
      </div>
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default EmploymentDetails;