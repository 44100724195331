const OnBoarding: { [key: string]: string } = {
    'personal': 'Personal',
    'information': 'information',

    /* old journey name */
    'personal_page4_label1': `What is your legal address?`,
    'personal_page4_label2': `Apartment / unit number`,
    'personal_page4_label3': `Zip code`,
    'employment_page3_label1': 'Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?',
    'employment_page3_label2': 'Are you a control person of a publicly traded company? A Director, Officer or 10% stock owner?',
    'employment_page3_label2_1': 'Company Name',
    'employment_page3_label2_2': 'Ticker symbol',
    'employment_page3_label3': 'Are you a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign)?',
    'employment_page3_label3_1': 'Name of official and family members',
    'employment_page4_label': 'Annual Income',
    'employment_static_t1': 'Ok, no problem!',
    'employment_static_t2': 'However you are obligated to notify your employer in writing of your intention to open an account',
    'employment_static_t3': `Please provide the names of that official and official's immediate family members (including former spouses)`,
    'employment_static_t4': `Please list the company name and its ticker symbol`,
    'investment_detail_info5': `Risk Tolerance`,
    'complete_application_t7': `Agreement`,
    'complete_application_t8': `Disclosures`,
    'waiting_page_t1': 'Your information {br} is being processed',
    'waiting_page_t2': 'is being processed',
    'waiting_page_t3': 'This could take up to 1 minute.',
    'waiting_page_t4': `We'll send you an email once you are confirmed.`,

    /* Button */
    'button_let_start': `Let’s start`,
    'button_set_later': `Set this up later`,

    /* footer */
    'onboarding_footer_text1': `Advisory services for investments are provided by Giraffe Invest, Inc., an SEC-registered investment adviser. Giraffe Invest Inc.’s internet-based advisory services are designed to assist clients in achieving discrete financial goals. For more details, see Giraffe Invest’s `,
    'onboarding_footer_form_crs': `Form CRS`,
    'onboarding_footer_form_adv_2': `Form ADV Part 2`,
    'onboarding_footer_text2': `Brokerage services are provided to clients of Giraffe Invest, Inc. by DriveWealth, LLC an SEC-registered broker-dealer and member of FINRA/SIPC.`,
    'onboarding_footer_text3': `All information you enter is encrypted using the highest data encryption standards.`,
    'onboarding_footer_text4': `All the information you enter is encrypted to the highest standards.`,

    /* New on-boarding */
    'start_page_text1': `Let's get your investment account set up.`,
    'start_page_text2': `We'll guide you through the process, laying the groundwork for your innovative investment journey.`,
    'start_page_text3': `To start, we kindly request that you share some basic information. We are required to ask for this information because, as a financial institution, we have a responsibility to collect identifying details in accordance with SEC regulations.`,

    /* User Name page */
    'username_text1': `What's your full name?`,
    'username_text2': `We require this information to proceed with setting up your investment account.`,
    'username_text3': `Please enter your legal first name`,
    'username_text4': `Please enter your legal last name`,

    /* PhoneNumber page */
    'phone_number_text1': `What’s your phone number?`,
    'phone_number_text2': `We’ll text you a security code to verify it’s you.`,
    'phone_number_text3': `Please enter your U.S. mobile phone number`,
    'phone_number_info_modal_text1': 'Our platform is expanding globally!',
    'phone_number_info_modal_text2': 'While currently available in the US only, stay tuned for our launch in Israel. Follow us on',
    'phone_number_info_modal_text3': 'LinkedIn',
    'phone_number_info_modal_text4': 'for updates.',
    'phone_number_info_modal_text5': 'Your financial journey is about to get even better!',

    /* OTPVerification page */
    'otp_page_text1': `Please enter the six-digit code sent to:`,

    /* Address page */
    'address_text1': `Where do you live?`,
    'address_text2': `Because of legal requirements, this must be a U.S. residential address, and it must match your ID. We will not send advertisement to your address.`,
    'address_text3': `What is your legal address?`,
    'address_text4': `Apartment / unit #`,
    'address_text5': `Zip code`,

    /* DobSsn page */
    'bod_ssn_text1': `Tell us more about yourself `,
    'bod_ssn_text2': `As a financial institution, we need to verify your identity in order to prevent money laundering. We take your identity protection seriously; as such, we encrypt all the information you enter using the highest data encryption standards.`,
    'bod_ssn_text3': `What is your date of birth?`,
    'bod_ssn_text4': `What is your SSN or ITIN?`,

    /* Country page */
    'country_text1': `What is your primary country of citizenship?`,

    /* TaxPayer page */
    'tax_payer_text1': `Are you a U.S. taxpayer?`,
    'tax_payer_text2': `Being a U.S. taxpayer means you are a U.S. citizen, permanent resident, or green card holder.`,

    /* EmploymentStatus page */
    'employment_status_text1': `What is your current employment status?`,
    'employment_status_text2': `In order to open an investment account for you, we kindly request some details about your employment status.`,

    /* EmploymentDetails page */
    'employment_detail_text1': `What’s the name of your company?`,
    'employment_detail_text2': `What is your position in the company?`,
    'employment_detail_text3': `What industry do you work in?`,
    'employment_detail_text4': `If you don’t find the exact industry or position, its OK! Just choose the closest alternative :)`,

    /* EmploymentStock page */
    'employment_stock_text1': `Are you affiliated with or employed by a stock exchange, member firm of an exchange or FINRA, or a municipal securities broker-dealer?`,
    'employment_stock_text2': `Are you a control person of a publicly traded company? A director, officer or 10% stock owner?`,
    'employment_stock_text3': `Are you a current or former politically exposed person or public official (includes U.S. and foreign)?`,
    'employment_stock_text4': `OK, no problem!`,
    'employment_stock_text5': `However, you are obligated to notify your employer in writing of your intention to open an account.`,
    'employment_stock_text6': `Please enter the company name and its ticker symbol`,
    'employment_stock_text7': `Please provide the name of that official and official’s immediate family members (including former spouses)`,

    /* AnnualIncome page */
    'annual_income_text1': `Household annual income (approximate)`,
    'annual_income_text2': `We are required to ask this so we have it on file for our investment advisor reporting and audits.`,

    /* NextWorth page */
    'net_worth_text1': `Approximate net worth`,
    'net_worth_text2': `We are required to ask this so we have it on file for our investment advisor reporting and audits.`,

    /* InvestingExperience page */
    'investing_experience_text1': `What is your investing experience?`,
    'investing_experience_text2': `This question will give us a general idea of your background in investing so that we know you have all of the knowledge you need to make the right investments.`,

    /* RiskTolerance page */
    'risk_tolerance_text1': `How much risk are you willing to take?`,
    'risk_tolerance_text2': `This question will give us a general idea of your risk tolerance while investing so that we can make sure you’re not taking on too much risk while investing with us.`,

    /* GiraffeTC page */
    'giraffe_tc_text1': `Approaching the finish line`,
    'giraffe_tc_text2': `By checking this box, you agree to Giraffe’s`,
    'giraffe_tc_text3': `Advisory Agreement`,
    'giraffe_tc_text4': `, DriveWealth’s`,
    'giraffe_tc_text5': `disclosures`,
    'giraffe_tc_text6': `privacy policy`,
    'giraffe_tc_text7': `terms & conditions`,
    'giraffe_tc_text8': `agreement`,
    'giraffe_tc_text9': `and acknowledge you have received a copy of our`,
    'giraffe_tc_text10': `Form CRS`,
    'giraffe_tc_text11': `Form ADV Part II`,
    'privacy_policy': `Privacy Policy`,
    't_and_c': `Terms and Conditions`,

    /* TaskComplete page */
    'kyc_pending_text1': `You rock!`,
    'kyc_pending_text2': `Task Completed`,
    'kyc_pending_text3': `Your information is being processed.`,
    'kyc_pending_text4': `(This could take up to a minute)`,

    /* Congratulation page */
    'congratulation_text1': `You rock!`,

    /* BankConnect page */
    'bank_connect_text1': `Now, let's connect your bank account.`,
    'bank_connect_text2': `I'll connect my bank later`,
}

export default OnBoarding;