//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//interweave library for html
import { Markup } from 'interweave';
//scrolling
import ScrollBar from "react-perfect-scrollbar";
// device detect library
import { isMobile } from "react-device-detect";
import "react-perfect-scrollbar/dist/css/styles.css";
// intl message library
import { useIntl } from 'react-intl';
//types props
import * as PropsFunction from "interface/PropsFunction"
import * as BasketType from 'api/APIMaster/BasketType'
//helper message
import IntlMessages from 'helper/IntlMessages';

//defining props
export type Props = {
    openInformationModal: boolean;
    closeInformationModal: PropsFunction.SimpleFunction;
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
}

const ESGCInformationModal: React.FC<Props> = (props: Props) => {
    const { openInformationModal, closeInformationModal, esgcValueList } = props; //destructring props

    const intl = useIntl();

    const scrollData: JSX.Element = <>
        {/* modal head */}
        <div className="row gx-0">
            <div className="col-md-3">
                <div className="gift-p-info-scored-img">
                    <img src={intl.formatMessage({ id: 'SCORED_VALUE_IMG' })} className="img-fluid" alt="SCORED_VALUE_IMG" />
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12">
                        <div className="gift-p-info-scored-text ps-lg-2 ps-0 mt-4">
                            <p>
                                {IntlMessages("escg_value_info_heading")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* static text */}
        <div className="row">
            <div className="col-12">
                <div className="gift-p-info-value-heading">
                    <span className="d-inline-block pe-2">  {IntlMessages('impact_giraffe_value')}</span>
                </div>
            </div>
        </div>

        {/* ESG-C value */}
        {esgcValueList.map((e, i) => (
            <div className="row" key={i}>
                <div className="col-md-12">
                    <div className="py-lg-5 py-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="gift-p-info-value-header d-flex align-items-center gap-3">
                                    <div className="gift-p-info-value-header-img d-lg-block d-none">
                                        <img src={e.icon} className="img-fluid" alt="IMG-FLUID" />
                                    </div>
                                    <div className="gift-p-info-value-header-text" style={{ color: e.banner2Color }} data-testid="value-name">
                                        {e.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11">
                                <div className="gift-p-info-value-body">
                                    <Markup content={e.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </>

    return (
        <Modal
            size='xl'
            centered
            show={openInformationModal}
            onHide={closeInformationModal}
            backdrop="static"
            keyboard={false}
            className="gi-port-ao-pc-companies-modal"
            contentClassName='border-0'
        >
            <Modal.Header className="px-3 py-2 ">
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={closeInformationModal} data-testid="handle-close"></button>
            </Modal.Header>
            <Modal.Body className={'p-lg-5 p-4 pb-2'}>
                <div>
                    {isMobile
                        ? <div className='gift-p-info-modal-card scroll-thumb pe-lg-0 pe-4' >
                            {scrollData}
                        </div>
                        : <ScrollBar component="div" className='gift-p-info-modal-card pe-lg-0 pe-4'>
                            {scrollData}
                        </ScrollBar>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ESGCInformationModal;
