import LearnService from 'api/APIServices/LearnService';

import * as Local from 'service/utils/LocalStorageData';

const Learn = new LearnService();

interface VideoUpdate {
    videoIdentifier: string;
    currentTime: number;
    currentDate: string;
}

export const getAnyContent = async (identifier: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        identifier: identifier,
        user: Local.getLocalData('identifier') ?? null,
    }

    return await Learn.getAnyContent(data)
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getUserVideo = async (videoUpdate: VideoUpdate, accessToken: string,) => {

    //set params
    const data = {
        viewedTill: videoUpdate.currentTime,
        lastViewedDateTime: videoUpdate.currentDate,
        liked: false,
        user: Local.getLocalData('identifier'),
        contentVideo: videoUpdate.videoIdentifier
    }

    return await Learn.userVideo(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })

}

export const getUserContent = async (forWeek: string, accessToken: string,) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        forWeek: forWeek ? Number(forWeek) : null,
        user: Local.getLocalData('identifier')
    }

    return await Learn.getUserContent(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getUserCalendarDetails = async (accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        user: Local.getLocalData('identifier')
    }

    return await Learn.getUserCalendarDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}