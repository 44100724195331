const Footer: { [key: string]: string } = {
    'finra': `FINRA`,
    'sipc': `SIPC`,
    'form_crs': `טופס CRS`,
    'form_adv_2': `טופס ADV חלק ב‘`,
    'code_of_ethics': `קוד אתיקה`,
    'market_data': `נתוני השוק`,
    'tiingo': `מאת Tiingo`,

    'footer_text1': `אנחנו כאן כדי לעזור לך!`,
    'footer_text2': `שלחו לנו הודעה, ואנו נגיב תוך 24 שעות כדי לספק את התמיכה והסיוע שאתם צריכים.`,
    'footer_text3': `המאמרים וחומרי התמיכה בלקוחות הזמינים הם חינוכיים בלבד ולא מהווים ייעוץ השקעות או מס.`,
    'footer_text4': `ייעוץ השקעות: `,
    'footer_text5': `שירותי ייעוץ להשקעות מסורתיות מסופקים על ידי Giraffe Invest, Inc., יועץ השקעות רשום ב-SEC. Giraffe Invest, Inc. דורשת הפקדה מינימלית להשקעה ראשונית של $100, אך אינה דורשת מלקוחות לשמור על יתרת חשבון השקעה מינימלית. עם זאת, לחשבונות מתחת ליתרה מסוימת עשויות להיות הגבלות מסוימות.`,
    'footer_text6': `שירותי השקעות ברוקראז‘ מסורתיים ומשמורת: שירותי ברוקראז‘ ניתנים ללקוחות של Giraffe Invest, Inc. על ידי DriveWealth, LLC, סוחר ברוקר רשום ב-SEC וחבר ב-`,
    'footer_text7': `אם אתם בוחרים להשקיע עם Giraffe, אנא עיינו ב- שלנו`,
    'footer_text8': `הסכם ייעוץ`,
    'footer_text9': `בתשומת לב, שכן הוא מכיל תנאים חשובים כגון מדיניות העמלות שלנו.`,
    'footer_text10': `השקעה כרוכה בסיכון ויש פוטנציאל להפסיד כסף כאשר אתם משקיעים בניירות ערך. ביצועי העבר אינם מבטיחים תוצאות עתידיות והסבירות לתוצאות ההשקעה הן היפותטיות במהותן.`,
    'footer_text11': `ההשקעות בניירות ערך הן: `,
    'footer_text12': `לא מבוטח FDIC • לא מובטח בנקאית • עלול לאבד ערך.`,
    'footer_text13': `לפני ההשקעה, שקלו את יעדי ההשקעה שלכם ואת העמלות וההוצאות של Giraffe Invest, Inc. שירותי הייעוץ מבוססי האינטרנט של Giraffe Invest Inc נועדו לסייע ללקוחות בהשגת יעדים פיננסיים דיסקרטיים. הם אינם מיועדים לספק ייעוץ מס מקיף או תכנון פיננסי ביחס לכל היבט של מצבו הפיננסי של הלקוח ואינם משלבים השקעות ספציפיות שלקוחות מחזיקים במקומות אחרים. לפרטים נוספים, ראו Giraffe Invest's`,
    'footer_text14': `לא הצעה, שידול להצעה או ייעוץ לקנייה או מכירה של ניירות ערך בתחומי שיפוט שבהם Giraffe Invest, Inc. אינה רשומה.`,
    'footer_text15': `מי מספק את נתוני השוק?`,
    'footer_text16': `אתם צופים בנכס אינטרנטי הממוקם ב`,
    'footer_text17': `נכסים שונים עשויים להיות מסופקים על ידי גורם אחר עם תקני שיווק שונים.`,
    'footer_text18': `מוצרים מסוימים מסופקים באמצעות שותפות עם Standard & Poor's.`,
    'footer_text19': `מידע נוסף`,
    'footer_text20': `תנאי שימוש`,
    'footer_text21': `מדיניות הפרטיות`,

    /* ISRAEL FOOTER */
    'il_footer_text1': `אנחנו כאן כדי לעזור!`,
    'il_footer_text2': `שאלות נפוצות באתר`,
    'il_footer_text3': `שלחו לנו הודעה, ואנו נגיב תוך 24 שעות כדי לספק את התמיכה והסיוע שאתם צריכים.`,
    'il_footer_text4': `שירותי ניהול התיקים במסגרת פלטפורמה זו ניתנים על ידי ג׳ירף פינטק בע״מ (״ג׳ירף״) `,
    'il_footer_text5': `בעלת רישיון מכוח חוק הסדרת העיסוק בייעוץ השקעות, בשיווק השקעות ובניהול תיקי השקעות,תשנ״ה-1995. `,
    'il_footer_text6': `אם תבחר להשקיע עם ג'ירף, אנא עיין בדף שלנו`,
    'il_footer_text7': `הסכם ניהול תיקים`,
    'il_footer_text8': `בזהירות, מכיוון שהוא מכיל מונחים חשובים.`,
    'il_footer_text9': `לפני ההשקעה, שקול את יעדי ההשקעה שלך ו`,
    'il_footer_text10': `עמלות של ג'ירפה`,
    'il_footer_text11': `תנאי שימוש`,
    'il_footer_text12': `מדיניות פרטיות `,
    'il_footer_text13': `דיסקליימר`,
};

export default Footer;