//default props
import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
//helper message
import IntlMessages from 'helper/IntlMessages';
//import currencyFormatter
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
//types props
import * as BasketType from "api/APIMaster/BasketType";
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';
// chart option
import { AreaChartOptions } from 'config/ChartOptions';

export type Props = {
    investmentDetailGraph: BasketType.Sp500ReturnOnInvestmentResponseModel[];
}

const AreaChartPortfolio: React.FC<Props> = (props: Props) => {
    const { investmentDetailGraph } = props;  //destructing props
    const [selectedYear, setSelectedYear] = React.useState<number>(10);

    const locale = useSelector(getLocale);


    const yearObject = [
        { value: 10, label: IntlMessages('years_number', { number: '10' }) },
        { value: 20, label: IntlMessages('years_number', { number: '20' }) },
        { value: 30, label: IntlMessages('years_number', { number: '30' }) },
    ];

    // handle year selection
    const onHandleYear = (e: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
        const selectedYear = e.currentTarget.getAttribute('data-value')
        setSelectedYear(Number(selectedYear));
    };

    React.useEffect(() => {
        const updateOption = {
            title: {
                text: OtherTranslations[locale.language].inThousands,
                align: locale.direction === 'rtl' ? 'right' : 'left',
                style: {
                    ...AreaChartOptions.title?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            },
            tooltip: {
                ...AreaChartOptions.tooltip,
                style: {
                    ...AreaChartOptions.tooltip?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            }
        }
        ApexCharts.exec('area-chart-option', 'updateOptions', { ...updateOption }, false, false, false);
        ApexCharts.exec('area-chart-option', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')
    }, [])

    const seriesData = investmentDetailGraph.filter((e) => {
        return e.period === selectedYear
    })[0]

    const series: ApexCharts.ApexOptions['series'] = [
        {
            name: OtherTranslations[locale.language].portfolio,
            data: seriesData.historicPerformance.map((e) => ({
                x: new Date(e.date),
                y: e.performance,
            }))
        },
    ];

    return (
        <div className="row" >
            <div className="col-12">
                <div className="gift-invest-card px-3 py-lg-5 pt-5 pb-4">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-12">
                                    {/* graph range */}
                                    <div className="row">
                                        {/* static text */}
                                        <div className="col-12">
                                            <div className="gift-invest-price-text d-block d-md-none mt-2 mb-4 pb-2">
                                                <span> {IntlMessages('portfolio_text4')}  </span>
                                                <p>{IntlMessages('portfolio_text7')}</p>
                                            </div>
                                        </div>
                                        {/* graph range */}
                                        <div className="col-12">
                                            <div className="gift-invest-header ps-lg-3 ps-0 pb-2">
                                                <ul className="d-flex flex-wrap py-2 mt-1">
                                                    <li>
                                                        <span className="d-flex align-items-center h-100">
                                                            {IntlMessages('portfolio_year_back')}
                                                        </span>
                                                    </li>
                                                    {yearObject.map((e, i) => (
                                                        <li
                                                            className={`${selectedYear === e.value ? 'active' : ''}`}
                                                            onClick={onHandleYear}
                                                            data-value={e.value}
                                                            data-testid={e.value}
                                                            key={i}
                                                        >
                                                            <a data-value={e.value}>{e.label}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* graph & data point*/}
                                    <div className="row gx-5">
                                        {/* graph */}
                                        <div className="col-md-6">
                                            <div className="gift-invest-graph">
                                                <ReactApexChart options={AreaChartOptions} series={series} type="area" height={255} dir='ltr' />
                                            </div>
                                        </div>
                                        {/* data point */}
                                        <div className="col-md-6">
                                            <div className="gift-invest-text">
                                                <div className="gift-invest-text-heading d-none d-md-block">
                                                    {IntlMessages('portfolio_text7')}
                                                </div>
                                                <div className="gift-invest-text-price mt-3 mb-5">
                                                    <ul className="py-1">
                                                        <li>
                                                            <span>
                                                                {IntlMessages('portfolio_original')}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <strong>
                                                                {currencyFormatter(seriesData.originalInvestment).replace('.00', '')}
                                                            </strong>
                                                        </li>
                                                    </ul>
                                                    <ul className="py-1">
                                                        <li><span>{IntlMessages('portfolio_aggregated')}</span></li>
                                                        <li><strong>{seriesData.aggregatedReturn.toLocaleString('en-US')}%</strong></li>
                                                    </ul>
                                                    <ul className="py-1">
                                                        <li><span>{IntlMessages('portfolio_average')}</span></li>
                                                        <li><strong>{seriesData.averageAnnualReturn.toLocaleString('en-US')}%</strong></li>
                                                    </ul>
                                                    <ul className="py-1">
                                                        <li><span>{IntlMessages('portfolio_return_over', { year: selectedYear })}</span></li>
                                                        <li><strong>{currencyFormatter(seriesData.returnOnInvestment).replace('.00', '')}</strong></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AreaChartPortfolio;

























