//default library
import * as React from 'react'
import Modal from "react-bootstrap/Modal";
// type import
import * as PropsFunction from 'interface/PropsFunction';
// lottie animation
import lottie from 'lottie-web/build/player/lottie_light'
// loading animation
import LoadingAnimation from 'service/animation/Loading.json'

const animationStyle = {
  height: 295,
  width: 250,
  margin: '20px auto',
}

export type Props = {
  openModal: boolean;
  onHandleClose: PropsFunction.SimpleFunction;
}

const LoadingComponent2: React.FC<Props> = (props: Props) => {

  const { openModal, onHandleClose } = props;

  const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

  // load lottie animation
  React.useEffect(() => {
    lottie.loadAnimation({
      container: lottieAnimation.current as any,
      renderer: 'svg',
      animationData: LoadingAnimation,
      loop: true, // Set to true if you want the animation to loop
      autoplay: true, // Set to true if you want the animation to play automatically
    });

  }, [openModal])

  return (
    <Modal
      centered
      size='sm'
      show={openModal}
      onHide={onHandleClose}
      backdrop="static"
      keyboard={false}
      className={`gi-ob-white-bottom-curve enter-code-modal`}
    >
      <div ref={lottieAnimation} style={animationStyle} />
    </Modal>
  )
}

export default LoadingComponent2