import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from "react-router-dom";
// react redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistedStore } from 'reduxStore/store';
// auth0 
import Auth0WithHistory from 'helper/Auth0WithHistory';

// datadog
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import TagManager from 'react-gtm-module'
// main app
import App from '@/App';
// theme selector
import ThemeSelector from 'themes/ThemeSelector';

// google tag manager
if (JSON.parse(import.meta.env.REACT_APP_GOOGLE_TAG_MANAGER)) {
  const tagManagerArgs = {
    gtmId: 'GTM-MNWSBM3'
  }
  TagManager.initialize(tagManagerArgs)
}

// datadog 
if (JSON.parse(import.meta.env.REACT_APP_DATADOGS)) {
  const releaseVersion = '1.3.0';
  const applicationId = '3959edc8-2e47-4b91-a317-04de08e52e12';
  const clientToken = 'pub5697dc27da38f7bdedc24cfaf206e4c3';
  const service = 'giraffeinvest-user';

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: 'datadoghq.com',
    service: service,
    env: import.meta.env.REACT_APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: releaseVersion,
    sampleRate: navigator.userAgent.includes('DatadogSynthetics') ? 0 : 100,
    sessionReplaySampleRate: 100,
    premiumSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  JSON.parse(import.meta.env.REACT_APP_SESSION_REPLAY) && datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: service,
    env: import.meta.env.REACT_APP_ENVIRONMENT,
    version: releaseVersion,
    forwardConsoleLogs: ['error']
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <Router>
        <ThemeSelector>
          <Auth0WithHistory>
            <App />
          </Auth0WithHistory>
        </ThemeSelector>
      </Router>
    </PersistGate>
  </Provider>,
)
