// default library
import * as React from 'react';
// intl message
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    divClassName: string;
    labelClassName: string;
    strongClassName: string;
    labelText: string;
    strongText: string;
}

const LabelValueField: React.FC<Props> = (props: Props) => {
    const { divClassName, labelClassName, labelText, strongClassName, strongText } = props;
    return (
        <div className={divClassName}>
            <label className={`${labelClassName} text-start ltr`}>{IntlMessages(labelText)}</label>
            {strongText && <strong className={strongClassName}>{strongText}</strong>}
        </div >
    )
}

export default LabelValueField