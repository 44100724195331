// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
//regex from number
import { ONLY_NUMBER } from "config/Config";
// custom function
import { getCurrencySymbol } from 'service/utils/GetCurrencySymbol';
import { getNumberSuffix } from 'service/utils/GetNumberSuffix';
// api call
import * as UserService from 'middleware/UserService';
// custom function
import { openURL } from "service/utils/DownloadFile";
//types props
import * as PropsFunction from "interface/PropsFunction";
import * as DashboardType from 'api/APIMaster/DashboardType';
// custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import InfoModalIL from 'components/ModalComponent/InfoModalIL';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    openModal: boolean;
    organizationName: string | null;
    defaultCurrency: string | null;
    getFundLocation: DashboardType.GetFundLocationsData;
    deductionDate: number;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
}


const MonthlySavingSetupModal: React.FC<Props> = (props: Props) => {
    const { openModal, organizationName, defaultCurrency, getFundLocation, deductionDate, onHandleClose, onHandleSuccess } = props;

    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [savingSetUpModal, setSavingSetUpModal] = React.useState<boolean>(false); // monthly saving modal
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal
    const [infoModal, setInfoModal] = React.useState<boolean>(false); //info modal

    const [infoModalId, setInfoModalId] = React.useState<string>(''); //info modal id

    const [savingPortfolioList, setSavingPortfolioList] = React.useState<PropsFunction.Option>([]); //portfolio list

    const [savingsAmount, setSavingsAmount] = React.useState<string>(''); //monthly saving amount
    const [savingsAmountError, setSavingsAmountError] = React.useState<boolean>(false); //monthly saving amount error
    const [savingPortfolio, setSavingPortfolio] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //portfolio
    const [isTermAgreed, setIsTermAgreed] = React.useState<boolean>(false); //term checkbox

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the 

    const amountInfoText: JSX.Element[] = [
        <>
            <h5 className="mb-0">{IntlMessages('monthly_saving_amount_info_text1')}</h5>
            <p>{IntlMessages('monthly_saving_amount_info_text2', { date: getNumberSuffix(deductionDate) })}</p>
        </>
    ]

    const portfolioInfoText: JSX.Element[] = [
        <p>
            <strong> {IntlMessages('monthly_saving_portfolio_info_text1')} </strong>
            {IntlMessages('monthly_saving_portfolio_info_text2')}
        </p>
    ]

    React.useEffect(() => {
        setSavingSetUpModal(openModal);
        setLoadingModal(false);
        // reset the value of
        setSavingsAmount('');
        setSavingsAmountError(false);
        setSavingPortfolio(OnBoardingData.defaultOptionValue);
        setIsTermAgreed(false);
        setIsDisabled(true);

        // set dropdown data
        const portfolioList = getFundLocation
            .filter(e => e.type !== 'Bank Account')
            .map(e => {
                return {
                    value: e.id,
                    label: e.name,
                    active: e.type === 'Cash Account' ? true : false,
                }
            });

        setSavingPortfolioList(portfolioList);
    }, [openModal])

    // update the input value 
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        const value = fieldValue.replace(ONLY_NUMBER, '')
        setSavingsAmount(value);
        setSavingsAmountError(false);
    }

    // select value from dropdown
    const selectDropDownValue: PropsFunction.SelectedOption = (_fieldName, fieldValue) => {
        setSavingPortfolio(fieldValue);
    }

    //  updating input values in state
    const setInputValueCheckbox = (_fieldName: string, fieldValue: boolean): void => {
        setIsTermAgreed(fieldValue);
    }

    const openInfoModal = (e: React.MouseEvent): void => {
        const target = e.target as HTMLAnchorElement;
        setInfoModalId(target.id);
        setInfoModal(true);
    }

    // close modal
    const onHandleCloseFunction = (): void => {
        setLoadingModal(false);
        setInfoModal(false);
    }

    // setup monthly saving API call
    const setupSaving = async (): Promise<void> => {
        if (Number(savingsAmount) >= 200 && Number(savingsAmount) <= 5000) {
            setLoadingModal(true); //loading modal show
            setSavingSetUpModal(false);

            const data = {
                savingsAmount: Number(savingsAmount),
                basketId: !savingPortfolio.active ? savingPortfolio.value.toString() : null,
                forCashAccount: savingPortfolio.active,
                termsAgreed: isTermAgreed,
            }

            const accessToken = await initiateAction() //get access token
            const monthlySaving = await UserService.addMonthlySaving(data, accessToken);

            setLoadingModal(false); //loading modal hide

            if (monthlySaving.response || monthlySaving.request) {
                setIsError(true);
                setErrorLog(monthlySaving);
            } else {
                if (monthlySaving) {
                    onHandleSuccess();
                }
            }

        } else {
            setSavingsAmountError(true)
        }
    }

    // monthly saving agreement
    const monthlyAgreement = async (): Promise<void> => {
        setLoadingModal(true); //loading modal show
        setSavingSetUpModal(false);

        const accessToken = await initiateAction() //get access token
        const savingAgreement = await UserService.monthlySavingAgreement(savingsAmount, accessToken);

        setLoadingModal(false); //loading modal hide
        setSavingSetUpModal(true);

        if (savingAgreement.response || savingAgreement.request) {
            setIsError(true);
            setErrorLog(savingAgreement);
        } else {
            openURL(savingAgreement.agreementUrl, 'monthly-saving-agreement');
        }

    }

    React.useEffect(() => {
        if (!savingsAmount) {
            setIsDisabled(true);
        } else if (!savingPortfolio.value) {
            setIsDisabled(true);
        } else if (!isTermAgreed) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [savingsAmount, savingPortfolio, isTermAgreed])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    // user currency symbol
    const currencySymbol = getCurrencySymbol(defaultCurrency ?? '');

    return (
        <>
            <Modal
                centered
                size='sm'
                show={savingSetUpModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {/* modal header */}
                <Modal.Header className="py-4 mt-2 px-4 border-bottom-0">
                    <div className="setup-modal-header px-3">
                        <h5>{IntlMessages('monthly_saving_text1', { br: <br /> })}</h5>
                    </div>
                </Modal.Header>

                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>

                {/* modal content */}
                <Modal.Body className='gi-ob-withdraw-modal-body px-4 py-3'>
                    <div className="px-3 py-3">
                        <div className="row gy-4">
                            {/* organization name */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={"gi-setup-form-label mb-2"}
                                        labelText={'monthly_saving_organization_label'}
                                        className='check-icon'
                                        fieldValue={organizationName ?? ''}
                                        name={'organizationName'}
                                        placeholder={'placeholder_IL_first_name'}
                                        readOnly={true}
                                        disabled={false}
                                        isError={false}
                                        errorMessage={''}
                                        inputValue={setInputValue}
                                        dataTestId={'organization-name'}
                                    />
                                </div>
                            </div>
                            {/* amount */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <label className="gi-setup-form-label mb-2" htmlFor="firstAmmount">
                                        {IntlMessages('monthly_saving_amount_label', { br: <br /> })}
                                        <a id='amountInfo' onClick={openInfoModal}>
                                            <img id='amountInfo' src={intl.formatMessage({ id: 'INFO_GREEN_ICON' })} alt="INFO_GREEN_ICON" />
                                        </a>
                                    </label>
                                </div>
                                <div className={`gi-ob-form form-group gi-ob-price-input ${savingsAmountError ? 'gi-ob-price-input-error' : ''}`}>
                                    <InputTextComponent
                                        labelClassName={""}
                                        labelText={''}
                                        className=''
                                        fieldValue={savingsAmount}
                                        name={'savingsAmount'}
                                        placeholder={''}
                                        readOnly={false}
                                        disabled={false}
                                        errorMessage={''}
                                        isError={savingsAmountError}
                                        inputValue={setInputValue}
                                        dataTestId={'saving-amount'}
                                    />
                                    <span className="symbol">{currencySymbol}</span>
                                </div>
                                {savingsAmountError && (
                                    <div className="gi-ob-form form-group">
                                        <ul className="gi-ob-custom-multiple-error ps-0 mb-0 mt-2">
                                            <li className="invalid-error setup-invalid-error my-1">
                                                <span className="icon">
                                                    <img className="invalid-icon" src={intl.formatMessage({ id: 'UP_ARROW_RED_CIRCLE' })} alt="UP_ARROW_RED_CIRCLE" />
                                                </span>
                                                {IntlMessages('monthly_saving_amount_error_text1', { br: <br /> })}
                                                <span className="gi-ob-shackles">{currencySymbol}</span>{IntlMessages('monthly_saving_amount_error_text2')}
                                                <span className="gi-ob-shackles">{currencySymbol}</span>{IntlMessages('monthly_saving_amount_error_text3')}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {/* portfolio */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <label className="gi-setup-form-label-02 mb-2" htmlFor="brunchNumber">
                                        {IntlMessages('monthly_saving_portfolio', { br: <br /> })}
                                        <a id='portfolioInfo' onClick={openInfoModal}>
                                            <img id='portfolioInfo' src={intl.formatMessage({ id: 'INFO_GREEN_ICON' })} alt="INFO_GREEN_ICON" />
                                        </a>
                                    </label>
                                    <SelectDropdown
                                        labelClassName={''}
                                        labelText={''}
                                        name={'savingPortfolio'}
                                        placeholder={'placeholder_dropdown_select'}
                                        option={savingPortfolioList}
                                        fieldValue={savingPortfolio}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'saving-portfolio'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                            </div>
                            {/* term check box */}
                            <div className="col-12">
                                <div className="gi-setup-form-check form-check mt-2 pb-2">
                                    <InputCheckboxComponent
                                        labelText={''}
                                        labelClass={''}
                                        fieldName={'isTermAgreed'}
                                        fieldValue={'isTermAgreed'}
                                        checkValue={isTermAgreed}
                                        inputClass={'form-check-input mt-1'}
                                        inputValue={setInputValueCheckbox}
                                        dataTestId={'term-agreed'}
                                    />
                                    <label className="gi-setup-form-check-label form-check-label mt-1" htmlFor="source-check-01">
                                        {IntlMessages('monthly_saving_term_text1')}
                                        <br />
                                        <a href="#!" onClick={monthlyAgreement}>
                                            {IntlMessages('monthly_saving_term_text2')}
                                        </a>
                                    </label>
                                </div>
                            </div>
                            {/* button */}
                            <div className="col-12">
                                <div className="pt-3 pb-4 my-2 d-flex align-items-center justify-content-center gap-2">
                                    {/* back button */}
                                    <button type="button" className="gi-ob-cancel-btn" onClick={onHandleClose}>
                                        {IntlMessages('button_back')}
                                    </button>
                                    {/* submit button */}
                                    <button type="button" className="gi-ob-withdraw-btn" disabled={isDisabled} onClick={setupSaving}>
                                        {IntlMessages('button_submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />

            {/* info modal  */}
            <InfoModalIL
                openModal={infoModal}
                messageBody={infoModalId === 'amountInfo' ? amountInfoText : infoModalId === 'portfolioInfo' ? portfolioInfoText : []}
                dialogClassName={'px-2'}
                bodyClassName={'p-4 mt-2 mb-3'}
                divClassName={`gi-setup-modal-text-success mt-2 px-3 mb-3 ${infoModalId === 'amountInfo' ? 'amount-info' : ''}`}
                onHandleClose={onHandleCloseFunction}
            />
        </>
    )
}

export default MonthlySavingSetupModal