// default library
import * as React from 'react';
import Select from 'react-select';
// intl
import { useIntl } from 'react-intl';
//types import
import * as OnBoarding from "interface/OnBoarding";

export type Props = {
    name: string,
    placeholder: string,
    ariaLabel: string
    option: OnBoarding.OptionData[],
    fieldValue: OnBoarding.OptionData,
    inputValue: OnBoarding.SelectedOption
}

const SelectOnBoarding: React.FC<Props> = (props: Props) => {

    const { name, placeholder, option, fieldValue, inputValue, ariaLabel } = props; //destructuring props
    const intl = useIntl();

    const handleOnChange = (e: any) => {
        inputValue(e);
    };

    return (
        <div className='custom-react-dropdown-kyc'>
            <Select
                name={name}
                id={name}
                classNamePrefix='react-select'
                options={option}
                aria-label={ariaLabel}
                onChange={handleOnChange}
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
                value={fieldValue.value ? fieldValue : ''}
                components={{ IndicatorSeparator: () => null }}
            />
        </div>
    )
}

export default SelectOnBoarding