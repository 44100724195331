// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from 'api/APIMaster/UserType';
// message import
import IntlMessages from 'helper/IntlMessages';
//custom component
import DetailButton from 'components/ButtonComponent/DetailButton';
import BasketRenameModal from 'components/ModalComponent/BasketRenameModal';
import SvgIcon from 'components/CommonComponent/SvgIcon';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';


export type Props = {
    currentBasket: DashboardType.BasketSummaryResponseModel;
    monthlySaving: UserType.MonthlySaving | null;
    changeBasketName: PropsFunction.ParamsIdStringFunction;
    renameBasket: PropsFunction.ParamsIdStringFunction;
    setBuyOpenModal: PropsFunction.ParamsIdNumberFunction;
    openInfoModal: PropsFunction.OpenInfoModal;
    infoMonthly: PropsFunction.SimpleFunction;
}

const HomeBasketCard: React.FC<Props> = (props: Props) => {

    const { currentBasket, monthlySaving, changeBasketName, renameBasket, setBuyOpenModal, openInfoModal, infoMonthly } = props;
    const history = useHistory();

    const [basketName, setBasketName] = React.useState<string>('');

    const basketNameChange = (_fieldName: string, fieldValue: string): void => {
        setBasketName(fieldValue);
        changeBasketName(fieldValue);
    }

    const clickEarning = (): void => {
        const textHeading = IntlMessages('info_earning_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_earning_body')}</p>
        openInfoModal(textHeading, messageBody, false);
    }

    const clickPortfolioValue = (): void => {
        const textHeading = IntlMessages('info_portfolio_basket_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_portfolio_basket_body')}</p>
        openInfoModal(textHeading, messageBody, false);
    }

    const gotoBasket = (event: React.MouseEvent): void => {
        const id = (event.target as HTMLAnchorElement).id;
        history.push(`/dashboard/portfolio/${id}`)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-dash-ao-retirement-card mt-md-2 mt-1">
                    {/* basket name, status, btn */}
                    <div className="gi-dash-ao-retirement-header d-flex align-items-center ps-lg-5 pe-lg-4 py-lg-2 px-3 py-2">
                        <div className="row gx-lg-0 gx-2 w-100">
                            {/* name */}
                            <div className="col-lg-5 col-6">
                                <div className="gi-dash-ao-retirement-heading d-flex align-items-center h-100">
                                    <h5>{currentBasket.name}:</h5>
                                </div>
                            </div>
                            {/* status, btn */}
                            <div className="col-lg-7 col-6">
                                <div className="row">
                                    {/* status */}
                                    <div className="col-lg-5 col-10">
                                        <div className="gi-dash-ao-retirement-settlement d-flex align-items-center text-nowrap h-100">
                                            {currentBasket.isPending && (
                                                <a className="d-flex align-items-center gap-2" id={currentBasket.id.toString()} onClick={gotoBasket}>
                                                    <SvgIcon iconId='notification' width={15} height={17} />
                                                    {IntlMessages('transaction_in_settlement')}
                                                </a>
                                            )}
                                            {(monthlySaving && (currentBasket.id === monthlySaving.basketId)) && (
                                                <a className="d-flex align-items-center gap-2 fst-normal" id={currentBasket.id.toString()} onClick={infoMonthly}>
                                                    <SvgIcon iconId='user-icon' width={13} height={13} />
                                                    {IntlMessages('home_monthly_saving')}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    {/* btn */}
                                    <div className="col-lg-7 col-2">
                                        <div className="gi-dash-ao-retirement-btn-group d-flex justify-content-end gap-2 h-100">
                                            <DetailButton redirectId={currentBasket.id} isMobile={false} addFundButton={setBuyOpenModal} />

                                            <BasketRenameModal
                                                basketName={basketName}
                                                identifier={currentBasket.identifier}
                                                changeBasketName={basketNameChange}
                                                renameBasket={renameBasket}
                                                basketID={currentBasket.id}
                                                showEdit={currentBasket?.isInvestmentPending}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gi-dash-ao-retirement-body px-lg-5 px-3 py-lg-4 py-0 pb-4">
                        <div className="gi-dash-ao-retirement-btn-group d-flex gap-2 justify-content-end py-2 mb-4 py-sm-0 mb-sm-0">
                            <DetailButton redirectId={currentBasket.id} isMobile={true} addFundButton={setBuyOpenModal} />
                        </div>
                        <div className="row gx-4 gy-3">
                            <div className="col-lg-5 col-6">
                                <div className="gi-dash-ao-retirement-value-text">
                                    <h6 className="d-flex align-items-center gap-2 pb-lg-3 pb-0">
                                        {IntlMessages('portfolio_value')}
                                        <a className="d-flex align-items-center" onClick={clickPortfolioValue}>
                                            <SvgIcon iconId='info-icon' width={16} height={16} />
                                        </a>
                                    </h6>
                                    <h5 className="pt-lg-2 pt-1 ltr">{currencyFormatter(currentBasket.currentValue)}</h5>
                                </div>
                            </div>
                            <div className="col-lg-7 col-6">
                                <div className="gi-dash-ao-retirement-value-text">
                                    <h6 className="d-flex align-items-center gap-2 pb-lg-3 pb-0">
                                        {IntlMessages('transaction_earning')}
                                        <a className="d-flex align-items-center" onClick={clickEarning}>
                                            <SvgIcon iconId='info-icon' width={16} height={16} />
                                        </a>
                                    </h6>
                                    <h5 className="pt-lg-2 pt-1 ltr">{currencyFormatter(currentBasket.totalGain)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeBasketCard;
