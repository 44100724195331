//default library
import * as React from 'react';
// gsap animation
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
//helper message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//types props
import * as BasketType from "api/APIMaster/BasketType"
import * as PropsFunction from "interface/PropsFunction"
import * as Dashboard from 'interface/Dashboard'
//custom component
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import PieChartPortfolio from 'components/ChartSection/PieChartPortfolio';

gsap.registerPlugin(ScrollTrigger);

//defining props
export type Props = {
    resetUserInput: boolean;
    excludedCompanyInput: string;
    loadAnimation: boolean;
    headingText: string;
    selectedEsgcValueList: BasketType.GiftEsgcValueRequestModel[];
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
    companyCount: Dashboard.CompanyCountData;
    nextClick: PropsFunction.SimpleFunction;
    openReadMoreModal: PropsFunction.SimpleFunction;
    setCompanyName: PropsFunction.ParamsIdStringFunction;
    openUserExclusionModal: PropsFunction.SimpleFunction;
    selectRemoveEsgcValue: PropsFunction.ParamsIdStringFunction;
    openAdvanceModal: PropsFunction.ParamsIdStringFunction;
    openCompanyTableModal: PropsFunction.ParamEventFunction;
}

const SelectEsgcPortfolioDesktop: React.FC<Props> = (props: Props) => {
    const { nextClick, openReadMoreModal, setCompanyName, openUserExclusionModal, selectRemoveEsgcValue, openAdvanceModal, openCompanyTableModal, selectedEsgcValueList, esgcValueList, companyCount, resetUserInput, excludedCompanyInput, loadAnimation, headingText } = props //destructuring props

    const textOneRef = React.useRef<HTMLDivElement>(null);
    const intl = useIntl();

    //gsap scrolling
    React.useEffect(() => {
        const textOne = textOneRef.current as HTMLDivElement;

        const matchMedia = gsap.matchMedia();
        matchMedia.add('(min-width: 991.98px)', () => {
            gsap.to(textOne, {
                y: 512 - textOne.clientHeight - 32,
                scrollTrigger: {
                    trigger: '#sectionOne',
                    pin: '#sectionOne',
                    scrub: true,
                    start: 'top top',
                    end: '+=700px',
                    markers: false,
                },
            });
        });

        return () => {
            ScrollTrigger.getAll().forEach((t: { kill: () => any; }) => t.kill());
        };
    }, []);

    // company name for user exclusion
    const setCompanyNameHandler = (_name: string, value: string): void => {
        setCompanyName(value);
    };

    // select and remove ESG-C value
    const selectEsgcValueHandler = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (!(e.target as HTMLElement).closest(".gift-p-advance-btn")) {
            const closestGreenValueMain = (e.target as HTMLElement).closest(".gift-p-green-value-main");
            if (closestGreenValueMain) {
                const id = closestGreenValueMain.id;
                selectRemoveEsgcValue(id);
            }
        }
    };
    //open Advance modal
    const openAdvanceModalHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
        openAdvanceModal(e.currentTarget.id);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="gift-p-green-card px-sm-0 px-3">
                    {/*  */}
                    <div className="row">
                        <div className="col-12">
                            <div className="gift-p-green-header px-5 py-4">
                                <h5 className="d-flex align-items-center gap-3">
                                    {IntlMessages(headingText)}
                                    <a className="d-flex align-items-center justify-content-center mt-2 rounded-circle" onClick={openReadMoreModal}>
                                        <img src={intl.formatMessage({ id: 'INFO_ICON_PORTFOLIO' })} alt="INFO_ICON_PORTFOLIO" />
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="row gx-0">
                        {/*  */}
                        <div className="col-lg-6 order-lg-0 order-1">
                            <div className="gift-p-green-value-card">
                                {/* static text */}
                                <div className="gift-p-green-value-header d-flex align-items-center gap-2 px-sm-5 py-3 px-0 position-relative">
                                    {IntlMessages('portfolio_text3')}
                                    <a className="d-inline-flex d-sm-none" onClick={openReadMoreModal}>
                                        <img src={intl.formatMessage({ id: 'INFO_ICON_PORTFOLIO' })} alt="" />
                                    </a>
                                </div>
                                {/* ESG-C value list, user-exclusion */}
                                <div className="gift-p-green-value-body">
                                    <div className="gift-p-green-value-scroll" ref={textOneRef}>
                                        {/* ESG-C value list */}
                                        <div className="gift-p-green-value-ul">
                                            {esgcValueList.map((e, i) => {
                                                const valueSelect = selectedEsgcValueList.find((a) => a.esgcValueIdentifier === e.identifier);
                                                return (
                                                    <div
                                                        className={`gift-p-green-value-main d-flex align-items-center justify-content-between px-sm-5 py-sm-3 px-2 py-2 ${valueSelect ? (valueSelect.deepClean || valueSelect.addGoodCompanies) ? 'advance-active deep-clean-active' : 'advance-active clean-active' : ''}`}
                                                        onClick={selectEsgcValueHandler}
                                                        id={e.identifier}
                                                        key={i}
                                                        data-testid='check-double-check-icon'
                                                    >
                                                        <div className="gift-p-green-value-start d-flex align-items-center gap-3">
                                                            <div className="gift-p-green-value-img">
                                                                <img src={e.icon} className="img-fluid" alt="" />
                                                            </div>
                                                            <div className="gift-p-green-value-text">{e.name}</div>
                                                        </div>
                                                        <div className="gift-p-green-value-end">
                                                            <div className="gift-p-green-value-button d-flex align-items-center gap-2">
                                                                <button className="gift-p-single-check-btn double-active" type="button"></button>
                                                                <button
                                                                    className={`gift-p-advance-btn ${(e.deepCleanExclusionList && e.deepCleanExclusionList.length > 0 || e.inclusionList.length > 0) ? '' : 'invisible'}`}
                                                                    type="button"
                                                                    id={e.identifier}
                                                                    onClick={openAdvanceModalHandler}
                                                                    data-testid="select-esgc-value"
                                                                >
                                                                    {IntlMessages('button_advanced')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                );
                                            })}

                                            {/* User Excluded companies */}
                                            <div className="gift-p-green-chose-card px-sm-5 px-0 pt-4 mt-1">
                                                <div className="gift-p-green-chose-text">
                                                    <p className="mb-3 pb-1">
                                                        <strong className="d-block">{IntlMessages('portfolio_text5')}</strong> {IntlMessages('portfolio_text6', { br: <br /> })}
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-9">
                                                        <div className="gift-p-green-chose-form d-flex align-items-center gap-2">
                                                            {resetUserInput && (
                                                                <InputTextComponent
                                                                    labelClassName={''}
                                                                    labelText={''}
                                                                    name={'openUserExclusion'}
                                                                    placeholder={'portfolio_placeholder_company'}
                                                                    className={'form-control'}
                                                                    fieldValue={excludedCompanyInput}
                                                                    inputValue={setCompanyNameHandler}
                                                                    readOnly={false}
                                                                    disabled={false}
                                                                    dataTestId={'excluded-company-input'}
                                                                    isError={false}
                                                                    errorMessage={''}
                                                                />
                                                            )}
                                                            <button className="rounded-circle" type="button" onClick={openUserExclusionModal} disabled={excludedCompanyInput.length === 0} data-testid="add-excluded-company-button">
                                                                <span data-testid="arrow-button">
                                                                    <img src={intl.formatMessage({ id: 'WHITE_ARROW_RIGHT' })} className="white-arrow-icon" alt="" />
                                                                    <img src={intl.formatMessage({ id: 'GREEN_ARROW_RIGHT' })} className="green-arrow-icon" alt="" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* pie chart */}
                        <div className="col-lg-6 order-lg-1 order-0">
                            <PieChartPortfolio nextClick={nextClick} openCompanyTable={openCompanyTableModal} companyCount={companyCount} loadAnimation={loadAnimation} nextDisabled={!(selectedEsgcValueList.length > 0)} />
                        </div>
                    </div>
                    {/*  */}
                </div>
            </div>
        </div>
    );
};

export default SelectEsgcPortfolioDesktop;