// default library
import * as React from 'react';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// intl message
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    headingLabel: PropsFunction.IntlMessages | string;
    editData: string;
    editClick: PropsFunction.ParamsIdStringFunction;
}

const ListHeading: React.FC<Props> = (props: Props) => {

    const { headingLabel, editData, editClick } = props;

    // edit button click 
    const editButtonClick = () => {
        editClick(editData)
    }

    return (
        <div className="gi-d-ac-heading-main">
            <div className="gi-d-ac-heading py-2">
                <h5>
                    {typeof (headingLabel) === 'object'
                        ? IntlMessages(...headingLabel)
                        : IntlMessages(headingLabel)
                    }
                </h5>
            </div>
            {editData && (
                <div className="gi-d-ac-heading py-2">
                    <a href="#!" onClick={editButtonClick}>
                        {IntlMessages('button_edit')}
                    </a>
                </div>
            )}
        </div>
    )
}

export default ListHeading