//default library
import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
//image import
import { useIntl } from 'react-intl';
//helper message
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    openSuccessModal: boolean;
    successMessage: string;
    onHandleClose: PropsFunction.SimpleFunction;
}

const GiftRedemptionSuccessModal: React.FC<Props> = (props: Props) => {

    const { openSuccessModal, successMessage, onHandleClose } = props
    const intl = useIntl();

    return (
        <Modal
            size='xl'
            centered
            show={openSuccessModal}
            backdrop="static"
            keyboard={false}
            className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal"
        >
            <Modal.Body className='p-xl-4 p-3'>
                <button className="btn-close-white p-0 border-0 position-absolute opacity-100" type="button" onClick={onHandleClose} data-testid='close-icon'></button>

                <div className="p-xl-2 p-0">
                    <div className="gi-g-new-transaction-card d-flex align-items-center justify-content-center">
                        <div className="gi-g-new-transaction-text text-center px-3">
                            <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="GIRAFFE_MODAL_ICON" />
                            <h5>
                                {IntlMessages(successMessage)}
                            </h5>
                            <button className={`gi-d-ok-btn mt-3`} type="button" onClick={onHandleClose} data-testid='close-text'>
                                {IntlMessages('button_ok')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GiftRedemptionSuccessModal;
