// default library
import * as React from 'react';
// intl message
import { useIntl } from 'react-intl';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from "src/interface/PropsFunction";
// custom component
import ErrorKYCIsrael from 'components/ErrorComponent/ErrorKYCIsrael';

export type Props = {
    labelText: string;
    fieldValue: string;
    name: string;
    className: string;
    placeholder: string;
    errorMessage: string;
    dataTestId: string;
    inputValue: PropsFunction.PropsInputValueFunction;
    [key: string]: any
}

const TextAreaComponent3: React.FC<Props> = (props: Props) => {

    const { labelText, fieldValue, name, className, placeholder, errorMessage, dataTestId, inputValue, ...res } = props;

    const intl = useIntl();

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);// Call the inputValue function with the updated value
    };

    return (
        <>
            {labelText && <label>{IntlMessages(labelText)}</label>}
            <textarea
                name={name}
                className={`form-control ${className} ${errorMessage ? 'form-error' : ''}`}
                id={name}
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
                value={fieldValue}
                data-testid={dataTestId}
                onChange={handleOnChange}
                {...res}
            />
            {errorMessage && <ErrorKYCIsrael message={errorMessage} fieldName={name} />}
        </>
    );
};

export default TextAreaComponent3;
