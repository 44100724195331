//default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//message provider
import IntlMessages from 'helper/IntlMessages';
//image
import { useIntl } from 'react-intl'
//type props
import * as PropsFunction from 'interface/PropsFunction'
import * as Dashboard from 'interface/Dashboard';
//custom component
import WeekChangeSection from 'components/SectionComponent/WeekChangeSection';

export type Props = {
    userVideoList: Dashboard.UserVideoListData,
    scheduleSection: Dashboard.ScheduleSection,
    forWeek: PropsFunction.ParamsIdStringFunction,
    playVideo: PropsFunction.ParamsIdStringFunction
}

const CurrentWeekSection: React.FC<Props> = (props: Props) => {
    const { userVideoList, scheduleSection, forWeek, playVideo } = props;

    const intl = useIntl();
    const history = useHistory()

    //redirect to playlist calender
    const handleViewListPage = (): void => {
        history.push("financial-playlist-list")
    };

    //handle play video function
    const handlePlayVideo = (e: React.MouseEvent<HTMLDivElement>): void => {
        const target = (e.currentTarget as HTMLElement).id;
        playVideo(target);

    };
    //handle for week function
    const handleForWeek = (selectedWeek: string): void => {
        forWeek(selectedWeek);
    };

    return (
        <>
            {/* giraffe calender icon */}
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-full-view d-flex align-items-center justify-content-end px-3 py-2 mb-2">
                        <a className="d-flex align-items-center gap-2" type="button" onClick={handleViewListPage} data-testid='handle-view-list'>
                            {IntlMessages('giraffe_learn_list_topics')}
                            <span className="gi-g-calendar-icon d-block" data-testid="calender-icon"></span>
                        </a>
                    </div>
                </div>
            </div>
            {/* video list */}
            <div className="row">
                <div className="col-md-12">
                    <div className="gi-g-white-card p-lg-5 pb-lg-1 p-3 position-relative mb-2">
                        {/* feature video */}
                        {userVideoList.featuredVideo && (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gi-g-current-week mb-5 pb-2">
                                        <div className="gi-g-current-week-img" id={userVideoList.featuredVideo.identifier} onClick={handlePlayVideo} data-testid='featured-play-video'>
                                            <img src={userVideoList.featuredVideo.bannerImage} className="img-fluid w-100" alt="" />
                                            <a className="d-flex align-items-center justify-content-center m-auto rounded-circle" type="button" id={userVideoList.featuredVideo.identifier} data-testid='video-play'>
                                                <img src={intl.formatMessage({ id: 'BRIGHT_PLAY_ICON' })} alt="BRIGHT_PLAY_ICON" />
                                            </a>
                                            <div className="gi-g-current-heading" id={userVideoList.featuredVideo.identifier}>
                                                <h5>
                                                    {IntlMessages('week')} -
                                                    <span data-testid="current-video">
                                                        {userVideoList.forWeek < 10
                                                            ? `0${userVideoList.forWeek}`
                                                            : userVideoList.forWeek
                                                        }
                                                    </span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="gi-g-current-week-text pt-3" data-testid="current-week-test">
                                            <h5 className="mt-4">{userVideoList.featuredVideo.title}</h5>
                                            <p className="mt-3 pt-1">{userVideoList.featuredVideo.longDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* thumbnail video */}
                        <div className="row gx-5 gy-5">
                            {userVideoList.thumbnailVideo.length > 0 && userVideoList.thumbnailVideo.map((e) => (
                                <div className="col-sm-6" key={e.identifier}>
                                    <div className="gi-g-week-card pb-2">
                                        <div className="gi-g-week-card-img position-relative" id={e.identifier} onClick={handlePlayVideo} data-testid='thumbnail-play-video'>
                                            <img src={e.thumbnailImage} className='img-fluid w-100' alt="" />
                                            <a className="d-flex align-items-center justify-content-center m-auto rounded-circle" type='button' id={e.identifier}>
                                                <img src={intl.formatMessage({ id: 'BRIGHT_PLAY_ICON' })} alt="BRIGHT_PLAY_ICON" />
                                            </a>
                                        </div>
                                        <div className="gi-g-week-card-text pt-4 mt-1">
                                            <p data-testid='short-description'>{e.shortDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <WeekChangeSection
                                scheduleSection={scheduleSection}
                                forWeek={handleForWeek}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurrentWeekSection;

