import * as LearnType from "api/APIMaster/LearnType";
import * as Dashboard from "interface/Dashboard";

export const scheduleSectionData: Dashboard.ScheduleSection = {
    previousWeek: 0,
    nextWeek: 0,
    forWeek: 0
};

export const userContentVideoResponseListData: LearnType.UserContentVideoResponseList = {
    identifier: '',
    bannerImage: '',
    thumbnailImage: '',
    title: '',
    shortDescription: '',
    longDescription: '',
    featured: false,
    videoId: '',
    startFrom: 0,
    duration: 0,
    priority: 0,
    active: false,
    viewedTill: 0,
    fullyViewed: false,
    lastViewedDateTime: '',
    numberOfViews: 0,
    liked: false
};

export const userVideoListData: Dashboard.UserVideoListData = {
    featuredVideo: userContentVideoResponseListData,
    thumbnailVideo: [userContentVideoResponseListData],
    forWeek: 0
};

export const userCalenderContent = {
    identifier: '',
    forWeek: 0,
    title: '',
    userContentVideoResponseList: [userContentVideoResponseListData]
}

export const currentUserCalenderType = {
    section: '',
    current: false,
    userCalendarContentResponseList: [userCalenderContent]
}

export const userCalendarSection = {
    currentCalendar: currentUserCalenderType,
    userCalendar: [currentUserCalenderType],
}

export const userCalendarResponse = {
    locale: '',
    user: '',
    userCalendarSectionResponseList: userCalendarSection,
    userBadgeResponseList: []
}