//default library
import React from 'react'

// intl message library 
import { useIntl } from 'react-intl';

const LogoComponent: React.FC = () => {
    const intl = useIntl();

    return (
        <div className="gi-header-left">
            <img src={intl.formatMessage({ id: 'NEW_GIRAFFE_LOGO2' })} alt="" />
        </div>
    )
}

export default LogoComponent