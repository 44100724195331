// default library
import * as React from "react";
import { Modal } from "react-bootstrap";
// intl message library
import { useIntl } from "react-intl";
import IntlMessages from "helper/IntlMessages";
// custom hook
import { useLogoutHook } from "helper/Logout";

export type Props = {
    timeoutModalOpen: boolean;
};

const SessionTimeOutModal: React.FC<Props> = (props: Props) => {
    const { timeoutModalOpen } = props;
    const intl = useIntl();
    const logoutUser = useLogoutHook();

    // logout function
    const logoutFunction = (): void => {
        logoutUser();
    };

    return (
        <Modal
            centered
            show={timeoutModalOpen}
            backdrop="static"
            keyboard={false}
            className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal"
        >
            <Modal.Header className="px-3 py-2">
                <button className="btn-close p-0 m-0 border-0 opacity-100" type="button" onClick={logoutFunction} aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body className="px-5 pt-5 pb-4 mb-3">
                <div className="error-modal-body d-flex align-items-center justify-content-center">
                    <div className="error-modal-text">
                        <img className="session-img" src={intl.formatMessage({ id: "HOURGLASS_ANIMATION" })} alt="" />
                        <h5 className="mt-3 pt-2">{IntlMessages("session_timeout2")}</h5>
                        <p className="mt-4 pb-1">
                            {IntlMessages("session_timeout_t3", { br: <br /> })}
                            <br />
                            {IntlMessages("session_timeout_t4")}
                        </p>

                        <div className="text-center mt-4 pt-1">
                            <button className="contact-btn" type="button" onClick={logoutFunction}>
                                {IntlMessages("button_confirm")}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SessionTimeOutModal;
