// default library
import * as React from 'react';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from: number, to: number, step: number = 1): number[] => {
    let i = from;
    const rangeArr: number[] = [];
    while (i <= to) {
        rangeArr.push(i);
        i += step;
    }
    return rangeArr;
};

type Props = {
    totalRecords: number;
    pageLimit: number;
    pageNeighbors: number;
    onPageChanged: PropsFunction.OnPageChanged;
}

const PaginationNew: React.FC<Props> = (props: Props) => {
    const { totalRecords, pageLimit, pageNeighbors, onPageChanged, } = props
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(0);

    let _pageLimit: number = pageLimit;
    let _totalRecords: number = totalRecords;
    let _pageNeighbors: number = Math.max(0, Math.min(pageNeighbors, 2));

    React.useEffect(() => {
        _totalRecords = totalRecords;
        _pageLimit = pageLimit;
        _pageNeighbors = pageNeighbors;

        const totalPagesCount = Math.ceil(_totalRecords / _pageLimit);
        setTotalPages((_prev) => totalPagesCount);

        setCurrentPage(1);
        gotoPage(1);
    }, [totalRecords, pageLimit, pageNeighbors]);

    const gotoPage = (page: number): void => {
        const totalPagesCount = Math.ceil(_totalRecords / _pageLimit);
        const currentPageNum = Math.max(0, Math.min(page, totalPagesCount));
        setCurrentPage(currentPageNum);

        const paginationData: PropsFunction.PaginationData = {
            currentPage: currentPageNum,
            totalPages: totalPagesCount,
            pageLimit: _pageLimit,
            totalRecords: _totalRecords,
        };

        onPageChanged && onPageChanged(paginationData);
    };

    const handleClick = (evt: React.MouseEvent): void => {
        const page = Number((evt.target as HTMLAnchorElement).id)
        gotoPage(page);
    };

    const handleMoveLeft = (evt: React.MouseEvent): void => {
        evt.preventDefault();
        gotoPage(currentPage - _pageNeighbors);
    };

    const handleMoveRight = (evt: React.MouseEvent): void => {
        evt.preventDefault();
        gotoPage(currentPage + _pageNeighbors);
    };

    const fetchPageNumbers = (): (number | string)[] => {
        const totalPagesCount = totalPages;
        const pageNeighbors = _pageNeighbors + 1;

        const totalNumbers = pageNeighbors * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPagesCount > totalBlocks) {
            const startPage: number = Math.max(2, currentPage - pageNeighbors);
            const endPage: number = Math.min(totalPages - 1, currentPage + pageNeighbors);
            let pages: (number | string)[] = range(startPage, endPage);

            const hasLeftSpill: boolean = startPage > 2;
            const hasRightSpill: boolean = (totalPages - endPage) > 1;
            const spillOffset: number = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages: number[] = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages, totalPages]; // handle: < {5 6} [7] {8 9} (10)
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages: number[] = range(endPage + 1, endPage + spillOffset);
                    pages = [1, ...pages, ...extraPages, RIGHT_PAGE]; // handle: (1) {2 3} [4] {5 6} >
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]; // handle: < {4 5} [6] {7 8} >
                    break;
                }
            }

            return [...pages]; // handle: < {4 5} [6] {7 8} >
        }

        return range(1, totalPages);
    };

    if (!_totalRecords || totalPages === 1) return (
        <nav className="gi-p-pagination" aria-label="paginationNav">
            <ul className="pagination pagination-sm"></ul>
        </nav>
    );

    const pages = fetchPageNumbers();

    return (
        <>
            <nav className="gi-p-pagination pt-4" aria-label="paginationNav">
                <ul className="pagination pagination-sm justify-content-end position-relative pe-0">
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE) {
                            return (
                                <li key={index} className="page-item">
                                    <a className="page-link page-behind" onClick={handleMoveLeft} data-testid='previous-page'>
                                        {IntlMessages('previous')}
                                    </a>
                                </li>
                            );
                        } else if (page === RIGHT_PAGE) {
                            return (
                                <li key={index} className="page-item">
                                    <a className="page-link page-upcoming" onClick={handleMoveRight} data-testid='next-page'>
                                        {IntlMessages('next')}
                                    </a>
                                </li>
                            );
                        } else {
                            return (
                                <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <a className="page-link" onClick={handleClick} id={page.toString()} data-testid='goto-page'>
                                        {page}
                                    </a>
                                </li>
                            );
                        }
                    })}
                </ul>
            </nav>
        </>
    );
}

export default PaginationNew