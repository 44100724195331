import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
//types props
import * as BasketType from "api/APIMaster/BasketType";
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// chart option
import { LineChartOptions } from 'config/ChartOptions';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';

export type Props = {
    sp500GraphData: BasketType.MonthlyPerformanceData[];
}

const LineChartPortfolio: React.FC<Props> = (props: Props) => {
    const { sp500GraphData } = props;  //destructuring props

    const locale = useSelector(getLocale);

    React.useEffect(() => {
        const updateOption: ApexOptions = {
            title: {
                text: OtherTranslations[locale.language].accumulatedTitle,
                align: locale.direction === 'rtl' ? 'right' : 'left',
                style: {
                    ...LineChartOptions.title?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            },
            legend: {
                ...LineChartOptions.legend,
                horizontalAlign: locale.direction === 'rtl' ? 'right' : 'left',
                fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
            },
            tooltip: {
                ...LineChartOptions.tooltip,
                style: {
                    ...LineChartOptions.tooltip?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            }
        }
        ApexCharts.exec('line-chart-graph', 'updateOptions', { ...updateOption }, false, false, false);
        ApexCharts.exec('line-chart-graph', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')

    }, [])

    const series: ApexCharts.ApexOptions['series'] = [
        {
            name: OtherTranslations[locale.language].series1,
            data: sp500GraphData.map((e) => ({
                x: new Date(e.performanceDate),
                y: e.personalizedPerformance,
            })),
        },
        {
            name: OtherTranslations[locale.language].series2,
            data: sp500GraphData.map((e) => ({
                x: new Date(e.performanceDate),
                y: e.sp500Performance,
            })),
        },
    ];

    return (
        <div className="row">
            <ReactApexChart
                options={LineChartOptions}
                series={series}
                type="line"
                height={277}
                dir='ltr'
            />
        </div>
    );
};

export default LineChartPortfolio;
