//default library
import * as  React from 'react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
//api call
import * as DashboardService from "middleware/DashboardService"
//custom component
import PaginationNew from 'components/PaginationComponent/PaginationNew';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateFormatter } from 'service/utils/DateFormatter';
import { DateRange } from 'service/utils/DateFilter';
//default values
import { taxesDropdown } from 'config/DefaultValue';

export type Props = {
    advisoryFeesData: DashboardType.GetAccountFeesData
}

const AdvisoryFeesCard: React.FC<Props> = (props: Props) => {
    const { advisoryFeesData } = props;

    const [dataLoad, setDataLoad] = React.useState<boolean>(false);//loading flag
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});//error message

    const [totalAdvisoryFeesData, setTotalAdvisoryFeesData] = React.useState<DashboardType.GetAccountFeesData>([]);//advisoryFees data, date wise
    const [currentAdvisoryFeesData, setCurrentAdvisoryFeesData] = React.useState<DashboardType.GetAccountFeesData>([]);//current advisoryFees data

    const [selectedValue, setSelectedValue] = React.useState<PropsFunction.OptionData>(taxesDropdown[0]);

    const { initiateAction } = useAccessToken();

    React.useEffect(() => {
        if (totalAdvisoryFeesData.length === 0) {
            setTotalAdvisoryFeesData(advisoryFeesData);
            setDataLoad(true);
        }

    }, []);

    //page change function
    const onPageChanged: PropsFunction.OnPageChanged = (data): void => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentAdvisoryData = totalAdvisoryFeesData.slice(offset, offset + pageLimit);
        setCurrentAdvisoryFeesData(currentAdvisoryData);
    };

    //selected value function 
    const onSelectValue: PropsFunction.SelectedOption = async (_fieldName, fieldValue): Promise<void> => {
        setDataLoad(false);
        const dateRange = DateRange(fieldValue.value.toString());
        const accessToken = await initiateAction();
        const advisoryFeesData = await DashboardService.accountFeesDetails(dateRange, accessToken);

        if (advisoryFeesData.response) {
            setErrorLog(advisoryFeesData)
            setErrorAPI(true);
        }
        else {
            setTotalAdvisoryFeesData(advisoryFeesData);
            setDataLoad(true);
            setSelectedValue(fieldValue)
        }
    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {!dataLoad && <LoadingComponent />}
            <div className="row gx-20" data-testid="advisory-card">
                <div className="col-12">
                    <div className="px-10">
                        <div className="row">
                            <div className="col-sm-6 col-md-6">
                                <div className="gi-d-ac-select-dropdown d-flex align-items-center flex-wrap gap-2 my-4 py-2">
                                    <div><label>{IntlMessages('table_range')}:</label></div>
                                    <div className='activity-dropdown'>
                                        <SelectDropdown
                                            labelClassName={''}
                                            labelText={''}
                                            name='transaction'
                                            option={taxesDropdown}
                                            fieldValue={selectedValue}
                                            inputValue={onSelectValue}
                                            placeholder={''}
                                            ariaLabel={"advisory-label"}
                                            className={'custom-react-fund'}
                                            isDisabled={false}
                                            isModalDropdown={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-12" data-testid="current-page">
                    <div className="table-responsive">
                        <table className="table gi-d-table-striped-02 border-top gi-d-table-investing-history mb-4" role="presentation">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <span>
                                            {IntlMessages('table_date')}
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span> {IntlMessages('table_description')}
                                        </span>
                                    </th>
                                    <th scope="col">
                                        <span>{IntlMessages('table_amount')}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentAdvisoryFeesData.length > 0
                                    ? currentAdvisoryFeesData.map((e, i) => (
                                        <tr className={e.amount > 0 ? i % 2 === 0 ? 'bg-striped' : '' : 'bg-light'} key={i} data-testid="tabel-content">
                                            <td>
                                                <span data-testid="date-name">{DateFormatter(e.date)}</span>
                                            </td>
                                            <td>
                                                <span data-testid="description-name">{e.description}</span>
                                            </td>
                                            <td className="amount-td">
                                                <span className={`${e.amount > 0 ? 'text-gi-green' : 'text-gi-clay'} ltr`}>
                                                    {e.amount > 0
                                                        ? `+${currencyFormatter(e.amount)}`
                                                        : currencyFormatter(e.amount)
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                    : <tr className="bg-light" data-testid="tabel-content">
                                        <td colSpan={12}>
                                            <span>
                                                {IntlMessages('table_not_range')}
                                            </span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {dataLoad && <PaginationNew totalRecords={totalAdvisoryFeesData.length} pageLimit={10} pageNeighbors={1} onPageChanged={onPageChanged} data-testid="page-change" />}
                </div>
            </div>
        </>
    );
};

export default AdvisoryFeesCard;