// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//types props
import * as PropsFunction from "interface/PropsFunction";
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    openModal: boolean;
    taxPayer: string
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
    raiseTicket: PropsFunction.SimpleFunction;
}

const TaxPayerInfoModal: React.FC<Props> = (props: Props) => {
    const { openModal, taxPayer, onHandleClose, onHandleSuccess, raiseTicket } = props;

    return (
        <Modal
            centered
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={`gi-ob-grey-bottom-curve modal modal-sm enter-code-modal`}
        >
            <Modal.Body className={`${taxPayer === 'true' ? 'py-5' : 'py-4 my-2'} px-4 `}>
                <div className="enter-code-modal-text px-1">
                    <h5>{IntlMessages('kyc_note')}</h5>
                    {taxPayer === 'true' && (
                        <>
                            <p className='mt-3 mb-4'>{IntlMessages('kyc_info_us_yes_text1', { br: <br /> })}</p>
                            <p className='mt-3'>{IntlMessages('kyc_info_us_yes_text2', { br: <br /> })}</p>
                        </>
                    )}

                    {taxPayer === 'false' && (
                        <p className='mt-3'>{IntlMessages('kyc_info_us_no_text1', { br: <br /> })}</p>
                    )}
                </div>
            </Modal.Body>

            <Modal.Footer className="enter-code-modal-footer justify-content-center">
                <div className="text-center py-3" >
                    {taxPayer === 'true' && (
                        <>
                            <button className="gi-ob-note-btn" onClick={raiseTicket}>
                                {IntlMessages('kyc_info_us_yes_btn1')}
                            </button>
                            <span className="d-block mt-2" onClick={onHandleClose}>
                                <a href="#!">
                                    {IntlMessages('kyc_info_us_yes_btn2')}
                                </a>
                            </span>
                        </>
                    )}

                    {taxPayer === 'false' && (
                        <button type='button' onClick={onHandleSuccess}>{IntlMessages('kyc_info_us_no_btn1')}</button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TaxPayerInfoModal