// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// intl messaging formatting
import IntlMessages from 'helper/IntlMessages';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    myImpactList: DashboardType.ImpactDetailResponseModel[],
    isExample: boolean
    openDescriptionModal: PropsFunction.PropsInputValueFunction
}

const MyImpactSection: React.FC<Props> = (props: Props) => {

    const { myImpactList, isExample, openDescriptionModal } = props;
    const history = useHistory();

    const createInvestment = (): void => {
        history.push('add-portfolio')
    }

    const openDescriptionModalFunction = (e: React.MouseEvent): void => {
        const id = (e.target as HTMLAnchorElement).id;
        const dataId = (e.target as HTMLAnchorElement).getAttribute('data-id') as string

        openDescriptionModal(id, dataId)
    }

    return (
        <>
            {myImpactList.map((e, i) => {
                // ESG-C value style
                const esgcCard = {
                    cardBackground: {
                        background: `url(${e.myImpactBanner1Desktop}) no-repeat top center`,
                        "@media (maxWidth: 575.98px)": {
                            background: `url(${e.myImpactBanner1Mobile}) no-repeat top center`,
                        },
                    },
                    cardText: {
                        background: e.myImpactBanner1Color,
                    }
                }
                // removed company count
                const removedCompanyCount = e.removedCompanyList ? e.removedCompanyList.length : 0
                // added company count
                const addedCompanyCount = e.addedCompanyList ? e.addedCompanyList.length : 0

                return (
                    <div className="row" key={i}>
                        <div className="col-12">
                            <div className="gi-impact-default-card px-lg-5 pt-lg-4 px-3 pt-3 mb-2">
                                {/* if there is no amount invested in any ESG-C value */}
                                {isExample && (
                                    <>
                                        <div className="gi-impact-default-soon-main mt-lg-4 mt-0" data-testid='example-section'>
                                            <div className="gi-impact-default-soon d-flex align-items-center justify-content-center text-center p-4">
                                                <div>
                                                    <h3>
                                                        {IntlMessages('my_impact_text1')}
                                                    </h3>
                                                    <p className="mt-3 pb-1">
                                                        {IntlMessages('my_impact_text2')}
                                                    </p>
                                                    <button type="button" onClick={createInvestment} data-testid='example-start-btn' className="mt-4">
                                                        {IntlMessages('button_get_started')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gi-impact-default-example mt-5 mb-5">
                                            <h3 className="text-center">
                                                {IntlMessages('my_impact_text3')}
                                            </h3>
                                            <p className="text-center">
                                                {IntlMessages('my_impact_text4')}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {/* ESG-C value header */}
                                <div className="gi-impact-default-heading mt-lg-2 mt-1 pb-1">
                                    <h5>
                                        {IntlMessages('my_impact_text5', { esgcValue: <strong>{e.name}</strong> })}
                                    </h5>
                                </div>
                                {/* ESG-C value banner and text */}
                                <div className="mt-2">
                                    <div className="gi-impact-default-esgc-bg" style={esgcCard.cardBackground}>
                                        {isExample && (
                                            <div className="gi-impact-default-boosted d-flex align-items-center justify-content-center" data-testid='example-label'>
                                                {IntlMessages('my_impact_text6')}
                                            </div>
                                        )}
                                        {e.deepClean && (
                                            <div className="gi-impact-default-boosted d-flex align-items-center justify-content-center" data-testid='boost-label'>
                                                {IntlMessages('basket_boosted2')}
                                            </div>
                                        )}
                                    </div>
                                    <div className="gi-impact-default-esgc-text d-flex align-items-center justify-content-center py-3 px-lg-5 px-3" style={esgcCard.cardText}>
                                        <div className="gi-impact-default-esgc-text-main">
                                            <p data-testid={`banner-text${i}`} className="my-lg-0 my-1">
                                                {e.deepClean
                                                    ? e.myImpactDeepCleanBannerText
                                                    : e.myImpactBannerText
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* ESG-C value footer */}
                                <div className="gi-impact-default-esgc-details py-lg-3 px-lg-0 py-2 px-3">
                                    {/* removed company count */}
                                    {removedCompanyCount > 0 && (
                                        <div className='py-4 px-lg-5 px-0' data-testid='removed-count-section'>
                                            {/* removed company text */}
                                            <div className="gi-impact-view-list-text pb-3" data-testid={`removed-banner-text${i}`}>
                                                <p> {e.deepClean ? e.myImpactDeepCleanRemovedCompaniesText : e.myImpactRemovedCompaniesText} </p>
                                            </div>
                                            {/* removed company list count */}
                                            {!isExample && (
                                                <div className="gi-impact-view-list d-flex flex-wrap gap-4 mt-1">
                                                    <div>
                                                        <h5 className="d-block" data-testid={`removed-count${i}`}>{IntlMessages('portfolio_removed')} {removedCompanyCount}</h5>
                                                    </div>
                                                    <div>
                                                        <a className="d-block" id={e.identifier} data-id='removed-company' onClick={openDescriptionModalFunction} data-testid={`removed-company${i}`}>
                                                            {IntlMessages('basket_view_list')}
                                                        </a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* added company count */}
                                    {addedCompanyCount > 0 && (
                                        <div className={`py-4 px-lg-5 px-0 ${removedCompanyCount > 0 ? 'gi-border-2' : ''}`} data-testid='added-count-section'>
                                            {/* add company text */}
                                            <div className="gi-impact-view-list-text pb-3" data-testid={`added-banner-text${i}`}>
                                                <p> {e.myImpactAddedCompaniesText} </p>
                                            </div>
                                            {/* add company list count */}
                                            {!isExample && (
                                                <div className="gi-impact-view-list d-flex flex-wrap gap-4 mt-1">
                                                    <div>
                                                        <h5 className="d-block" data-testid={`added-count${i}`}>{IntlMessages('portfolio_added_company', { addedCompanyCount: addedCompanyCount })}</h5>
                                                    </div>
                                                    <div>
                                                        <a className="d-block" id={e.identifier} data-id='added-company' onClick={openDescriptionModalFunction} data-testid={`added-company${i}`}>
                                                            {IntlMessages('basket_view_list')}
                                                        </a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div >
                )
            })}
        </ >
    )
}

export default MyImpactSection