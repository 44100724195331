//default library
import * as  React from 'react';
// custom component
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
//message import
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
//types import
import * as OnBoarding from "interface/OnBoarding"
import * as PropsFunction from "interface/PropsFunction"

export type Props = {
    letStartText: boolean;
    nextRoute: string;
    sendText: boolean;
    isRouteChange: boolean;
    submitText: boolean;
    parentButton: boolean;
    fieldDetails: OnBoarding.OnBoardingData;
    startKYC?: PropsFunction.SimpleFunction;
    onHandleParentClick?: PropsFunction.SimpleFunction;
}

const KYCFooterButton: React.FC<Props> = (props: Props) => {

    const { letStartText, fieldDetails, nextRoute, sendText, isRouteChange, submitText, parentButton, startKYC, onHandleParentClick } = props
    const intl = useIntl();

    //function to handle the parent click event
    const onHandleParentClickFunction = (): void => {
        onHandleParentClick && onHandleParentClick();
    };

    return (
        <footer className="gi-ob-mob-footer" data-testid='kyc-footer'>
            <div className="gi-ob-mob-footer-main">
                <div className="gi-ob-mob-btn-group">
                    {letStartText ? (
                        <button
                            type="button"
                            className="gi-ob-mob-let-btn"
                            onClick={startKYC}
                            data-testid="start-true"
                        >
                            {IntlMessages('button_let_start')}
                        </button>
                    ) : (
                        <ButtonComponent
                            isFooter={true}
                            fieldDetails={fieldDetails}
                            nextRoute={nextRoute}
                            sendText={sendText}
                            isRouteChange={isRouteChange}
                            submitText={submitText}
                            parentButton={parentButton}
                            onHandleParentClick={onHandleParentClickFunction}
                        />
                    )}
                </div>
                <div className="gi-ob-mob-footer-heading">
                    <img src={intl.formatMessage({ id: 'LOCK_ICON_GREEN' })} alt="" />
                    <span>{IntlMessages('onboarding_footer_text4')}</span>
                </div>
            </div>
        </footer>
    )
}

export default KYCFooterButton