//default library
import * as React from 'react';
//config
import { actionValue } from 'config/DefaultValue';
//custom components
import InputComponentRadio2 from 'components/InputComponent/InputComponentRadio2';
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
// custom function
import * as Local from 'service/utils/LocalStorageData'

// Define the PropTypes 
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
};

const TaxPayer: React.FC<Props> = (props: Props) => {
  const { propsData } = props; // Destructuring the receiving props

  const [taxPayer, setTaxPayer] = React.useState<string>('');//tax payer, initialized with an empty string
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//newTaxPayer data from OnBoardingData

  //for page navigation and button configurations
  const nextPage = 'page7';
  const sendText = false;
  const isRouteChange = true;
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {
    // Accessing data from local storage
    const localStorageData = Local.getLocalData('onBoardingDetails');

    // props data 
    propsData({
      previousPage: 'page5',
      pageNumber: 7,
    });

    // Setting taxPayer state from local storage if available
    localStorageData?.taxPayer && setTaxPayer(localStorageData.taxPayer);
  }, []);

  // Function to set input value and resize error
  const setInputValue = (fieldName: string, fieldValue: string): void => {
    fieldName === "taxPayer" && setTaxPayer(fieldValue)
  };

  // useEffect to update fieldDetails when taxPayer changes
  React.useEffect(() => {
    setFieldDetails({ taxPayer: taxPayer });
  }, [taxPayer])

  return (
    <>
      <div className="gi-ob-slide-card" data-testid='tax-payer'>
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-taxpayer-text">
          <h5>{IntlMessages('tax_payer_text1')}</h5>
          <p>{IntlMessages('tax_payer_text2')} </p>
        </div>

        {/* tax payer option */}
        <div className="gi-ob-full-name-form gi-ob-taxpayer-form">
          <div>
            <div className="gi-onboard-tax-payer-radio" id="taxPayer">
              {actionValue.map((e, i) => {
                const checked = e.value === taxPayer;
                return (
                  <div className="gi-onboard-radio-inline form-check form-check-inline" key={i}>
                    <InputComponentRadio2
                      name={'taxPayer'}
                      fieldLabel={e.label}
                      fieldId={typeof e.label === 'object' && e.label.props.id}
                      fieldValue={e.value}
                      checked={checked}
                      inputValue={setInputValue}
                      dataTestId="radio-input"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {taxPayer === 'false' && (
            <ErrorKYC
              message={'error_non_us_taxpayer'}
              fieldName={'taxPayer'}
            />
          )}
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group gi-ob-taxpayer-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default TaxPayer;









































