// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import { useHistory, useLocation } from 'react-router-dom';
// moment
import moment from 'moment';
// intl message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
//regex from number
import { ONLY_NUMBER } from "config/Config";
// api call
import * as UserService from "middleware/UserService";
// custom function
import { getCurrencySymbol } from 'service/utils/GetCurrencySymbol';
import { getNumberSuffix } from 'service/utils/GetNumberSuffix';
import { openURL } from "service/utils/DownloadFile";
import * as Local from 'service/utils/LocalStorageData';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as UserType from 'api/APIMaster/UserType';
// custom component
import InfoModalIL from 'components/ModalComponent/InfoModalIL';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';

export type Props = {
    isModalOpen: boolean;
    organizationName: string | null;
    deductionDate: number;
    defaultCurrency: string | null;
    monthlySaving: UserType.MonthlySaving
    fundLocation: DashboardType.GetFundLocationsData;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
}

type MonthArrType = {
    month: string;
    isActive: boolean;
    isPaused: boolean;
}[]

const MonthlySavingEditModal: React.FC<Props> = (props: Props) => {
    const { isModalOpen, organizationName, deductionDate, defaultCurrency, monthlySaving, fundLocation, onHandleClose, onHandleSuccess } = props;

    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [editSavingModal, setEditSavingModal] = React.useState<boolean>(false); //edit modal open
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal
    const [infoModal, setInfoModal] = React.useState<boolean>(false); //info modal

    const [isPaused, setIsPaused] = React.useState<boolean>(false); //to show paused btn

    const [savingPortfolioList, setSavingPortfolioList] = React.useState<PropsFunction.Option>([]); //portfolio list

    const [infoModalBody, setInfoModalBody] = React.useState<JSX.Element[]>([]); //info modal body
    const [infoModalId, setInfoModalId] = React.useState<string>(''); //info modal id

    const [savingAmount, setSavingAmount] = React.useState<string>(''); //monthly saving amount
    const [savingAmountError, setSavingAmountError] = React.useState<boolean>(false); //monthly saving amount error
    const [savingPortfolio, setSavingPortfolio] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //portfolio
    const [isTermAgreed, setIsTermAgreed] = React.useState<boolean>(false); //term checkbox

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    const [monthArray, setMonthArray] = React.useState<MonthArrType>([]); //list of month names

    const organizationEdit: JSX.Element[] = [
        <p className="mb-0 w-100">
            {IntlMessages('monthly_saving_edit_organization_info')}
        </p>
    ]

    const amountInfo: JSX.Element[] = [
        <>
            <h5 className="mb-0">
                {IntlMessages('monthly_saving_edit_amount_info_text1')}
            </h5>
            <p className="mb-0">
                {IntlMessages('monthly_saving_edit_amount_info_text2', { date: getNumberSuffix(deductionDate) })}
            </p>
        </>
    ]

    const portfolioListInfo: JSX.Element[] = [
        <>
            <h5 className="mb-0">
                {IntlMessages('monthly_saving_edit_portfolio_info_text1')}
            </h5>
            <p className="mb-0">
                {IntlMessages('monthly_saving_edit_portfolio_info_text2')}
            </p>
        </>
    ]

    React.useEffect(() => {
        setEditSavingModal(isModalOpen);
        setIsPaused(monthlySaving.status !== 'PAUSED')

        // reset the value
        setLoadingModal(false);
        setInfoModal(false);
        setSavingPortfolioList([]);
        setInfoModalBody([]);
        setSavingAmount(monthlySaving.afterCutoffAmount.toString());
        setSavingAmountError(false);
        setIsTermAgreed(false);
        setIsDisabled(true);

        // set dropdown data
        const portfolioList = fundLocation
            .filter(e => e.type !== 'Bank Account')
            .map(e => {
                return {
                    value: e.id,
                    label: e.name,
                    active: e.type === 'Cash Account' ? true : false,
                }
            });

        portfolioList.push({
            value: -1,
            label: '+ Create a new portfolio',
            active: true,
        })

        const portfolioValue = portfolioList.find(e => monthlySaving.forCashAccount
            ? e.active
            : e.value === monthlySaving.basketId);

        setSavingPortfolio(portfolioValue ?? OnBoardingData.defaultOptionValue);
        setSavingPortfolioList(portfolioList);

        // month array
        getMonthArr(true);

    }, [isModalOpen]);

    // get the month arr
    const getMonthArr = (isUseEffect: boolean): void => {
        const isPaused = monthlySaving.status === 'PAUSED';

        // set moment local
        const language = Local.getCurrentLanguage();
        if (language === 'he_IL') {
            moment.locale('he');
        } else {
            moment.locale('en');
        }

        const currentDate = moment();

        const sixMonths = [
            {
                month: currentDate.format('MMM'), //current month
                isActive: isPaused && isUseEffect ? true : currentDate.date() > deductionDate, //check deduction date in gone or not
                isPaused: false,
            }
        ];
        // remaining five month
        for (let i = 1; i <= 5; i++) {
            // Calculate the month and year for each iteration
            const nextMonth = currentDate.clone().add(i, 'months');
            const monthName = nextMonth.format('MMM');

            // Push the formatted string into the array
            sixMonths.push({
                month: monthName,
                isActive: isUseEffect ? monthlySaving.status === 'PAUSED' : false,
                isPaused: monthlySaving.status === 'PAUSED'
            });
        }

        setMonthArray(sixMonths);
    }

    // update the input value 
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        const value = fieldValue.replace(ONLY_NUMBER, '')
        setSavingAmount(value);
        setSavingAmountError(false);
        onHandleResumed()
    }

    // select value from dropdown
    const selectDropDownValue: PropsFunction.SelectedOption = (_fieldName, fieldValue) => {
        setSavingPortfolio(fieldValue);
        if (fieldValue.value === -1) {
            history.push({
                pathname: '/dashboard/add-portfolio',
                state: {
                    previousPath: location.pathname
                }
            }); //redirect to create portfolio flow
        }
    }

    //  updating input values in state
    const setInputValueCheckbox = (_fieldName: string, fieldValue: boolean): void => {
        setIsTermAgreed(fieldValue);
    }

    // open info modal
    const openInfoModal = (e: React.MouseEvent): void => {
        const target = e.target as HTMLAnchorElement;
        setInfoModal(true);
        setInfoModalId(target.id);
        target.id === 'organizationEdit' && setInfoModalBody(organizationEdit);
        target.id === 'portfolioInfo' && setInfoModalBody(portfolioListInfo);
        target.id === 'amountInfo' && setInfoModalBody(amountInfo);
    }

    // close modal
    const onHandleCloseFunction = (): void => {
        setLoadingModal(false);
        setInfoModal(false);
    }

    // handle paused button click
    const onHandlePaused = (): void => {
        setMonthArray((prev) => {
            const newMonthArr = [...prev];

            newMonthArr.forEach(e => {
                if (e.isActive) {
                    e.isPaused = false;
                } else {
                    e.isPaused = true;
                    e.isActive = true;
                }
            });

            return newMonthArr;
        }) //set month arr

        setIsPaused(false);
    }

    // handle resumed button click
    const onHandleResumed = (): void => {
        setIsPaused(true);
        getMonthArr(false);
    }

    // setup monthly saving API call
    const setupSaving = async (): Promise<void> => {
        if ((Number(savingAmount) >= 200 && Number(savingAmount) <= 5000)) {
            setLoadingModal(true); //loading modal show
            setEditSavingModal(false);

            const data = {
                savingsAmount: Number(savingAmount),
                basketId: !savingPortfolio.active ? savingPortfolio.value.toString() : null,
                forCashAccount: savingPortfolio.active,
                termsAgreed: isTermAgreed,
                active: isPaused
            }

            const accessToken = await initiateAction() //get access token
            const monthlySaving = await UserService.editMonthlySaving(data, accessToken);

            setLoadingModal(false); //loading modal hide

            if (monthlySaving.response || monthlySaving.request) {
                setIsError(true);
                setErrorLog(monthlySaving);
            } else {
                if (monthlySaving) {
                    onHandleSuccess();
                }
            }
        } else {
            setSavingAmountError(true)
        }
    }

    // monthly saving agreement
    const monthlyAgreement = async (): Promise<void> => {
        setLoadingModal(true); //loading modal show
        setEditSavingModal(false);

        const accessToken = await initiateAction() //get access token
        const savingAgreement = await UserService.monthlySavingAgreement(savingAmount, accessToken);

        setLoadingModal(false); //loading modal hide
        setEditSavingModal(true);

        if (savingAgreement.response || savingAgreement.request) {
            setIsError(true);
            setErrorLog(savingAgreement);
        } else {
            openURL(savingAgreement.agreementUrl, 'monthly-saving-agreement');
        }

    }

    React.useEffect(() => {
        if (!savingAmount) {
            setIsDisabled(true);
        } else if (!savingPortfolio.value) {
            setIsDisabled(true);
        } else if (!isTermAgreed) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [savingAmount, savingPortfolio, isTermAgreed])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    // progress bar width
    const progressBarWidth = monthArray.filter(e => e.isActive).length
    // user currency symbol
    const currencySymbol = getCurrencySymbol(defaultCurrency ?? '');

    return (
        <>
            {/* monthly saving modal edit */}
            <Modal
                centered
                size='sm'
                show={editSavingModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                contentClassName='border-0'
                className={`gi-ob-grey-bottom-curve enter-code-modal`}
            >
                {/* close modal */}
                <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>

                {/* modal body */}
                <Modal.Body className='px-4 py-3 monthly-saving-edit-modal-react'>
                    <div className="px-3 py-3">
                        <div className="row gy-5">
                            {/* Header */}
                            <div className="col-12">
                                <div className="setup-modal-header mt-4">
                                    <h5 className="mb-0">{IntlMessages('monthly_saving_edit_header')}</h5>
                                </div>
                            </div>

                            {/* Organization name */}
                            <div className="col-12">
                                <div className="row gx-1">
                                    <div className="col-12">
                                        <div className="gi-ob-form form-group">
                                            <label className="mb-2" htmlFor="organizationName">
                                                {IntlMessages('monthly_saving_organization_label')}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="gi-ob-form form-group">
                                            <InputTextComponent
                                                labelClassName={''}
                                                labelText={''}
                                                className='check-icon'
                                                fieldValue={organizationName ?? ''}
                                                name={'organizationName'}
                                                placeholder={'placeholder_IL_first_name'}
                                                readOnly={true}
                                                disabled={false}
                                                isError={false}
                                                errorMessage={''}
                                                inputValue={setInputValue}
                                                dataTestId={'organization-name'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="edit-btn" id='organizationEdit' onClick={openInfoModal}>{IntlMessages('button_edit')}</button>
                                    </div>
                                </div>
                            </div>

                            {/* amount and time line */}
                            <div className="col-12">
                                <div className="row gx-1">
                                    {/* label and info icon */}
                                    <div className="col-12">
                                        <div className="gi-ob-form form-group">
                                            <label>{IntlMessages('monthly_saving_edit_amount')}</label>
                                            <label className="gi-setup-form-label-02 mb-2" htmlFor="brunchNumber">
                                                {IntlMessages('monthly_saving_edit_amount_text', { date: getNumberSuffix(deductionDate), br: <br /> })}
                                                <a id='amountInfo' onClick={openInfoModal}>
                                                    <img id='amountInfo' src={intl.formatMessage({ id: 'INFO_GREEN_ICON' })} alt="INFO_GREEN_ICON" />
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    {/* amount */}
                                    <div className="col-8">
                                        <div className={`gi-ob-form form-group gi-ob-price-input ${savingAmountError ? 'gi-ob-price-input-error' : ''} ${isPaused ? '' : 'disabled-field'}`}>
                                            <InputTextComponent
                                                labelClassName={""}
                                                labelText={''}
                                                className=''
                                                fieldValue={savingAmount !== '0' ? savingAmount : ''}
                                                name={'savingAmount'}
                                                placeholder={''}
                                                readOnly={false}
                                                disabled={!isPaused}
                                                errorMessage={''}
                                                isError={savingAmountError}
                                                inputValue={setInputValue}
                                                maxLength={5}
                                                dataTestId={'saving-amount'}
                                            />
                                            <span className="symbol">{currencySymbol}</span>
                                        </div>
                                    </div>
                                    {/* Pause and Resume button */}
                                    <div className="col-4">
                                        {isPaused
                                            ? <button type="button" className="edit-btn" onClick={onHandlePaused}>{IntlMessages('button_pause')}</button>
                                            : <button type="button" className="resume-btn" onClick={onHandleResumed}>{IntlMessages('button_resume')}</button>
                                        }
                                    </div>
                                    {/* error message for amount */}
                                    {savingAmountError && (
                                        <div className="col-12">
                                            <div className="invalid-feedback d-block mb-2">
                                                <i>
                                                    {IntlMessages('monthly_saving_amount_error_text1', { br: <br /> })}
                                                    <span className="gi-ob-shackles2">{currencySymbol}</span>{IntlMessages('monthly_saving_amount_error_text2')}
                                                    <span className="gi-ob-shackles2">{currencySymbol}</span>{IntlMessages('monthly_saving_amount_error_text3')}
                                                </i>
                                            </div>

                                        </div>
                                    )}
                                    {/* month timeline */}
                                    <div className="col-12">
                                        <div className="gi-custom-navigator mt-3">
                                            {/* progress bar */}
                                            <div className="progress-line">
                                                <div className="progress-line-grey" style={{ width: progressBarWidth > 5 ? '100%' : progressBarWidth * 20 + '%' }}>
                                                </div>
                                            </div>
                                            {/* month name */}
                                            <ul className="ps-0 mb-0">
                                                {monthArray.map((e, i) => (
                                                    <li key={i} className={`${e.isActive ? 'is-active' : ''}`}>
                                                        <span>{e.month}</span>
                                                        <b>
                                                            {e.isActive
                                                                ? e.isPaused
                                                                    ? 0
                                                                    : monthlySaving.beforeCutoffAmount
                                                                : savingAmount ? savingAmount : 0
                                                            }
                                                        </b>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Portfolio List */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <label>{IntlMessages('monthly_saving_edit_investment_list')}</label>
                                    <label className="gi-setup-form-label-02 mb-2" htmlFor="brunchNumber">
                                        {IntlMessages('monthly_saving_edit_investment_list_text', { br: <br /> })}
                                        <a id='portfolioInfo' onClick={openInfoModal}>
                                            <img id='portfolioInfo' src={intl.formatMessage({ id: 'INFO_GREEN_ICON' })} alt="INFO_GREEN_ICON" />
                                        </a>
                                    </label>
                                    <SelectDropdown
                                        labelClassName={''}
                                        labelText={''}
                                        name={'savingPortfolio'}
                                        placeholder={'monthly_saving_edit_investment_placeholder'}
                                        option={savingPortfolioList}
                                        fieldValue={savingPortfolio}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'saving-portfolio'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                            </div>

                            {/* term check box */}
                            <div className="col-12">
                                <div className="gi-setup-form-check form-check">
                                    <InputCheckboxComponent
                                        labelText={''}
                                        labelClass={''}
                                        fieldName={'isTermAgreed'}
                                        fieldValue={'isTermAgreed'}
                                        checkValue={isTermAgreed}
                                        inputClass={'form-check-input mt-1'}
                                        inputValue={setInputValueCheckbox} dataTestId={''} />
                                    <label className="gi-setup-form-check-label form-check-label mt-1" htmlFor="isTermAgreed">
                                        {IntlMessages('monthly_saving_term_text1')}
                                        <br />
                                        <a href="#!" onClick={monthlyAgreement}>
                                            {IntlMessages('monthly_saving_term_text2')}
                                        </a>
                                    </label>
                                </div>
                            </div>

                            {/* button */}
                            <div className="col-12">
                                <div className="pb-4 d-flex align-items-center justify-content-center gap-2">
                                    <button type="button" className="gi-ob-save-btn" disabled={isDisabled} onClick={setupSaving}>
                                        {IntlMessages('button_save')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />

            {/* info modal  */}
            <InfoModalIL
                openModal={infoModal}
                messageBody={infoModalBody}
                dialogClassName={'px-2'}
                bodyClassName={'p-4'}
                divClassName={`gi-setup-modal-text-success mt-2 px-3 mb-3 ${infoModalId === 'amountInfo' ? 'amount-info' : ''}`}
                onHandleClose={onHandleCloseFunction}
            />
        </>
    )
}

export default MonthlySavingEditModal