// default library
import * as React from 'react'
import Modal from "react-bootstrap/Modal";
// type import
import * as PropsFunction from 'interface/PropsFunction';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// custom component
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import ErrorPendingModal from 'components/ModalComponent/ErrorPendingModal';
import PlaidComponent from 'components/PlaidComponent/PlaidComponent';
import LoadingComponent3 from 'components/LoadingComponent/LoadingComponent3';

export type Props = {
    userName: string,
    openBankModal: boolean,
    plaidData: any,
    message: string,
    closeBankModal: PropsFunction.SimpleFunction,
    componentDidMount: PropsFunction.SimpleFunction,
}

const BankConnectModal: React.FC<Props> = (props: Props) => {
    const { userName, openBankModal, plaidData, message, closeBankModal, componentDidMount } = props
    const intl = useIntl();

    const [openBank, setOpenBank] = React.useState<boolean>(false);
    const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = React.useState<boolean>(false);
    const [displayLoading, setDisplayLoading] = React.useState<boolean>(false);

    const [display, setDisplay] = React.useState<string>('block');
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    React.useEffect(() => {
        setOpenBank(openBankModal)
    }, [openBankModal])

    const onHandleClose = (): void => {
        setOpenBank(false);
        setDisplay('block');
        setOpenErrorModal(false);

        closeBankModal();
    }

    //after bank successful bank connect
    const bankConnect = (): void => {
        setOpenLoadingModal(false);

        onHandleClose() //close bank modal
        componentDidMount()
    }

    //comes when data fetch from api 
    const isLoading = (): void => {
        setOpenBank(false); //close connect modal
        setOpenLoadingModal(true);
    }

    // if plaid modal open hide connect modal
    const openPlaid = (): void => {
        setDisplay('none');
        const bankModal: HTMLElement | null = document.querySelector('.gi-ob-white-bottom-curve.gi-port-ao-pc-companies-modal.source-pop-up.error-modal ');

        if (bankModal) {
            bankModal.style.opacity = '0';
        }
    }

    // error modal handle
    const handleErrorModal = (value: any): void => {
        let errorMessage = ''
        if (value?.message === `Personal data doesn't match bank data`) {
            errorMessage = `error_connecting_account`
        }
        else {
            errorMessage = `error_connecting_account1`
        }
        setOpenLoadingModal(false);
        setOpenErrorModal(true);
        setErrorMessage(errorMessage);
    }

    // plaid token generate
    const tokenGenerate = async (value: boolean) => {
        setDisplayLoading(value)
    }

    return (
        <>
            {/* bank connect modal */}
            <Modal
                centered
                show={openBank}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false} className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal"
                style={{ display: display }}
            >
                <Modal.Header className='px-3 py-2'>
                    <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" aria-label="Close" onClick={onHandleClose}></button>
                </Modal.Header>

                <Modal.Body className="px-5 pt-5 pb-4">
                    <div className='error-modal-body d-flex align-items-center justify-content-center' >
                        <div style={{ display: displayLoading ? 'block' : 'none' }}>
                            <div className='loading'>
                                <LoadingComponent3 />
                            </div>
                        </div>
                        <div className="error-modal-text" style={{ display: !displayLoading ? 'block' : 'none' }}>
                            <img src={intl.formatMessage({ id: 'BANK_CONNECT_ANIMATION' })} className="img-fluid" alt="" />
                            <h6 className="mt-3 pt-2">
                                {IntlMessages(message, { br: <br /> })}
                            </h6>
                            <div className="text-center mt-4 pt-1">
                                <PlaidComponent
                                    className={'contact-btn'}
                                    isLoading={isLoading}
                                    onHandleClose={onHandleClose}
                                    componentDidMount={bankConnect}
                                    buttonName={IntlMessages('button_confirm')}
                                    userName={userName}
                                    openPlaid={openPlaid}
                                    handleErrorModal={handleErrorModal}
                                    plaidData={plaidData}
                                    tokenGenerate={tokenGenerate}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Loading modal */}
            <LoadingModal
                openLoadingModal={openLoadingModal}
                title={'modal_connect_bank'}
                onHandleClose={onHandleClose}
            />

            {/* Error Modal */}
            <ErrorPendingModal
                openModelError={openErrorModal}
                closeErrorModal={onHandleClose}
                h3={errorMessage}
                label={'error_please_try'}
            />
        </>
    )
}

export default BankConnectModal