//default library
import * as React from 'react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//type props
import * as PropsFunction from 'interface/PropsFunction'
import * as Dashboard from 'interface/Dashboard'

export type Props = {
    scheduleSection: Dashboard.ScheduleSection,
    forWeek: PropsFunction.ParamsIdStringFunction
}

const WeekChangeSection: React.FC<Props> = (props: Props) => {
    const { scheduleSection, forWeek } = props

    //handle for week function
    const handleForWeek = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        const targetId = (e.currentTarget.id);
        forWeek(targetId);
    };

    return (
        <div className="col-12">
            <div className="gi-g-week-change d-flex align-items-center justify-content-end mb-3 pb-3">
                <div className="gi-g-weekbox d-flex align-items-center gap-2 position-relative">
                    {/* pervious week icon */}
                    {scheduleSection.previousWeek && (
                        <a
                            id={scheduleSection.previousWeek.toString()}
                            className="gi-g-start-icon"
                            onClick={handleForWeek}
                            data-testid="previous-week"
                        />
                    )}
                    {/* current week number */}
                    <span className="d-block text-center" data-testid="current-week">{IntlMessages('week')} {scheduleSection.forWeek}</span>
                    {/* next week icon */}
                    {scheduleSection.nextWeek && (
                        <a
                            id={scheduleSection.nextWeek.toString()}
                            className="gi-g-end-icon"
                            onClick={handleForWeek}
                            data-testid="next-week"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default WeekChangeSection;
