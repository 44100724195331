//default library
import * as React from 'react';
//custom components
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
import * as UserType from "api/APIMaster/UserType"
// custom function
import * as Local from 'service/utils/LocalStorageData'

//defining props
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
  staticData: UserType.OnboardingStaticDataResponseModel
}

const RiskTolerance: React.FC<Props> = (props: Props) => {
  const { propsData, staticData } = props //destructuring props
  const intl = useIntl();

  const [isLoadingImg, setIsLoadingImg] = React.useState(true);
  const [riskTolerance, setRiskTolerance] = React.useState<string>('');// managing the selected risk tolerance
  const [riskToleranceList, setRiskToleranceList] = React.useState<OnBoarding.OptionData[]>([]);//storing the list of available risk tolerances
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});// managing the field details related to risk tolerance

  const nextPage = 'page14';
  const sendText = false;
  const isRouteChange = true;
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {
    const localStorageData = Local.getLocalData('onBoardingDetails');

    //props data
    propsData({
      previousPage: 'page12',
      pageNumber: 14,
    });

    // Creating a list of investor risk tolerances
    const investorRiskToleranceList = staticData.investorRiskToleranceList.map((value) => ({
      value: value.identifier,
      label: value.nameLocale,
    }));

    // Setting the state variables
    setRiskToleranceList(investorRiskToleranceList);
    setRiskTolerance(investorRiskToleranceList[0].value);

    // Storing local storage value if present
    localStorageData?.riskTolerance && setRiskTolerance(localStorageData.riskTolerance);

  }, []);

  // Function to handle image loading
  const handleLoad = (): void => {
    setIsLoadingImg(false);
  };

  const handleClick = (e: React.MouseEvent): void => {
    const id = (e.target as HTMLAnchorElement).id
    setRiskTolerance(id);
  };

  //update field details on risk tolerance change
  React.useEffect(() => {
    setFieldDetails({ riskTolerance: riskTolerance, });
  }, [riskTolerance])

  return (
    <>
      {isLoadingImg ? <LoadingComponent /> : ''}

      <div className="gi-ob-slide-card" data-testid='risk-tolerance'>
        <div className="gi-ob-full-name-text gi-ob-willing-text">
          <h5>{IntlMessages('risk_tolerance_text1')}</h5>
          <p>{IntlMessages('risk_tolerance_text2')}</p>
        </div>

        <div className="gi-ob-full-name-form gi-ob-willing-form">
          <div className="gi-ob-range-slider">
            <div className="gi-ob-range-slider-bg" data-testid="handle-load">
              <img src={intl.formatMessage({ id: 'RANGE_LINE' })} className="d-none d-sm-block" alt="RANGE_LINE" onLoad={handleLoad} />
              <img src={intl.formatMessage({ id: 'RANGE_LINE_XS' })} className="d-block d-sm-none" alt="RANGE_LINE_XS" onLoad={handleLoad} />
            </div>

            <div className="gi-ob-range-slider-text">
              <ul className='ps-0'>
                {riskToleranceList.length > 0 &&
                  riskToleranceList.map((e, i) => (
                    <li key={i}>
                      <a
                        id={e.value.toString()}
                        className={`${riskTolerance === e.value ? 'active' : ''}`}
                        onClick={handleClick}
                      >
                        <span id={e.value.toString()}></span> {e.label}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="gi-ob-let-btn-group gi-ob-employment-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default RiskTolerance;






