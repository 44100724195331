import * as LearnType from "api/APIMaster/LearnType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class LearnService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {
    constructor() {
        super({
            baseURL: URLList.BASE_URL.GIRAFFE_LEARN
        });
    }

    /**
     * @description Get any content
     *
     * @name GetAnyContent
     * @summary Get the content of the video list
     * @request POST:/any/an-content
     * @secure
     */
    getAnyContent = (data: LearnType.AnyContentRequest, params: RequestParams = {}) =>
        this.request<LearnType.GetAnyContentData, void | ExceptionResponse>({
            path: URLList.LEARN_SERVICE.LEARN_ANY_CONTENT.path,
            method: URLList.LEARN_SERVICE.LEARN_ANY_CONTENT.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Update user video data
     *
     * @name UserVideo
     * @summary Update the user video
     * @request PUT:/user/user-video
     * @secure
     */
    userVideo = (data: LearnType.UserContentVideoRequest, params: RequestParams = {}) =>
        this.request<LearnType.UserVideoData, void | ExceptionResponse>({
            path: URLList.LEARN_SERVICE.LEARN_USER_VIDEO.path,
            method: URLList.LEARN_SERVICE.LEARN_USER_VIDEO.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Get User Content Data
    *
    * @name getUserContent
    * @summary Post the user content
    * @request POST:/user/user-content
    * @secure
    */
    getUserContent = (data: LearnType.UserContentRequest, params: RequestParams = {}) =>
        this.request<LearnType.UserContentResponse, void | ExceptionResponse>({
            path: URLList.LEARN_SERVICE.USER_CONTENT.path,
            method: URLList.LEARN_SERVICE.USER_CONTENT.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Get User Calendar Data
    *
    * @name getUserCalendarDetails
    * @summary Post the user Calender data
    * @request POST:/user/user-calendar
    * @secure
    */
    getUserCalendarDetails = (data: LearnType.UserCalenderRequest, params: RequestParams = {}) =>
        this.request<LearnType.UserCalendarResponse, void | ExceptionResponse>({
            path: URLList.LEARN_SERVICE.USER_CALENDAR.path,
            method: URLList.LEARN_SERVICE.USER_CALENDAR.method,
            body: data,
            secure: true,
            ...params,
        });
}



export default LearnService
