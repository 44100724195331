// default library
import * as React from 'react'
import Modal from "react-bootstrap/Modal";
// intl message library 
import { useIntl } from 'react-intl';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    openSuccessModal: boolean;
    successMessage: string;
    onHandleCloseSuccess: PropsFunction.SimpleFunction;
}

const SuccessModal: React.FC<Props> = (props: Props) => {

    const { openSuccessModal, successMessage, onHandleCloseSuccess } = props
    const intl = useIntl();


    return (
        <Modal
            size='xl'
            centered
            show={openSuccessModal}
            backdrop="static"
            keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal"
        >
            <Modal.Body className='p-xl-4 p-3'>
                <button className="btn-close-white p-0 border-0 position-absolute opacity-100" type="button" onClick={onHandleCloseSuccess} data-testid='close-icon'></button>
                <div className="p-xl-2 p-0">
                    <div className="gi-g-new-transaction-card d-flex align-items-center justify-content-center">
                        <div className="gi-g-new-transaction-text text-center px-3">
                            <img src={intl.formatMessage({ id: 'GIRAFFE_MODAL_ICON' })} alt="GIRAFFE_MODAL_ICON" />
                            <h5 className='mt-2 pt-1 text-center'>
                                {IntlMessages('fund_success')}
                            </h5>
                            <p className='mt-2 mb-4 pb-2 text-center'>
                                {IntlMessages(successMessage)}
                            </p>
                            <button className='gi-d-ok-btn' type="button" onClick={onHandleCloseSuccess} data-testid='confirm-btn'>
                                {IntlMessages('button_ok')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModal