const currencySymbols: { [code: string]: string } = {
    'USD': '$',
    'ILS': '₪',
};

export const getCurrencySymbol = (currencyCode: string): string => {

    // Convert the input currency code to uppercase for case-insensitivity
    const upperCaseCurrencyCode = currencyCode.toUpperCase();

    // Check if the currency code exists in the map
    if (upperCaseCurrencyCode in currencySymbols) {
        return currencySymbols[upperCaseCurrencyCode];
    } else {
        // Return a default value or handle the case when the currency code is not found
        return '';
    }
}