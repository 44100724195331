import * as React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export type Props = {
    component: React.ComponentType<any>,
    [key: string]: any
}

const ProtectedRoute: React.FC<Props> = (props: Props) => {

    const { isAuthenticated } = useAuth0()
    const { component: Component, ...rest } = props

    return (
        <>
            {isAuthenticated
                ? <Route render={() => <Component {...rest} />} />
                : <Redirect push to={'/'} />
            }
        </>
    );
}

export default ProtectedRoute
