const OnBoarding: { [key: string]: string } = {
    'personal': 'אישי',
    'information': 'מידע',

    /* old journey name */
    'personal_page4_label1': `מה הכתובת החוקית שלך?`,
    'personal_page4_label2': `מספר דירה/יחידה`,
    'personal_page4_label3': `מיקוד`,
    'employment_page3_label1': 'האם אתם מקשורים או מועסקים בבורסה לניירות ערך, חברים בבורסה או FINRA, או סוחרים בניירות ערך עירוניים?',
    'employment_page3_label2': 'האם אתם אנשי שליטה בחברה ציבורית? דירקטורים, נושאי משרה או בעלי מניות של 10%?',
    'employment_page3_label2_1': 'שם החברה',
    'employment_page3_label2_2': 'סמל המנייה',
    'employment_page3_label3': 'האם אתם חשופים פוליטית בהווה או בעבר או פקידים ציבוריים (כולל ארה"ב ומחוץ לה)?',
    'employment_page3_label3_1': 'שם עובד הציבור ובני המשפחה',
    'employment_page4_label': 'הכנסה שנתית',
    'employment_static_t1': 'בסדר, אין בעיה!',
    'employment_static_t2': 'עם זאת אתם מחויבים להודיע בכתב למעסיק שלכם על כוונתכם לפתוח חשבון',
    'employment_static_t3': `נא לספק את שמו של אותו עובד ציבור, ושמותיהם של בני משפחתו הקרובים (כולל בני / בנות זוג לשעבר)`,
    'employment_static_t4': `נא לרשום את שם החברה וסמל המניה שלה`,
    'investment_detail_info5': `רמת סיכון`,
    'complete_application_t7': `הסכם`,
    'complete_application_t8': `גילויים`,
    'waiting_page_t1': 'המידע שלך {br}נמצא בעיבוד',
    'waiting_page_t2': 'נמצא בעיבוד',
    'waiting_page_t3': 'זה יכול לקחת עד דקה אחת.',
    'waiting_page_t4': `נשלח לך דוא“ל לאחר שתאושר.`,

    /* Button */
    'button_let_start': `בואו נתחיל`,
    'button_set_later': `הגדירו זאת מאוחר יותר`,

    /* footer */
    'onboarding_footer_text1': `שירותי ייעוץ להשקעות ניתנים על ידי Giraffe Invest, Inc., יועץ השקעות רשום ב-SEC. שירותי הייעוץ מבוססי האינטרנט של Giraffe Invest Inc. נועדו לסייע ללקוחות בהשגת יעדים פיננסיים נפרדים. לפרטים נוספים, ראו Giraffe Invest's `,
    'onboarding_footer_form_crs': `טופס CRS`,
    'onboarding_footer_form_adv_2': `טופס ADV חלק ב‘`,
    'onboarding_footer_text2': `שירותי ברוקראז‘ ניתנים ללקוחות של Giraffe Invest, Inc. על ידי DriveWealth, LLC, סוחר ברוקר רשום ב-SEC וחבר ב-FINRA/SIPC.`,
    'onboarding_footer_text3': `כל מידע שתזינו מוצפן באמצעות תקני הצפנת הנתונים הגבוהים ביותר.`,
    'onboarding_footer_text4': `כל מידע שתזינו מוצפן בתקנים הגבוהים ביותר.`,

    /* New on-boarding */
    'start_page_text1': `בואו נגדיר את חשבון ההשקעות שלכם.`,
    'start_page_text2': `אנחנו נדריך אותך לאורך התהליך, ונניח את היסודות למסע ההשקעות החדשני שלך.`,
    'start_page_text3': `בשביל להתחיל, אנחנו מבקשים שתשתפו מידע בסיסי. אנחנו נדרשים לבקש את המידע הזה מכיוון שכמוסד פיננסי, יש לנו אחריות לאסוף פרטים מזהים בהתאם לתקנות ה-SEC.`,

    /* User Name page */
    'username_text1': `מה השם המלא שלך?`,
    'username_text2': `אנחנו דורשים את המידע הזה בשביל להמשיך בהגדרת חשבון ההשקעות שלך.`,
    'username_text3': `נא להזין את השם הפרטי החוקי שלך`,
    'username_text4': `נא להזין את שם המשפחה החוקי שלך`,

    /* PhoneNumber page */
    'phone_number_text1': `מה מספר הטלפון שלך?`,
    'phone_number_text2': `נשלח לך קוד אבטחה כדי לאמת את זהותך.`,
    'phone_number_text3': `נא להזין את מספר הטלפון הנייד שלך בארה"ב`,
    'phone_number_info_modal_text1': 'הפלטפורמה שלנו מתרחבת ברחבי העולם!',
    'phone_number_info_modal_text2': 'אמנם זמין כעת בארה"ב בלבד, הישאר מעודכן לקראת ההשקה שלנו בישראל. עקבו אחרינו ב',
    'phone_number_info_modal_text3': 'לינקדאין',
    'phone_number_info_modal_text4': 'לעדכונים.',
    'phone_number_info_modal_text5': 'המסע הפיננסי שלך עומד להשתפר עוד יותר!',

    /* OTPVerification page */
    'otp_page_text1': `נא להזין את הקוד בן שש הספרות שנשלח אל:`,

    /* Address page */
    'address_text1': `איפה אתם גרים?`,
    'address_text2': `בגלל דרישות משפטיות, זו חייבת להיות כתובת מגורים בארה"ב, והיא חייבת להתאים לתעודת הזהות שלך. לא נשלח חומרי פרסום לכתובת שלך.`,
    'address_text3': `מה הכתובת החוקית שלך?`,
    'address_text4': `מספר דירה/יחידה`,
    'address_text5': `מיקוד`,

    /* DobSsn page */
    'bod_ssn_text1': `ספרו לנו עוד על עצמכם `,
    'bod_ssn_text2': `כמוסד פיננסי, עלינו לאמת את זהותך כדי למנוע הלבנת הון. אנו מתייחסים ברצינות להגנת זהותך; למעשה, אנו מצפינים את כל המידע המוזן באמצעות תקני הצפנת הנתונים הגבוהים ביותר.`,
    'bod_ssn_text3': `מה תאריך הלידה שלך?`,
    'bod_ssn_text4': `מה ה-SSN או ה-ITIN שלך?`,

    /* Country page */
    'country_text1': `מהי מדינת האזרחות העיקרית שלך?`,

    /* TaxPayer page */
    'tax_payer_text1': `האם אתם משלמים מיסים בארה"ב?`,
    'tax_payer_text2': `פירוש היותכם משלמי מסים בארה"ב הוא שאתם אזרחי ארה"ב, תושבי קבע או בעלי גרין קארד.`,

    /* EmploymentStatus page */
    'employment_status_text1': `מה מצב התעסוקה הנוכחי שלך?`,
    'employment_status_text2': `על מנת לפתוח עבורך חשבון השקעות, אנו מבקשים כמה פרטים על מצב התעסוקה שלך.`,

    /* EmploymentDetails page */
    'employment_detail_text1': `מה שם החברה שלך?`,
    'employment_detail_text2': `מה תפקידך בחברה?`,
    'employment_detail_text3': `באיזו תעשייה אתם עובדים?`,
    'employment_detail_text4': `אם אתם לא מוצאים את הענף או התפקיד המדויק, זה בסדר! פשוט בחרו את החלופה הקרובה ביותר :)`,

    /* EmploymentStock page */
    'employment_stock_text1': `האם אתם מקשורים או מועסקים על ידי בורסה לניירות ערך, חברה עמיתה בבורסה או FINRA, או סוחרים בניירות ערך עירוניים?`,
    'employment_stock_text2': `האם אתם אנשי שליטה בחברה ציבורית? דירקטורים, נושאי משרה או בעלי מניות של 10%?`,
    'employment_stock_text3': `האם אתם חשופים פוליטית בהווה או בעבר או פקידים ציבוריים (כולל ארה"ב ומחוץ לה)?`,
    'employment_stock_text4': `בסדר, אין בעיה!`,
    'employment_stock_text5': `עם זאת אתם מחויבים להודיע בכתב למעסיק שלכם על כוונתכם לפתוח חשבון`,
    'employment_stock_text6': `נא להזין את שם החברה וסמל המנייה שלה`,
    'employment_stock_text7': `נא לספק את שמו של אותו עובד ציבור, ושמותיהם של בני משפחתו הקרובים (כולל בני / בנות זוג לשעבר)`,

    /* AnnualIncome page */
    'annual_income_text1': `הכנסה שנתית למשק בית (מוערך)`,
    'annual_income_text2': `אנו נדרשים לבקש זאת כדי שיהיה לנו את זה בקובץ לדיווח ולביקורות של יועץ ההשקעות שלנו.`,

    /* NextWorth page */
    'net_worth_text1': `שווי נכסים משוער`,
    'net_worth_text2': `אנו נדרשים לבקש זאת כדי שיהיה לנו את זה בקובץ לדיווח ולביקורות של יועץ ההשקעות שלנו.`,

    /* InvestingExperience page */
    'investing_experience_text1': `מה הניסיון שלך בהשקעות?`,
    'investing_experience_text2': `שאלה זו תיתן לנו מושג כללי על הרקע שלך בהשקעות, כך שנדע שיש לך את כל הידע שצריך כדי לבצע את ההשקעות הנכונות.`,

    /* RiskTolerance page */
    'risk_tolerance_text1': `כמה סיכון אתם מוכנים לקחת?`,
    'risk_tolerance_text2': `שאלה זו תיתן לנו מושג כללי על רמת הסיכון שאתם מוכנים לקחת בזמן ההשקעה, כך שנוכל לוודא שאתם לא לוקחים על עצמכם יותר מדי סיכון בזמן ההשקעה איתנו.`,

    /* GiraffeTC page */
    'giraffe_tc_text1': `מתקרבים לקו הסיום`,
    'giraffe_tc_text2': `על ידי סימון תיבה זו, אתם מסכים ל-Giraffe`,
    'giraffe_tc_text3': `הסכם ייעוץ`,
    'giraffe_tc_text4': `של DriveWealth`,
    'giraffe_tc_text5': `גילויים`,
    'giraffe_tc_text6': `מדיניות הפרטיות`,
    'giraffe_tc_text7': `תנאים והגבלות`,
    'giraffe_tc_text8': `הסכם`,
    'giraffe_tc_text9': `ומאשרים שקיבלתם עותק שלנו`,
    'giraffe_tc_text10': `טופס CRS`,
    'giraffe_tc_text11': `טופס ADV חלק ב‘`,
    'privacy_policy': 'מדיניות פרטיות',
    't_and_c': 'תנאים והגבלות',

    /* TaskComplete page */
    'kyc_pending_text1': `אתם תותחים!`,
    'kyc_pending_text2': `משימה הושלמה`,
    'kyc_pending_text3': `המידע שלך נמצא בעיבוד.`,
    'kyc_pending_text4': `(זה יכול לקחת עד דקה)`,

    /* Congratulation page */
    'congratulation_text1': `אתם תותחים!`,

    /* BankConnect page */
    'bank_connect_text1': `כעת, בואו נחבר את חשבון הבנק שלכם.`,
    'bank_connect_text2': `אני אחבר את הבנק שלי מאוחר יותר`,
}

export default OnBoarding;