import * as React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// jquery
import $ from 'jquery';
//pages
import * as DashboardPage from 'pages/Dashboard'
//component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import DashboardHeader from 'components/HeaderComponent/DashboardHeader';
import DesktopSidebar from 'components/SidebarComponent/DesktopSidebar';
import MobileSidebar from 'components/SidebarComponent/MobileSidebar';
import FooterApp from 'components/FooterComponent/FooterApp';
import FooterIL from 'components/FooterComponent/FooterIL';
import SvgIcon from 'components/CommonComponent/SvgIcon';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { initialState, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
//ProtectedRoute for dashboard
import ProtectedRoute from 'routes/ProtectedRoute';

//api call
import * as DashboardService from 'middleware/DashboardService';
import * as GiftService from 'middleware/GiftService';

// scroll top 
import ScrollToTop from 'helper/ScrollToTop';
// type import
import * as RouteInterface from 'interface/Route'
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as GiftType from 'api/APIMaster/GiftType';
import IntlMessages from 'helper/IntlMessages';

let routChangeCount: number = 0

type Props = {
    checkStatus: RouteInterface.RouteState | undefined,
}

const DashboardRoutes: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const appCommonData = useSelector(getAppCommonData);

    const { initiateAction } = useAccessToken();
    const history = useHistory();
    const { checkStatus } = props

    const locale = useSelector(getLocale);
    const countryCode = locale.countryCode; //user country code
    const isAppAPILanguageMatch = locale.language === locale.apiLang; // check api language and app language is match or not


    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isLoading, setIsLoading] = React.useState<boolean>(true); //loading data
    const [checkWithdrawal, setCheckWithdrawal] = React.useState<boolean>(false); //click check withdrawal button
    const [withdrawButton, setWithdrawButton] = React.useState<boolean>(false); //click withdraw button
    const [basketList, setBasketList] = React.useState<DashboardType.GetBasketBasicDetailsByUser1Data>([]); //basket name array
    const [giftPendingList, setGiftPendingList] = React.useState<GiftType.GetGiftUserByAccessTokenData>([]); //gift route array

    const createNavigation = async () => {
        setIsLoading(true)
        const accessToken = await initiateAction() //get access token
        const apiCallList = [DashboardService.getBasketBasicDetails(accessToken), GiftService.getGiftUserByAccessToken(accessToken)] //basket information
        const [getBasketList, giftPendingList] = await Promise.all(apiCallList) //api call

        if (getBasketList.response || giftPendingList.response) {
            getBasketList.response && setErrorLog(getBasketList)
            giftPendingList.response && setErrorLog(giftPendingList)
            setIsError(true)
        }

        getBasketList.length === 0 ? routChangeCount++ : routChangeCount = 0
        setBasketList(getBasketList ?? [])
        setGiftPendingList(giftPendingList ?? [])
        setIsLoading(false)
    }

    // simple set header color and remove localStorage
    React.useEffect(() => {
        localStorage.removeItem('onBoardingDetails') //remove on boarding details from local storage
        localStorage.removeItem('boostedEsgcValueIdentifier') //remove boostedEsgcValueIdentifier from local storage
        localStorage.removeItem('isClickRedeemed') //remove redeem click flag
        dispatch(setKYCData(initialState)); //store to redux

        const sectionOneBG = document.getElementById('sectionOne')
        if (sectionOneBG) {
            sectionOneBG.style.background = '#077'
        }

        $(".gi-g-menu-new").click(() => {
            routChangeCount = 0
        });
    }, [])

    // call api for to create the nav menu
    React.useEffect(() => {
        languageCheck();
        initialAPICall();
    }, []);

    // language check based on the country code and api language
    const languageCheck = (): void => {
        if (countryCode === 'ISR') {
            !isAppAPILanguageMatch && updateLocal();
        }
    }

    // update the location language to IL
    const updateLocal = (): void => {
        const langCode = locale.apiLang === 'en_US' ? 'ISR_EN' : 'ISR';

        dispatch(setCurrentLanguage(langCode));
    }

    const initialAPICall = async (): Promise<void> => {
        const accessToken = await initiateAction() //get access token

        const apiCallList = [DashboardService.getBasketBasicDetails(accessToken)] //basket information
        JSON.parse(import.meta.env.REACT_APP_SHOW_NAVIGATION_GIFT) ? apiCallList.push(GiftService.getGiftUserByAccessToken(accessToken)) : apiCallList.push(Promise.resolve([])) //gift information

        const [getBasketList, giftPendingList] = await Promise.all(apiCallList) //api call

        if (getBasketList.response || giftPendingList.response || getBasketList.request || giftPendingList.request) {
            (getBasketList.response || getBasketList.request) && setErrorLog(getBasketList);
            (giftPendingList.response || giftPendingList.request) && setErrorLog(giftPendingList);
            setIsError(true)
        }

        getBasketList.length === 0 ? routChangeCount++ : routChangeCount = 0
        setBasketList(getBasketList ?? [])
        setGiftPendingList(giftPendingList ?? [])
        setIsLoading(false)
    }

    // menu open in mobile view
    const menuOpen = (): void => {
        const menuOpen: HTMLElement | null = document.getElementById("gi-g-menu-open");
        if (menuOpen) {
            menuOpen.classList.toggle("active");
        }
    }

    // creating basket menu
    const createBasketRoute = async (): Promise<void> => {
        const accessToken = await initiateAction() //get access token

        const getBasketList = await DashboardService.getBasketBasicDetails(accessToken); //basket summary
        if (getBasketList.response) {
            setErrorLog(getBasketList)
            setIsError(true)
        }

        setBasketList(getBasketList)
    }

    // creating gift menu
    const createGiftRoute = async (): Promise<void> => {
        const accessToken = await initiateAction() //get access token

        const giftPendingList = await GiftService.getGiftUserByAccessToken(accessToken); //gift router list
        if (giftPendingList.response) {
            setErrorLog(giftPendingList)
            setIsError(true)
        }

        setGiftPendingList(giftPendingList)
    }

    // check withdraw button
    const checkWithdrawalButton = async (value: number): Promise<void> => {
        setCheckWithdrawal(Number(value) > 0)
    }

    // withdraw button click
    const withdrawClick = (): void => {
        setWithdrawButton(true)
    }

    // close withdraw modal
    const onHandleClose = (value: boolean): void => {
        setWithdrawButton(value)
    }

    const disabledAPI: boolean = Boolean(routChangeCount === 0 || (giftPendingList.length > 0 && (appCommonData.isNewUser || checkStatus?.bankConnectPage))) //use for disable API call on Home page for first time
    const gotoGift: boolean = giftPendingList.length > 0 && basketList.length === 0 && !isLoading && !appCommonData.isNewUser && !checkStatus?.bankConnectPage //redirect to gift customized page
    const gotoCreatePortfolio: boolean = giftPendingList.length === 0 && basketList.length === 0 //redirect to create portfolio page
    const showGiftTab = !(appCommonData.isNewUser || appCommonData.isKycDenied || appCommonData.isKycRejected || appCommonData.isKycManualReview); //check to show gift tab or not

    const isAccessAfterKYC: boolean = (appCommonData.isKycDenied || appCommonData.isKycRejected || appCommonData.isKycManualReview); //check the kyc status

    React.useEffect(() => {
        {/* redirect to gift redeem page */ }
        if (gotoGift && !isAccessAfterKYC) {
            history.push(`/dashboard/gift/${giftPendingList[0]?.identifier}`);
        }

        {/* redirect to create an investment */ }
        if (gotoCreatePortfolio && !isLoading) {
            history.push(`/dashboard/add-portfolio`);
        }

    }, [giftPendingList, basketList])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }


    return (
        <div>
            <ScrollToTop />
            {/* Header */}
            <DashboardHeader dashboard={true} menuOpen={menuOpen} />

            {/* Main section */}
            <section className="gi-g-main-section gi-dash-section">
                <div className="container-xxl px-md-4 px-0">
                    <div className="row gx-2">
                        {/* nav bar */}
                        <div className="col-xl-2">
                            <div className="gi-g-nav-sidebar" id="gi-g-menu-open">
                                {/* close icon */}
                                <div className="close-icon" onClick={menuOpen}>
                                    <SvgIcon iconId={'close-icon'} width={23} height={23} />
                                </div>

                                {/* Desktop sidebar */}
                                <DesktopSidebar
                                    basketList={basketList}
                                    giftPendingList={giftPendingList}
                                />

                                {/* Mobile sidebar */}
                                <MobileSidebar
                                    basketList={basketList}
                                    giftPendingList={giftPendingList}
                                    menuOpen={menuOpen}
                                />
                            </div>
                        </div>
                        {/* page content */}
                        <div className="col-xl-10">
                            {isLoading
                                ? <LoadingComponent />
                                : <Switch>
                                    {/* home page */}
                                    <ProtectedRoute exact path={`/dashboard`}
                                        component={DashboardPage.HomePage}
                                        disabledAPI={disabledAPI || isAccessAfterKYC}
                                        withdrawButton={withdrawButton}
                                        checkWithdrawalButton={checkWithdrawalButton}
                                        createBasketRoute={createBasketRoute}
                                        onHandleClose={onHandleClose}
                                    />

                                    {/* basket listing */}
                                    {basketList.length > 0 && (basketList?.map(e => {
                                        return (
                                            <ProtectedRoute exact path={`/dashboard/portfolio/${e.id}`} key={e.id}
                                                component={DashboardPage.PortfolioPage}
                                                createBasketRoute={createBasketRoute}
                                            />
                                        )
                                    }))}

                                    {/* my impact */}
                                    <ProtectedRoute exact path={`/dashboard/my-impact`}
                                        component={DashboardPage.MyImpactPage}
                                    />

                                    {/* create portfolio */}
                                    <ProtectedRoute exact path={`/dashboard/add-portfolio`}
                                        component={DashboardPage.AddPortfolio}
                                    />
                                    <ProtectedRoute exact path={`/dashboard/create-portfolio`}
                                        component={DashboardPage.CreatePortfolio}
                                        createBasketRoute={createBasketRoute}
                                    />

                                    {/* activity */}
                                    <ProtectedRoute exact path={`/dashboard/activity`}
                                        component={DashboardPage.ActivityPage}
                                    />

                                    {/* account setting */}
                                    <ProtectedRoute exact path={`/dashboard/account-setting`}
                                        component={DashboardPage.AccountSetting}
                                    />

                                    {/* tax and documentation */}
                                    <ProtectedRoute exact path={`/dashboard/taxes`}
                                        component={DashboardPage.TaxesPage}
                                    />

                                    {/* gift redeemed */}
                                    {JSON.parse(import.meta.env.REACT_APP_IN_APP_GIFT) && showGiftTab && (
                                        <ProtectedRoute exact path={`/dashboard/gifts`}
                                            component={DashboardPage.GiftGivenPage}
                                            createGiftRoute={createGiftRoute}
                                        />
                                    )}

                                    {/* gift list */}
                                    {JSON.parse(import.meta.env.REACT_APP_SHOW_NAVIGATION_GIFT) && showGiftTab
                                        && giftPendingList.length > 0
                                        && (giftPendingList.map(e => {
                                            return (
                                                !e.isRedeemed && (
                                                    <ProtectedRoute exact path={`/dashboard/gift/${e.identifier}`} key={e.id}
                                                        component={DashboardPage.GiftPage}
                                                        componentDidMount={createNavigation}
                                                    />
                                                )
                                            )
                                        }))
                                    }

                                    {/* giraffe learn */}
                                    {appCommonData.isLearnAccess && (
                                        <ProtectedRoute exact path={`/dashboard/financial-playlist`}
                                            component={DashboardPage.GiraffeLearnPage}
                                        />
                                    )}
                                    {appCommonData.isLearnAccess && (
                                        <ProtectedRoute exact path={`/dashboard/financial-playlist-list`}
                                            component={DashboardPage.GiraffeLearnListPage}
                                        />
                                    )}

                                    {/* contact us */}
                                    <ProtectedRoute exact path={`/dashboard/contact-us`}
                                        component={DashboardPage.ContactUsPage}
                                    />

                                    <Route path={'*'}>
                                        <Redirect to='/dashboard' />
                                    </Route>
                                </Switch>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* Withdraw button */}
            {location.pathname === '/dashboard' && (
                <section className="gi-dash-withdraw-section">
                    <div className="container-xxl">
                        <div className="row">
                            <div className="col-12 offset-xl-2">
                                <div className="gi-dash-withdraw-main">
                                    <button type="button" className='px-0' disabled={!checkWithdrawal} onClick={withdrawClick}>
                                        {IntlMessages('button_withdraw')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* footer */}
            {locale.countryCode === "ISR" ? <FooterIL /> : <FooterApp />}

        </div>
    );
}

export default DashboardRoutes