// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// jquery
import $ from 'jquery';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// message formatter
import IntlMessages from 'helper/IntlMessages';
// custom function
import SvgIcon from 'components/CommonComponent/SvgIcon';
import InputComponentText2 from 'components/InputComponent/InputComponentText2';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
// config import
import * as Config from 'config/Config';

export type Props = {
    basketName: string,
    identifier: string,
    basketID: number,
    showEdit: boolean,
    changeBasketName: PropsFunction.PropsInputValueFunction,
    renameBasket: PropsFunction.ParamsIdStringFunction,
}

const BasketRenameModal: React.FC<Props> = (props: Props) => {

    const { basketName, identifier, changeBasketName, renameBasket, basketID, showEdit } = props
    const history = useHistory()

    const [isEditable, setIsEditable] = React.useState<boolean>(false);

    // set jquery function for ellipsis button click
    React.useEffect(() => {
        $(".gi-dash-ellipsis-btn").on('click', function () {
            $('.gi-dash-ao-retirement-card').removeAttr("style");
            if ($(this).hasClass('show')) {
                $(this).closest('.gi-dash-ao-retirement-card').css({ 'z-index': '10', })
            }
        });
    }, [])

    React.useEffect(() => {
        const isEditEnable = Boolean(!(basketName.length > 1 && basketName.length <= Config.MAX_NUMBER_18) || basketName.match(Config.WHITESPACE));
        setIsEditable(isEditEnable)
    }, [basketName])


    // redirect to edit portfolio page(add-portfolio page)
    const gotoEditPortfolio = (): void => {
        history.push({ pathname: '/dashboard/add-portfolio', state: { basketID: basketID } })
    }

    // 
    const clickEdit = (event: React.MouseEvent): void => {
        const basketId = (event.target as HTMLButtonElement).id
        renameBasket(basketId)
    }

    return (
        <div className="gi-edit-port-dropdown">
            <button type="button" className="gi-dash-ellipsis-btn p-0" data-bs-toggle="dropdown" aria-expanded="false" >
                <SvgIcon iconId='kebab-icon' width={3} height={15} />
            </button>
            {/* edit modal */}
            <ul className="dropdown-menu" aria-labelledby="change-dropdown-new">
                <div className="gi-edit-port-card active">
                    {/* rename section */}
                    <div className="gi-edit-port-li gi-edit-port-li-top p-4">
                        <div className="gi-edit-port-form-card">
                            <div className="gi-edit-port-form-text">
                                <span className="d-block mb-2">{IntlMessages('edit_name')}</span>
                            </div>
                            <div className="gi-edit-port-form d-flex justify-content-between gap-2">
                                <InputComponentText2
                                    name={'basketName'}
                                    placeholder={'placeholder_type_here'}
                                    inputValue={changeBasketName}
                                    dataTestId={'basket-name'}
                                    className={'form-control'}
                                    fieldValue={basketName}
                                    readOnly={false}
                                    disabled={false}
                                />
                                <button type="button" className="px-3 text-center border-0" onClick={clickEdit} disabled={isEditable} id={identifier} data-testid='edit-btn-name'>
                                    {IntlMessages('button_edit')}
                                </button>
                            </div>
                            {(basketName.length > Config.MAX_NUMBER_18) && (
                                <ErrorComponent message={['error_max_length', { number: Config.MAX_NUMBER_18 }]} dashboard={true} display={'block'} />
                            )}
                            {(basketName && basketName.match(Config.WHITESPACE)) && (
                                <ErrorComponent message={'error_basket_name'} dashboard={true} display={'block'} />
                            )}
                        </div>
                    </div>
                    {/* edit button */}
                    {showEdit && (
                        <div className="gi-edit-port-li gi-edit-port-li-bottom p-4">
                            <div className="gi-edit-port-text-card d-flex align-items-end justify-content-between">
                                <div className="gi-edit-port-text-first">
                                    <span>{IntlMessages('edit_value')}</span>
                                    <p>{IntlMessages('edit_value_message')}</p>
                                </div>
                                <div className="gi-edit-port-text-second pb-1" onClick={gotoEditPortfolio}>
                                    <button type="button" className="px-3 text-center border-0" data-testid='edit-btn-portfolio'>
                                        {IntlMessages('button_edit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ul>
        </div>
    );
}

export default BasketRenameModal;
