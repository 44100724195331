// default library
import * as React from 'react';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    className: string;
    textStrong: string;
    userName: string | undefined;
    otherAttr: string | PropsFunction.IntlMessages
}

const PageHeader: React.FC<Props> = (props: Props) => {
    const { textStrong, userName, className, otherAttr } = props;

    return (
        <div className="row">
            <div className="col-12">
                <div className={className}>
                    <p data-testid='heading-text'>
                        {otherAttr ?
                            <>
                                <strong>
                                    {IntlMessages(textStrong)}&nbsp;
                                    {userName}!&nbsp;
                                </strong>

                                {typeof (otherAttr) === 'object'
                                    ? IntlMessages(...otherAttr)
                                    : IntlMessages(otherAttr)
                                }
                            </>
                            : <>
                                <strong>
                                    {IntlMessages(textStrong)} &nbsp;
                                </strong>
                                {userName}
                            </>
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PageHeader