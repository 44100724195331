// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    h3: string | PropsFunction.IntlMessages,
    label: string;
    image?: string;
    openModelError: boolean;
    closeErrorModal: PropsFunction.ParamsBooleanFunction
}

const ErrorPendingModal: React.FC<Props> = (props: Props) => {

    const { h3, label, image, openModelError, closeErrorModal } = props;

    const onHandleClose = (): void => {
        closeErrorModal(false)
    }

    return (
        <Modal
            size='xl'
            centered
            show={openModelError}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false} className="gi-port-ao-pc-companies-modal gi-g-new-fund-modal"
        >
            <Modal.Body className='p-xl-4 p-3'>
                <button className="btn-close-white p-0 border-0 position-absolute opacity-100" type="button" onClick={onHandleClose} data-testid='close-icon'></button>

                <div className="p-xl-2 p-0">
                    <div className="gi-g-new-transaction-card d-flex align-items-center justify-content-center">
                        <div className="gi-g-new-transaction-text text-center px-3">
                            {image && <img src={image} className="img-fluid" data-testid={'error-image'} />}
                            <h5 className="mt-3 pt-3">
                                {typeof (h3) === 'object'
                                    ? IntlMessages(...h3)
                                    : IntlMessages(h3)
                                }
                            </h5>
                            <a onClick={onHandleClose} data-testid='close-text'>
                                {IntlMessages(label)}
                            </a>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ErrorPendingModal