//types props
import * as Dashboard from "interface/Dashboard";
import * as BasketType from "api/APIMaster/BasketType";

type CompanyCountData = (
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[],
    allCompanyList: BasketType.CompanyUserExclusionResponseModel[],
    selectedEsgcValueList: BasketType.GiftEsgcValueRequestModel[],
    userExcludedList: string[],
    sp500CompanyList: BasketType.CompanyUserExclusionResponseModel[]
) => Dashboard.CompanyCalculationData


/**
 * Helper function to give the company count and company list
 *
 * @param {BasketType.BasketCustomizationEsgcValueResponseModel[]} esgcValueList list of ESG-C value
 * @param {BasketType.CompanyUserExclusionResponseModel[]} allCompanyList list of all company
 * @param {BasketType.GiftEsgcValueRequestModel[]} selectedEsgcValueList user selected ESG-C value
 * @param {string[]} userExcludedList user excluded companies
 * @param {BasketType.CompanyUserExclusionResponseModel[]} sp500CompanyList S&P500 company list
 * @return {Dashboard.CompanyCalculationData} totalCompanyCount,excludedCompaniesList,addCompaniesList
 */

const companyCountCalculation: CompanyCountData = (esgcValueList, allCompanyList, selectedEsgcValueList, userExcludedList, sp500CompanyList) => {
    const excludedCompanies: BasketType.CompanyUserExclusionResponseModel[] = [] //excluded company
    const userExcludedCompanies: BasketType.CompanyUserExclusionResponseModel[] = [] //excluded company
    const addedCompanies: BasketType.CompanyUserExclusionResponseModel[] = [] //added company

    esgcValueList.map(e => {
        selectedEsgcValueList.map(s => {
            if (s.esgcValueIdentifier === e.identifier) {
                // if deeper clean is true
                if (s.deepClean) {
                    excludedCompanies.push(...e.deepCleanExclusionList)
                } else {
                    excludedCompanies.push(...e.exclusionList)
                }

                // if add good company true
                if (s.addGoodCompanies) {
                    addedCompanies.push(...e.inclusionList)
                }
            }
            return null
        })
        return null
    })

    // user excluded company list
    allCompanyList.map(e => {
        userExcludedList.map(u => {
            if (e.identifier === u) {
                userExcludedCompanies.push(e)
            }
            return null
        })
        return null
    })

    // remove S&P500 companies from added companies
    const spCompaniesList = [...new Set(sp500CompanyList.map((e) => e.tickerSymbol))]

    //get the unique list identifier of excluded companies
    const excludedCompaniesList = [...new Set(excludedCompanies.map((e) => e.tickerSymbol))]

    //get the unique list identifier of user excluded companies
    const userExcludedCompaniesList = [...new Set(userExcludedCompanies.map((e) => e.tickerSymbol))]

    //get the unique list identifier of added companies
    const addedCompanyList = [...new Set(addedCompanies.map((e) => e.tickerSymbol))]

    // ---------------------------------------------------------------------------------------

    // check user exclusion company is part of s&p500 company list
    const inSp500List = userExcludedCompaniesList.filter((e) => spCompaniesList.includes(e));

    // check user exclusion company is part of s&p500 company list
    const notInSp500List = addedCompanyList.filter((e) => spCompaniesList.includes(e));

    // if company exist in then add into excluded company
    const exclusionCompanyList = [...new Set([...excludedCompaniesList, ...inSp500List])]

    // remove user exclusion company list
    const addCompaniesList = addedCompanyList.filter((e) => !userExcludedCompaniesList.includes(e));

    // final added company list
    const finalAddCompaniesList = addCompaniesList.filter((e) => !exclusionCompanyList.includes(e));

    const addedSp500List = addedCompanyList.filter((e) => exclusionCompanyList.includes(e));

    // ---------------------------------------------------------------------------------------

    //total company count after exclusion and inclusion of company
    const totalCompanyCount = spCompaniesList.length - exclusionCompanyList.length + addCompaniesList.length - notInSp500List.length + addedSp500List.length

    const companyCount: Dashboard.CompanyCalculationData = {
        totalCompanyCount: totalCompanyCount, //total company count
        excludedCompaniesList: exclusionCompanyList, //excluded company count
        addCompaniesList: finalAddCompaniesList, //added company count
    }

    return companyCount //company count

}

export default companyCountCalculation;