//default library
import * as React from 'react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//import images
import { useIntl } from 'react-intl';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as PropsFunction from 'interface/PropsFunction';
import * as DriveWealthType from 'api/APIMaster/DriveWealthType';
//api call
import * as DriveWealthService from "middleware/DriveWealthService"
//custom function
import { DateFormatter } from 'service/utils/DateFormatter';
import { DateRange } from 'service/utils/DateFilter';
import { downloadFile, resetStyle } from 'service/utils/DownloadFile';
//default value
import { taxesDropdown } from 'config/DefaultValue';
//custom component
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import PaginationNew from 'components/PaginationComponent/PaginationNew';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

// Define the props type
export type Props = {
    tradeConfirmationData: DriveWealthType.TradeConfirmationResponseModel;
}
// Define the functional component
const TradeConfirmationTableCard: React.FC<Props> = (props: Props) => {
    const { tradeConfirmationData } = props;   // Destructure props

    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error flag
    const [errorLog, setErrorLog] = React.useState<any>({});//error message

    const [currentTradeConfirmationData, setCurrentTradeConfirmationData] = React.useState<DriveWealthType.TradeConfirmationResponseModel>([]);//current trade confirmation data
    const [totalTradeConfirmationData, setTotalTradeConfirmationData] = React.useState<DriveWealthType.TradeConfirmationResponseModel>([]);//total trade confirmation data
    const [dataLoad, setDataLoad] = React.useState<boolean>(false);//loading flag
    const [pdfLoad, setPdfLoad] = React.useState<boolean>(false);//pdf flag
    const [selectedValue, setSelectedValue] = React.useState<PropsFunction.OptionData>(taxesDropdown[0]);

    const intl = useIntl();

    // Custom hooks 
    const { initiateAction } = useAccessToken();

    React.useEffect(() => {
        if (totalTradeConfirmationData.length === 0) {
            setTotalTradeConfirmationData(tradeConfirmationData);
            setDataLoad(true);
        }
    }, []);

    // Pagination callback
    const onPageChanged: PropsFunction.OnPageChanged = (data): void => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentData = totalTradeConfirmationData.slice(offset, offset + pageLimit);
        setCurrentTradeConfirmationData(currentData);

        setTimeout(() => {
            resetStyle(currentData.map((e) => e.fileKey));
        }, 0);
    };

    // Select dropdown callback
    const onSelectValue: PropsFunction.SelectedOption = async (_fieldName, fieldValue): Promise<void> => {
        setDataLoad(false);
        setPdfLoad(true);
        const dateRange = DateRange(fieldValue.value.toString());
        const accessToken = await initiateAction();
        const confirmationData = await DriveWealthService.tradeConfirmationDetails(dateRange, accessToken);

        if (confirmationData.response) {
            setErrorLog(confirmationData)
            setErrorAPI(true);
        }
        else {
            setTotalTradeConfirmationData(confirmationData);
            setDataLoad(true);
            setPdfLoad(false);
            setSelectedValue(fieldValue)
        }

    };

    // Download PDF callback
    const downLoadPDF = async (e: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
        setPdfLoad(true);

        const fileKey = (e.target as HTMLElement).id
        const accessToken = await initiateAction();
        const downLoadURL = await DriveWealthService.downloadPdfDetails(fileKey, accessToken);

        if (downLoadURL.response) {
            setErrorLog(downLoadURL)
            setErrorAPI(true);
        } else {
            downloadFile(downLoadURL, fileKey);
            setPdfLoad(false);
        }
    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {pdfLoad && <LoadingComponent />}

            <div className="row" data-testid='trade-confirmation-card'>
                <div className="col-12">
                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2 mb-10">
                        <div className="gi-d-ac-new-card pt-md-4 p-md-5 pb-md-4 pt-0 p-3">
                            <div className="row gx-20">
                                <div className="col-12">
                                    <div className="px-10">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="gi-d-ac-select-dropdown d-flex align-items-center flex-wrap gap-2 my-4 py-2">
                                                    <div>
                                                        <label>
                                                            {IntlMessages('table_range')}:
                                                        </label>
                                                    </div>
                                                    <div className='activity-dropdown'>
                                                        <SelectDropdown
                                                            labelClassName={''}
                                                            labelText={''}
                                                            name='tradeConfirmation'
                                                            option={taxesDropdown}
                                                            fieldValue={selectedValue}
                                                            inputValue={onSelectValue}
                                                            placeholder={''}
                                                            ariaLabel={"trade-confirmation-label"}
                                                            className={'custom-react-fund'}
                                                            isDisabled={false}
                                                            isModalDropdown={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped gi-d-table-striped mb-30" role="presentation">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <span>
                                                            {IntlMessages('table_date')}
                                                        </span>
                                                    </th>
                                                    <th scope="col">
                                                        <span>
                                                            {IntlMessages('table_trade')}
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTradeConfirmationData.length > 0
                                                    ? currentTradeConfirmationData.map((e, i) => (
                                                        <tr key={i} data-testid="tabel-content">
                                                            <td>
                                                                <span data-testid='statement-date'>
                                                                    {DateFormatter(e.statementDate)}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {e.fileKey && (
                                                                    <div data-testid="file-pdf">
                                                                        <a onClick={downLoadPDF} id={e.fileKey} style={{ display: 'block' }} >
                                                                            <img src={intl.formatMessage({ id: 'PDF_ICON' })} alt="PDF_ICON" id={e.fileKey} />
                                                                        </a>
                                                                        <a id={`${e.fileKey}-view`} target="_blank" rel="noreferrer" style={{ display: 'none' }}>
                                                                            {IntlMessages('button_view')}
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : <tr className="bg-light" data-testid="tabel-content">
                                                        <td colSpan={12}>
                                                            <span>
                                                                {IntlMessages('table_no_data')}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {dataLoad && <PaginationNew totalRecords={totalTradeConfirmationData.length} pageLimit={5} pageNeighbors={1} onPageChanged={onPageChanged} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TradeConfirmationTableCard;