// default library
import * as React from 'react';
// auth0
import { useAuth0 } from '@auth0/auth0-react'
// custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// message import
import IntlMessages from 'helper/IntlMessages';
// custom logout
import { useLogoutHook } from 'helper/Logout';
// jwt token decode
import jwt from 'jwt-decode';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// api call
import { resendEmail } from 'middleware/UserService';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import LogoComponent from 'components/LogoComponent/LogoComponent';

const IL_FLOW = JSON.parse(import.meta.env.REACT_APP_IL_FLOW)

const ThankYouPage: React.FC = () => {

    const { initiateAction } = useAccessToken();
    const { user, isAuthenticated } = useAuth0();
    const logoutUser = useLogoutHook();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false); //error occurred flag

    React.useEffect(() => {
        (async () => {
            if (!locale.isReducerCalled && isAuthenticated) {
                if (IL_FLOW) {
                    const accessToken = await initiateAction()
                    const countryCode = jwt<string>(accessToken)[import.meta.env.REACT_APP_COUNTRY_CODE];
                    !locale.isReducerCalled && dispatch(setCurrentLanguage(countryCode));
                } else {
                    dispatch(setCurrentLanguage('USA'))
                }
            }
        })()
    }, [isAuthenticated])

    // resend email
    const resendEmailAPI = async (): Promise<void> => {
        setIsLoading(true);

        const accessToken = await initiateAction() //get access token
        const resendApi = await resendEmail(accessToken)

        if (resendApi.response || resendApi.request) {
            setErrorLog(resendApi)
            setErrorAPI(true);
        }

        setIsLoading(false);
    }

    // logout function
    const logOut = (): void => {
        logoutUser()
    }

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}

            <div className="gi-thanks-page w-100 min-vh-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-md-5">
                                    <div className="gi-thanks-modal p-5">
                                        <div className="gi-thanks-modal-logo pb-4 d-flex justify-content-center">
                                            <div className="gi-header-left">
                                                <LogoComponent />
                                            </div>
                                        </div>
                                        <div className="gi-thanks-modal-text pt-4 text-center">
                                            <h5>
                                                {IntlMessages('thank_you1')}
                                            </h5>
                                            <p className="mt-3">
                                                {IntlMessages('verify_email_t1')}
                                            </p>
                                            <span className="d-block p-3 my-4">
                                                {user?.email}
                                            </span>
                                            <a href="#!" onClick={resendEmailAPI}>
                                                {IntlMessages('resend_email')}
                                            </a>
                                            <h6 className="mt-4 pt-1">
                                                {IntlMessages('wrong_email')}&nbsp;
                                                <a href="#!" onClick={logOut}>
                                                    {IntlMessages('sign_out')}
                                                </a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYouPage