export const currencyFormatter = (num: number): string => {

    const roundNum: number = parseFloat(Number(num).toFixed(2))
    const number: number = roundNum === 0.00 ? Math.abs(roundNum) : roundNum

    const currency: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number);

    return currency
}