//default library
import * as React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom'
// custom pages
import { onBoardingRoute } from 'routes/Route'
// custom component
import KYCHeader from 'components/HeaderComponent/KYCHeader'
import KYCProgressBar from 'components/ProcessBarComponent/KYCProgressBar'
import KYCLogo from 'components/LogoComponent/KYCLogo'
import KYCFooter from 'components/FooterComponent/KYCFooter'
import EncryptionInformation from 'components/SectionComponent/EncryptionInformation'

import * as RouteInterface from 'interface/Route';
import * as UserType from "api/APIMaster/UserType"

export type Props = {
    staticData: UserType.OnboardingStaticDataResponseModel
}
interface PreviousData {
    previousPage: string;
    pageNumber: number;
}
const InformationPage: React.FC<Props> = (props: Props) => {
    const { staticData } = props;

    const [pervPage, setPervPage] = React.useState<string>('');
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    const propsData = (previousData: PreviousData) => {
        setPervPage(previousData.previousPage)
        setCurrentPage(previousData.pageNumber)
    }

    return (
        <>
            <div className="gi-ob-body-main">
                {/* header */}
                <KYCHeader perviousPage={pervPage} isFirstPage={false} />

                {/* progress bar */}
                <KYCProgressBar pageNumber={currentPage} />

                {/* body */}
                <section className="gi-ob-main-section">
                    <div className="container">
                        <div className="row">
                            <div className="co-12">
                                {/* logo component for KYC */}
                                <KYCLogo />

                                {/* content */}
                                <Switch >
                                    {onBoardingRoute.map((ele: RouteInterface.Route, i) => {
                                        return (
                                            <Route exact path={ele.path} key={i}>
                                                <ele.Component staticData={staticData} propsData={propsData} />
                                            </Route>
                                        )
                                    })}
                                    <Route path={'*'}>
                                        <Redirect to='/error' />
                                    </Route>
                                </Switch>
                            </div>
                        </div>

                        {/* Encryption Information Text */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-ob-advisory-text">
                                    <EncryptionInformation />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* footer */}
                <KYCFooter />
            </div>
        </>
    );
}

export default InformationPage
