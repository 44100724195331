//default library
import * as React from 'react';

// lottie animation
import lottie from 'lottie-web/build/player/lottie_light'

// loading animation
import LoadingAnimation from 'service/animation/Loading.json';


const animationStyle = {
  height: 295,
  width: 250,
  margin: '0 auto',
}

const LoadingComponent: React.FC = () => {

  const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

  // load lottie animation
  React.useEffect(() => {
    lottie.loadAnimation({
      container: lottieAnimation.current as any,
      renderer: 'svg',
      animationData: LoadingAnimation,
      loop: true, // Set to true if you want the animation to loop
      autoplay: true, // Set to true if you want the animation to play automatically
    });
  }, [])

  return (
    <div className="gi-spinner-animation">
      <div ref={lottieAnimation} style={animationStyle} />
    </div>
  )
}

export default LoadingComponent