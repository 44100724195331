import GiftService from 'api/APIServices/GiftService';
import * as Local from 'service/utils/LocalStorageData';

const Gift = new GiftService();

export const updateGiftUser = async (giftIdentifier: string, accessToken: string) => {
    const data = {
        giftIdentifier
    };

    return await Gift.updateGiftUser(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getGiftUserByAccessToken = async (accessToken: string) => {

    return await Gift.getGiftUserByAccessToken({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const giftReceivedDetails = async (accessToken: string) => {

    const data = {
        locale: Local.getLocalData('currentLanguage'),
    }

    return await Gift.giftReceivedDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const redeemGiftCodeDetails = async (giftCode: string, accessToken: string,) => {

    return await Gift.redeemGiftCodeDetails(giftCode, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}