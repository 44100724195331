// default library
import * as React from 'react';
// type import
import * as PropsFunction from "interface/PropsFunction";
// intl message
import { useIntl } from 'react-intl';

export type Props = {
    labelText: string | React.ReactNode;
    fieldName: string;
    fieldValue: string;
    checkValue: boolean;
    inputClass: string;
    labelClass: string;
    dataTestId: string;
    inputValue: PropsFunction.PropsStringBooleanFunction;
    infoOpen?: PropsFunction.ParamsIdStringFunction;
}

const InputCheckboxComponent: React.FC<Props> = (props: Props) => {
    const { labelText, fieldName, dataTestId, fieldValue, checkValue, inputClass, labelClass, inputValue, infoOpen } = props;
    const intl = useIntl()

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.value, target.checked);// Call the inputValue function with the updated value
    };

    // info button click
    const handleInfoClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        const target = e.target as HTMLAnchorElement
        infoOpen && infoOpen(target.id)
    };

    return (
        <>
            <input
                type='checkbox'
                name={fieldName} //set field name
                id={fieldName} //set field name
                value={fieldValue} //set field value
                checked={checkValue}
                className={inputClass}
                data-testid={dataTestId}
                onChange={handleOnChange} //invoke on every key press
            />
            {labelText && (
                <label className={labelClass} htmlFor={fieldName}>
                    {labelText}
                    {infoOpen && (
                        <a id={fieldName} onClick={handleInfoClick}>
                            <img className='ms-1' src={intl.formatMessage({ id: 'IL_ONBOARDING_INFO_GREY' })} alt='IL_ONBOARDING_INFO_GREY' />
                        </a>
                    )}
                </label>
            )}
        </>
    )
}

export default InputCheckboxComponent