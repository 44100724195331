// default library
import * as React from 'react';
import $ from 'jquery'

export type Props = {
    pageNumber: number
}

const KYCProgressBar: React.FC<Props> = (props: Props) => {
    const { pageNumber } = props;

    React.useEffect(() => {
        const handleResize = () => {
            const totalWidth = window.innerWidth - 400;
            const parentageWidth = totalWidth / 15;
            const calculate = parentageWidth * pageNumber;
            $(".gi-ob-progress-main").css("width", 200 + calculate + 'px');
        };

        handleResize(); // Initial setup

        $(window).on('resize', handleResize);

        return () => {
            $(window).off('resize', handleResize);
        };
    }, [pageNumber])
    return (
        <section className="gi-ob-progress">
            <div className="gi-ob-progress-main"></div>
        </section>
    )
}

export default KYCProgressBar