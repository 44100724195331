import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'reduxStore/store'

export type InitialState = {
    email: string | undefined;
    firstNameLocale: string;
    lastNameLocale: string;
    phoneNumber: string;
    otpVerified: boolean;

    dob: string | null;
    firstNameEn: string | null;
    lastNameEn: string | null;
    taxPayer: string;
    gender: string;

    street1: string;
    city: string;
    houseNumber: string;
    apartmentUnit: string;
    postalCode: string;
    province: string;
    countryIdentifier: string;
    reportsMailingChoice: string;

    employmentStatus: string;
    companyName: string;
    position: string;
    occupationField: string;

    annualIncome: number | string;

    investingExperience: string;

    investmentPurpose: string;
    investmentAmount: number | string;

    amountIntent: string;
    netWorthTotal: number | null;

    investmentFunds: string;

    sourceMoney: string;
    otherSource: string;

    publicOfficial: string;
    publicOfficialText: string;
    stockOwner: string;
    companyNameStock: string;
    tickerSymbol: string;
    brokerDealer: string;

    riskToleranceIdentifier: string | null;

    passportDoc: boolean;
    drivingDoc: boolean;
}

export const initialState: InitialState = {
    email: '',
    firstNameLocale: '',
    lastNameLocale: '',
    phoneNumber: '',
    otpVerified: false,

    dob: null,
    firstNameEn: null,
    lastNameEn: null,
    taxPayer: '',
    gender: '',

    street1: '',
    city: '',
    apartmentUnit: '',
    postalCode: '',
    houseNumber: '',
    province: '',
    countryIdentifier: '',
    reportsMailingChoice: '',

    employmentStatus: '',
    companyName: '',
    position: '',
    occupationField: '',

    annualIncome: 0,

    investingExperience: '',

    investmentAmount: 0,
    investmentPurpose: '',

    amountIntent: '',
    netWorthTotal: null,

    investmentFunds: '',

    sourceMoney: '',
    otherSource: '',

    publicOfficial: 'false',
    publicOfficialText: '',
    stockOwner: 'false',
    companyNameStock: '',
    tickerSymbol: '',
    brokerDealer: 'false',

    riskToleranceIdentifier: null,

    passportDoc: false,
    drivingDoc: false,
}

const israelKYCSlice = createSlice({
    name: 'israelKYC',
    initialState,
    reducers: {
        setKYCData: (state: InitialState, action: PayloadAction<any>) => {
            return ({
                ...state,
                ...(action.payload)
            })
        }
    }
})

export const { setKYCData } = israelKYCSlice.actions;

export default israelKYCSlice.reducer;

export const getKYCData = (state: RootState) => state.israelKYC;