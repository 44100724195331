// default library
import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, setCurrentAPILang, getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// message import
import IntlMessages from 'helper/IntlMessages'
import { useIntl } from 'react-intl';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// custom hook
import { useLogoutHook } from 'helper/Logout';
// api call
import * as UserService from 'middleware/UserService';
// custom component
import DashboardProfile from 'components/ProfileComponent/DashboardProfile'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

type Props = {
    dashboard: boolean;
    menuOpen: PropsFunction.SimpleFunction;
}

const DashboardHeader: React.FC<Props> = (props: Props) => {

    const { dashboard, menuOpen } = props;
    const history = useHistory();
    const logoutUser = useLogoutHook()
    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const dispatch = useDispatch();
    const appCommonData = useSelector(getAppCommonData);
    const locale = useSelector(getLocale);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [isLoading, setIsLoading] = React.useState<boolean>(false);  //error logs

    const logOut = (): void => {
        logoutUser()
    }

    const openSetting = (): void => {
        history.push('/dashboard/account-setting')
    }

    const openContact = (): void => {
        history.push('/dashboard/contact-us')
    }

    // change local
    const changeLocal = async (e: React.MouseEvent): Promise<void> => {
        const ele = (e.target as HTMLAnchorElement).getAttribute('data-value')
        if (ele) {
            setIsLoading(true);

            const accessToken = await initiateAction() //get access token
            const isLocaleUpdate = await UserService.uploadLocale(ele, accessToken);

            setIsLoading(false);

            if (isLocaleUpdate.response || isLocaleUpdate.request) {
                setIsError(true);
                setErrorLog(isLocaleUpdate)
            } else {
                dispatch(setCurrentAPILang(ele))
                dispatch(setCurrentLanguage(ele))
            }
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    // check the user country if country is IL or user from IL 
    const showLanguageOption: boolean = appCommonData.kycCountry ? appCommonData.kycCountry === 'ISR' : (locale.code === 'ISR' || locale.code === 'ISR_EN');

    return (
        <>
            {isLoading && <LoadingComponent />}

            <header className="gi-g-header">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-g-header-main">
                                <div className="gi-g-logo">
                                    <NavLink exact className="nav-link" to={'/'} activeClassName='active'>
                                        <img className="" src={intl.formatMessage({ id: 'DASHBOARD_HEADER_LOGO' })} alt="" />
                                    </NavLink>
                                </div>
                                <div className="gi-g-mini-nav">
                                    <div className="gi-g-profile d-none d-md-flex">
                                        <DashboardProfile />
                                    </div>
                                    {dashboard && (
                                        <div className="gi-g-menu-btn" onClick={menuOpen}>
                                            <button id="gi-menu-btn" />
                                        </div>
                                    )}
                                    <div className="dropdown gi-g-toggle-ellipsis">
                                        <a className="gi-g-vertical-ellipsis-icon" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {!appCommonData.isNewUser && (
                                                <li>
                                                    <a className='dropdown-item' onClick={openSetting}>
                                                        {IntlMessages('dashboard_account_setting')}
                                                    </a >
                                                </li>
                                            )}
                                            <li>
                                                <a className='dropdown-item' onClick={openContact}>
                                                    {IntlMessages('dashboard_contact_us')}
                                                </a>
                                            </li>
                                            <li>
                                                <a type='button' className="dropdown-item" onClick={logOut}>
                                                    {IntlMessages('logout2')}
                                                </a>
                                            </li>
                                            <div className="space"></div>
                                            {showLanguageOption && (
                                                <div className="language">
                                                    <li>
                                                        <a className="dropdown-item lang-he" href="#" data-value="ISR" onClick={changeLocal}>
                                                            עברית
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item lang-en" href="#" data-value="ISR_EN" onClick={changeLocal}>
                                                            English
                                                        </a>
                                                    </li>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default DashboardHeader