// default library
import * as React from 'react';

type Props = {
    iconId: string;
    [key: string]: any
}

const SvgIcon: React.FC<Props> = (props: Props) => {

    const { iconId, ...res } = props;

    return (
        <svg style={res}>
            <use href={`${window.location.origin}/svg/giraffe.svg#${iconId}`}></use>
        </svg>
    );
}

export default SvgIcon