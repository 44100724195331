// default library
import React from "react";
import { Modal } from "react-bootstrap";

// error handlers
import { ExceptionResponse } from "api/AxiosClient";
import APIErrorHandle from "service/utils/APIErrorHandle";

// datadog logs
import { datadogLogs } from "@datadog/browser-logs";

// intl messages
import IntlMessages from "helper/IntlMessages";
import { wrapClassInHook } from "helper/Logout";

type ErrorState = { isError?: Error; errorLog?: ExceptionResponse };

type Props = {
    children: React.ReactNode;
    auth0?: any;
    logoutUser?: any;
};

class ErrorBoundaries extends React.PureComponent<Props, ErrorState> {
    constructor(props: Props) {
        super(props);

        this.onHandleClose = this.onHandleClose.bind(this);
    }

    state: ErrorState = {
        isError: undefined,
        errorLog: undefined,
    };

    static getDerivedStateFromError(isError: Error) {
        return { isError };
    }

    isJsonString(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        const errorMessage = this.isJsonString(error?.message)
            ? JSON.parse(error?.message)
            : false;

        const errorData = APIErrorHandle(errorMessage);
        datadogLogs.logger.error("ErrorHandler:- ", { apiError: errorData });
        datadogLogs.logger.error("StackError:- ", { stackError: info });

        this.setState({
            errorLog: errorData,
        });
    }

    onHandleClose(): void {
        const { isAuthenticated } = this.props.auth0; //auth0 props

        const pathName = window.location.pathname; //get window path

        // if is logged in user
        if (isAuthenticated) {
            // if path is dashboard, onBoarding, kycPending and occurred then logout user
            if (
                pathName === "/dashboard" ||
                pathName === "/on-boarding" ||
                pathName === "/on-boarding/task-complete"
            ) {
                this.props.logoutUser();
            } else {
                //else redirect to dashboard,
                window.location.replace("/");
            }
        }
    }

    render() {
        const { isError, errorLog } = this.state;
        const { children } = this.props;

        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(isError)) {
            return (
                <>
                    <Modal
                        centered
                        show={true}
                        backdrop="static"
                        keyboard={false}
                        className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal"
                        onHide={this.onHandleClose}
                    >
                        <Modal.Header className="px-3 py-2">
                            <button className="btn-close p-0 m-0 border-0 opacity-100" type="button" aria-label="Close" onClick={this.onHandleClose}></button>
                        </Modal.Header>
                        <Modal.Body className="px-5 pt-3 pb-3">
                            <div className="error-modal-body d-flex align-items-center justify-content-center">
                                <div className="error-modal-text error-modal-text-react">
                                    <p className="mb-4">
                                        {errorLog && errorLog.message
                                            ? errorLog.message
                                            : IntlMessages("error_unable_process")}
                                    </p>
                                    <button
                                        className="error-boundaries"
                                        onClick={this.onHandleClose}
                                    >
                                        {IntlMessages("error_please_try")}
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            );
        }
        //if error not occurred then return child component
        return children;
    }
}

export default wrapClassInHook(ErrorBoundaries);
