import * as BasketType from 'api/APIMaster/BasketType';
import * as Dashboard from 'interface/Dashboard'

export const esgcValueList: BasketType.BasketDetailEsgcValueResponseModel = {
    name: '',
    description: '',
    myImpactPortfolioBannerDesktop: '',
    myImpactPortfolioBannerMobile: '',
    myImpactPortfolioBannerText: '',
    myImpactPortfolioDeepCleanBannerText: '',
    titleLinearGradientDirectionDeg: '',
    titleLinearGradientColorStop1: '',
    titleLinearGradientColorStopPercent1: '',
    titleLinearGradientColorStop2: '',
    titleLinearGradientColorStopPercent2: '',
    giftImage: '',
    deepClean: false,
}

export const companyList: BasketType.BasketCompanyResponseModel = {
    companyName: '',
    tickerSymbol: '',
    industry: '',
    weight: 0,
    value: 0,
}


export const companySummary: BasketType.BasketCompanySummaryResponseModel = {
    totalCompanies: 0,
    totalIndustries: 0,
    excludedCompanies: 0,
    addedCompanies: 0,
    totalCompanyList: [companyList],
    excludedCompanyList: [companyList],
    addedCompanyList: [companyList],
}

export const esgcValueBreakDownList: BasketType.EsgcValueBreakDownResponseModel = {
    id: 0,
    identifier: '',
    name: '',
    boosted: false,
    expenseRatioPercent: 0,
}

export const expenseRatio: BasketType.ExpenseRatioResponseModel = {
    total: 0,
    esgcValueBreakDownList: [esgcValueBreakDownList],
}

export const basketDetail: BasketType.BasketDetailResponseModel = {
    id: 0,
    identifier: '',
    name: '',
    type: '',
    isGiftBasket: false,
    currentValue: 0,
    isInvestmentPending: false,
    isFirstInvestmentPending: false,
    investmentPendingDate: '',
    isPending: false,
    isOrderOnHold: false,
    isBankOrderPending: false,
    pendingTransactionDate: '',
    pendingOrderType: '',
    pendingOrderAmount: 0,
    allowCashOut: false,
    allowCashOutOnly: false,
    totalGain: 0,
    totalCashAvailableForTrade: 0,
    esgcValueList: [esgcValueList],
    companySummary: companySummary,
    twentyYearProjection: 0,
    expenseRatio: expenseRatio,
    canBeDeleted: false,
    minSellValue: 0,
    maxSellValue: 0,
}

export const companyCountData: Dashboard.CompanyCountData = {
    totalCompanyCount: 0,
    excludedCompanyCount: 0,
    addedCompanyCount: 0,
}

export const sp500GraphData: BasketType.BasketPerformanceComparisonData = {
    monthlyPerformance: [],
    yearlyPerformance: []
}