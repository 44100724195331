import * as React from 'react';
import { useParams } from 'react-router-dom';
// intl message library 
import { useIntl } from 'react-intl';
// API call
import * as UserService from 'middleware/UserService';
// custom component
import GiftHeader from 'components/HeaderComponent/GiftHeader';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent'
import IntlMessages from 'helper/IntlMessages';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// type import
import * as Route from 'interface/Route';
import * as DefaultValueType from 'interface/DefaultValueType';
import * as OnBoarding from 'interface/OnBoarding';


const emailNotification: DefaultValueType.OtherSourceOption = [
    { label: IntlMessages('email_subscription_marketing'), name: 'marketing', active: false },
    { label: IntlMessages('email_subscription_learn'), name: 'learn', active: false },
]

const EmailSubscription: React.FC = () => {
    const params: Route.UrlParams = useParams();
    const emailIdentifier: string = params.id; //set the email identifier
    const intl = useIntl();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    const [isLoading, setIsLoading] = React.useState<boolean>(true); //loading page
    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [emailNotificationList, setEmailNotificationList] = React.useState<OnBoarding.CheckBoxList>(emailNotification);

    const [updateSubscriptionFlag, setUpdateSubscriptionFlag] = React.useState<boolean>(false); //update email subscription flag
    const [checkUpdateButton, setCheckUpdateButton] = React.useState<boolean>(true); //true when we don't click any of the option for update notification button

    const [userEmail, setUserEmail] = React.useState<string>(''); //user email id

    React.useEffect(() => {
        if (!locale.isReducerCalled) {
            dispatch(setCurrentLanguage('USA'))
        }
    }, [])

    React.useEffect(() => {
        (async () => {
            window.scroll(0, 0)

            const subscriptionDetail = await UserService.emailSubscriptionDetail(emailIdentifier); //email subscription detail
            if (subscriptionDetail.response) {
                setErrorLog(subscriptionDetail)
                setIsError(true)
            } else {
                const updatedOptions = emailNotificationList.map(option => {
                    if (option.name === 'marketing') {
                        return { ...option, active: subscriptionDetail.marketing };
                    }
                    if (option.name === 'learn') {
                        return { ...option, active: subscriptionDetail.learn };
                    }
                    return option;
                });

                setUserEmail(subscriptionDetail.email);
                setEmailNotificationList(updatedOptions);
                setUpdateSubscriptionFlag(false);
                setCheckUpdateButton(true);
            }
            setIsLoading(false)
        })()
    }, []);

    //  updating input values in state
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        setEmailNotificationList((prev) => {
            const notificationItem = [...prev];
            const index = notificationItem.findIndex(e => e.name === fieldName);
            notificationItem[index].active = fieldValue;
            return notificationItem;
        })
        setCheckUpdateButton(false)
        setUpdateSubscriptionFlag(false);
    }

    // update subscription
    const emailUnsubscribeAll = async (): Promise<void> => {
        await setEmailNotificationList((prev) => {
            const notificationItem = [...prev];
            notificationItem.forEach(e => e.active = false);
            return notificationItem;
        })
        setCheckUpdateButton(false);
        updateSubscription()
    }

    // update email subscribe api call
    const updateSubscription = async (): Promise<void> => {
        setIsLoading(true);

        const updateDetails = {
            emailIdentifier: emailIdentifier,
            marketing: emailNotificationList.find(e => e.name === 'marketing')?.active ?? false,
            learn: emailNotificationList.find(e => e.name === 'learn')?.active ?? false
        }
        const updateSubscription = await UserService.updateEmailSubscription(updateDetails)

        setIsLoading(false);
        if (updateSubscription.response) {
            setErrorLog(updateSubscription)
            setIsError(true)
        } else {
            setCheckUpdateButton(true)
            setUpdateSubscriptionFlag(updateSubscription)
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            {/* header */}
            <GiftHeader giftHash={''} />

            {/* main content */}
            <section className="gi-unsubscribe-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-unsubscribe-main">
                                <div className="gi-unsubscribe-top">
                                    <h4 className="text-center">{IntlMessages('email_subscription_text1')}</h4>
                                    <p className="text-center">
                                        <span className="d-block text-center"> {IntlMessages('email_subscription_text2')} {userEmail}</span>
                                        {IntlMessages('email_subscription_text3')}
                                    </p>
                                </div>
                                <div className="gi-unsubscribe-center  pt-5">
                                    <div className="row justify-content-center">
                                        <div className="col-md-6 mx-auto">
                                            <div className="gi-unsubscribe-card">
                                                <div className="gi-unsubscribe-card-header py-2 px-4">
                                                    <h5>{IntlMessages('email_subscription_text4')}</h5>
                                                </div>
                                                <div className="gi-unsubscribe-card-center px-4 pt-4 pb-5">
                                                    <div className="gi-unsubscribe-radio">
                                                        {emailNotificationList.map((e, i) => (
                                                            <div className="gi-onboard-check-inline form-check" key={i}>
                                                                <InputCheckboxComponent
                                                                    labelText={e.label}
                                                                    fieldName={e.name}
                                                                    fieldValue={e.name}
                                                                    checkValue={e.active}
                                                                    inputClass={'form-check-input'}
                                                                    labelClass={'form-check-label'}
                                                                    inputValue={setInputValueCheckbox}
                                                                    dataTestId={'check-box1'}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="gi-unsubscribe-btn-group mt-4 d-flex gap-3 flex-wrap">
                                                        <button type="button" className="gi-unsub-update-btn text-center" onClick={updateSubscription} disabled={checkUpdateButton}>
                                                            {IntlMessages('email_subscription_button1')}
                                                        </button>
                                                        <button type="button" className="gi-unsub-unsubscribe-btn text-center" onClick={emailUnsubscribeAll}>
                                                            {IntlMessages('email_subscription_button2')}
                                                        </button>
                                                    </div>
                                                </div>
                                                {updateSubscriptionFlag && (
                                                    <div className="gi-unsubscribe-card-footer px-4 py-2">
                                                        <p className='my-1 ps-4'>{IntlMessages('email_subscription_notification')}</p>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-md-6 mx-auto">
                                        <div className="gi-unsubscribe-bottom">
                                            <p>{IntlMessages('email_subscription_text5', { br: <br /> })}
                                                <a href="https://www.transamerica.com/privacy-policy" target="_blank" rel="noreferrer">
                                                    &nbsp;{IntlMessages('email_subscription_text6')}&nbsp;
                                                </a>
                                                {IntlMessages('email_subscription_text7')}
                                                <a href="https://www.transamerica.com/sms-terms-of-use" target="_blank" rel="noreferrer">
                                                    &nbsp;{IntlMessages('email_subscription_text8')}
                                                </a>
                                            </p>
                                            <img src={intl.formatMessage({ id: 'SUBSCRIPTION_GIRAFFE' })} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ >
    );
}

export default EmailSubscription