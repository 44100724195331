const Dashboard: { [key: string]: string } = {

    /* Home Page */
    'home_heading': `Here's an overview of your investment`,
    'home_today': `Invest today {br} for a better tomorrow...`,
    'home_tomorrow': `for a better tomorrow...`,
    'home_processing': `Great! Processing your {basketName} portfolio now.`,
    'home_portfolio': `portfolio now.`,
    'home_fund': `Fund your {br} future today`,
    'home_future': `future today`,
    'home_withdraw': `Withdraw money`,
    'home_tooltip': `Withdraw money from your giraffe account to your bank account`,
    'home_banner_t1': `Two main things to do`,
    'home_banner_t2': `Build your index {br} Give a gift`,
    'home_gift_date': `Date redeemed`,
    'home_static_t1': `{span} this is a hypothetical projection based on the historical returns of the S&P 500`,
    'account_overview': `Account overview`,
    'account_value': `Account value`,
    'total_portfolio_value': `Total portfolio value`,
    'cash_balance': `Cash balance`,
    'home_pending_gift_text1': `Hi, you have a new gift!`,
    'home_pending_gift_text2': `You have a new gift!`,
    'awarded_by': `Awarded by:`,
    'redeem_your_gift': `Redeem your gift`,
    'portfolio_value': `Portfolio value`,
    'my_impact_slider_heading1': `The impact you've made with this portfolio`,
    'my_impact_slider_heading2': `Your portfolio's impact`,
    'my_impact_slider_body1': `Make an impact`,
    'my_impact_slider_body2': `Your selected values`,
    'my_impact_slider_body3': `Your portfolio's impact`,

    /* Transaction card heading */
    'transaction_total_account': `Total account`,
    'transaction_total_current': `Current total invested`,
    'transaction_total_cash': `Total cash`,
    'transaction_earning': `Earnings`,
    'transaction_current': `Current value`,
    'transaction_cash_balance': `Cash Balance`,
    'transaction_projection': `20 years projection`,
    'transaction_total_value': `Total Value`,
    'transaction_redeemed_value': `Redeemed Value`,
    'transaction_in_settlement': `In settlement`,
    'transaction_performance': `Performance`,
    'transaction_future_projection': `Future projection`,
    'table_value': `Value`,
    'table_percentage_of_basket': `Percentage of basket`,

    /* Basket Details Page */
    'basket_historical': `Historical Returns`,
    'basket_future': `Future projection`,
    'basket_not_enough': `Not enough data to display`,
    'basket_chart_text1': `Chart is a hypothetical comparison. Investing involves risk of loss and performance is not guaranteed.`,
    'basket_chart_text2': `Projected variance of your portfolio vs. the S&P 500 over the same time`,
    'basket_your_value': 'Your values',
    'basket_companies': `Companies`,
    'basket_industry': `Industries`,
    'basket_excluded_companies': `Excluded companies`,
    'basket_view_list': `View list`,
    'basket_companies_list': `{totalCompany} Companies Total`,
    'basket_excluded': `Excluded`,
    'basket_excluded1': `{excludedCompany} Excluded`,
    'basket_expense': `Expense ratio`,
    'basket_text1': `The following companies have met the criteria for high return without compromising on your values.`,
    'basket_text2': `The following companies were excluded from your portfolio because they do not align with your values.`,
    'basket_expense_text2': `See below for a breakdown of this portfolio's expenses:`,
    'basket_expense_breakdown': `Expense breakdown:`,
    'basket_boosted': `Boost`,
    'basket_boosted2': `Boosted`,
    'basket_expense_ratio': `Total Expense Ratio`,
    'basket_change_name': 'Change portfolio name:',
    'basket_excluded_text': `Choose your values above to start excluding companies and customizing the S&P 500`,
    'basket_delete': `Are you sure you want to permanently delete this portfolio?`,
    'edit_name': `Edit portfolio name:`,
    'edit_value': `Edit portfolio values`,
    'edit_value_message': `Edit portfolio name:`,
    'compare_sp500_graph': `Compare to the S&P 500`,
    'label_your_portfolio': `Your portfolio`,
    'compare_sp500': `S&P 500`,
    'future_average': `Average`,
    'future_range': `Range`,
    'future_text1': `Please note that the future projection of returns provided is for illustrative purposes only. `,
    'future_text2': ` about our calculation methodology.`,
    'portfolio_companies': `Portfolio companies`,
    'excluded_companies': `Excluded companies`,
    'added_companies': `Added companies`,
    'added_companies_text': `The following companies were added to your portfolio because they excel in the values you care about.`,
    'company_name_ticker': `Company name (Ticker)`,
    'escg_value_info_heading': `By choosing a value, you'll be excluding the companies that scored the lowest in the S&P 500 in that value`,

    /* Bank connect */
    'bank_text1': `In order to invest {br} please connect to your bank account`,
    'bank_text2': `please connect to your bank account`,
    'bank_text3': `In order to withdraw cash, {br} please connect to your bank account`,

    /* Fund Modal */
    'fund_select_amount': `Select amount`,
    'fund_account': `From account:`,
    'fund_portfolio': 'To portfolio:',
    'giraffe_cash_account': 'Your Giraffe Cash Account',
    'fund_confirm_transaction': 'Please confirm your transaction',
    'fund_preparing': 'Preparing Transfer',
    'fund_success': 'Transfer success!',
    'fund_your_portfolio': 'Fund your {br} portfolio',
    'bank_transfer': 'Bank transfers take 2-3 days to process.',
    'fund_transfer_market_close': 'We will finalize the transaction while the stock market is open.',
    'fund_transfer_market_open': 'Your transaction will be completed in the next 5-10 minutes.',
    'fund_transfer_text1': '{sellBalance} available to sell',
    'fund_cash_available': 'Cash available for trade:',
    'fund_static_il_text1': 'At this moment funds are transferred through your employer only',
    'fund_static_il_text2': 'We are glad you want to save more on your own and will let you know once this service is available',

    /* Buy Modal */
    'buy_head1': `Add money to your`,
    'buy_head2': `do-good portfolio`,

    /* Sell Modal */
    'sell_head': 'Sell',
    'sell_portfolio': 'From portfolio:',
    'sell_amount': `Amount`,
    'sell_stock': `Sell stocks`,
    'sell_value_stock': `Value of stocks:`,
    'sell_current_value': `Current value:`,
    'select_amount': `Select amount`,
    'select_amount_placeholder': 'From ${minSellValue} to ${maxSellValue}',
    'sell_modal_note_text1': 'Please note: ',
    'sell_modal_note_text2': 'Due to market conditions, the final amount transferred to your account may slightly differ from the current balance.',
    'approve_sale_btn': 'Approve sale',
    'sell_all_text': 'Sell all',
    'partial_sell_text': 'Partial sale',

    /* Withdraw Modal */
    'withdraw_head1': `Withdraw to your {br} bank account`,
    'withdraw_text1': `You cannot transfer more than you have in cash`,
    'withdraw_account': `To account:`,
    'withdraw': `Withdraw`,
    'withdraw_charge': `Our partners charge $0.25 for outgoing money transfers`,
    'withdraw_bank_account': 'Withdraw to your {br} bank account',
    'withdraw_text2': `* Giraffe and Drivewealth have a seasoning period that prevents unnecessary charges for our users. You can withdraw cash from your Giraffe account at least five days after depositing it.`,
    'withdraw_text3': 'Your cash available for withdrawal: {amount}*',
    'withdraw_amount_il': 'Enter amount from Giraffe cash account',
    'withdraw_cash_available': 'Cash available for withdraw *',
    'withdraw_cash_settlement': 'Cash in settlement',
    'withdraw_cash_label': 'Withdraw to:',
    'withdraw_bank_number': 'Bank number',
    'withdraw_branch_number': 'Branch number',
    'withdraw_account_number': 'Account number',
    'withdraw_account_name': 'Account owner name',
    'withdraw_term_checkbox': 'I hereby declare that I am the owner and beneficiary of the bank account to which I request to withdraw the funds.',
    'withdraw_static_text1': '* Giraffe and DriveWealth have a waiting period in place to avoid unnecessary charges for our users. Cash withdrawals from your Giraffe account are permitted at least five days after the initial deposit.',
    'withdraw_bank_transfer': 'Bank transfers take 2-3 days to process. ',
    'withdraw_bank_owner_name_error1': `Ops, the account owner name doesn't match Giraffe's account `,
    'withdraw_bank_owner_name_error2': 'Please try again, or contact our support ',

    /* Cash-out Modal */
    'cashOut_modal_text1': `Each one of your portfolios contains hundreds of stocks! So we can only allow you to sell in increments of $100…`,
    'cashOut_modal_text2': `If you have less than $100 invested, you must convert your entire portfolio to cash. We suggest leaving your money invested long-term though!`,
    'cashOut_modal_select': `Select amount:`,
    'cashOut_modal_from': `From portfolio:`,
    'cashOut_modal_to': `To account:`,
    'cashOut_modal_cash': `Cashing out`,

    /* My Impact */
    'my_impact_heading': ` Here's an overview of your impact`,
    'my_impact_text1': `You haven't started investing yet!`,
    'my_impact_text2': `Once you start investing, you'll be able to see the impact you are making. Looking forward to seeing you back here soon :)`,
    'my_impact_text3': `For example:`,
    'my_impact_text4': `See the impact you'd make if you invested in: `,
    'my_impact_text5': `Your impact on: {esgcValue}`,
    'my_impact_text6': `Example:`,
    'my_impact_text7': `Companies we divest from are responsible for 1.3 billion tons of carbon emissions every year`,
    'my_impact_text8': `Companies we divest from cause 10x more pollution than the average company in the S&P 500`,
    'my_impact_text9': `We replaced high emissions companies with those who are advancing a sustainable future with renewable energy sources`,

    /* Create Basket */
    'create_basket_text1': `Choose the values that are necessary for`,
    'create_basket_text2': `creating a bright future`,
    'create_basket_text3': 'Creating your portfolio',
    'create_basket_text4': 'Including companies from the S&P 500 that align with your values and excluding the top ones that do not',
    'create_basket_text5': `Your 'Do-Good' portfolio`,
    'create_basket_text6': `Start with:`,
    'create_basket_text7': `Wait patiently for:`,
    'create_basket_text8': `And you'll have:`,
    'view_portfolio': 'View portfolio',
    'create_basket_boost': `Boost!`,
    'create_basket_unboost': `Unboost`,
    'create_basket_remove': `Remove`,
    'create_basket_trending': `Trending values`,
    'create_basket_more': `More values`,
    'create_basket_head_t1': `Please choose from the following values to focus your investment`,
    'create_basket_card_t1': `Customize the S&P 500 by excluding companies`,
    'create_basket_text9': `Choose the values that are necessary for creating a bright future.`,
    'create_basket_text10': `Wish to boost your impact?`,
    'create_basket_text11': `By choosing to boost, you will be removing more companies that don't align with your values`,
    'create_basket_text12': `How did we choose who to exclude?`,
    'create_basket_text13': `The S&P 500 graph presented is the historical performance of the SPDR S&P 500 ETF Trust (ticker symbol:SPY), held in an automated internet advisor investment account (robo-advisor). The SPDR S&P 500 ETF Trust seeks to provide investment results that, before expenses, correspond generally to the price and yield performance of the S&P 500® Index. The graph presented includes the expense fee, which is equal to 0.0945% as of date, and assumes reinvestments of the dividends. The S&P 500 (Managed by Advisor) graph also includes a yearly 0.25% annual fee, which is the typical AUM annual fee charged by a robo-advisor. The hypothetical past performance of your personalized portfolio seeks to approximate the past performance of the S&P 500 index, assuming the companies removed from your personalized portfolio would have been excluded from the S&P 500 index over the same time period. The performance presented is net of fees, which are 0.25% annually, assuming a starting portfolio size of 10,000$. The performance assumes dividend reinvestment back into the portfolio. Results are hypothetical and do not reflect actual performance of a Giraffe portfolio.{br} Personalized portfolio performance assumes that all of the companies excluded from the S&P by the user value choice were part of the S&P over the previous five years. Hypothetical projections cannot accurately forecast future investment performance, and may understate or overstate risks and costs. In general, the more companies you exclude from your portfolio, the more likely your portfolio performance is to diverge from that of the S&P 500.`,
    'create_basket_text14': `Compare`,
    'create_basket_text15': `Your Personalized Portfolio Compared to the S&P 500`,

    /* 2.4 Activity Page */
    'activity_tab1': 'Transactions',
    'activity_tab2': 'Investing history',
    'activity_tab3': 'Dividends',
    'activity_tab4': 'Account fees',

    /* 2.5 Account Setting */
    'account_setting_my_account': 'My account',
    'account_setting_bank': 'Bank Account',
    'account_setting_bank_monthly': 'Monthly savings from your salary',
    'account_setting_personal': 'Personal information',
    'account_setting_status': 'Current Employment Status',
    'account_setting_broker': 'Broker Dealer and Political Exposure',
    'account_setting_investing': 'Investing Information',
    'account_setting_delete': 'Delete account',
    'account_delete': 'Delete Account',
    'account_delete_text': 'In order to delete your account, at this time you must first speak to customer support. Apologies for the inconvenience.',
    'account_setup_bank_connect': 'Connect my bank account',
    'account_setup_bank_monthly': 'Set up your monthly savings',
    'account_setting_email': 'Email',
    'account_setting_phone': 'US Phone Number',
    'account_setting_phone_il': 'Phone Number',
    'account_setting_first': 'Legal first name',
    'account_setting_last': 'Legal last name',
    'account_setting_first_en': 'Legal first name (English)',
    'account_setting_last_en': 'Legal last name (English)',
    'account_setting_gender': 'Gender',
    'account_setting_address': 'Legal address',
    'account_setting_citizenship': 'Primary citizenship',
    'account_setting_ssn': 'SSN Number',
    'account_setting_id_number': 'ID Number',
    'account_setting_dob': 'Date of Birth',
    'account_setting_status1': 'Status',
    'account_setting_company_name': 'Company name',
    'account_setting_industry': 'Industry',
    'account_setting_position': 'Position',
    'account_setting_broker_dealer': 'Broker/Dealer affiliation',
    'account_setting_public_company': 'Public company control person',
    'account_setting_political': 'Political exposures person',
    'account_setting_investing_exp': 'Investing Experience',
    'account_setting_net_worth': 'Net Worth',
    'account_setting_annual_income': 'Annual Income',
    'account_setting_risk_tolerance': `Risk Tolerance`,
    'account_unlink_modal': 'You cannot unlink your bank account while money is being moved. Please wait until your {type} is complete and then try again!',
    'account_unlink_confirmation': 'Are you sure you want to unlink your bank account?',
    'account_setting_saving_status': 'Status',
    'account_setting_work_email': 'Work Email',
    'account_setting_saving_amount': 'Monthly savings amount',
    'account_setting_portfolio': 'Investment portfolio',

    /* Notification Email */
    'account_setting_notification1': 'Notification Settings',
    'account_setting_notification2': 'Receive information about sustainable investing',
    'account_setting_email_marketing': 'Product and general Giraffe updates from the Savannah',
    'account_setting_email_learn': 'Weekly Giraffe learn emails',
    'account_setting_notification_text1': 'I would like to be subscribed to:',
    'account_setting_notification_text2': 'We love that you all want to stay informed...and don’t want to abuse that privilege. We promise not to bombard you with emails.',
    'account_setting_notification_report': 'I would like to receive my annual reports through:',
    'account_setting_email_report': 'Email',
    'account_setting_postal_service_report': 'Postal service',
    'account_setting_notification_btn': 'Update notifications',
    'account_setting_notification_msg': 'Notifications updated',

    /* 2.5.1 Account Setting Modal */
    'account_setting_modal_my_account_head': 'My account',
    'account_setting_modal_my_account_text1': 'Please contact customer support if you would like to change your phone number or email.',
    'account_setting_modal_email': 'Email:',
    'account_setting_modal_phone': 'Phone Number:',
    'account_setting_modal_personal_info_head': 'Personal information',
    'account_setting_modal_personal_info_text1': 'Please contact customer support if you would like to change any of the locked information.',
    'account_setting_modal_apart': 'Apart/unit#',
    'account_setting_modal_zip_code': 'Zip code',

    /* Taxes and Documentation */
    'taxes_head_text': `Taxes and Documentation`,

    /* Gift Page */
    'gift_head_text2': `Here's an overview of your investment`,
    'gift_read_more': 'Read More',
    'gift_show_less': 'Less',
    'gift_change_value': 'Yes! Change the value to',
    'gift_accept_gift': 'Accept the gift',
    'gift_cash_out': 'Cash out',
    'gift_redeemed_button1': `Redeem gift`,
    'gift_redeemed_button2': `Read more`,
    'gift_cash_out_text1': 'Are you sure you want to cash out your entire portfolio?',
    'gift_congratulation': 'Congratulations.',
    'gift_your_gift_ready': 'Your gift is ready!',
    'gift_card_header': 'Personalize your investment',
    'gift_static_text1': 'Please take a moment to read and confirm the details of your gift.',
    'gift_static_text2': 'You may also choose to personalize your investment:',
    'gift_static_text3': 'You may start by personalizing your investment.',
    'gift_static_text4': 'Once you personalize and redeem your investment, {giftAmount} will be invested in your new portfolio!',
    'gift_static_text5': 'Learn how to invest according to your values and make responsible financial decisions for you and the planet.',
    'gift_static_text6': 'We encourage you to use this gift to explore the new world of sustainable investing.',
    'gift_static_text_investment1': 'Change the values in your investment',
    'gift_static_text_investment2': 'Exclude specific stocks from your investment',
    'gift_static_text_investment3': 'Add new stocks to your investment',
    'gift_amount': 'Gift amount:',
    'gift_card_by': 'Gift card by:',
    'gift_awarded': 'Awarded to:',
    'gift_pre_selected': 'Pre-selected values:',
    'gift_selected_value': 'Values selected:',
    'gift_select_below': 'Select below',
    'gift_select_value': 'Selected values:',
    'gift_redeem_modal_text1': 'Once you click “confirm” your personalized portfolio will be purchased according to your selections!*',
    'gift_redeem_modal_text2': 'Up to 48 hours',
    'gift_redeem_modal_text3': '*Money transfers and purchasing the stocks take around two business days to process.',

    /* Side Banner */
    'right_sidebar_text1': `Expand your knowledge with our`,
    'right_sidebar_text2': `Financial playlist`,

    /* In-App gift page */
    'gift_tab_1': `Gifts I've received`,
    'gift_tab_2': `Gifts I've given`,
    'gift_redeem_gift': `Redeem a gift`,
    'gift_redeem_text1': `If you have a gift and it's not here, enter the redemption code above.`,
    'gift_redeem_text2': `You have a new gift`,
    'gift_redeem_date': `Date redeemed`,
    'gift_redeem_value': `Redeemed Value`,
    'gift_current_value': `Current value`,
    'gift_earning_value': `Earnings:`,
    'gift_given_text1': `It looks like you haven't given a gift yet!*`,
    'gift_given_text2': `* We check according to the email used when purchasing a gift.`,
    'gift_given_button': `Give a gift`,
    'gift_given_link': `Gift link:`,
    'gift_redeemed_date': `Redeemed Date`,
    'gift_value_amount': `Value Amount`,
    'gift_sent_to': `Sent to:`,
    'gift_date_delivery': `Date of delivery:`,
    'gift_status': `Status:`,
    'gift_redeemed_success': `Your gift redeemed successfully.`,

    /* Gift accept modal */
    'gift_accept_text1': `Gift Redeemed!`,
    'gift_accept_text2': `Your investment is being finalized`,
    'gift_accept_text3': `The money transfer and investment will take 2-3 business days to process. After which you'll be able to start tracking your do-good investment!`,

    /* Giraffe Learn */
    'giraffe_learn_welcome': `Welcome aboard!`,
    'giraffe_static_text1': `You have received the gift of knowledge from someone who cares about you.`,
    'giraffe_start_learning': `Let's start learning`,
    'giraffe_mini_mba': `Mini-MBA`,
    'giraffe_mini_mba_text1': `Also included in your gift:`,
    'giraffe_mini_mba_text2': `Exclusive two-day workshop.`,
    'giraffe_mini_mba_text3': `The program was designed to capture the highlights of top ivy league MBA schools and was developed originally for Microsoft Venture's Academy.`,
    'giraffe_mini_mba_register': `Register today`,
    'giraffe_work': `How does Giraffe {br} learn work?`,
    'giraffe_work_learn': `learn work?`,
    'giraffe_work_text1': `Have you ever wondered how companies {br} and investors use the market today? {br} How was it even invented?`,
    'giraffe_work_text2': `and investors use the market today?`,
    'giraffe_work_text3': `x`,
    'giraffe_watch': `Watch now`,
    'giraffe_learn_list_topics': `View full list of topics`,
    'giraffe_learn_back': `Back to Schedule`,
    'giraffe_learn_path': `Your financial playlist path`,
    'giraffe_learn_of_week': `Week {forWeek}: {title}`,
    'giraffe_learn_achievement_text1': `Each step of the way, you'll learn and get achievements`,
    'giraffe_learn_achievement_text2': `For each achievement, you'll be able to share with friends and become an expert in the world of impact`,

    /* Giraffe Mini-MBA */
    'mini_mba': 'Mini-MBA',
    'mini_mba_text1': 'You have received the gift of knowledge {br} from someone who cares about you.',
    'mini_mba_text2': `Exclusive two-day workshop.`,
    'mini_mba_text3': `The program was designed to capture the highlights of top ivy league MBA schools and was developed originally for Microsoft Venture's Academy.`,
    'mini_mba_register': `Register today`,
    'mini_mba_text4': `We need a title about the event here`,
    'mini_mba_text5': `We need a description about the event here`,
    'mini_mba_text6': `Led by leading business school professors.`,
    'mini_mba_secure': `Secure your place today.`,

    /* Giraffe Learn Banner */
    'giraffe_banner_10': `10%`,
    'giraffe_banner_75': `$75`,
    'giraffe_banner_text1': `Your portfolio set-up is at:`,
    'giraffe_banner_text2': `Complete your account registration in order to redeem your gift card.`,
    'giraffe_banner_text3': `Climate Change Stock Bundle`,
    'giraffe_banner_text4': `A high-impact portfolio of companies supporting Climate Change.`,
    'giraffe_banner_text5': `Enter legal information`,
    'giraffe_banner_text6': `Redeem your gift card`,
    'giraffe_banner_text7': `Grow your impact tree.`,
    'giraffe_banner_text8': `Redeem Gift`,

    /* Contact us page */
    'contact_us_header1': `Contact us`,
    'contact_us_header2': `Hello`,
    'contact_us_header3': `Here's an overview of your activity`,
    'contact_us_header4': `Here you can drop us a message for an assistance`,
    'contact_us_text1': `How can we assist you?`,
    'contact_us_text2': `Drop us a message, and we'll respond within 24 hours to address your needs.`,
    'contact_us_text3': `Please enter your full name and a description.`,
    'contact_us_text4': `Email us at:`,
    'contact_us_text5': `Please enter your full name and a description.`,
    'contact_us_text6': `Please enter your first name`,
    'contact_us_text7': `Please enter your email`,
    'contact_us_text8': `Please enter a short description of the issue or question:`,
    'contact_us_text9': `Thanks for reaching out!`,
    'contact_us_text10': `We've received your form. Our team will review your message and get back to you promptly, usually within 24 hours on weekdays. We're here to assist you every step of the way!`,

    /* New Portfolio */
    'portfolio_card_header': `Customize your S&P 500 portfolio`,
    'gift_card_redeem_page': `Personalize your gift`,
    'portfolio_text0': `Your portfolio`,
    'portfolio_text1': `S&P 500 (managed by advisor)`,
    'portfolio_text2': `Customize your {br} S&P 500 portfolio`,
    'portfolio_text3': `Select your values:`,
    'portfolio_text4': `Invest for the long term`,
    'portfolio_text5': `Your choices`,
    'portfolio_text6': `Remove specific companies {br} from your portfolio`,
    'portfolio_text7': `Explore the historical returns of the S&P 500, the core of your portfolio.`,
    'portfolio_text8': `$100,000 invested in this portfolio, would be today...`,
    'portfolio_text9': `You divested from companies that take part in the following activities:`,
    'portfolio_text10': `By selecting {deep_clean} we will remove more companies against this value from your portfolio.`,
    'portfolio_text11': `By selecting {goodCompany} the best companies in this value be added to your portfolio.`,
    'portfolio_text12': `Remove specific companies from your portfolio.`,
    'portfolio_text13': `This is the list of companies that we've removed from the S&P 500 based on your values`,
    'portfolio_text14': `Your specific exclusions:`,
    'portfolio_text15': `This is the list of companies that we've added to your portfolio based on your selections`,
    'portfolio_text16': `This is the list of your final portfolio`,
    'portfolio_text17': `Give your portfolio a name!`,
    'portfolio_text18': `Your funds / investment is insured by the US government up to $500,000.`,
    'portfolio_text19': `{giraffeInvest} is a USA SEC registered investment advisor.`,
    'portfolio_text20': `Name your portfolio:`,
    'portfolio_text21': `Changes from the original S&P 500`,
    'portfolio_text22': `No results found!`,
    'portfolio_text23': `Your search results:`,
    'portfolio_chart1': `stocks`,
    'portfolio_excluded': `Excluded Companies`,
    'portfolio_added': `Added companies:`,
    'portfolio_removed': `Removed companies:`,
    'portfolio_excluded_company': `Excluded: {excludedCompanyCount}`,
    'portfolio_added_company': `Added companies: {addedCompanyCount}`,
    'portfolio_total_company': `Total Companies: {totalCompanyCount}`,
    'portfolio_added_company2': `Added companies`,
    'portfolio_average': `Average annual return`,
    'portfolio_aggregated': `Aggregated return (i)`,
    'portfolio_original': `Original investment`,
    'portfolio_return_over': `Return over {year} years`,
    'portfolio_giraffe_fees': ` Giraffe Fees:`,
    'portfolio_add_good': `Add {good} companies`,
    'portfolio_add': `Added:`,
    'portfolio_advanced': `Advanced`,
    'portfolio_see_list': `See list`,
    'portfolio_source': `Source`,
    'portfolio_advanced_action': `Advanced actions`,
    'portfolio_deep': `Deep clean`,
    'portfolio_enter_name': `Enter name`,
    'portfolio_create': `Create portfolio`,
    'portfolio_placeholder_company': `Any S&P 500 company`,
    'portfolio_placeholder': `Portfolio Name`,
    'portfolio_type1': `Your personalized portfolio`,
    'portfolio_type2': `S&P500`,
    'portfolio_type3': `Betterment`,
    'portfolio_type4': `Wealthfront`,
    'portfolio_type5': `as of`,
    'portfolio_tab1': `For yourself`,
    'portfolio_tab2': `A gift for a friend / family`,
    'portfolio_popover_text1': `{strong1} data is based on the SPDR S&P 500 ETF Trust. {br}{br} {strong2} and {strong3} are based on their reported returns on their websites {date}`,
    'portfolio_popover_text2': `18 characters max`,
    'create_basket_name_label': `Enter name`,
    'portfolio_table_title': 'Annual return (%):',
    'portfolio_year_back': 'Years back:',

    /* My impact */
    'impact_user_name': 'Hello {userName}!',
    'impact_selected_value': `Here's an overview of your selected values`,
    'impact_value': `Here's an overview of your impact`,
    'removed_company': `Removed companies`,
    'impact_giraffe_value': `Giraffe values:`,
    'impact_no_company_remove': 'No remove companies removed to your portfolio.',
    'impact_no_company_add': 'No good companies added to your portfolio.',
    'impact_company_name': 'Company name',
    'impact_table_text1': 'What do they do?',
    'impact_table_text2': 'Industry',

    /* new gift redeemed page */
    'new_gift_redeem_text1': `Redeem your gift`,
    'new_gift_redeem_text2': `If you have a gift and it’s not here,{br} enter the redemption code here.`,
    'new_gift_redeem_text3': `Found it!`,
    'new_gift_redeem_text4': `To claim your gift, your next step is to complete the setup process. Simply tap the 'Redeem' button to initiate the process.`,
    'new_gift_redeem_text5': `Hi, you have a new gift!`,
    'new_gift_redeem_text6': `You have a new gift!`,
    'new_gift_redeem_text7': `Awarded by:`,
    'new_gift_redeem_text8': `My gifts`,
    'new_gift_redeem_header1': `Awarded by`,
    'new_gift_redeem_header2': `Received value`,
    'new_gift_redeem_header3': `Received date`,
    'new_gift_redeem_header4': `Redeemed date`,
    'gift_redeem_accept_text1': 'All set.',
    'gift_redeem_accept_text2': 'Click',
    'gift_redeem_accept_text3': 'to view your {br} personalized portfolio.',
    'gift_redeem_accept_text4': 'The processing time for money transfers and stock purchases is approximately two days.',
    'gift_redeem_accept_text5': 'Your selected values:',

    /* Monthly Saving */
    'monthly_saving_email_modal_head': 'Set up your {br} monthly savings',
    'monthly_saving_email_label': 'Please enter your work email:',
    'monthly_saving_email_placeholder': 'Ofra@wix.com',
    'monthly_saving_email_code': 'Enter code',
    'monthly_saving_email_code_text': 'We sent a security code to your email, kindly enter it here:',
    'monthly_saving_email_code_wrong': 'Wrong code',
    'monthly_saving_email_code_wrong_text': 'Please check the code you entered.',
    'monthly_saving_email_code_spam_text': 'Didn’t get it? {br} Don’t forget to check your spam.',
    'monthly_saving_error_text1': 'Oops!',
    'monthly_saving_error_text2': 'This company isn’t on our list yet',
    'monthly_saving_error_text3': 'The company email you provided doesn’t currently offer this benefit.',
    'monthly_saving_error_text4': 'We will keep your contact information safe and inform you when the time is right.',
    'monthly_saving_text1': 'Set up your {br} monthly savings',
    'monthly_saving_organization_label': 'Employer:',
    'monthly_saving_amount_label': 'How much money would you {br}like to set aside from your salary{br} each month for savings?',
    'monthly_saving_amount_error_text1': ' Please enter an amount {br} between ',
    'monthly_saving_amount_error_text2': '200 and ',
    'monthly_saving_amount_error_text3': '5,000',
    'monthly_saving_portfolio': 'Select the investment portfolio {br} for your savings',
    'monthly_saving_term_text1': 'I have read and agreed to the',
    'monthly_saving_term_text2': 'salary deduction instruction',
    'monthly_saving_amount_info_text1': 'The specified amount will be deducted from your net salary.',
    'monthly_saving_amount_info_text2': 'Requests submitted on or before the {date} of the month will be applied to the current salary. Requests submitted after the {date} will apply a month later.',
    'monthly_saving_portfolio_info_text1': 'This is where your saved money will be invested.',
    'monthly_saving_portfolio_info_text2': `You can change your portfolio anytime. If you're unsure or haven’t created a portfolio yet, you can choose the Cash Account for now and switch later. Your choice, your control!`,
    'monthly_saving_success_text1': 'Hooray! {br} Setup complete',
    'monthly_saving_success_text2': `We've received your request, {br} and will send it to your employer {br} every month, starting `,
    'monthly_saving_success_text3': ` month.`,
    'monthly_saving_success_text4': `Please keep in mind that all salary deposits are subject to approval and processing by your employer.`,
    'monthly_saving_success_this': `this`,
    'monthly_saving_success_next': `next`,
    'monthly_saving_success_pending_text1': 'Setup complete!',
    'monthly_saving_success_pending_text2': `We've received your request, {br} and we're currently processing it.{br} We'll notify you once it's approved! {br}{br} Additionally, you can track the status in your account settings.`,

    /* Monthly saving  edit modal */
    'monthly_saving_edit_header': 'Edit monthly savings',
    'monthly_saving_edit_organization_info': 'Please contact customer support if you would like to change your employer details.',
    'monthly_saving_edit_amount': 'Monthly savings amount:',
    'monthly_saving_edit_amount_text': 'Changes made by the {date} {br} apply this month; changes {br} after take effect next month',
    'monthly_saving_edit_amount_info_text1': 'The specified amount will be deducted from your net salary.',
    'monthly_saving_edit_amount_info_text2': 'Changes made on or before the {date} of the month will be applied to the current salary. Changes made after the {date} will apply a month later.',
    'monthly_saving_edit_investment_list': 'Investment portfolio:',
    'monthly_saving_edit_investment_placeholder': 'Select an account',
    'monthly_saving_edit_investment_list_text': 'Changes apply immediately {br} for all future deposits',
    'monthly_saving_edit_portfolio_info_text1': 'This is where your saved money will be invested.',
    'monthly_saving_edit_portfolio_info_text2': 'Changes apply immediately for all future deposits, past deposits will not transfer automatically.',

    /* Monthly Home page */
    'home_monthly_saving': 'Monthly savings',
    'home_monthly_saving_portfolio_text1': 'This portfolio is defined as your monthly savings and investment portfolio, with a monthly deposit of ₪{amount} directly deducted from your salary by your employer.',
    'home_monthly_saving_portfolio_pending': 'Pending approval',
    'home_monthly_cash_basket_text1': 'Your future salary deposits will be transferred to your Giraffe cash account.{br}{br}We highly recommend selecting a designated investment portfolio:',
    'home_monthly_cash_basket_placeholder': 'Cash Account',

    /* Basket monthly saving delete */
    'basket_monthly_saving_delete_text1': `Oops! We can't delete a portfolio that is designated for monthly savings.{br}{br}Please assign a new monthly savings portfolio in your account settings before proceeding with deletion.`,
}

export default Dashboard;