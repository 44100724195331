// default function
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    redirectId: number,
    isMobile: boolean,
    addFundButton: PropsFunction.ParamsIdNumberFunction
}

const DetailButton: React.FC<Props> = (props: Props) => {

    const { redirectId, isMobile, addFundButton } = props

    const history = useHistory();

    const clickView = (): void => {
        history.push(`/dashboard/portfolio/${redirectId}`)
    }

    const clickAddFunds = (): void => {
        addFundButton(redirectId)
    }


    return (
        <>
            <button
                type="button"
                className={`view-btn ${isMobile ? 'd-block d-lg-none my-1' : 'd-none d-lg-block'}`}
                onClick={clickView}
            >
                {IntlMessages('button_view')}
            </button>
            <button
                type="button"
                className={`add-funds-btn ${isMobile ? 'd-block d-lg-none my-1' : 'd-none d-lg-block'}`}
                onClick={clickAddFunds}
            >
                {IntlMessages('button_add_funds')}
            </button>

        </>
    );
}

export default DetailButton
