const enOrdinalRules = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes: Map<string, string> = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
]);


/**
 * Used for the get the suffixed number
 *
 * @param {number} n
 * @return {string} '1st, 2nd, 3rd, 4th....'
 */
export const getNumberSuffix = (n: number): string => {
    // get the suffixed rule
    const rule = enOrdinalRules.select(n);

    // get the suffixed
    const suffix = suffixes.get(rule);

    // return number with suffixed
    return `${n}${suffix}`;
};