// default library
import * as React from 'react';
//chart
import ReactApexChart from 'react-apexcharts';
// message import
import IntlMessages from 'helper/IntlMessages';
// custom component
import CompanyListModal from 'components/ModalComponent/CompanyListModal';
// chart option
import { BasketPieChart } from 'config/ChartOptions';
// type import
import * as BasketType from 'api/APIMaster/BasketType';
// local import
import * as Local from 'service/utils/LocalStorageData';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';

type Props = {
    companySummary: BasketType.BasketCompanySummaryResponseModel
}

const CompanyDetailSection: React.FC<Props> = (props: Props) => {

    const { companySummary } = props;
    const currentLang = Local.getCurrentLanguage()

    const pieChartSeries: number[] = [companySummary.excludedCompanies, companySummary.addedCompanies, companySummary.totalCompanies] //chart series

    const [totalCompanyModal, setTotalCompanyModal] = React.useState<boolean>(false);
    const [excludedCompanyModal, setExcludedCompanyModal] = React.useState<boolean>(false);
    const [addedCompanyModal, setAddedCompanyModal] = React.useState<boolean>(false);

    // close the all modal
    const onHandleClose = (): void => {
        setTotalCompanyModal(false); //flag for total company
        setExcludedCompanyModal(false); //flag for e company
        setAddedCompanyModal(false); //flag for total company  
    }

    // open portfolio company list modal
    const totalCompanyClick = (): void => {
        setTotalCompanyModal(true); //flag for total company
    }

    // open excluded company list modal
    const excludedCompanyClick = (): void => {
        setExcludedCompanyModal(true);  //flag for e company
    }

    // open added company list modal
    const addedCompanyClick = (): void => {
        setAddedCompanyModal(true); //flag for total company 
    }

    React.useEffect(() => {
        const updateOption = {
            labels: [
                OtherTranslations[currentLang].excludedCompanies,
                OtherTranslations[currentLang].addedCompanies,
                OtherTranslations[currentLang].portfolioCompanies,
            ]
        }
        ApexCharts.exec('basket-pie-chart', 'updateOptions', { ...updateOption }, false, false, false);
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="gi-dash-ao-view-list-card p-lg-5 px-md-3 py-md-5 p-5 ps-0 mt-2">
                        <div className="row align-items-center gx-md-3 gx-0">
                            {/* apex chart */}
                            <div className="col-md-3 col-7">
                                <div className="gi-dash-ao-view-list-graph">
                                    <ReactApexChart options={BasketPieChart} series={pieChartSeries} type='donut' dir='ltr' />
                                </div>
                            </div>

                            <div className="col-md-9 col-5">
                                <div className="row gy-md-0 gy-4">
                                    {/* portfolio company */}
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <div className="gi-dash-ao-view-list-count-text">
                                                    <div className="gi-dash-ao-view-list-count-top d-flex align-items-center justify-content-between gap-3 pb-2">
                                                        <h6>{IntlMessages('portfolio_companies')}</h6>
                                                        <span data-testid='total-company-count'>{companySummary.totalCompanies}</span>
                                                    </div>
                                                    <div className="gi-dash-ao-view-line-portfolio"></div>
                                                    <div className="gi-dash-ao-view-list-count-bottom d-flex pt-2">
                                                        <button type='button' className="d-inline-flex" onClick={totalCompanyClick} disabled={!companySummary.totalCompanies} data-testid='total-company'>
                                                            {IntlMessages('basket_view_list')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* excluded company */}
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <div className="gi-dash-ao-view-list-count-text">
                                                    <div className="gi-dash-ao-view-list-count-top d-flex align-items-center justify-content-between gap-3 pb-2">
                                                        <h6>{IntlMessages('excluded_companies')}</h6>
                                                        <span data-testid='excluded-company-count'>{companySummary.excludedCompanies}</span>
                                                    </div>
                                                    <div className="gi-dash-ao-view-line-excluded"></div>
                                                    <div className="gi-dash-ao-view-list-count-bottom d-flex pt-2">
                                                        <button type='button' className="d-inline-flex" onClick={excludedCompanyClick} disabled={!companySummary.excludedCompanies}
                                                            data-testid='excluded-company'>
                                                            {IntlMessages('basket_view_list')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* added company */}
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <div className="gi-dash-ao-view-list-count-text">
                                                    <div className="gi-dash-ao-view-list-count-top d-flex align-items-center justify-content-between gap-3 pb-2">
                                                        <h6 className="add-companies-h6">
                                                            {IntlMessages('added_companies')}
                                                        </h6>
                                                        <span data-testid='added-company-count'>{companySummary.addedCompanies}</span>
                                                    </div>
                                                    <div className="gi-dash-ao-view-line-added"></div>
                                                    <div className="gi-dash-ao-view-list-count-bottom d-flex pt-2">
                                                        <button type='button' className="d-inline-flex" onClick={addedCompanyClick} disabled={!companySummary.addedCompanies}
                                                            data-testid='added-company'>
                                                            {IntlMessages('basket_view_list')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* total company list */}
            <CompanyListModal
                companyListModal={totalCompanyModal}
                totalCompanyTable={true}
                excludedCompanyTable={false}
                addedCompanyTable={false}
                companyList={companySummary.totalCompanyList}
                companyCount={companySummary.totalCompanies}
                onHandleClose={onHandleClose}
            />

            {/* excluded company list */}
            <CompanyListModal
                companyListModal={excludedCompanyModal}
                totalCompanyTable={false}
                excludedCompanyTable={true}
                addedCompanyTable={false}
                companyList={companySummary.excludedCompanyList}
                companyCount={companySummary.excludedCompanies}
                onHandleClose={onHandleClose}
            />

            {/* added company list */}
            <CompanyListModal
                companyListModal={addedCompanyModal}
                totalCompanyTable={false}
                excludedCompanyTable={false}
                addedCompanyTable={true}
                companyList={companySummary.addedCompanyList}
                companyCount={companySummary.addedCompanies}
                onHandleClose={onHandleClose}
            />
        </ >
    )
}

export default CompanyDetailSection