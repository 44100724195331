const Notification: { [key: string]: string } = {
    /* notification message  for both */
    'notification_buy': `We are currently processing your stock purchase request for your {basketName} portfolio. This process typically takes 10-15 minutes, but is limited to stock market trading hours and subject to market activity. Please note that during this time, your account summary may not display precise monetary values.`,
    'notification_sell': `We are currently processing your stock sales request for your {basketName} portfolio. This process typically takes 10-15 minutes, but is limited to stock market trading hours and subject to market activity. Please note that during this time, your account summary may not display precise monetary values.`,
    'notification_new_gift': `Great news! You have successfully redeemed your gift, and we have initiated your stock purchase. Please note that it may take up to 3 business days to transfer the gift funds to your account and finalize the stock purchase for your portfolio. During this time, your account summary may not display precise monetary values.`,
    'notification_withdrawal': `Your withdrawal request is currently being processed. Please be aware that it may take up to 2 business days for the withdrawal to be completed.`,
    'notification_kyc_not_done': `Welcome! We're excited that you've joined us. To get started on your investment journey, go ahead and create your very first portfolio.`,
    'notification_gift_not_redeem': `Welcome! We're thrilled that you've joined us. To claim your gift, your next step is to complete the setup process. Simply tap the 'Redeem' button to initiate the process.`,
    'notification_gift_add_code': `To claim your gift, your next step is to complete the setup process. Simply tap the 'Redeem' button to initiate the process.`,
    'notification_gift_add_code_KYC': `To claim your gift, simply tap the 'Redeem' button to initiate the process.`,

    /* US notification */
    'notification_bank_to_cash': `We are currently processing your bank transfer request. Typically, fund transfers take up to 2 business days. Once the funds are successfully deposited into your account, you will be able to allocate them to a specific portfolio and start purchasing stocks. `,
    'notification_bank_to_basket': `We are currently processing your bank transfer request to {basketName} portfolio. Typically, fund transfers take up to 2 business days to complete. Once the funds are successfully added to your account, our system will automatically initiate the process of purchasing stocks for your selected portfolio.`,
    'notification_new_portfolio': `Great news! Your portfolio has been successfully created! To start investing and buying stocks, simply click on the 'Add funds' button.`,
    'notification_without_kyc_portfolio': `Great news! Your portfolio has been successfully created! To start investing and buying stocks, your next step is to complete the setup process and connect your bank account. Just tap the 'Add funds' button to get started.`,

    /* Info modal message */
    'info_account_heading': 'Learn how we calculate your account value: ',
    'info_account_body1': `Account value represents the total worth of your investments, including both your portfolio holdings and any available cash balance.`,
    'info_account_body2': `to explore what influences your account value.`,
    'info_account_more_body': `You have the option to increase your account balance by adding funds to a portfolio and purchasing more stocks or by adding funds to your cash balance. Withdrawals can only be made from your cash balance.{br} If you need to withdraw more than what's available in cash, you must sell your investments first.`,
    'info_portfolio_heading': `Learn how we calculate your total portfolio value: `,
    'info_portfolio_body': `Total portfolio value represents the combined worth of all the stocks in your portfolios, which reflects the current value of your investments with Giraffe.{br}Total portfolio value gives you a snapshot of the present value of your investments.`,
    'info_cash_balance_heading': `Learn how we calculate your cash balance: `,
    'info_cash_balance_body': `Your cash balance represents the total amount of money in your account that is not invested in stocks.{br}This balance includes recent money transfers that have not yet been allocated to investments; funds from stocks that you have sold or dividends that you have received. Your cash balance is available for investment or withdrawal.`,
    'info_performance_heading': `Learn how we calculate your performance: `,
    'info_performance_body': `Performance represents the total amount of money you have earned from your investments across all your portfolios since you started investing with Giraffe. Performance includes both the increases and decreases in the values of your stocks and any dividends you may have earned. The performance measurement has already factored in our advisory fee.`,
    'info_gift_pending_heading': `You've been gifted an investment credit from someone who values you.`,
    'info_gift_pending_body': `Once you redeem your gift, you'll have the opportunity to craft a portfolio that aligns with your values and effortlessly purchase it using the credit you've received.{br}{br}You'll also be able to track the impact you've made with this gift and add more funds to your portfolio to maximize your positive influence.`,
    'info_earning_heading': `Learn how we calculate your portfolio earnings: `,
    'info_earning_body': `Earnings represent the current profit or loss of the stocks you own as of today. We calculate earnings by taking the current price of a stock in your portfolio and subtracting the price you bought it for; we then repeat this calculation for all stocks in your portfolio and add up these values.`,
    'info_portfolio_basket_heading': `Learn how we calculate your portfolio value:`,
    'info_portfolio_basket_body': `Your portfolio value represents the collective worth of the stocks in your portfolio, reflecting the current value of your investments in this specific portfolio. It serves as an indicator of the present valuation of your holdings within this portfolio.`,
    'info_twenty_year_heading': `Learn how we calculate your future projection:`,
    'info_twenty_year_body': `The future projection illustrates a range of scenarios out of many potential financial outcomes.{br}{br}The average annual return of the S&P 500 has historically been about 9.3%.The Giraffe projection assumes that the annual return of your portfolio will be approximately 9.3% plus or minus 0.5% to account for market variability.With Deep Clean value options, there is greater uncertainty regarding the effects of values-based investing on returns.This means that an additional plus or minus 1% variability is calculated into your projections for each Deep Clean value that you selected.{br}{br}The future projection is not a guarantee or forecast of investment results and does not account for periods of abnormally high or low performance or the timingof stock sale. Actual results are likely to vary from the projection, especially if stocks experience a series of losses early in the investment period or if you change your investment preferences midway through the period.{br}{br}This projection is not a simulation (such as a Monte Carlo simulation) and should not be used to determine the probability of a given investment result.It is designed to illustrate the potential benefits of making investing a habit.`,
    'info_basket_performance_heading': `Learn how we calculate your portfolio performance:`,
    'info_basket_performance_body': `Portfolio performance represents the total amount of money you have earned from this portfolio since you started investing in it. Portfolio performance includes both increases and decreases in the values of your stocks and any dividends you may have earned.`,

    /* Israel Notification */
    'il_set_monthly_saving': `Great news! Your portfolio has been successfully created! To start saving and investing, your next step is to complete the setup process. Just tap the 'Set monthly savings' button to get started.`,
    'il_monthly_saving_cash': `Please note that you have chosen to transfer all future savings deposits to your Giraffe cash account. We highly recommend selecting a designated investment portfolio. {clickText} to easily make the change.`,
    'il_kyc_denied': `Unfortunately, the setup process has failed, and we can't open an investment account in your name. For further details, please contact us via email or through the 'contact us' tab.`,
    'il_kyc_reject': `Please be aware that certain details entered during the setup process do not align with the information in your identification documentation. {clickText} to update.`,
    'il_monthly_deduction_start': `Great news! Your monthly savings from your salary are being processed and are on their way to us. Please note that it may take up to 3-4 business days to transfer the funds to your account and finalize the stock purchase for your portfolio. During this time, your account summary may not display precise monetary values.`,
}

export default Notification