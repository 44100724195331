import Common from 'lang/locales/he-IL/Language/Common';
import Footer from 'lang/locales/he-IL/Language/Footer';
import Dashboard from 'lang/locales/he-IL/Language/Dashboard';
import OnBoarding from 'lang/locales/he-IL/Language/OnBoarding';
import Error from 'lang/locales/he-IL/Language/Error';
import Notification from 'lang/locales/he-IL/Language/Notification';
import IsraelOnBoarding from 'lang/locales/he-IL/Language/IsraelOnBoarding';

import IMAGES from 'lang/locales/he-IL/Images';

const heIl = {
    ...Common, //common 
    ...Footer, //app footer
    ...Dashboard, //home, basket detail, gift, etc. page
    ...OnBoarding, //on-boarding journey
    ...Error, //error message
    ...Notification, //notification
    ...IsraelOnBoarding, //israel onboarding

    ...IMAGES, //images

};
export default heIl