//default library
import * as React from 'react';
import { useIntl } from 'react-intl';
// react google auto place library
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
// datadog logs
import { datadogLogs } from '@datadog/browser-logs';
//types import
import * as UserType from "api/APIMaster/UserType"
import * as PropsFunction from "interface/PropsFunction";
//localStorage import
import * as Local from 'service/utils/LocalStorageData';
// config 
import { ShouldBeComponentAddress } from 'config/DefaultValue';

export type Props = {
    textCenter: boolean;
    className: string;
    value: string;
    dataTestId: string;
    countryList: UserType.CountryResponseModel[];
    inputValue: PropsFunction.PropsInputAutoComplete
}

const AutoComplete: React.FC<Props> = (props: Props) => {
    // Destructuring props
    const { textCenter, className, value, countryList, dataTestId, inputValue } = props;

    const intl = useIntl();


    const handlePlaceSelect = (place: google.maps.GeocoderResult[]) => {
        const address: {
            [key: string]: string;
            streetNumber: string;
            address1: string;
            postcode: string;
            city: string;
            province: string;
            country: string;
        } = {
            streetNumber: "",
            address1: "",
            postcode: "",
            city: "",
            province: "",
            country: ""
        };
        let countryIdentifier = '';
        let errorFlag = false;

        place?.[0].address_components.forEach((component: any) => {
            for (const shouldBe in ShouldBeComponentAddress) {
                if (ShouldBeComponentAddress[shouldBe].includes(component.types[0])) {
                    address[shouldBe] = shouldBe === "province" ? component.short_name : component.long_name;
                }
            }
        });

        const localStorageData = Local.getLocalData('onBoardingDetails');

        // set the country identifier
        const countryData = countryList.find(e => e.name === address.country)
        countryData && (countryIdentifier = countryData.identifier)

        //set country data to localStorage
        const onBoardingDetails = {
            ...localStorageData,
            street1: address.address1,
            city: address.city,
            province: address.province,
            postalCode: address.postcode,
            country: countryIdentifier,
            countryDisclosure: address.country
        }
        Local.setLocalData('onBoardingDetails', onBoardingDetails)

        if (address.address1 === '' || address.city === '' || address.province === '' || address.country === '' || address.postcode === '') {
            errorFlag = true
            datadogLogs.logger.error(`Auto Complete Address Error: `, { place: place?.[0] });

            (address.address1 === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'address' });
            (address.city === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'city' });
            (address.province === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'state' });
            (address.country === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'country' });
            (address.postcode === '') && datadogLogs.logger.error(`Auto Complete Missing Field: `, { filedName: 'postcode' });
        }

        const autoCompleteData = {
            addressAutoFill: address.address1,
            errorFlag: errorFlag,
            postalCode: address.postcode,
            city: address.city,
            province: address.province,
            country: countryIdentifier,
        } //create obj for send data to parent

        inputValue(autoCompleteData) //pass data to props
    }

    // Function to handle changes in the input field
    const onChangeHandle = async (e: { value: { place_id: string; }; }): Promise<void> => {
        if (e?.value.place_id) {
            const address = await geocodeByPlaceId(e.value.place_id)
            handlePlaceSelect(address)
        } else {
            // If no place is selected, reset error flag and postal code
            const autoCompleteData = {
                errorFlag: false,
                postalCode: '',
                addressAutoFill: '',
                city: '',
                province: '',
                country: '',
            };
            inputValue(autoCompleteData);
        }
    };

    // Return the GooglePlacesAutocomplete component
    return (
        <GooglePlacesAutocomplete
            apiKey={import.meta.env.REACT_APP_GOOGLE_PLACE_API_KEY}
            apiOptions={{ language: 'en', region: 'US' }}
            data-testid={dataTestId}
            withSessionToken={true}
            debounce={100}
            autocompletionRequest={{
                componentRestrictions: { country: 'us' },
                types: ['address'],
            }}
            selectProps={{
                id: 'addressAutoFill',
                onChange: onChangeHandle,
                placeholder: intl.formatMessage({ id: 'placeholder_auto_select' }),
                classNamePrefix: className,
                className: `form-control ${className} ${textCenter ? 'gi-text-center' : ''}`,
                defaultInputValue: value,
                isClearable: true,
            }}
        />
    );

};

export default AutoComplete;




