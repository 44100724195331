import { combineReducers } from '@reduxjs/toolkit';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import LanguageReducer from './LanguageReducer';
import IsraelKYCReducer from './IsraelKYCReducer';
import AppCommonDataReducer from './AppCommonDataReducer';

// combine all reducers
export const rootReducer = combineReducers({
    language: LanguageReducer,
    israelKYC: IsraelKYCReducer,
    appCommonData: AppCommonDataReducer,
});


const persistConfig = {
    key: 'giraffe',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;