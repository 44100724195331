//default library
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//api call
import * as DashboardService from "middleware/DashboardService";
//types props
import * as DashboardType from 'api/APIMaster/DashboardType';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom component
import AdvisoryFeesCard from 'components/CardComponent/AdvisoryFeesCard';
import DividendsCard from 'components/CardComponent/DividendsCard';
import InvestingHistoryCard from 'components/CardComponent/InvestingHistoryCard';
import TransactionsCard from 'components/CardComponent/TransactionsCard';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
//custom function
import { DateRange } from 'service/utils/DateFilter';
import { getUserName } from 'service/utils/UtilFunctions';

const ActivityPage: React.FC = () => {
  const [errorAPI, setErrorAPI] = React.useState<boolean>(false);
  const [errorLog, setErrorLog] = React.useState<any>({});//error occurred flag

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [transactionData, setTransactionData] = React.useState<DashboardType.GetTransactionsData>([]);// transaction data
  const [investingHistoryData, setInvestingHistoryData] = React.useState<DashboardType.GetInvestingHistoryData>([]);// investing history data
  const [dividendData, setDividendData] = React.useState<DashboardType.GetDividendsData>([]);//dividend data
  const [advisoryFeesData, setAdvisoryFeesData] = React.useState<DashboardType.GetAccountFeesData>([]);//advisory fees data

  const { initiateAction } = useAccessToken();
  const { user } = useAuth0();
  const locale = useSelector(getLocale);

  const userName = getUserName(locale.language);
  const firstName = userName.firstName ? userName.firstName : user?.email?.split('@')[0];

  React.useEffect(() => {
    initialAPICall();
  }, []);

  //API's
  const initialAPICall = async (): Promise<void> => {
    window.scroll(0, 0);
    setIsLoading(true);

    const dateRange = DateRange('past-week');//set default value date range
    const accessToken = await initiateAction(); //get access token

    const [transactionData, investingHistoryData, dividendData, advisoryFeesData,] = await Promise.all([
      DashboardService.transactionDetails(dateRange, accessToken),
      DashboardService.investingHistoryDetails(dateRange, accessToken),
      DashboardService.dividendsDetails(dateRange, accessToken),
      DashboardService.accountFeesDetails(dateRange, accessToken),
    ]);

    setIsLoading(false);

    if ((transactionData.response || transactionData.request)
      || (investingHistoryData.response || investingHistoryData.request)
      || (dividendData.response || dividendData.request)
      || (advisoryFeesData.response || advisoryFeesData.request)
    ) {
      (transactionData.response || transactionData.request) && setErrorLog(transactionData);
      (investingHistoryData.response || investingHistoryData.request) && setErrorLog(investingHistoryData);
      (dividendData.response || dividendData.request) && setErrorLog(dividendData);
      (advisoryFeesData.response || advisoryFeesData.request) && setErrorLog(advisoryFeesData);

      setErrorAPI(true);
    } else {
      setTransactionData(transactionData);
      setInvestingHistoryData(investingHistoryData);
      setDividendData(dividendData);
      setAdvisoryFeesData(advisoryFeesData);
    }
  };

  if (errorAPI) {
    throw new Error(JSON.stringify(errorLog));
  }

  return (
    <>
      {
        isLoading
          ? <LoadingComponent />
          : <div data-testid="activity-page">
            {/* header */}
            <PageHeader
              className={'g-heading px-sm-0 px-4'}
              textStrong={'contact_us_header2'}
              userName={firstName}
              otherAttr={'contact_us_header3'}
            />

            <div className="row gx-2">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-12">
                    {/* actual content */}
                    <div className="row">
                      <div className="col-12">
                        <div className="gi-d-activity-card p-md-1 p-2 mb-10">
                          <div className="d-flex align-items-center gi-d-new-heading py-2 px-md-5 px-3">
                            <h5>
                              {IntlMessages('dashboard_activity')}
                            </h5>
                          </div>

                          {/* tab navigation */}
                          <div className="gi-d-activity-card-body px-md-5 py-md-4">
                            <div className="gi-d-activity-card-header">
                              <ul className="gi-ob-custom-nav-pills nav nav-pills nav-fill nav-justified gap-2" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                  <a className="nav-link text-center d-flex align-items-center justify-content-center text-nowrap px-1 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-01" role="tab" aria-controls="gi-tabs-01" aria-selected="true">
                                    {IntlMessages('activity_tab1')}
                                  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <a className="nav-link text-center d-flex align-items-center justify-content-center text-nowrap px-1" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-02" role="tab" aria-controls="gi-tabs-02" aria-selected="false">
                                    {IntlMessages('activity_tab2')}
                                  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <a className="nav-link text-center d-flex align-items-center justify-content-center text-nowrap px-1" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-03" role="tab" aria-controls="gi-tabs-03" aria-selected="false">
                                    {IntlMessages('activity_tab3')}
                                  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <a className="nav-link text-center d-flex align-items-center justify-content-center text-nowrap px-1" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gi-tabs-04" role="tab" aria-controls="gi-tabs-04" aria-selected="false">
                                    {IntlMessages('activity_tab4')}
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="tab-content" id="myTabContent" data-testid='tab-content-data'>
                              <div className="tab-pane fade show active" id="gi-tabs-01" role="tabpanel" aria-labelledby="gi-tabs-01-tab">
                                <TransactionsCard transactionData={transactionData} />
                              </div>
                              <div className="tab-pane fade" id="gi-tabs-02" role="tabpanel" aria-labelledby="gi-tabs-02-tab">
                                <InvestingHistoryCard investingHistoryData={investingHistoryData} />
                              </div>
                              <div className="tab-pane fade" id="gi-tabs-03" role="tabpanel" aria-labelledby="gi-tabs-03-tab">
                                <DividendsCard dividendData={dividendData} />
                              </div>
                              <div className="tab-pane fade" id="gi-tabs-04" role="tabpanel" aria-labelledby="gi-tabs-04-tab">
                                <AdvisoryFeesCard advisoryFeesData={advisoryFeesData} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div >
                </div >
              </div>

              {/* banner */}
              <div className="col-lg-3">
                <GiraffeLearnBannerSection />
              </div>
            </div>

            {/* bank space */}
            <div className="row">
              <div className="col-12">
                <div className="h-dash"></div>
              </div>
            </div>
          </div >
      }
    </>
  )
};

export default ActivityPage;