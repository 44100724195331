// default library
import * as React from 'react';
// custom component
import EncryptionInformation from 'components/SectionComponent/EncryptionInformation';
//helper message
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';

const KycCompleteFooter: React.FC = () => {

    const intl = useIntl();

    return (
        <footer className="gi-ob-c-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="gi-ob-c-footer-main">
                            <div className="gi-ob-c-footer-heading d-none d-md-flex">
                                <img src={intl.formatMessage({ id: 'LOCK_ICON_WHITE' })} alt="" />
                                <span>{IntlMessages('onboarding_footer_text3')}</span>
                            </div>
                            <div className="gi-ob-c-footer-heading d-flex d-md-none">
                                <img src={intl.formatMessage({ id: 'LOCK_ICON_WHITE' })} alt="" />
                                <span>{IntlMessages('onboarding_footer_text4')}</span>
                            </div>
                            <div className="gi-ob-c-footer-text">
                                <EncryptionInformation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default KycCompleteFooter;