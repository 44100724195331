const Notification: { [key: string]: string } = {
    /* notification message for both */
    'notification_buy': `אנו מעבדים כעת את בקשת רכישת המניות שלך עבור תיק ה {basketName} שלך. תהליך זה אורך בדרך כלל 10-15 דקות, אך מוגבל לשעות המסחר בבורסה ובכפוף לפעילות בשוק. שים לב שבמהלך זמן זה, ייתכן שסיכום חשבונך לא יציג ערכים כספיים מדויקים.`,
    'notification_sell': `אנו מעבדים כעת את בקשתך למכירת מניות עבור תיק ההשקעות שלך ב-{basketName}. תהליך זה אורך בדרך כלל 10-15 דקות, אך מוגבל לשעות המסחר בבורסה ובכפוף לפעילות בשוק. שים לב שבמהלך תקופה זו, ייתכן שסיכום חשבונך לא יציג ערכים כספיים מדויקים.`,
    'notification_new_gift': `חדשות נהדרות! מימשת בהצלחה את המתנה שלך, והתחלנו ברכישת המניות שלך. שים לב שייתכן שיחלפו עד 3 ימי עסקים כדי להעביר את כספי המתנה לחשבונך ולסיים את רכישת המניות לתיק שלך. במהלך תקופה זו, ייתכן שסיכום חשבונך לא יציג ערכים כספיים מדויקים.`,
    'notification_withdrawal': `בקשת המשיכה שלך נמצאת כעת בעיבוד. לידיעתך, עשויים לחלוף עד 2 ימי עסקים עד להשלמת המשיכה.`,
    'notification_kyc_not_done': `ברוכים הבאים! אנחנו שמחים שהצטרפתם אלינו. כדי להתחיל במסע ההשקעות שלכם, גשו וצרו את תיק ההשקעות הראשון שלכם.`,
    'notification_gift_not_redeem': `ברוכים הבאים! אנו נרגשים שהצטרפתם אלינו. כדי לקבל את המתנה שלכם, השלב הבא הוא להשלים את תהליך ההגדרה הראשוני. פשוט הקישו על כפתור 'פדיון' כדי להתחיל את התהליך.`,
    'notification_gift_add_code': `כדי לבקש את המתנה שלכם, השלב הבא הוא להשלים את תהליך ההגדרה הראשוני. פשוט הקישו על כפתור 'פדיון' כדי להתחיל את התהליך.`,
    'notification_gift_add_code_KYC': `כדי לבקש את המתנה שלכם, פשוט הקישו על כפתור 'פדיון' כדי להתחיל את התהליך.`,

    /* US notification */
    'notification_bank_to_cash': `אנו מעבדים כעת את בקשתך להעברה בנקאית. בדרך כלל, העברות כספים נמשכות עד 2 ימי עסקים. לאחר שהכספים יופקדו בהצלחה בחשבונך, תהיה אפשרות להקצות אותם לתיק מסוים ולהתחיל ברכישת מניות. `,
    'notification_bank_to_basket': `אנו מעבדים כעת את בקשת ההעברה הבנקאית שלך לתיק {basketName}. בדרך כלל, העברות כספים נמשכות עד 2 ימי עסקים. לאחר שהכספים יתווספו בהצלחה לחשבונך, המערכת שלנו תתחיל אוטומטית את תהליך רכישת המניות עבור התיק שבחרת.`,
    'notification_new_portfolio': `חדשות טובות! תיק ההשקעות שלך נוצר בהצלחה! כדי להתחיל להשקיע ולרכוש מניות, יש ללחוץ פשוט על כפתור 'הוספת כספים'.`,
    'notification_without_kyc_portfolio': `חדשות טובות! תיק ההשקעות שלך נוצר בהצלחה! כדי להתחיל להשקיע ולקנות מניות, השלב הבא שלך הוא להשלים את תהליך ההגדרה ולחבר את חשבון הבנק שלך. יש להקיש פשוט על הלחצן 'הוספת כספים' כדי להתחיל.`,

    /* Info modal message */
    'info_account_heading': 'למדו כיצד אנחנו מחשבים את ערך החשבון שלכם: ',
    'info_account_body1': `ערך החשבון מייצג את השווי הכולל של ההשקעות שלך, כולל גם אחזקות התיק שלך וכל יתרת מזומנים זמינה.`,
    'info_account_body2': `כדי לחקור מה משפיע על ערך החשבון שלך.`,
    'info_account_more_body': `יש לך אפשרות להגדיל את יתרת החשבון שלך על ידי הוספת כספים לתיק ורכישת מניות נוספות או על ידי הוספת כספים ליתרת המזומנים שלך. ניתן לבצע משיכות רק מיתרת המזומנים שלך.{br} אם ישנו צורך למשוך יותר ממה שזמין ביתרת המזומנים, קודם כל עליך למכור את ההשקעות שלך.`,
    'info_portfolio_heading': `למדו כיצד אנחנו מחשבים את ערך התיק הכולל שלכם: `,
    'info_portfolio_body': `ערך התיק הכולל מייצג את השווי המשולב של כל המניות בתיקים שלך, אשר משקף את הערך הנוכחי של ההשקעות שלך עם Giraffe. {br}ערך התיק הכולל נותן לך תמונת מצב של הערך הנוכחי של ההשקעות שלך.`,
    'info_cash_balance_heading': `למדו כיצד אנחנו מחשבים את יתרת המזומנים שלכם: `,
    'info_cash_balance_body': `יתרת המזומנים שלך מייצגת את סך הכסף בחשבון שלך שאינו מושקע במניות. {br} יתרה זו כוללת העברות כספים אחרונות שטרם הוקצו להשקעות; כספים ממניות שמכרת או דיבידנדים שקיבלת. יתרת המזומנים שלך זמינה להשקעה או משיכה.`,
    'info_performance_heading': `למדו כיצד אנחנו מחשבים את הביצועים שלכם: `,
    'info_performance_body': `ביצועים מייצגים את סכום הכסף הכולל שהרווחת מההשקעות שלך בכל התיקים שלך מאז שהתחלת להשקיע עם Giraffe. הביצועים כוללים הן את העליות והירידות בשווי המניות שלך והן את כל הדיבידנדים שהרווחת. מדידת הביצועים כבר לקחה בחשבון את עמלת הייעוץ שלנו.`,
    'info_gift_pending_heading': `קיבלת במתנה אשראי להשקעה ממישהו שמעריך אותך.`,
    'info_gift_pending_body': `לאחר שתממשו את המתנה שלכם, תהיה לכם הזדמנות ליצור תיק השקעות המתאים לערכים שלכם ולרכוש אותו ללא מאמץ באמצעות האשראי שקיבלתם. {br}{br}תוכלו גם לעקוב אחרי ההשפעה שיצרתם עם המתנה הזו ולהוסיף עוד כספים לתיק שלכם כדי למקסם את ההשפעה החיובית שאתם יוצרים.`,
    'info_earning_heading': `למדו כיצד אנחנו מחשבים את הרווחים בתיק שלכם: `,
    'info_earning_body': `הרווחים מייצגים את הרווח או ההפסד הנוכחי של המניות שבבעלותך נכון להיום. אנחנו מחשבים רווחים על ידי לקיחת המחיר הנוכחי של מניה בתיק שלך והפחתת המחיר שבו קנית אותה; לאחר מכן אנו חוזרים על החישוב הזה עבור כל המניות בתיק שלך ומחברים את הערכים הללו.`,
    'info_portfolio_basket_heading': `למדו כיצד אנחנו מחשבים את ערך התיק שלכם:`,
    'info_portfolio_basket_body': `ערך התיק שלך מייצג את השווי הקולקטיבי של המניות בתיק שלך, המשקף את הערך הנוכחי של ההשקעות שלך בתיק הספציפי הזה. זה משמש כמדד להערכת השווי הנוכחית של האחזקות שלך בתיק זה.`,
    'info_twenty_year_heading': `למדו כיצד אנחנו מחשבים את התחזית העתידית שלכם:`,
    'info_twenty_year_body': `התחזית העתידית ממחישה מגוון תרחישים מתוך מגוון תוצאות פיננסיות אפשריות רבות. {br}{br} התשואה השנתית הממוצעת של ה-S&P 500 עמדה היסטורית על כ-9.3%. התחזית של Girrafe מניחה שהתשואה השנתית של התיק שלך תהיה כ-9.3% ועוד או פחות 0.5% כדי לקחת בחשבון את השונות בשוק. עם האפשרויות של ערך ניקיון עמוק, יש חוסר ודאות גדול יותר לגבי ההשפעות של השקעה מבוססת ערכים על התשואות. המשמעות היא ששונות נוספת של ועוד או פחות 1% מחושבת בתחזיות שלך עבור כל ערך ניקיון עמוק שבחרת.{br}{br} התחזית העתידית אינה ערובה או תחזית לתוצאות ההשקעה ואינה מתייחסת לתקופות של ביצועים גבוהים או נמוכים באופן חריג או את עיתוי מכירת המניות. סביר להניח שהתוצאות בפועל יהיו שונות מהתחזית, במיוחד אם מניות עברו סדרה של הפסדים בתחילת תקופת ההשקעה או אם ישתנו העדפות ההשקעה שלך באמצע התקופה. {br}{br} תחזית זו אינה הדמיה (כגון הדמיית מונטה קרלו) ואין להשתמש בה כדי לקבוע את ההסתברות לתוצאת השקעה נתונה. היא נועדה להמחיש את היתרונות הפוטנציאליים של הפיכת השקעה להרגל.`,
    'info_basket_performance_heading': `למדו כיצד אנחנו מחשבים את ביצועי התיק שלך:`,
    'info_basket_performance_body': `ביצועי התיק מייצגים את סכום הכסף הכולל שהרווחת מתיק זה מאז שהתחלת להשקיע בו. ביצועי התיק כוללים הן עליות והן ירידות בשווי המניות שלך ואת כל הדיבידנדים שהרווחת.`,

    /* Israel Notification */
    'il_set_monthly_saving': `חדשות טובות! תיק העבודות שלך נוצר בהצלחה! כדי להתחיל לחסוך ולהשקיע, השלב הבא שלך הוא להשלים את תהליך ההגדרה. פשוט הקש על כפתור 'הגדר חיסכון חודשי' כדי להתחיל.`,
    'il_monthly_saving_cash': `שימו לב שבחרתם להעביר את כל הפקדות החיסכון העתידיות לחשבון המזומן שלכם בג'ירף. אנו ממליצים בחום לבחור תיק השקעות ייעודי. {clickText} כדי לבצע את השינוי בקלות.`,
    'il_kyc_denied': `למרבה הצער, תהליך ההגדרה נכשל, ולא נוכל לפתוח חשבון השקעה על שמך. לפרטים נוספים, אנא צור איתנו קשר בדוא"ל או דרך הכרטיסייה 'צור קשר'.`,
    'il_kyc_reject': `אנא שים לב שפרטים מסוימים שהוזנו במהלך תהליך ההגדרה אינם מתאימים למידע בתיעוד הזיהוי שלך. {clickText} כדי לעדכן.`,
    'il_monthly_deduction_start': `חדשות טובות! החיסכון החודשי שלך ממשכורת נמצא בעיבוד ונמצא בדרך אלינו. שים לב שייתכן שיחלפו עד 3-4 ימי עסקים כדי להעביר את הכספים לחשבונך ולסיים את רכישת המניות עבור התיק שלך. במהלך תקופה זו, ייתכן שסיכום החשבון שלך לא יציג ערכים כספיים מדויקים.`,
}

export default Notification