import * as DefaultType from 'interface/DefaultValueType';
import * as PropsFunction from 'interface/PropsFunction';
import * as DefaultValueType from 'interface/DefaultValueType';

import IntlMessages from 'helper/IntlMessages';

// default direction
export const defaultDirection: DefaultType.String = 'ltr';
export const defaultLocale: DefaultType.String = 'en_US';
export const defaultCountry: DefaultType.String = 'USA';

export const localeOptions: DefaultType.LocalOption = [
    { language: 'en_US', label: 'English - US', direction: 'ltr', code: 'USA', countryCode: 'USA' },
    { language: 'en_US', label: 'Hebrew', direction: 'ltr', code: 'ISR_EN', countryCode: 'ISR' },
    { language: 'he_IL', label: 'Hebrew', direction: 'rtl', code: 'ISR', countryCode: 'ISR' },
]

// radio button value
export const actionValue: DefaultType.Options = [
    { value: 'true', label: IntlMessages('yes'), id: 'yes' },
    { value: 'false', label: IntlMessages('no'), id: 'no' },
]

// taxes document
export const taxesDropdown: PropsFunction.Option = [
    { value: 'past-week', label: IntlMessages('past_week'), active: true },
    { value: 'three-month', label: IntlMessages('past_three_week'), active: true },
    { value: 'six-month', label: IntlMessages('past_six_week'), active: true },
    { value: 'one-year', label: IntlMessages('past_year'), active: true },
    { value: 'all-time', label: IntlMessages('all_time'), active: true },
]

export const historicalDropdown: DefaultType.Options = [
    { value: 'one-month', label: IntlMessages('one_month') },
    { value: 'three-month', label: IntlMessages('three_month') },
    { value: 'ytd', label: IntlMessages('year_to_date') },
    { value: 'one-year', label: IntlMessages('one_year') },
    { value: 'two-year', label: IntlMessages('two_year') },
    { value: 'all-time', label: IntlMessages('all_time') },
] //static data for chart for historical

export const dateRange: DefaultType.Options = [
    { value: 'one-month', label: IntlMessages('dynamic_month', { month: '01' }) },
    { value: 'three-month', label: IntlMessages('dynamic_month', { month: '03' }) },
    { value: 'one-year', label: IntlMessages('dynamic_year', { year: '01' }) },
    { value: 'all-time', label: IntlMessages('all_time') },
]

export const futureDropdown: DefaultType.Options = [
    { value: '15', label: IntlMessages('year_number', { number: '15' }) },
    { value: '20', label: IntlMessages('year_number', { number: '20' }) },
    { value: '30', label: IntlMessages('year_number', { number: '30' }) },
    { value: '50', label: IntlMessages('year_number', { number: '50' }) },

] //static data for chart for future

export const startWithDropdown: DefaultType.Options = [
    { value: 1000, label: IntlMessages('number', { number: '$1,000' }) },
    { value: 2000, label: IntlMessages('number', { number: '$2,000' }) },
    { value: 3000, label: IntlMessages('number', { number: '$3,000' }) },
]

export const waitPatientlyDropdown: DefaultType.Options = [
    { value: 20, label: IntlMessages('years_number', { number: '20' }) },
    { value: 30, label: IntlMessages('years_number', { number: '30' }) },
    { value: 40, label: IntlMessages('years_number', { number: '40' }) },
]

//
export const genderOption: DefaultType.Options = [
    { value: 'male', label: IntlMessages('kyc_gender_male'), id: 'male' },
    { value: 'female', label: IntlMessages('kyc_gender_female'), id: 'female' },
]

// sorting type
const sortUp: DefaultType.String = 'sort-up'
const sortDown: DefaultType.String = 'sort-down'
const sortDefault: DefaultType.String = 'sort'

export const sortType: DefaultType.SortTypeMap = {
    sortTypesTicker: {
        up: {
            className: sortUp,
            fn: (a, b) => a.tickerSymbol.localeCompare(b.tickerSymbol),
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.tickerSymbol.localeCompare(a.tickerSymbol),
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
    sortTypesCompany: {
        up: {
            className: sortUp,
            fn: (a, b) => a.companyName.localeCompare(b.companyName),
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.companyName.localeCompare(a.companyName),
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
    sortTypesCompany2: {
        up: {
            className: sortUp,
            fn: (a, b) => a.localeCompare(b),
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.localeCompare(a),
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
    sortTypesDollarValue: {
        up: {
            className: sortUp,
            fn: (a, b) => a.value - b.value,
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.value - a.value,
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
    sortTypesIndustry: {
        up: {
            className: sortUp,
            fn: (a, b) => a.industry.localeCompare(b.industry),
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.industry.localeCompare(a.industry),
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
    sortTypesPercentage: {
        up: {
            className: sortUp,
            fn: (a, b) => a.weight - b.weight,
        },
        down: {
            className: sortDown,
            fn: (a, b) => b.weight - a.weight,
        },
        default: {
            className: sortDefault,
            fn: (a, _b) => a,
        },
    },
};

// basket name option
export const basketName: DefaultValueType.Options = [
    { value: 'Impact fund', label: 'Impact fund', id: 'impact-fund' },
    { value: 'Five year savings', label: 'Five year savings', id: 'five-year-savings' },
    { value: 'School fund', label: 'School fund', id: 'school-fund' },
]

// static amount range
export const amountRange: DefaultType.Options = [
    { value: 20000, label: IntlMessages('less_forty_thousand') },
    { value: 60000, label: IntlMessages('less_eighty_thousand') },
    { value: 115000, label: IntlMessages('less_one_fifty_thousand') },
    { value: 225000, label: IntlMessages('less_three_hundred_thousand') },
    { value: 500000, label: IntlMessages('more_three_hundred_thousand') },
]

export const annualIncomeRange: DefaultType.Options = [
    { value: 50000, label: IntlMessages('less_fifty_thousand') },
    { value: 150000, label: IntlMessages('less_two_lakhs') },
    { value: 350000, label: IntlMessages('less_five_lakhs') },
    { value: 750000, label: IntlMessages('less_ten_lakhs') },
    { value: 2000000, label: IntlMessages('more_ten_lakhs') },
]

// static amount range for IL
export const amountRangeIL: PropsFunction.Option = [
    { value: "20000", label: IntlMessages('amount_less_160000'), active: true },
    { value: "60000", label: IntlMessages('amount_btw_160000_320000'), active: true },
    { value: "115000", label: IntlMessages('amount_btw_320000_600000'), active: true },
    { value: "225000", label: IntlMessages('amount_btw_600000_1200000'), active: true },
    { value: "500000", label: IntlMessages('amount_more_1200000'), active: true },
]

export const transferType: DefaultType.TransferType = {
    BANK_TO_BASKET: 'BANK_TO_BASKET',
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL',
    BUY: 'BUY',
    SELL: 'SELL',
    BASKET_BUY: 'BASKET_BUY',
    BASKET_SELL: 'BASKET_SELL',
}

// location country dropdown
export const locationSelector: DefaultType.LocationSelector = [
    { language: 'en-US', label: IntlMessages('location_us'), value: 'USA', checkIcon: 'GREEN_RIGHT' },
    { language: 'he-IL', label: IntlMessages('location_israel'), value: 'ISR', checkIcon: 'GREEN_RIGHT' },
    { language: '', label: IntlMessages('location_other'), value: 'Other', checkIcon: 'GREY_WRONG' },
]

// israel KYC dropdown option
export const amountRangeInvestment: PropsFunction.Option = [
    { value: '40000', label: IntlMessages('amount_less_50000'), active: true },
    { value: '150000', label: IntlMessages('amount_bet_50000_250000'), active: true },
    { value: '375000', label: IntlMessages('amount_bet_250000_500000'), active: true },
    { value: '600000', label: IntlMessages('amount_more_500000'), active: true },
]

export const investmentPurposeOption: PropsFunction.Option = [
    { value: 'Long term savings', label: IntlMessages('investment_purpose_long_term'), active: true },
    { value: 'Future property purchase', label: IntlMessages('investment_purpose_future'), active: true },
    { value: 'Savings for the benefit of the children', label: IntlMessages('investment_purpose_saving'), active: true },
    { value: 'There is no specific designation', label: IntlMessages('investment_purpose_no_specific'), active: true },
]

export const amountIntentOption: PropsFunction.AmountIntentOption = [
    { value: 'Less then 10%', label: IntlMessages('amount_intent_10'), active: true, minValue: 1 },
    { value: 'Between 10% - 50%', label: IntlMessages('amount_intent_10_50'), active: true, minValue: 10 },
    { value: 'Between 50% - 95%', label: IntlMessages('amount_intent_50_95'), active: false, minValue: 50 },
    { value: 'Over 95%', label: IntlMessages('amount_intent_95'), active: false, minValue: 95 },
]

export const investmentFundsOption: PropsFunction.Option = [
    { value: 'In the next two years', label: IntlMessages('investment_fund_year_2'), active: false },
    { value: '2 - 5 years', label: IntlMessages('investment_fund_year_2_5'), active: true },
    { value: '6 - 10 years', label: IntlMessages('investment_fund_year_6_10'), active: true },
    { value: '11 - 15 years', label: IntlMessages('investment_fund_year_11_15'), active: true },
    { value: 'More than 15 years', label: IntlMessages('investment_fund_year_15'), active: true },
]

export const sourceMoneyOption: DefaultValueType.Options = [
    { value: 'salary', label: IntlMessages('kyc_source_money_salary'), id: 'salary' },
    { value: 'other', label: IntlMessages('kyc_source_money_other'), id: 'other' },
]

export const otherSourceOption: DefaultValueType.OtherSourceOption = [
    { label: IntlMessages('other_source_1'), name: 'Salary', active: false },
    { label: IntlMessages('other_source_2'), name: 'Exports & yielding assets', active: false },
    { label: IntlMessages('other_source_3'), name: 'Savings in the bank', active: false },
    { label: IntlMessages('other_source_4'), name: 'Forex trading', active: false },
    { label: IntlMessages('other_source_5'), name: 'Real estate / saving funds', active: false },
    { label: IntlMessages('other_source_6'), name: 'Crypto', active: false },
    { label: IntlMessages('other_source_7'), name: 'Gift/compensation/inheritance', active: false },
    { label: IntlMessages('other_source_8'), name: 'Gambling', active: false },
    { label: IntlMessages('other_source_9'), name: 'Pension or social security allowance', active: false },
]

export const monthlySavingStatus: DefaultValueType.SavingStatus = {
    ACTIVE: {
        label: IntlMessages('monthly_saving_status_active'),
        className: 'text-active'
    },
    PAUSED: {
        label: IntlMessages('monthly_saving_status_paused'),
        className: 'text-pause'
    },
    FAILED: {
        label: IntlMessages('monthly_saving_status_failed'),
        className: 'text-failed'
    },
    PENDING: {
        label: IntlMessages('monthly_saving_status_pending'),
        className: 'text-pause'
    },
    DENIED: {
        label: IntlMessages('monthly_saving_status_denied'),
        className: 'text-failed'
    },
    DENIED_ACTION: {
        label: IntlMessages('monthly_saving_status_denied_action'),
        className: 'text-failed'
    }
}

export const ShouldBeComponentAddress: {
    [key: string]: string[];
} = {
    streetNumber: ["street_number"],
    postcode: ["postal_code"],
    address1: ["route"],
    province: [
        'administrative_area_level_1',
    ],
    city: [
        "sublocality",
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'sublocality_level_5',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'administrative_area_level_6',
        'administrative_area_level_7',
    ],
    country: ["country"]
};
