import DashboardService from 'api/APIServices/DashboardService';
import * as Local from 'service/utils/LocalStorageData';
import * as DashboardType from 'api/APIMaster/DashboardType';

const Dashboard = new DashboardService();

export const getBasketBasicDetails = async (accessToken: string) => {

    return await Dashboard.getBasketBasicDetails({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const transferFund = async ({
    amount,
    fromAccount,
    toAccount, }: {
        amount: number,
        fromAccount: any,
        toAccount: any
    },
    accessToken: string) => {

    const data = {
        amount: amount,
        fromAccount: {
            id: fromAccount.split('|')[0],
            type: fromAccount.split('|')[1]
        },
        toAccount: {
            id: toAccount.split('|')[0],
            type: toAccount.split('|')[1]
        }
    }

    return await Dashboard.transferFund(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getPortfolioSummary = async (accessToken: string) => {
    const data = {
        locale: Local.getCurrentLanguage()
    }

    return await Dashboard.getPortfolioSummary(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getFundLocations = async (accessToken: string) => {

    return await Dashboard.getFundLocations({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getAllImpactDetail = async (accessToken: string) => {
    const data = {
        locale: Local.getCurrentLanguage()
    }

    return await Dashboard.getAllImpactDetail(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getAccountPerformance = async (dateRange: any, accessToken: string) => {
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Dashboard.getAccountPerformance(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const isFundTransferPending = async (accessToken: string) => {

    return await Dashboard.isFundTransferPending({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const getGiftReceived = async (giftIdentifier: string, accessToken: string) => {

    const data = {
        locale: Local.getCurrentLanguage(),
        identifier: giftIdentifier
    }

    return await Dashboard.getGiftDetailByGiftIdentifier(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const accountFeesDetails = async (dateRange: DashboardType.CommonActivityRequestModel, accessToken: string,) => {
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Dashboard.accountFeesDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
};

export const dividendsDetails = async (dateRange: DashboardType.CommonActivityRequestModel, accessToken: string,) => {

    //set params
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Dashboard.dividendsDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const investingHistoryDetails = async (dateRange: DashboardType.CommonActivityRequestModel, accessToken: string,) => {

    //set params
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Dashboard.investingHistoryDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })

}

export const transactionDetails = async (dateRange: DashboardType.CommonActivityRequestModel, accessToken: string) => {

    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await Dashboard.transactionDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const withdrawMoney = async (withdrawData: DashboardType.WithdrawMoneyRequestModal, accessToken: string) => {

    return await Dashboard.withdrawMoney(withdrawData, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}