
import * as React from 'react';
// custom component
import InputComponentText2 from 'components/InputComponent/InputComponentText2';
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
import AutoComplete from 'components/AutoComplete/AutoComplete';
// custom function
import resizeErrorMessage from 'service/utils/ResizeErrorMessage';
//message import
import IntlMessages from 'helper/IntlMessages';
import * as Config from 'config/Config';
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
import * as UserType from "api/APIMaster/UserType";
// local import
import * as Local from 'service/utils/LocalStorageData';

type Props = {
  propsData: PropsFunction.PropsPageDataFunction
  staticData: UserType.OnboardingStaticDataResponseModel
}

const Address: React.FC<Props> = (props: Props) => {
  const { propsData, staticData } = props // Destructure props for easier access

  const [addressAutoFill, setAddressAutoFill] = React.useState<string>('');//auto field text
  const [apartmentUnit, setApartmentUnit] = React.useState<string>('');//apartment unit
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});
  const [postalCode, setPostalCode] = React.useState<string>(''); //postal code
  const [isDataLoad, setIsDataLoad] = React.useState(true);//load component
  const [errorFlag, setErrorFlag] = React.useState(false);//load component

  const nextPage = 'page4';//next page
  const sendText = false;//to display send text in the button
  const isRouteChange = true;//check page change
  const submitText = false;//to display submit text in the button
  const parentButton = false;//to display parent click props button

  React.useEffect(() => {
    const localStorageData = Local.getLocalData('onBoardingDetails');

    // Call propsData function to update parent component's state
    propsData({
      previousPage: 'page2',
      pageNumber: 4,
    });

    //get phone number from localStorage
    localStorageData?.addressAutoFill && setAddressAutoFill(localStorageData.addressAutoFill);
    localStorageData?.apartmentUnit && setApartmentUnit(localStorageData.apartmentUnit);
    localStorageData?.postalCode && setPostalCode(localStorageData.postalCode);

    setIsDataLoad(false);

  }, []);

  // resize the error message
  const resizeError = (): void => {
    resizeErrorMessage('addressAutoFill', 'apartmentUnit', 'postalCode')
  }

  // auto complete field data
  const setAutoCompleteValue = (params: { addressAutoFill: string; postalCode: string; errorFlag: boolean }): void => {
    setAddressAutoFill(params.addressAutoFill);
    setPostalCode(params.postalCode);
    setErrorFlag(params.errorFlag);
  };

  // set input value
  const setInputValue = (fieldName: string, fieldValue: string): void => {
    fieldName === 'apartmentUnit' && setApartmentUnit(fieldValue);
    if (fieldName === 'postalCode') {
      const value = fieldValue.replace(Config.POSTAL_CODE_INPUT, '');
      setPostalCode(value);
    }
  };

  //  holding field details values 
  React.useEffect(() => {
    const updatedFieldDetails = {
      addressAutoFill: addressAutoFill,
      apartmentUnit: apartmentUnit,
      postalCode: postalCode,
    };

    setFieldDetails(updatedFieldDetails)

    return () => {
      resizeError(); // resizing error messages
    };
  }, [addressAutoFill, apartmentUnit, postalCode])

  return (
    <>
      {/* static text */}
      <div className="gi-ob-slide-card" data-testid="address-page">
        <div className="gi-ob-full-name-text gi-ob-legal-text">
          <h5>{IntlMessages('address_text1')}</h5>
          <p>{IntlMessages('address_text2')}</p>
        </div>
        {/* full address */}
        <div className="gi-ob-full-name-form gi-ob-legal-form">
          <div className="gi-ob-input-box gi-ob-legal-input gi-ob-legal-address">
            <label>{IntlMessages('address_text3')}</label>

            {!isDataLoad && (
              <AutoComplete
                className="react-google-place"
                inputValue={setAutoCompleteValue}
                value={addressAutoFill}
                countryList={staticData.countryList}
                textCenter={false}
                dataTestId={"auto-complete-value"}
              />
            )}

            {errorFlag &&
              <ErrorKYC
                message={'error_invalid_address'}
                fieldName={'addressAutoFill'}
              />
            }
          </div>

          {/* apartment/unit & zip-code */}
          <div className="gi-ob-legal-form-column-2">
            {/* apartment/unit */}
            <div className="gi-ob-input-box gi-ob-legal-input gi-ob-legal-unit">
              <label>{IntlMessages('address_text4')}</label>
              <InputComponentText2
                name={'apartmentUnit'}
                placeholder={'placeholder_apartment_2'}
                className={''}
                fieldValue={apartmentUnit}
                inputValue={setInputValue}
                readOnly={false}
                disabled={false}
                dataTestId={'apartment-unit'}
              />
              {apartmentUnit.length > Config.MAX_NUMBER_36 && (
                <ErrorKYC
                  message={['error_max_length', { number: 36 }]}
                  fieldName={'apartmentUnit'}
                />
              )}
            </div>

            {/* zip-code */}
            <div className="gi-ob-input-box gi-ob-legal-input gi-ob-legal-Zip gi-ob-legal-unit">
              <label>{IntlMessages('address_text5')}</label>
              <InputComponentText2
                name={'postalCode'}
                placeholder={'personal_page4_label3'}
                className={`zip-code ${postalCode ? 'success' : ''}`}
                fieldValue={postalCode}
                inputValue={setInputValue}
                readOnly={true}
                disabled={false}
                dataTestId={'postal-code'}
              />
              {postalCode.length > Config.MAX_NUMBER_11 && (
                <ErrorKYC
                  message={['error_max_length', { number: 9 }]}
                  fieldName={'postalCode'}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton} />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton} />
    </>
  );
};

export default Address;




