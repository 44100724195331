// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// device detect library
import { isMobile } from "react-device-detect";
// react you-tube
import YouTube from 'react-youtube';
import { useAuth0 } from '@auth0/auth0-react';
import { useAccessToken } from 'hook/UseAccessToken';
// custom component
import DashboardHeader from 'components/HeaderComponent/DashboardHeader';
// API call
import * as LearnType from 'api/APIMaster/LearnType';
import * as LearnService from 'middleware/LearnService';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// intl message library 
import { useIntl } from 'react-intl';

const opts = {
    height: '390',
    width: '100%',
    playerVars: {
        autoplay: 1,
    },
};

const GiraffeLearnVideoModal: React.FC = () => {

    const { isAuthenticated } = useAuth0()
    const { initiateAction } = useAccessToken();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    const intl = useIntl();
    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [currentVideo, setCurrentVideoList] = React.useState<LearnType.AnyContentVideoResponse>({
        identifier: '',
        bannerImage: '',
        thumbnailImage: '',
        title: '',
        shortDescription: '',
        longDescription: '',
        featured: false,
        videoId: '',
        startFrom: 0,
        duration: 0,
        priority: 0,
        viewedTill: 0,
    });
    const [thumbnailVideo, setThumbnailVideo] = React.useState<LearnType.AnyContentVideoResponse[]>([]);

    React.useEffect(() => {
        initialAPICall()
    }, [locale.language])

    const initialAPICall = async (): Promise<void> => {
        const userIdentifier = urlParams.get('cid') as string;

        const videoList = await LearnService.getAnyContent(userIdentifier); //no need access token
        if (videoList.response) {
            setErrorLog(videoList)
            setIsError(true)
        } else {
            const currentIdentifier: string = urlParams.get('vid') ?? videoList[0].anyContentVideoResponseList[0].identifier;

            const currentVideo = await videoList?.[0]?.anyContentVideoResponseList?.filter((e: LearnType.AnyContentVideoResponse) => e.identifier === currentIdentifier)[0]

            setCurrentVideoList(currentVideo)
            setThumbnailVideo(videoList?.[0]?.anyContentVideoResponseList)
        }
    }

    React.useEffect(() => {
        if (!locale.isReducerCalled) {
            dispatch(setCurrentLanguage('USA'))
        }
    }, [])

    const videoReady = async (event: any) => {
        event.target.loadVideoById({
            videoId: currentVideo?.videoId ?? '',
            startSeconds: currentVideo?.viewedTill,
        })
    }

    const playVideo = async (event: any) => {
        const currentVideo = thumbnailVideo.filter(e => e.videoId === event.target.parentElement.id)

        setCurrentVideoList(currentVideo[0])
    }

    const endTime = async (event: any) => {

        const videoIdentifier = currentVideo.identifier
        const currentTime = event.target.getCurrentTime()
        const currentDate = new Date()

        const videoUpdate = {
            videoIdentifier: videoIdentifier,
            currentTime: Math.ceil(currentTime),
            currentDate: currentDate.toISOString().split('T')[0] + ' ' + currentDate.toTimeString().split(' ')[0],
        }
        if (isAuthenticated) {
            const accessToken = await initiateAction() //get access token
            const isVideoUpdate = await LearnService.getUserVideo(videoUpdate, accessToken)
            if (isVideoUpdate.response) {
                setErrorLog(isVideoUpdate)
                setIsError(true)
            }
        }
    }

    const navigation = async (e: any) => {
        window.history.back()
        e.preventDefault()
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const scrollData: JSX.Element = <>
        <div className="gi-g-v-list pe-3 h-100 position-absolute">
            {thumbnailVideo.map((e, i) => (
                <div className="gi-g-v-list-point d-flex align-items-center gap-3 mb-3 pb-1" id={e.videoId} onClick={playVideo} key={i}>
                    <div className="gi-g-v-list-thumbnail" id={e.videoId}>
                        <img src={e.thumbnailImage} className="img-fluid" alt={e.thumbnailImage} />
                    </div>
                    <div className="gi-g-v-list-tittle ps-1" id={e.videoId}>
                        {e.title}
                    </div>
                </div>
            ))}
        </div>
    </>

    return (
        <>

            {/* Header  */}
            {isAuthenticated && <DashboardHeader dashboard={false} menuOpen={() => { }} />}

            {/* Actual Content */}
            <section className="gi-g-video-section">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12">
                            <div className="learn-back-btn py-5 mt-3 mb-4">
                                <a href='#!' onClick={navigation}>
                                    <img src={intl.formatMessage({ id: 'LEFT_WHITE_ARROW' })} className="img-fluid" alt="LEFT_WHITE_ARROW" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='gi-g-video-section h-100'>
                <div className='container-xxl'>
                    <div className='row gx-3'>
                        <div className='col-lg-8 col-12 px-lg-3 px-0'>
                            <div className='row' >
                                <div className="col-12">
                                    <div className="gi-g-v-video-player pe-lg-1 pe-0">
                                        <div className="w-100">
                                            <YouTube videoId={currentVideo?.videoId ?? ''} opts={opts} onReady={videoReady} onPause={endTime} onEnd={endTime} />;
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="gi-g-v-video-description d-block d-lg-none pb-5 px-2">
                                        <h5 className="mt-3 pt-3"> {currentVideo?.title}</h5>
                                        <p className="mt-3 pt-3">{currentVideo?.longDescription} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 pb-lg-0 pb-3">
                            {/* <div className=""> */}
                            {isMobile
                                ? <div className='gi-g-v-list-main w-100 h-100 position-relative scroll-thumb p-0' >
                                    {scrollData}
                                </div>
                                : <ScrollBar component="div" className=' gi-g-v-list-main w-100 h-100 position-relative'>
                                    {scrollData}
                                </ScrollBar>
                            }

                            {/* </div> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div className="gi-g-v-video-description d-none d-lg-block pb-5">
                                <h5 className="mt-3 pt-3"> {currentVideo?.title}</h5>
                                <p className="mt-3 pt-3">{currentVideo?.longDescription} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </ >
    );
}

export default GiraffeLearnVideoModal;
