import * as UserType from 'api/APIMaster/UserType';
import * as DefaultType from 'interface/DefaultValueType';

export type Data = {
    [key: string]: any
}

export const userFormDataEN = (data: Data): UserType.UserRequestModel => {
    //construct user address modal
    const userAddress: UserType.UserAddressCommonModel = {
        street1: data.street1,
        street2: data.apartmentUnit ?? null,
        street3: null,
        city: data.city,
        province: data.province,
        postalCode: data.postalCode,
        countryIdentifier: data.country
    }

    //construct user employment modal
    const userEmployment: UserType.UserEmploymentCommonModel = {
        statusIdentifier: data.employmentIdentifier,
        company: data.company ?? null,
        typeIdentifier: data.typeIdentifier ?? null,
        positionIdentifier: data.positionIdentifier ?? null,
        affiliatedWithBroker: data.brokerDealer,
        directorOf: data.directorOf ?? null
    }

    //construct user investor modal
    const userInvestor: UserType.UserInvestorCommonModel = {
        experienceIdentifier: data.experienceIdentifier,
        annualIncome: data.annualIncome,
        networthTotal: data.netWorth,
        riskToleranceIdentifier: data.riskTolerance
    }

    //construct user disclosure modal
    const userDisclosure: UserType.UserDisclosureCommonModel = {
        termsOfUse: true,
        customerAgreement: true,
        marketDataAgreement: true,
        rule14b: true,
        findersFee: false,
        privacyPolicy: true,
        dataSharing: true,
        extendedHoursAgreement: false,
        giraffeTermsAndConditions: true
    }

    //construct form data
    const formData: UserType.UserRequestModel = {
        name: `${data.firstName} ${data.lastName}`,
        firstName: data.firstName,
        middleName: null,
        lastName: data.lastName,
        email: data.emailId,
        marketing: true,
        phone: data.phone,
        language: data.language,
        taxIdNumber: (data.ssnNumber),
        citizenship: (data.citizenship),
        dob: (data.dateOfBirth),
        politicallyExposedNames: (data.politicallyExposedNames),
        irsBackupWithholdings: false,
        identifier: data.identifier,
        showKycStatus: true,
        usTaxPayer: data.taxPayer,
        userAddress: userAddress,
        userEmployment: userEmployment,
        userInvestor: userInvestor,
        userDisclosure: userDisclosure,
        metadataList: [],
        gender: null,
        kycCountry: "USA",
        locale: 'en_US',
    }

    return formData;
}

export const userFormDataIL = (data: Data): UserType.UserRequestModel => {

    const remainingChar = '000000000';
    const taxIdNumber = data.docID ? (remainingChar.substring(0, 9 - data.docID.length) + data.docID) : ''

    //construct user address modal
    const userAddress: UserType.UserAddressCommonModel = {
        street1: data.street1,
        street2: data.houseNumber ?? null,
        street3: data.apartmentUnit ?? null,
        city: data.city,
        province: data.province,
        postalCode: data.postalCode,
        countryIdentifier: data.countryIdentifier
    }

    //construct user employment modal
    const userEmployment: UserType.UserEmploymentCommonModel = {
        statusIdentifier: data.employmentStatus,
        company: data.companyName ?? null,
        typeIdentifier: data.occupationField ?? null,
        positionIdentifier: data.position ?? null,
        affiliatedWithBroker: data.brokerDealer,
        directorOf: data.directorOf
    }

    //construct user investor modal
    const userInvestor: UserType.UserInvestorCommonModel = {
        experienceIdentifier: data.investingExperience ?? null,
        annualIncome: data.annualIncome,
        networthTotal: data.netWorthTotal,
        riskToleranceIdentifier: data.riskToleranceIdentifier,
    }

    //construct user disclosure modal
    const userDisclosure: UserType.UserDisclosureCommonModel = {
        termsOfUse: true,
        customerAgreement: true,
        marketDataAgreement: true,
        rule14b: true,
        findersFee: false,
        privacyPolicy: true,
        dataSharing: true,
        extendedHoursAgreement: false,
        giraffeTermsAndConditions: true
    }

    //construct form data
    const formData: UserType.UserRequestModel = {
        name: (data.firstNameEn && data.lastNameEn) ? `${data.firstNameEn} ${data.lastNameEn}` : '',
        firstName: data.firstNameEn,
        middleName: null,
        lastName: data.lastNameEn,
        email: data.email,
        marketing: data.tcCheck ?? false,
        phone: data.phoneNumber,
        language: data.language,
        taxIdNumber: taxIdNumber,
        citizenship: data.countryIdentifier ?? null,
        dob: data.dob ?? null,
        politicallyExposedNames: data.politicallyExposedNames,
        irsBackupWithholdings: false,
        identifier: data.identifier,
        showKycStatus: data.tcCheck ?? false,
        usTaxPayer: data.taxPayer ?? null,
        userAddress: data.street1 ? userAddress : null,
        userEmployment: data.employmentStatus ? userEmployment : null,
        userInvestor: data.annualIncome ? userInvestor : null,
        userDisclosure: data.tcCheck ? userDisclosure : null,
        gender: data.gender ?? null,
        metadataList: Object.entries(data)
            .filter(([key, value]) => (key === 'firstNameLocale'
                || key === 'lastNameLocale'
                || key === 'investmentPurpose'
                || key === 'investmentAmount'
                || key === 'amountIntent'
                || key === 'investmentFunds'
                || key === 'sourceMoney'
                || key === 'reportsMailingChoice'
                || key === 'otherSource') && value !== ''
            )
            .map(([key, value]) => ({
                fieldName: key,
                fieldValue: value
            })),
        kycCountry: null,
        locale: null
    }

    return formData;
}

export const userFormData = (userLang: DefaultType.LocalData, email: string, apiLang: string): UserType.UserRequestModel => {
    /**
     * set the user language based on the user country and data
     * if country is USA then set the locale to en_US
     * if country is not the USA then check the apiLang 
     *      - apiLang is present it means set the default language selected by user before the KYC
     *      - apiLang is not present then set the language that selected by user during the KYC
     */
    const locale: string = userLang.countryCode === 'USA'
        ? userLang.language
        : apiLang
            ? apiLang
            : userLang.language;

    //construct form data
    const formData: UserType.UserRequestModel = {
        name: null,
        firstName: null,
        middleName: null,
        lastName: null,
        email: email,
        marketing: false,
        phone: null,
        language: null,
        taxIdNumber: null,
        citizenship: null,
        dob: null,
        politicallyExposedNames: null,
        irsBackupWithholdings: false,
        identifier: null,
        showKycStatus: false,
        usTaxPayer: null,
        userAddress: null,
        userEmployment: null,
        userInvestor: null,
        userDisclosure: null,
        gender: null,
        metadataList: [],
        kycCountry: userLang.countryCode,
        locale: locale
    }

    return formData;
}